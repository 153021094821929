import { AxiosResponse } from 'axios'
import i18next from 'i18next'
import { all, call, put, select, takeEvery } from 'redux-saga/effects'

import { CustomizationService } from '../../lib/api/CustomizationService'
import { getAuth0AccessToken } from '../auth0/selectors'
import { addNotificationAction } from '../notification/actions'
import { NotificationOptions } from '../notification/types'
import { getCurrentTeam } from '../team/selectors'
import { Team } from '../team/types'
import {
  UPLOAD_LOGO_IMAGE_REQUEST,
  uploadLogoImageFailure,
  UploadLogoImageRequestAction,
  uploadLogoImageSuccess,
} from './actions'

function* handleUploadLogoImage(action: UploadLogoImageRequestAction) {
  const { logoImageFile } = action.payload
  try {
    const accessToken: string = yield select(getAuth0AccessToken)
    const customizationService = new CustomizationService(accessToken)
    const currentTeam: Team | null = yield select(getCurrentTeam)

    if (!currentTeam) {
      throw new Error('No team in state, can not upload logo image')
    }

    const { data: logoImageUrl }: AxiosResponse<string> = yield call(() =>
      customizationService.uploadLogoImage(currentTeam.id, logoImageFile),
    )

    yield put(uploadLogoImageSuccess(logoImageUrl))
  } catch (error) {
    const notificationOptions: NotificationOptions = {
      title: i18next.t('customization.form.error.update.title'),
      message: i18next.t('customization.form.error.update.message'),
      level: 'error',
    }
    yield put(addNotificationAction(notificationOptions))
    error.message = `Could not update customization: ${error.message}`

    yield put(uploadLogoImageFailure(error.message))
  }
}

export function* logoImageSaga() {
  yield all([takeEvery(UPLOAD_LOGO_IMAGE_REQUEST, handleUploadLogoImage)])
}
