import { OptionGroup } from '@pluggyai/ui/dist/components/Dropdown/Dropdown.types'
import i18next from 'i18next'
import { Connector } from 'pluggy-js'

import { groupBy } from '../../../utils/array'
import { ConnectorFilterOption } from './ConnectorsFilter.types'

/**
 * Build initial list of connectors filter options.
 *
 * @param applications
 */
export function buildConnectorsFilterOptionGroups(
  connectors: Connector[] | null,
): OptionGroup[] {
  if (!connectors) {
    return []
  }

  const connectorGroups = groupBy(connectors, 'type').map(
    ({ name: connectorType, values: connectors_ }) => ({
      label: i18next.t(`dailyItems.connectors-filter.${connectorType}`),
      options: connectors_.map(({ id, name: connectorName }) => ({
        id,
        name: connectorName,
      })),
    }),
  )

  return [
    {
      // leaving group label empty to render the 'all connectors' option as a standalone option
      label: '',
      options: [getAllConnectorsFilterOption()],
    },
    ...connectorGroups,
  ]
}

/**
 * Retrieve 'all connectors' filter option data
 */
export function getAllConnectorsFilterOption(): ConnectorFilterOption {
  return {
    id: 'all',
    name:
      i18next.t('dailyItems.connectors-filter.all') || 'Todos os Conectores', // TODO: fix this so we don't have to hardcode the fallback
  }
}
