import { memo } from 'react'
import { useTranslation } from 'react-i18next'

import { formatRate } from '../../../../modules/stats/utils'
import { ItemExecutionResultType } from '../Report.types'

import './LegendItem.css'

const LegendItem = ({
  name,
  value,
  color,
  total,
}: Omit<ItemExecutionResultType, 'detail'> & { total?: number }) => {
  const { t } = useTranslation()

  return (
    <div className={'LegendItem'}>
      <div className={'circle'} style={{ backgroundColor: color }} />
      {t(`items-stats.legend.${name}`)}
      {total !== undefined ? (
        <label className="percentage">{formatRate(value / total)}</label>
      ) : (
        <label className="value">{value}</label>
      )}
    </div>
  )
}

export default memo(LegendItem)
