import React, { useCallback } from 'react'
import { useTranslation } from 'react-i18next'

import { Button } from '@pluggyai/ui'
import { Modal } from 'semantic-ui-react'

import { CloseIcon } from '../../../../Icon'
import { Props } from './NoOwnersLeftErrorModal.types'

import './NoOwnersLeftErrorModal.css'

const NoOwnersLeftErrorModal = ({ onClose, onGoBack }: Props) => {
  const { t } = useTranslation()

  const goBackButtonI18nKey =
    'settings.team.members.no-owners-left-modal.action'
  const goBackButtonText = t(goBackButtonI18nKey)

  const handleGoBackButtonClick = useCallback(() => {
    onGoBack({
      i18nKey: goBackButtonI18nKey,
      text: goBackButtonText,
    })
  }, [goBackButtonText, onGoBack])

  return (
    <Modal
      className={'NoOwnersLeftErrorModal'}
      onClose={onClose}
      open={true}
      closeIcon={<CloseIcon />}
    >
      <Modal.Header>
        {t('settings.team.members.no-owners-left-modal.header')}
      </Modal.Header>
      <Modal.Content>
        {t('settings.team.members.no-owners-left-modal.content')}
      </Modal.Content>
      <Modal.Actions>
        <Button primary onClick={handleGoBackButtonClick}>
          {goBackButtonText}
        </Button>
      </Modal.Actions>
    </Modal>
  )
}

export default React.memo(NoOwnersLeftErrorModal)
