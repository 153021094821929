import { memo } from 'react'

const FacilitateIcon = () => (
  <svg
    width="24"
    height="24"
    viewBox="0 0 40 40"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M15.8334 2.33331H30.8334L21.6667 14H34.1667L14.1667 35.6666L18.3334 19.8333H6.66675L15.8334 2.33331Z"
      stroke="#EBE8F1"
      fill="#271158"
      strokeWidth="3"
      strokeLinejoin="round"
    />
  </svg>
)

export default memo(FacilitateIcon)
