import { connect } from 'react-redux'

import { RootState } from '../../../../modules/reducer'
import {
  DELETE_USER_REQUEST,
  FETCH_USER_REQUEST,
} from '../../../../modules/user/actions'
import {
  getLoading,
  getUser,
  getUserError,
  isLoadingType,
} from '../../../../modules/user/selectors'
import UserDeleteWidget from './UserDeleteWidget'
import {
  MapDispatch,
  MapDispatchProps,
  MapStateProps,
} from './UserDeleteWidget.types'

const mapState = (state: RootState): MapStateProps => ({
  error: getUserError(state),
  isLoading:
    isLoadingType(getLoading(state), FETCH_USER_REQUEST) ||
    isLoadingType(getLoading(state), DELETE_USER_REQUEST),
  user: getUser(state),
})

const mapDispatch = (_dispatch: MapDispatch): MapDispatchProps => ({})

export default connect(mapState, mapDispatch)(UserDeleteWidget)
