import { connect } from 'react-redux'

import { RootState } from '../../../modules/reducer'
import { FETCH_ITEMS_STATS_REQUEST } from '../../../modules/stats/actions'
import {
  getItemsStatsReportItemStats,
  getItemStatsFilters,
  getLoading as isLoadingStats,
  isLoadingType,
} from '../../../modules/stats/selectors'
import { getTheme } from '../../../modules/user/selectors'
import Report from './Report'
import { MapDispatch, MapDispatchProps, MapStateProps } from './Report.types'

const mapState = (state: RootState): MapStateProps => ({
  isLoading: isLoadingType(isLoadingStats(state), FETCH_ITEMS_STATS_REQUEST),
  itemsStatsReport: getItemsStatsReportItemStats(state),
  startDate: getItemStatsFilters(state).startDate,
  endDate: getItemStatsFilters(state).endDate,
  theme: getTheme(state),
  currentItemsType: getItemStatsFilters(state).selectedExecutionStatusGroups,
})

const mapDispatch = (_dispatch: MapDispatch): MapDispatchProps => ({
  // nothing yet
})

export default connect(mapState, mapDispatch)(Report)
