import { loadingReducer } from '../loading/reducer'
import { LoadingState } from '../loading/types'
import {
  FETCH_ACTIVITY_FAILURE,
  FETCH_ACTIVITY_REQUEST,
  FETCH_ACTIVITY_SUCCESS,
  FetchActivityFailureAction,
  FetchActivityRequestAction,
  FetchActivitySuccessAction,
} from './actions'
import { ActivityItem } from './types'

export type ActivityState = {
  data: ActivityItem[] | null
  loading: LoadingState
  error: string | null
}

const INITIAL_STATE: ActivityState = {
  data: null,
  loading: [],
  error: null,
}

type ActivityReducerAction =
  | FetchActivityRequestAction
  | FetchActivitySuccessAction
  | FetchActivityFailureAction

export function activityReducer(
  state: ActivityState = INITIAL_STATE,
  action: ActivityReducerAction,
): ActivityState {
  switch (action.type) {
    case FETCH_ACTIVITY_REQUEST:
      return {
        ...state,
        loading: loadingReducer(state.loading, action),
      }
    case FETCH_ACTIVITY_SUCCESS:
      return {
        ...state,
        loading: loadingReducer(state.loading, action),
        error: null,
        data: action.payload.activityItems,
      }
    case FETCH_ACTIVITY_FAILURE:
      return {
        ...state,
        loading: loadingReducer(state.loading, action),
        error: action.payload.error,
      }
    default:
      return state
  }
}
