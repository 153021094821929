import { AnyAction } from 'redux'

import { LoadingState } from '../loading/types'
import { LogoImageState } from '../logoImage/reducer'
import { RootState } from '../reducer'

export const getState = (state: RootState): LogoImageState =>
  state.logoImage as LogoImageState
export const getData = (state: RootState) => getState(state).data
export const getLoading = (state: RootState) => getState(state).loading
export const getError = (state: RootState) => getState(state).error

export const isLoadingType = (state: LoadingState, type: string): boolean =>
  state.some((action: AnyAction) => action.type === type)

export const getTemporaryLogoImageUrl = (state: RootState) =>
  getData(state).temporaryImageLogoUrl
