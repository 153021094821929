import i18n from 'i18next'

import { TeamRole } from '../../../modules/team/types'

const teamRolesI18nKeyMap: Record<TeamRole, string> = {
  OWNER: 'team.teamRole.owner',
  ADMIN: 'team.teamRole.admin',
  MEMBER: 'team.teamRole.member',
}

export function teamRoleToString(teamRoleValue: TeamRole): string {
  const teamRoleFilterKey = teamRolesI18nKeyMap[teamRoleValue]
  return i18n.t(teamRoleFilterKey)
}
