import { all } from 'redux-saga/effects'

import { activitySaga } from './activity/sagas'
import { analyticsSaga } from './analytics/sagas'
import { applicationSaga } from './application/sagas'
import { auth0Saga } from './auth0/sagas'
import { billingSaga } from './billing/sagas'
import { connectTokenSaga } from './connectToken/sagas'
import { customizationSaga } from './customization/sagas'
import { goToProductionSaga } from './goToProduction/sagas'
import { itemSaga } from './item/sagas'
import { logoImageSaga } from './logoImage/sagas'
import { noCodeSaga } from './noCode/sagas'
import { notificationSaga } from './notification/sagas'
import { statsSaga } from './stats/sagas'
import { teamSaga } from './team/sagas'
import { userSaga } from './user/sagas'
import { webhookSaga } from './webhook/sagas'
import { webhookEventsSaga } from './webhookEvents/sagas'

export function* rootSaga() {
  yield all([
    activitySaga(),
    analyticsSaga(),
    applicationSaga(),
    auth0Saga(),
    billingSaga(),
    customizationSaga(),
    notificationSaga(),
    statsSaga(),
    teamSaga(),
    userSaga(),
    connectTokenSaga(),
    goToProductionSaga(),
    noCodeSaga(),
    webhookSaga(),
    webhookEventsSaga(),
    logoImageSaga(),
    itemSaga(),
  ])
}
