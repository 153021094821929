import { LOCATION_CHANGE, LocationChangeAction } from 'connected-react-router'
import { all, call, takeEvery } from 'redux-saga/effects'

import { updateHotjarState } from '../../lib/hotjar'
import { update as intercomUpdate } from '../../lib/intercom'
import { page } from './utils'

/**
 * Track page navigation
 */
function* handleLocationChange(action: LocationChangeAction) {
  const {
    payload: {
      location: { pathname },
    },
  } = action

  yield call(() => page())
  yield call(() => updateHotjarState(pathname))
  yield call(() => intercomUpdate())
}

export function* analyticsSaga() {
  yield all([takeEvery(LOCATION_CHANGE, handleLocationChange)])
}
