import React from 'react'
import { useTranslation } from 'react-i18next'

import { Header } from 'semantic-ui-react'

import { Props } from './ApplicationDangerZone.types'
import { ApplicationDeleteWidget } from './ApplicationDeleteWidget'
import { ApplicationDisableWidget } from './ApplicationDisableWidget'

import './ApplicationDangerZone.css'

const ApplicationDangerZone = ({ application }: Props) => {
  const { t } = useTranslation()
  return (
    <div className={'ApplicationDangerZone'}>
      <Header as={'h3'}>{t('application.page.danger.header')}</Header>
      <div className={'widgets-section'}>
        <ApplicationDisableWidget application={application} />
        <ApplicationDeleteWidget application={application} />
      </div>
    </div>
  )
}

export default React.memo(ApplicationDangerZone)
