import React from 'react'
import { useTranslation } from 'react-i18next'

import { TeamItem } from '../TeamItem'
import { Props } from './TeamsList.types'

import './TeamsList.css'

const TeamsList = ({ teams }: Props) => {
  const { t } = useTranslation()

  return (
    <div className={'TeamsList'}>
      {teams && (
        <>
          <h3>{t('user.team-list.header')}</h3>
          {teams.map((team) => (
            <TeamItem team={team} key={team.id} />
          ))}
        </>
      )}
    </div>
  )
}

export default React.memo(TeamsList)
