import React from 'react'

import Icon from '../Icon'

import './UnhideIcon.css'

type Props = {
  onClick?: () => void
  className?: string
}

const UnhideIcon = ({ onClick, className }: Props) => (
  <Icon
    className={`CopyIcon ${className || ''}`}
    onClick={onClick}
    width="20"
    height="20"
    viewBox="0 0 20 20"
    fill="none"
  >
    <g id="Icon/Visibility">
      <path
        id=" &#226;&#134;&#179;Color"
        fillRule="evenodd"
        clipRule="evenodd"
        d="M10 5C6.81818 5 4.10091 6.97909 3 9.77273C4.10091 12.5664 6.81818 14.5455 10 14.5455C13.1818 14.5455 15.8991 12.5664 17 9.77273C15.8991 6.97909 13.1818 5 10 5ZM10 12.9545C8.24364 12.9545 6.81818 11.5291 6.81818 9.77273C6.81818 8.01636 8.24364 6.59091 10 6.59091C11.7564 6.59091 13.1818 8.01636 13.1818 9.77273C13.1818 11.5291 11.7564 12.9545 10 12.9545ZM10 7.86364C8.94364 7.86364 8.09091 8.71636 8.09091 9.77273C8.09091 10.8291 8.94364 11.6818 10 11.6818C11.0564 11.6818 11.9091 10.8291 11.9091 9.77273C11.9091 8.71636 11.0564 7.86364 10 7.86364Z"
        fill="#111111"
      />
      <mask
        id="mask0_0_126"
        style={{ maskType: 'alpha' }}
        maskUnits="userSpaceOnUse"
        x="3"
        y="5"
        width="14"
        height="10"
      >
        <path
          id=" &#226;&#134;&#179;Color_2"
          fillRule="evenodd"
          clipRule="evenodd"
          d="M10 5C6.81818 5 4.10091 6.97909 3 9.77273C4.10091 12.5664 6.81818 14.5455 10 14.5455C13.1818 14.5455 15.8991 12.5664 17 9.77273C15.8991 6.97909 13.1818 5 10 5ZM10 12.9545C8.24364 12.9545 6.81818 11.5291 6.81818 9.77273C6.81818 8.01636 8.24364 6.59091 10 6.59091C11.7564 6.59091 13.1818 8.01636 13.1818 9.77273C13.1818 11.5291 11.7564 12.9545 10 12.9545ZM10 7.86364C8.94364 7.86364 8.09091 8.71636 8.09091 9.77273C8.09091 10.8291 8.94364 11.6818 10 11.6818C11.0564 11.6818 11.9091 10.8291 11.9091 9.77273C11.9091 8.71636 11.0564 7.86364 10 7.86364Z"
          fill="white"
        />
      </mask>
      <g mask="url(#mask0_0_126)"></g>
    </g>
  </Icon>
)

export default React.memo(UnhideIcon)
