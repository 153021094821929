import { connect } from 'react-redux'

import { RootState } from '../../../../../modules/reducer'
import {
  DELETE_TEAM_REQUEST,
  deleteTeamRequest,
} from '../../../../../modules/team/actions'
import { getError } from '../../../../../modules/team/selectors'
import { Team } from '../../../../../modules/team/types'
import {
  getLoading,
  isLoadingType,
} from '../../../../../modules/user/selectors'
import TeamDeleteConfirmModal from './TeamDeleteConfirmModal'
import {
  MapDispatch,
  MapDispatchProps,
  MapStateProps,
} from './TeamDeleteConfirmModal.types'

const mapState = (state: RootState): MapStateProps => ({
  error: getError(state),
  isLoading: isLoadingType(getLoading(state), DELETE_TEAM_REQUEST),
})

const mapDispatch = (dispatch: MapDispatch): MapDispatchProps => ({
  onDeleteTeam: (team: Team) => dispatch(deleteTeamRequest(team)),
})

export default connect(mapState, mapDispatch)(TeamDeleteConfirmModal)
