import { connect } from 'react-redux'

import { RootState } from '../../../../modules/reducer'
import {
  deleteUserRequest,
  DELETE_USER_REQUEST,
} from '../../../../modules/user/actions'
import {
  getError,
  getLoading,
  isLoadingType,
} from '../../../../modules/user/selectors'
import UserDeleteConfirmModal from './UserDeleteConfirmModal'
import {
  MapDispatch,
  MapDispatchProps,
  MapStateProps,
} from './UserDeleteConfirmModal.types'

const mapState = (state: RootState): MapStateProps => ({
  error: getError(state).user,
  isLoading: isLoadingType(getLoading(state), DELETE_USER_REQUEST),
})

const mapDispatch = (dispatch: MapDispatch): MapDispatchProps => ({
  onDeleteUser: () => dispatch(deleteUserRequest()),
})

export default connect(mapState, mapDispatch)(UserDeleteConfirmModal)
