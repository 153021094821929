import { getUnixTime } from 'date-fns'

import { mapSubscriptionToPlanName, Team } from '../modules/team/types'
import { getUserTeamRole } from '../modules/team/utils'
import { User } from '../modules/user/types'

type IntercomCompany = Intercom_.IntercomCompany
type IntercomSettings = Intercom_.IntercomSettings

const { REACT_APP_INTERCOM_WIDGET_ID: intercomWidgetId, NODE_ENV } = process.env

const INTERCOM_API_BASE = 'https://api-iam.intercom.io'

if (NODE_ENV === 'production' && !intercomWidgetId) {
  console.warn('Intercom not enabled due to missing WIDGET_ID')
}

/**
 * Load Intercom.
 * This starts the Intercom Web plugin.
 */
export function loadIntercom(): void {
  if (!intercomWidgetId) {
    // not configured
    return
  }

  Intercom('boot', {
    api_base: INTERCOM_API_BASE,
    app_id: intercomWidgetId,
  })
}

/**
 * Load user data on Intercom messaging.
 * This should be called as soon as the user is logged in.
 *
 * @param id - user id
 * @param name - user full name
 * @param email - user email address
 * @param createdAt - user sign up date
 * @param imageUrl
 * @param companyRole
 */
export function identifyUserInIntercom({
  id,
  name,
  email,
  createdAt,
  imageUrl,
  companyRole,
}: Pick<
  User,
  'id' | 'name' | 'email' | 'createdAt' | 'imageUrl' | 'companyRole'
>): void {
  if (!intercomWidgetId) {
    // not configured
    return
  }

  const unixTime = getUnixTime(new Date(createdAt))
  const intercomUserData: IntercomSettings = {
    api_base: INTERCOM_API_BASE,
    app_id: intercomWidgetId,
    user_id: id,
    name,
    email,
    created_at: unixTime,
    // custom fields
    user_company_role: companyRole,
  }

  if (imageUrl) {
    intercomUserData.avatar = {
      type: 'avatar',
      image_url: imageUrl,
    }
  }

  Intercom('update', intercomUserData)
}

function mapTeamToIntercomCompany(team: Team): IntercomCompany {
  const { createdAt, subscription, id, name, members } = team

  return {
    name,
    id,
    created_at: getUnixTime(new Date(createdAt)),
    user_count: members.length,
    plan: mapSubscriptionToPlanName(subscription),
  }
}

/**
 * Reload Intercom data by calling 'update' method on it.
 * According to the docs: this should be called whenever the view or URL changes in the app,
 * and will allow people to receive your most recent messages.
 */
export function update(): void {
  if (!intercomWidgetId) {
    // not configured
    return
  }

  Intercom('update')
}

/**
 * Helper to open Intercom chat, optionally with a preset message.
 *
 * @param {string} presetMessage
 */
export function showIntercom(presetMessage?: string): void {
  if (!intercomWidgetId) {
    // not configured
    return
  }

  Intercom('showNewMessage', presetMessage)
}

/**
 * Helper to track all user teams as 'companies' data in Intercom
 *
 * @param userTeams
 */
export function trackUserTeamsInIntercom(userTeams: Team[]): void {
  if (!intercomWidgetId) {
    // not configured
    return
  }

  // refresh intercom messaging & attach user teams as 'companies' data
  const intercomSettings: IntercomSettings = {
    companies: userTeams.map(mapTeamToIntercomCompany),
  }

  Intercom('update', intercomSettings)
}

/**
 * Helper to track the currently selected team as 'company' data in Intercom
 *
 * @param currentTeam
 * @param user
 */
export function trackUserCurrentTeamInIntercom(
  currentTeam: Team,
  user: User,
): void {
  if (!intercomWidgetId) {
    // not configured
    return
  }

  // refresh intercom messaging & attach current team as 'company' data
  const intercomSettings: IntercomSettings = {
    company: mapTeamToIntercomCompany(currentTeam),
    team_member_role: getUserTeamRole(currentTeam, user),
  }

  Intercom('update', intercomSettings)
}

/**
 * Clear user conversations, should be called on log out
 */
export function shutdown(): void {
  if (!intercomWidgetId) {
    // not configured
    return
  }

  Intercom('shutdown')
}
