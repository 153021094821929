import React, { useEffect, useState } from 'react'

import { Button } from '@pluggyai/ui'

import { signOut } from '../../lib/auth0'
import { PluggyLogo } from './PluggyLogo'

import './LoadingPage.css'

const _15_SECONDS_MS = 15_000

const _6_SECONDS_MS = 6_000

const LoadingPage = () => {
  const [timedOut, setTimedOut] = useState(false)
  useEffect(() => {
    // Set timer to log out as a workaround in case the app takes too long to
    // move on, or if it got stuck.
    // This could be the case if the login or logout flows didn't finish correctly, and
    // the stored data/cookies/state got corrupted.
    const logoutTimeout = window.setTimeout(() => {
      signOut()
    }, _15_SECONDS_MS)

    const signOutButton = window.setTimeout(() => {
      setTimedOut(true)
    }, _6_SECONDS_MS)

    return () => {
      clearTimeout(logoutTimeout)
      clearTimeout(signOutButton)
    }
  }, [])

  return (
    <div className={'LoadingPage'}>
      <PluggyLogo />
      <div className={'progress-bar'} />
      {timedOut && (
        <Button
          secondary
          onClick={() => signOut()}
          size={'small'}
          className={'retry-button'}
        >
          Tentar novamente
        </Button>
      )}
    </div>
  )
}

export default React.memo(LoadingPage)
