import React, { useCallback } from 'react'
import { useTranslation } from 'react-i18next'

import { Button, DropdownOption, Input } from '@pluggyai/ui'

import {
  MemberInvitation,
  TEAM_ROLES,
  TeamFields,
  TeamRole,
} from '../../../modules/team/types'
import DeleteIcon from '../../Icon/DeleteIcon/DeleteIcon'
import { RoleOption } from '../../SettingsPage/TeamSettingsPage/TeamMembersSection/TeamMembersSection.types'
import { teamRoleToString } from './utils'

import './TeamMembersEmailsInput.css'

type Props = {
  userTeamMemberRole: TeamRole | null
  onFieldChange: (
    fieldName: keyof TeamFields,
    value: MemberInvitation[],
  ) => void
  value: MemberInvitation[]
  isEdit: boolean
  errors?: string[]
  isSubmitting: boolean
}

const emptyDefaultMemberInvite = (): MemberInvitation => ({
  email: '',
  role: 'MEMBER',
})

const TeamMembersEmailsInput = ({
  userTeamMemberRole,
  onFieldChange,
  value: currentMembersInvitations,
  isEdit = false,
  errors,
  isSubmitting,
}: Props) => {
  const { t } = useTranslation()

  const onValueChange = useCallback(
    (value: MemberInvitation[]) => {
      onFieldChange('members', value)
    },
    [onFieldChange],
  )

  const handleOnAddNewEmailInput = useCallback(
    (event: React.MouseEvent<HTMLButtonElement>) => {
      // preventing default & propagation to prevent parent form unwanted 'submit' event
      event.preventDefault()
      event.stopPropagation()
      const newMembersInvitations = [
        ...currentMembersInvitations,
        emptyDefaultMemberInvite(),
      ]
      onValueChange(newMembersInvitations)
    },
    [currentMembersInvitations, onValueChange],
  )

  const onValueEmailChange = useCallback(
    (emailValue: string, index: number) => {
      currentMembersInvitations[index].email = emailValue.trim()
      onValueChange([...currentMembersInvitations])
    },
    [onValueChange, currentMembersInvitations],
  )

  const onValueRoleChange = useCallback(
    (roleValue: TeamRole, index: number) => {
      currentMembersInvitations[index].role = roleValue
      onValueChange([...currentMembersInvitations])
    },
    [onValueChange, currentMembersInvitations],
  )

  const onMemberInvitationRemove = useCallback(
    (index: number) => {
      let newCurrentMembersInvitations: MemberInvitation[]
      if (currentMembersInvitations.length === 1) {
        // only 1 available input, don't delete it, just clear it
        currentMembersInvitations[0] = emptyDefaultMemberInvite()
        newCurrentMembersInvitations = [...currentMembersInvitations]
      } else {
        // more than one input available, remove it
        newCurrentMembersInvitations = [
          ...currentMembersInvitations.filter((_, i) => i !== index),
        ]
      }
      onValueChange(newCurrentMembersInvitations)
    },
    [onValueChange, currentMembersInvitations],
  )

  const handleTeamRoleChange = useCallback(
    (value: DropdownOption | DropdownOption[], memberIndex: number): void => {
      const { id: selectedRole } = value as RoleOption

      onValueRoleChange(selectedRole, memberIndex)
    },
    [onValueRoleChange],
  )

  const teamRolesOptions = TEAM_ROLES.map((roleValue) => ({
    id: roleValue,
    isDisabled:
      // disable, when editing Team (adding members)...
      isEdit &&
      // and I'm not OWNER - I can't invite other OWNERs
      ((roleValue === 'OWNER' &&
        userTeamMemberRole !== null &&
        userTeamMemberRole !== 'OWNER') ||
        // or, if I'm not ADMIN - I can't invite other ADMINs
        (roleValue === 'ADMIN' &&
          userTeamMemberRole !== null &&
          userTeamMemberRole !== 'OWNER' &&
          userTeamMemberRole !== 'ADMIN')),
    name: teamRoleToString(roleValue),
  }))

  return (
    <div className={'TeamMembersEmailsInput'}>
      <div className={'team-members-emails-header'}>
        <span>{t('team.form.field.members.header')}</span>
        <Button
          link
          onClick={handleOnAddNewEmailInput}
          disabled={isSubmitting}
          type="button"
        >
          {t('team.form.field.members.action.new')}
        </Button>
      </div>
      {currentMembersInvitations.map(({ email, role }, i) => {
        const currentTeamRole = teamRolesOptions.find(({ id }) => id === role)

        return (
          <div key={`emails-${i}`} className={'add-item-row'}>
            <Input
              label={t('team.form.field.members.field.email.label')}
              type="text"
              onChange={({ value }) => {
                onValueEmailChange(value, i)
              }}
              value={email}
              error={errors?.[i]}
              disabled={isSubmitting}
            />
            <div className="bottom-section">
              <Input
                label={t('team.form.field.members.field.role.label')}
                className={'team-role-input'}
                type="select"
                disabled={isSubmitting}
                options={teamRolesOptions}
                value={currentTeamRole}
                onChange={({
                  value,
                }: {
                  value: DropdownOption | DropdownOption[]
                }) => handleTeamRoleChange(value, i)}
              />

              <DeleteIcon
                disabled={isSubmitting}
                onClick={() => onMemberInvitationRemove(i)}
              />
            </div>
          </div>
        )
      })}
    </div>
  )
}

export default React.memo(TeamMembersEmailsInput)
