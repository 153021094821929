import React from 'react'

import { Loader } from 'semantic-ui-react'

import { Page } from '../Page'
import { Props } from './SettingsPage.types'
import { TeamSettingsPage } from './TeamSettingsPage'

import './SettingsPage.css'

const SettingsPage = ({ currentTeam, user, isLoading }: Props) => {
  if (!currentTeam && !user) {
    return null
  }

  return (
    <Page className={'SettingsPage Team'}>
      {isLoading ? (
        <div className={'widget-section-loader'}>
          <Loader size="big" active />
        </div>
      ) : (
        <TeamSettingsPage />
      )}
    </Page>
  )
}

export default React.memo(SettingsPage)
