import React, { useCallback, useEffect, useRef, useState } from 'react'
import { useTranslation } from 'react-i18next'

import { Alert } from '@pluggyai/ui'
import { Header } from 'semantic-ui-react'

import { TrackEventName } from '../../../modules/analytics/events'
import { track } from '../../../modules/analytics/utils'
import {
  ApplicationFields,
  ApplicationFieldsErrors,
} from '../../../modules/application/types'
import { isStringsArray } from '../../../utils/validation'
import { ApplicationForm, validateField } from '../../ApplicationForm'
import { ApplicationClientKeys } from '../ApplicationClientKeys'
import { ApplicationHeader } from '../ApplicationHeader'
import { Props } from './ApplicationDetails.types'
import { ApplicationWebhooks } from './ApplicationWebhooks'

import './ApplicationDetails.css'

const FORM_AUTO_SUBMIT_TIMEOUT_MS = 1000 // 1 second

const ApplicationDetails = ({
  application,
  isFeaturesLimited,
  isLoading,
  error,
  onFetchApplication,
  onUpdateApplication,
}: Props) => {
  const { t } = useTranslation()

  const {
    id: applicationId,
    name,
    shortDescription,
    allowedOrigins,
    environment,
  } = application

  const trackFormValidationError = useCallback(
    (errors: ApplicationFieldsErrors) => {
      track(TrackEventName.FORM_VALIDATION_ERRORS, {
        fields: Object.keys(errors),
        applicationId,
        location: 'ApplicationsPage ApplicationDetails',
      })
    },
    [applicationId],
  )

  const trackUpdateSubmit = useCallback(() => {
    track(TrackEventName.APPLICATION_DETAILS_UPDATE_SUBMIT, {
      applicationId,
    })
  }, [applicationId])

  const trackAddNewAllowedUrlInput = useCallback(() => {
    track(TrackEventName.LINK_APPLICATION_ADD_DOMAIN, {
      applicationId,
      location: 'ApplicationsPage ApplicationDetails',
    })
  }, [applicationId])

  const trackRemoveAllowedUrlInput = useCallback(() => {
    track(TrackEventName.ICON_APPLICATION_REMOVE_DOMAIN, {
      applicationId,
      location: 'ApplicationsPage ApplicationDetails',
    })
  }, [applicationId])

  const trackCopyClientIdClick = useCallback(() => {
    track(TrackEventName.ICON_APPLICATION_CLIENT_ID_COPY, {
      applicationId,
      location: 'ApplicationsPage ApplicationDetails',
    })
  }, [applicationId])

  const trackCopyClientSecretClick = useCallback(() => {
    track(TrackEventName.ICON_APPLICATION_CLIENT_SECRET_COPY, {
      applicationId,
      location: 'ApplicationsPage ApplicationDetails',
    })
  }, [applicationId])

  const trackShowClientSecretClick = useCallback(() => {
    track(TrackEventName.ICON_APPLICATION_CLIENT_SECRET_SHOW, {
      applicationId,
      location: 'ApplicationsPage ApplicationDetails',
    })
  }, [applicationId])

  const handleOnGoToDemoApplicationClick = useCallback(() => {
    track(TrackEventName.CTA_APPLICATION_GO_TO_DEMO, {
      applicationId,
      application,
      location: 'ApplicationsPage ApplicationDetails',
    })
  }, [application, applicationId])

  const handleShowApplicationClientSecretClick = useCallback(() => {
    onFetchApplication(applicationId)
    trackShowClientSecretClick()
  }, [onFetchApplication, applicationId, trackShowClientSecretClick])

  const [applicationValues, setApplicationValues] = useState<ApplicationFields>(
    {
      name,
      shortDescription: shortDescription || '',
      allowedOrigins:
        allowedOrigins && allowedOrigins.length > 0 ? allowedOrigins : [''],
    },
  )

  const [formErrors, setFormErrors] = useState<ApplicationFieldsErrors>({})
  const [isSubmitting, setIsSubmitting] = useState(false)

  const handleApplicationUpdate = useCallback(
    (applicationValues_: ApplicationFields) => {
      if (isFeaturesLimited) {
        // action disabled, do nothing
        return
      }

      setIsSubmitting(true)
      trackUpdateSubmit()

      // validate fields values
      const currentFormErrors: ApplicationFieldsErrors = {}
      let errorsCount = 0
      for (const [field, value] of Object.entries(applicationValues_)) {
        let fieldError: string | string[] | undefined
        if (isStringsArray(value)) {
          const valueJoined = value.join(',')
          const errorsJoined = validateField(
            field as keyof ApplicationFields,
            valueJoined,
          )
          const fieldErrors = errorsJoined?.split(',')
          if (fieldErrors?.some((fieldError_) => fieldError_.length > 0)) {
            errorsCount++
          }

          fieldError = fieldErrors
        } else {
          fieldError = validateField(
            field as keyof ApplicationFields,
            value as string,
          )

          if (fieldError) {
            errorsCount++
          }
        }

        if (fieldError) {
          // using 'as any' here as a hack to support both 'string' or 'string[]'
          // fieldError values.
          // TODO rework/improve this, maybe using separated validations per field
          currentFormErrors[field as keyof ApplicationFields] =
            fieldError as any
        }
      }

      setFormErrors(currentFormErrors)
      if (errorsCount > 0) {
        // got errors, can't proceed
        trackFormValidationError(currentFormErrors)
        return
      }
      // we pass the only input changed
      onUpdateApplication(applicationId, applicationValues_)
    },
    [
      applicationId,
      isFeaturesLimited,
      onUpdateApplication,
      trackUpdateSubmit,
      trackFormValidationError,
    ],
  )

  const submitTimeout = useRef<number>()

  const handleSubmit = useCallback(
    (event: React.FormEvent) => {
      event.preventDefault()
      if (submitTimeout.current) {
        clearTimeout(submitTimeout.current)
      }
      handleApplicationUpdate({ ...applicationValues })
    },
    [handleApplicationUpdate, applicationValues],
  )

  const handleValuesChange = useCallback(
    (fieldName: keyof ApplicationFields, value: string | string[]) => {
      if (formErrors[fieldName]) {
        delete formErrors[fieldName]
        setFormErrors({ ...formErrors })
      }

      const newApplicationValues = { ...applicationValues, [fieldName]: value }
      setApplicationValues(newApplicationValues)

      // set timer to auto-submit
      // if it's submitting -> clear timer
      if (submitTimeout.current) {
        clearTimeout(submitTimeout.current)
      }
      submitTimeout.current = window.setTimeout(() => {
        handleApplicationUpdate(newApplicationValues)
      }, FORM_AUTO_SUBMIT_TIMEOUT_MS)
    },
    [applicationValues, formErrors, handleApplicationUpdate],
  )

  useEffect(() => {
    if (isSubmitting && !isLoading) {
      setIsSubmitting(false)
    }
  }, [isSubmitting, isLoading])

  const isDemoApplication = environment === 'DEMO'
  const isDevelopmentApplication = environment === 'DEVELOPMENT'

  return (
    <div className={'ApplicationDetails'}>
      {error && <Alert size={'medium'} type={'error'} message={error} />}
      <ApplicationHeader
        hasDemoPreviewLink={isDemoApplication || isDevelopmentApplication}
        application={application}
        onGoToDemoApplicationClick={handleOnGoToDemoApplicationClick}
      />
      <Header as={'h3'}>{t('application.page.details.header.general')}</Header>

      <ApplicationForm
        onSubmit={handleSubmit}
        onFieldChange={handleValuesChange}
        values={applicationValues}
        errors={formErrors}
        isSubmitting={isSubmitting}
        isEditAllowed={!isFeaturesLimited}
        onAddNewAllowedUrlInput={trackAddNewAllowedUrlInput}
        onRemoveAllowedUrlInput={trackRemoveAllowedUrlInput}
        isDemoApplication={isDemoApplication}
        isEdit
      />

      <Header as={'h3'}>
        {t('application.page.details.header.credentials')}
      </Header>
      <ApplicationClientKeys
        isLoading={isLoading}
        application={application}
        onShowClientSecretClick={handleShowApplicationClientSecretClick}
        onCopyClientIdClick={trackCopyClientIdClick}
        onCopyClientSecretClick={trackCopyClientSecretClick}
      />
      <ApplicationWebhooks application={application} />
    </div>
  )
}

export default React.memo(ApplicationDetails)
