import React, { useCallback } from 'react'
import { Trans } from 'react-i18next'

import { Alert, Button } from '@pluggyai/ui'

import { openHubspotChat } from '../../../lib/hubspot'
import { TrackEventName } from '../../../modules/analytics/events'
import { track } from '../../../modules/analytics/utils'
import { Props } from './DevelopmentItemUsageAlert.types'

const DEVELOPMENT_APPLICATION_MAXIMUM_ITEMS_threshold = 100

const DevelopmentItemUsageAlert = ({
  applicationItemsCount,
  teamCanGoToProduction,
}: Props) => {
  const messageI18nKey =
    applicationItemsCount !== null &&
    // TODO this should be itemsCountExceptSandbox < (application.subscription.itemsLimit || items_limit_default) ...
    //  https://pluggy.atlassian.net/browse/RAM-235
    applicationItemsCount < DEVELOPMENT_APPLICATION_MAXIMUM_ITEMS_threshold
      ? 'application.page.development.alert.reaching-limit'
      : teamCanGoToProduction
      ? // TODO this doesn't make much sense, right now with production:true we don't apply any limit.
        //  so we don't show any message. Should we instead raise the limit to something like ~500
        //  in this scenario and then apply it again?
        //  https://pluggy.atlassian.net/browse/RAM-235
        'application.page.development.alert.exceeded-limit.team-can-go-to-production'
      : 'application.page.development.alert.exceeded-limit.team-free'

  const handleContactUsLinkClick = useCallback(
    (event: React.MouseEvent<HTMLButtonElement>) => {
      openHubspotChat('application.page.development.alert')

      track(TrackEventName.LINK_CLICKED, {
        location: 'ApplicationPage Alert',
        i18nKey: messageI18nKey,
        text: (event.target as HTMLButtonElement).textContent,
      })
    },
    [messageI18nKey],
  )
  return (
    <Alert
      type={'info'}
      message={
        <Trans
          i18nKey={messageI18nKey}
          components={{
            a: <Button link onClick={handleContactUsLinkClick} />,
          }}
        />
      }
      size={'medium'}
    />
  )
}

export default React.memo(DevelopmentItemUsageAlert)
