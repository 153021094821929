import { connect } from 'react-redux'

import { RootState } from '../../modules/reducer'
import { getCurrentTeam } from '../../modules/team/selectors'
import { getUser, isUserLoading } from '../../modules/user/selectors'
import SettingsPage from './SettingsPage'
import {
  MapDispatch,
  MapDispatchProps,
  MapStateProps,
} from './SettingsPage.types'

const mapState = (state: RootState): MapStateProps => ({
  user: getUser(state),
  currentTeam: getCurrentTeam(state),
  isLoading: isUserLoading(state),
})

const mapDispatch = (_dispatch: MapDispatch): MapDispatchProps => ({
  // nothing here yet
})

export default connect(mapState, mapDispatch)(SettingsPage)
