import React from 'react'

import { Image, Placeholder } from 'semantic-ui-react'

import { Props } from './Avatar.types'

const Avatar = ({ pictureUrl, className, isLoading = false }: Props) =>
  pictureUrl !== undefined && pictureUrl !== null ? (
    <Image
      avatar
      inline
      src={pictureUrl}
      alt="Profile"
      className={`oval has-picture ${className !== undefined ? className : ''}`}
      width="50"
    />
  ) : isLoading ? (
    <Placeholder className={`oval ${className !== undefined ? className : ''}`}>
      <Placeholder.Image />
    </Placeholder>
  ) : (
    <div
      className={`oval ui avatar inline image ${
        className !== undefined ? className : ''
      }`}
    />
  )

export default React.memo(Avatar)
