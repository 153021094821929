import { memo } from 'react'
import { useTranslation } from 'react-i18next'

import { CreateApplicationButton } from '../../CreateApplicationButton'
import { CreateWebhookButton } from '../../CreateWebhookButton'
import { Props } from './NoWebhooksCreatedSection.types'

import './NoWebhooksCreatedSection.css'

const NoWebhooksCreatedSection = ({ applications }: Props) => {
  const { t } = useTranslation()

  const teamHasApplications = applications?.some(({ environment }) =>
    ['DEVELOPMENT', 'PRODUCTION'].includes(environment),
  )

  return (
    <div className={'NoWebhooksCreatedSection'}>
      <h3 className={'title'}>
        {t('webhook-events.page.no-webhooks-created-section.title')}
      </h3>
      <p className={'description'}>
        {t('webhook-events.page.no-webhooks-created-section.description')}
      </p>
      {teamHasApplications ? (
        <CreateWebhookButton className={'set-up'} size={'small'}>
          {t('webhook-events.page.no-webhooks-created-section.action.set-up')}
        </CreateWebhookButton>
      ) : (
        <CreateApplicationButton
          webhookSetup
          className={'set-up'}
          size={'small'}
        >
          {t('webhook-events.page.no-webhooks-created-section.action.set-up')}
        </CreateApplicationButton>
      )}
    </div>
  )
}

export default memo(NoWebhooksCreatedSection)
