import React, { useCallback, useEffect } from 'react'
import { Trans, useTranslation } from 'react-i18next'

import { Button } from '@pluggyai/ui'

import {
  PLUGGY_DOCS_AUTHENTICATION_URL,
  PLUGGY_DOCS_URL,
} from '../../lib/constants/urls'
import { openHubspotChat } from '../../lib/hubspot'
import { TrackEventName } from '../../modules/analytics/events'
import { track } from '../../modules/analytics/utils'
import { Application } from '../../modules/application/types'
import { CreateApplicationButton } from '../CreateApplicationButton'
import { GoToProductionButton } from '../GoToProductionButton'
import { ListSection } from '../ListSection'
import { ApplicationEmptyPlaceholder } from './ApplicationEmptyPlaceholder'
import { ApplicationItem } from './ApplicationItem'
import { Props } from './ApplicationsList.types'
import {
  applicationsByCreatedAtDescendingOrder,
  applicationsByEnvironment,
  applicationsByItemsCountDescendingOrder,
} from './utils'

import './ApplicationsList.css'

const ApplicationsList = ({
  error,
  applications,
  itemsStatsByApplicationId,
  isLoadingApplications,
  isLoadingApplication,
  onFetchApplication,
  onFetchItemStats,
  onGoToApplicationPage,
  onNewApplicationModalOpen,
  onGoToDemoApplicationClick,
  onGoToNoCodeDashboardApplicationClick,
  onCopyClientIdClick,
  onCopyClientSecretClick,
  onShowClientSecretClick,
}: Props) => {
  const { t } = useTranslation()

  const handleShowClientSecretClick = useCallback(
    (application: Application) => {
      onFetchApplication(application.id)
      onShowClientSecretClick(application)
    },
    [onFetchApplication, onShowClientSecretClick],
  )

  useEffect(() => {
    if (!applications) {
      return
    }
    // TODO: this could cause a synchronization issue caused by
    //  two requests responding at the same time. See if we can
    //  improve this by making an action merge all requests in one
    //  to avoid overlapping in the itemStatsByApplicationId object.
    //  tkt: https://pluggy.atlassian.net/browse/DVP-245
    for (const { id } of applications) {
      onFetchItemStats({ applicationId: id, sandbox: false })
    }
  }, [applications, onFetchItemStats])

  const demoApplications = applications?.filter(
    (application_) => application_.environment === 'DEMO',
  )

  const applicationsExceptDemo = applications?.filter(
    (application_) => application_.environment !== 'DEMO',
  )

  // sort applications most used first, then newest first
  // TODO: note, due to the current way we fetch itemsCount stats this could cause UI items
  //  be re-ordered on the fly (see tkt: https://pluggy.atlassian.net/browse/DVP-245)
  const applicationsFilteredSorted = [...(applicationsExceptDemo || [])]
  if (itemsStatsByApplicationId) {
    applicationsFilteredSorted.sort(
      applicationsByItemsCountDescendingOrder(itemsStatsByApplicationId),
    )
  }
  applicationsFilteredSorted.sort(applicationsByCreatedAtDescendingOrder())
  applicationsFilteredSorted.sort(applicationsByEnvironment())

  const createApplicationButtonI18n =
    applicationsFilteredSorted.length > 0
      ? 'applications.action.create-more'
      : 'applications.action.create-first'

  const createApplicationButtonText = t(createApplicationButtonI18n)

  const handleNewApplicationModalButtonClick = useCallback(() => {
    onNewApplicationModalOpen(
      createApplicationButtonText,
      createApplicationButtonI18n,
    )
  }, [
    createApplicationButtonI18n,
    createApplicationButtonText,
    onNewApplicationModalOpen,
  ])

  const handleApplicationsHelpContactLinkClick = useCallback(
    (event: React.MouseEvent) => {
      openHubspotChat('applications.help')

      track(TrackEventName.LINK_CLICKED, {
        location: 'ApplicationsPage ApplicationsList help-message',
        text: (event.target as HTMLDivElement).textContent,
      })
    },
    [],
  )

  const handleApplicationsHelpDocumentationLinkClick = useCallback(
    (event: React.MouseEvent<HTMLAnchorElement>) => {
      track(TrackEventName.LINK_CLICKED, {
        text: (event.target as HTMLDivElement).textContent,
        linkTo: PLUGGY_DOCS_URL,
        location: 'ApplicationsPage ApplicationsList help-message',
      })
    },
    [],
  )

  return (
    <div className={'ApplicationsList'}>
      {demoApplications?.map((application) => (
        <ApplicationItem
          key={application.id}
          isLoading={isLoadingApplication(application)}
          application={application}
          hasDemoPreviewLink={true}
          onGoToDemoApplicationClick={onGoToDemoApplicationClick}
          onGoToNoCodeDashboardApplicationClick={
            onGoToNoCodeDashboardApplicationClick
          }
        />
      ))}
      <ListSection
        data={applicationsFilteredSorted}
        isLoading={
          isLoadingApplications && applicationsFilteredSorted.length === 0
        }
        title={t('applications.title')}
        description={
          <div className={'help-message'}>
            <Trans
              i18nKey={'applications.help.message'}
              components={{
                a1: (
                  // we don't need content inside the anchor because we are
                  // interpolating the element
                  // eslint-disable-next-line jsx-a11y/anchor-has-content
                  <a
                    href={PLUGGY_DOCS_AUTHENTICATION_URL}
                    target="_blank"
                    rel="noopener noreferrer"
                    onClick={handleApplicationsHelpDocumentationLinkClick}
                  />
                ),
                a2: (
                  <Button
                    link
                    onClick={handleApplicationsHelpContactLinkClick}
                  />
                ),
              }}
            />
          </div>
        }
        error={error}
        renderElement={(application: Application, index: number) => (
          <ApplicationItem
            key={index}
            isLoading={isLoadingApplication(application)}
            application={application}
            hasDemoPreviewLink={application.environment === 'DEMO'}
            onShowClientSecretClick={handleShowClientSecretClick}
            onCopyClientIdClick={onCopyClientIdClick}
            onCopyClientSecretClick={onCopyClientSecretClick}
            onGoToApplicationPage={onGoToApplicationPage}
            onGoToDemoApplicationClick={onGoToDemoApplicationClick}
            onGoToNoCodeDashboardApplicationClick={
              onGoToNoCodeDashboardApplicationClick
            }
          />
        )}
        EmptyPlaceholder={
          <ApplicationEmptyPlaceholder
            onNewApplicationModalOpen={handleNewApplicationModalButtonClick}
          />
        }
        NewComponentButton={
          <CreateApplicationButton
            floated={'right'}
            onModalOpen={handleNewApplicationModalButtonClick}
          >
            {createApplicationButtonText}
          </CreateApplicationButton>
        }
        SecondaryButton={
          <GoToProductionButton onModalOpen={onNewApplicationModalOpen} />
        }
      />
    </div>
  )
}
export default React.memo(ApplicationsList)
