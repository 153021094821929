import { AxiosResponse } from 'axios'
import { all, call, put, select, takeEvery } from 'redux-saga/effects'

import { EventsService as WebhookEventsService } from '../../lib/api/EventsService'
import { getAuth0AccessToken } from '../auth0/selectors'
import { getCurrentTeam } from '../team/selectors'
import { Team } from '../team/types'
import {
  CHANGE_WEBHOOK_EVENTS_FILTERS,
  ChangeWebhookEventsFiltersAction,
  FETCH_WEBHOOK_EVENTS_REQUEST,
  fetchWebhookEventsFailure,
  fetchWebhookEventsRequest,
  FetchWebhookEventsRequestAction,
  fetchWebhookEventsSuccess,
} from './actions'
import { DEFAULT_WEBHOOK_EVENTS_FILTERS } from './reducer'
import { getWebhookEventsFilters } from './selectors'
import { WebhookEventsFilters, WebhookEventsResponse } from './types'

const PLUGGY_PAGINATION_FIRST_ELEMENT_INDEX = 1

function* handleFetchWebhookEventsRequest(
  action: FetchWebhookEventsRequestAction,
) {
  const {
    payload: {
      request: { createdAtEndDate, createdAtStartDate, event, page, search },
    },
  } = action

  try {
    const accessToken: string = yield select(getAuth0AccessToken)

    if (!accessToken) {
      throw new Error('No access token in state, can not fetch stats')
    }

    const webhookEventsService: WebhookEventsService = new WebhookEventsService(
      accessToken,
    )

    const currentTeam: Team | null = yield select(getCurrentTeam)

    if (!currentTeam) {
      throw new Error('No current team in state, can not fetch webhook events')
    }

    const { data }: AxiosResponse<WebhookEventsResponse> = yield call(() =>
      webhookEventsService.getWebhookEvents({
        teamId: currentTeam.id,
        createdAtEndDate: createdAtEndDate?.toISOString(),
        createdAtStartDate: createdAtStartDate.toISOString(),
        event: event === 'all' ? undefined : event,
        page,
        search,
      }),
    )

    const { results } = data

    yield put(
      fetchWebhookEventsSuccess({
        ...data,
        results: results.map((result) => ({
          ...result,
          createdAt: new Date(result.createdAt),
          completedAt: result.completedAt ? new Date(result.completedAt) : null,
        })),
      }),
    )
  } catch (error) {
    yield put(fetchWebhookEventsFailure("Can't fetch webhook events"))
  }
}

function* handleWebhookEventsFiltersChange(
  action: ChangeWebhookEventsFiltersAction,
) {
  const { request } = action.payload

  const previousStatsFilters: WebhookEventsFilters = yield select(
    getWebhookEventsFilters,
  )

  // when changing filters, set page value to 1 (first page) when it's
  // not being set in the new filters request object
  const currentPage: number =
    request.page || PLUGGY_PAGINATION_FIRST_ELEMENT_INDEX

  const newWebhookEventsFilters = {
    ...DEFAULT_WEBHOOK_EVENTS_FILTERS,
    ...previousStatsFilters,
    ...request,
    page: currentPage,
  }

  yield put(fetchWebhookEventsRequest(newWebhookEventsFilters))
}

export function* webhookEventsSaga() {
  yield all([
    takeEvery(FETCH_WEBHOOK_EVENTS_REQUEST, handleFetchWebhookEventsRequest),
    takeEvery(CHANGE_WEBHOOK_EVENTS_FILTERS, handleWebhookEventsFiltersChange),
  ])
}
