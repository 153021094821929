import { loadingReducer } from '../loading/reducer'
import { LoadingState } from '../loading/types'
import {
  SUBMIT_NO_CODE_FAILURE,
  SUBMIT_NO_CODE_REQUEST,
  SUBMIT_NO_CODE_SUCCESS,
  SubmitNoCodeFailureAction,
  SubmitNoCodeRequestAction,
  SubmitNoCodeSuccessAction,
} from './actions'

export type NoCodeState = {
  data: string[]
  loading: LoadingState
  error: string | null
}

const INITIAL_STATE: NoCodeState = {
  data: [],
  loading: [],
  error: null,
}

type NoCodeReducerAction =
  | SubmitNoCodeRequestAction
  | SubmitNoCodeSuccessAction
  | SubmitNoCodeFailureAction

export function noCodeReducer(
  state: NoCodeState = INITIAL_STATE,
  action: NoCodeReducerAction,
): NoCodeState {
  switch (action.type) {
    case SUBMIT_NO_CODE_REQUEST:
    case SUBMIT_NO_CODE_SUCCESS:
      return {
        ...state,
        loading: loadingReducer(state.loading, action),
        error: null,
      }
    case SUBMIT_NO_CODE_FAILURE:
      return {
        ...state,
        loading: loadingReducer(state.loading, action),
        error: action.payload.error,
      }
    default:
      return state
  }
}
