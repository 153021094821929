import type { Connector } from 'pluggy-js'

import { loadingReducer } from '../loading/reducer'
import { LoadingState } from '../loading/types'
import {
  FETCH_CONNECTORS_FAILURE,
  FETCH_CONNECTORS_REQUEST,
  FETCH_CONNECTORS_SUCCESS,
  FETCH_CUSTOMIZATION_FAILURE,
  FETCH_CUSTOMIZATION_REQUEST,
  FETCH_CUSTOMIZATION_SUCCESS,
  FetchConnectorsFailureAction,
  FetchConnectorsRequestAction,
  FetchConnectorsSuccessAction,
  FetchCustomizationFailureAction,
  FetchCustomizationRequestAction,
  FetchCustomizationSuccessAction,
  UPDATE_CUSTOMIZATION_FAILURE,
  UPDATE_CUSTOMIZATION_REQUEST,
  UPDATE_CUSTOMIZATION_SUCCESS,
  UpdateCustomizationFailureAction,
  UpdateCustomizationRequestAction,
  UpdateCustomizationSuccessAction,
} from './actions'
import { Customization } from './types'

export type CustomizationState = {
  data: {
    customization: Customization | null
    connectors: Connector[] | null
  }
  loading: LoadingState
  error: {
    customization: string | null
    connectors: string | null
  }
}

const INITIAL_STATE: CustomizationState = {
  data: {
    customization: null,
    connectors: null,
  },
  loading: [],
  error: {
    customization: null,
    connectors: null,
  },
}

type CustomizationReducerAction =
  | FetchCustomizationRequestAction
  | FetchCustomizationSuccessAction
  | FetchCustomizationFailureAction
  | UpdateCustomizationRequestAction
  | UpdateCustomizationSuccessAction
  | UpdateCustomizationFailureAction
  | FetchConnectorsRequestAction
  | FetchConnectorsSuccessAction
  | FetchConnectorsFailureAction

export function customizationReducer(
  state: CustomizationState = INITIAL_STATE,
  action: CustomizationReducerAction,
): CustomizationState {
  switch (action.type) {
    case FETCH_CUSTOMIZATION_REQUEST:
    case UPDATE_CUSTOMIZATION_REQUEST: {
      return {
        ...state,
        loading: loadingReducer(state.loading, action),
        error: {
          ...state.error,
          customization: null,
        },
      }
    }
    case FETCH_CUSTOMIZATION_SUCCESS:
    case UPDATE_CUSTOMIZATION_SUCCESS: {
      return {
        ...state,
        loading: loadingReducer(state.loading, action),
        error: {
          ...state.error,
          customization: null,
        },
        data: {
          ...state.data,
          customization: action.payload.customization,
        },
      }
    }

    case FETCH_CUSTOMIZATION_FAILURE:
    case UPDATE_CUSTOMIZATION_FAILURE: {
      return {
        ...state,
        loading: loadingReducer(state.loading, action),
        error: {
          ...state.error,
          customization: action.payload.error,
        },
      }
    }
    case FETCH_CONNECTORS_REQUEST: {
      return {
        ...state,
        loading: loadingReducer(state.loading, action),
        error: {
          ...state.error,
          connectors: null,
        },
      }
    }
    case FETCH_CONNECTORS_SUCCESS: {
      const { connectors } = action.payload
      const connectorsSorted = connectors.sort((a, b) => (a.id > b.id ? 1 : -1))

      return {
        ...state,
        loading: loadingReducer(state.loading, action),
        error: {
          ...state.error,
          connectors: null,
        },
        data: {
          ...state.data,
          connectors: connectorsSorted,
        },
      }
    }
    case FETCH_CONNECTORS_FAILURE: {
      return {
        ...state,
        loading: loadingReducer(state.loading, action),
        error: {
          ...state.error,
          connectors: action.payload.error,
        },
      }
    }
    default:
      return state
  }
}
