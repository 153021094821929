import { connect } from 'react-redux'

import { RootState } from '../../../modules/reducer'
import { changeWebhookEventsFilters } from '../../../modules/webhookEvents/actions'
import {
  getPageNumber,
  getTotalPages,
} from '../../../modules/webhookEvents/selectors'
import PageControls from './PageControls'
import {
  MapDispatch,
  MapDispatchProps,
  MapStateProps,
} from './PageControls.types'

const mapState = (state: RootState): MapStateProps => ({
  page: getPageNumber(state),
  totalPages: getTotalPages(state),
})

const mapDispatch = (dispatch: MapDispatch): MapDispatchProps => ({
  onWebhookEventsPageChange: (page: number) =>
    dispatch(
      changeWebhookEventsFilters({
        page,
      }),
    ),
})

export default connect(mapState, mapDispatch)(PageControls)
