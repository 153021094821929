import {
  GetIdTokenClaimsOptions,
  GetTokenSilentlyOptions,
  IdToken,
} from '@auth0/auth0-react'
import { action } from 'typesafe-actions'

import { Auth0Result } from './types'

/**
 *  Auth0 Callback Login request
 */

export const AUTH0_LOGIN_REQUEST = '[Request] Auth0 Login'
export const AUTH0_LOGIN_SUCCESS = '[Success] Auth0 Login'
export const AUTH0_LOGIN_FAILURE = '[Failure] Auth0 Login'

export const auth0LoginRequest = (
  getIdTokenClaims: (options?: GetIdTokenClaimsOptions) => Promise<IdToken>,
  getAccessTokenSilently: (
    options?: GetTokenSilentlyOptions,
  ) => Promise<string>,
) => action(AUTH0_LOGIN_REQUEST, { getIdTokenClaims, getAccessTokenSilently })
export const auth0LoginSuccess = (auth0Result: Auth0Result) =>
  action(AUTH0_LOGIN_SUCCESS, { auth0Result })
export const auth0loginFailure = (error: string) =>
  action(AUTH0_LOGIN_FAILURE, { error })

export type Auth0LoginRequestAction = ReturnType<typeof auth0LoginRequest>
export type Auth0LoginSuccessAction = ReturnType<typeof auth0LoginSuccess>
export type Auth0LoginFailureAction = ReturnType<typeof auth0loginFailure>

/**
 *  Auth0 Logout request
 */

export const AUTH0_LOGOUT_REQUEST = '[Request] Auth0 Logout'
export const AUTH0_LOGOUT_SUCCESS = '[Success] Auth0 Logout'
export const AUTH0_LOGOUT_FAILURE = '[Failure] Auth0 Logout'

export const auth0LogoutRequest = () => action(AUTH0_LOGOUT_REQUEST)
export const auth0LogoutSuccess = () => action(AUTH0_LOGOUT_SUCCESS)
export const auth0LogoutFailure = (error: string) =>
  action(AUTH0_LOGOUT_FAILURE, { error })

export type Auth0LogoutRequestAction = ReturnType<typeof auth0LogoutRequest>
export type Auth0LogoutSuccessAction = ReturnType<typeof auth0LogoutSuccess>
export type Auth0LogoutFailureAction = ReturnType<typeof auth0LogoutFailure>
