import { connect } from 'react-redux'

import { RootState } from '../../../../../modules/reducer'
import {
  DELETE_TEAM_REQUEST,
  FETCH_TEAMS_REQUEST,
} from '../../../../../modules/team/actions'
import { getError } from '../../../../../modules/team/selectors'
import {
  getLoading,
  isLoadingType,
} from '../../../../../modules/user/selectors'
import TeamDeleteWidget from './TeamDeleteWidget'
import {
  MapDispatch,
  MapDispatchProps,
  MapStateProps,
} from './TeamDeleteWidget.types'

const mapState = (state: RootState): MapStateProps => ({
  error: getError(state),
  isLoading:
    isLoadingType(getLoading(state), FETCH_TEAMS_REQUEST) ||
    isLoadingType(getLoading(state), DELETE_TEAM_REQUEST),
})

const mapDispatch = (_dispatch: MapDispatch): MapDispatchProps => ({
  // nothing here, yet
})

export default connect(mapState, mapDispatch)(TeamDeleteWidget)
