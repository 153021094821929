import React, { useCallback } from 'react'
import { useTranslation } from 'react-i18next'

import {
  PLUGGY_CHANGELOG_URL,
  PLUGGY_DOCS_URL,
} from '../../../lib/constants/urls'
import { TrackEventName } from '../../../modules/analytics/events'
import { track } from '../../../modules/analytics/utils'

import './DocumentationLinks.css'

const DocumentationLinks = (): JSX.Element => {
  const { t } = useTranslation()

  const pluggyDocsI18nKey = 'navbar.documentation'
  const pluggyDocsText = t(pluggyDocsI18nKey)

  const pluggyChangeLogI18nKey = 'navbar.changelog'
  const pluggyChangeLogText = t(pluggyChangeLogI18nKey)

  const handlePluggyDocsLinkClick = useCallback(() => {
    track(TrackEventName.LINK_CLICKED, {
      i18nKey: pluggyDocsI18nKey,
      text: pluggyDocsText,
      linkTo: PLUGGY_DOCS_URL,
      location: 'navbar',
    })
  }, [pluggyDocsText])

  const handleChangelogLinkClick = useCallback(() => {
    track(TrackEventName.LINK_CLICKED, {
      i18nKey: pluggyChangeLogI18nKey,
      text: pluggyChangeLogText,
      linkTo: PLUGGY_CHANGELOG_URL,
      location: 'navbar',
    })
  }, [pluggyChangeLogText])

  return (
    <div className={'DocumentationLinks'}>
      <a
        className={'link'}
        href={PLUGGY_DOCS_URL}
        onClick={handlePluggyDocsLinkClick}
        target={'_blank'}
        rel="noreferrer"
      >
        {pluggyDocsText}
      </a>
      <hr />
      <a
        className={'link'}
        href={PLUGGY_CHANGELOG_URL}
        onClick={handleChangelogLinkClick}
        target={'_blank'}
        rel="noreferrer"
      >
        {pluggyChangeLogText}
      </a>
    </div>
  )
}

export default React.memo(DocumentationLinks)
