import React, { useCallback, useState } from 'react'
import { useTranslation } from 'react-i18next'

import { Button, Radio } from '@pluggyai/ui'
import { Divider, Header, Loader } from 'semantic-ui-react'

import { TrackEventName } from '../../../../modules/analytics/events'
import { track } from '../../../../modules/analytics/utils'
import { ApplicationDeleteConfirmModal } from '../ApplicationDeleteConfirmModal'
import { Props } from './ApplicationDeleteWidget.types'

import './ApplicationDeleteWidget.css'

const ApplicationDeleteWidget = ({ application, isLoading }: Props) => {
  const { t } = useTranslation()

  const { name, id } = application

  const [confirmChecked, setConfirmChecked] = useState(false)
  const handleOnConfirmCheckClick = useCallback(
    () => setConfirmChecked((value) => !value),
    [],
  )

  const trackDeleteClick = useCallback(() => {
    track(TrackEventName.CTA_APPLICATION_DELETE, {
      applicationId: id,
    })
  }, [id])

  const [confirmModalVisible, setConfirmModalVisible] = useState(false)
  const handleOnDeleteActionClick = useCallback(() => {
    setConfirmModalVisible(true)
    setConfirmChecked(false)
    trackDeleteClick()
  }, [trackDeleteClick])

  const handleOnConfirmModalClose = useCallback(() => {
    setConfirmModalVisible(false)
  }, [])

  return (
    <div className={'ApplicationDeleteWidget'}>
      {isLoading ? (
        <div className={'widget-section-loader'}>
          <Loader size="big" active />
        </div>
      ) : (
        <>
          <div className={'pluggy card application-delete'}>
            <Header as={'h5'} className={'application-delete-header'}>
              {t('application.page.danger.delete.header')}
            </Header>
            {/* TODO: render error <Alert> message */}
            <p>{t('application.page.danger.delete.content')}</p>
            <Radio
              checked={confirmChecked}
              onClick={handleOnConfirmCheckClick}
              label={t('application.page.danger.delete.confirm', { name })}
            />
            <Divider />
            <div className={'action-buttons'}>
              <Button
                primary
                onClick={handleOnDeleteActionClick}
                disabled={!confirmChecked}
              >
                {t('application.page.danger.delete.action')}
              </Button>
            </div>
          </div>
          {confirmModalVisible && (
            <ApplicationDeleteConfirmModal
              application={application}
              onClose={handleOnConfirmModalClose}
            />
          )}
        </>
      )}
    </div>
  )
}

export default React.memo(ApplicationDeleteWidget)
