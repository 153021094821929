import { action } from 'typesafe-actions'

import { ActivityItem } from './types'

/// Fetch Applications request

export const FETCH_ACTIVITY_REQUEST = '[Request] Fetch Activity'
export const FETCH_ACTIVITY_SUCCESS = '[Success] Fetch Activity'
export const FETCH_ACTIVITY_FAILURE = '[Failure] Fetch Activity'

export const fetchActivityRequest = () => action(FETCH_ACTIVITY_REQUEST)
export const fetchActivitySuccess = (activityItems: ActivityItem[]) =>
  action(FETCH_ACTIVITY_SUCCESS, { activityItems })
export const fetchActivityFailure = (error: string) =>
  action(FETCH_ACTIVITY_FAILURE, { error })

export type FetchActivityRequestAction = ReturnType<typeof fetchActivityRequest>
export type FetchActivitySuccessAction = ReturnType<typeof fetchActivitySuccess>
export type FetchActivityFailureAction = ReturnType<typeof fetchActivityFailure>
