import { loadingReducer } from '../loading/reducer'
import { LoadingState } from '../loading/types'
import {
  CREATE_TEAM_FAILURE,
  CREATE_TEAM_MEMBER_FAILURE,
  CREATE_TEAM_MEMBER_REQUEST,
  CREATE_TEAM_MEMBER_SUCCESS,
  CREATE_TEAM_REQUEST,
  CREATE_TEAM_SUCCESS,
  CreateTeamFailureAction,
  CreateTeamMemberFailureAction,
  CreateTeamMemberRequestAction,
  CreateTeamMemberSuccessAction,
  CreateTeamRequestAction,
  CreateTeamSuccessAction,
  DELETE_TEAM_FAILURE,
  DELETE_TEAM_MEMBER_FAILURE,
  DELETE_TEAM_MEMBER_REQUEST,
  DELETE_TEAM_MEMBER_SUCCESS,
  DELETE_TEAM_REQUEST,
  DELETE_TEAM_SUCCESS,
  DeleteTeamFailureAction,
  DeleteTeamMemberFailureAction,
  DeleteTeamMemberRequestAction,
  DeleteTeamMemberSuccessAction,
  DeleteTeamRequestAction,
  DeleteTeamSuccessAction,
  FETCH_TEAMS_FAILURE,
  FETCH_TEAMS_REQUEST,
  FETCH_TEAMS_SUCCESS,
  FetchTeamsFailureAction,
  FetchTeamsRequestAction,
  FetchTeamsSuccessAction,
  IMPERSONATE_TEAM_FAILURE,
  IMPERSONATE_TEAM_REQUEST,
  IMPERSONATE_TEAM_SUCCESS,
  ImpersonateTeamFailureAction,
  ImpersonateTeamRequestAction,
  ImpersonateTeamSuccessAction,
  RESEND_TEAM_INVITATION_FAILURE,
  RESEND_TEAM_INVITATION_REQUEST,
  RESEND_TEAM_INVITATION_SUCCESS,
  ResendTeamInvitationFailureAction,
  ResendTeamInvitationRequestAction,
  ResendTeamInvitationSuccessAction,
  SELECT_CURRENT_TEAM_FAILURE,
  SELECT_CURRENT_TEAM_REQUEST,
  SELECT_CURRENT_TEAM_SUCCESS,
  SelectCurrentTeamFailureAction,
  SelectCurrentTeamRequestAction,
  SelectCurrentTeamSuccessAction,
  UPDATE_TEAM_FAILURE,
  UPDATE_TEAM_MEMBER_ROLE_FAILURE,
  UPDATE_TEAM_MEMBER_ROLE_REQUEST,
  UPDATE_TEAM_MEMBER_ROLE_SUCCESS,
  UPDATE_TEAM_REQUEST,
  UPDATE_TEAM_SUCCESS,
  UpdateTeamFailureAction,
  UpdateTeamMemberRoleFailureAction,
  UpdateTeamMemberRoleRequestAction,
  UpdateTeamMemberRoleSuccessAction,
  UpdateTeamRequestAction,
  UpdateTeamSuccessAction,
} from './actions'
import { retrieveCurrentTeamId } from './storage'
import { Team } from './types'

export type TeamState = {
  data: {
    teams: Team[] | null
    current: Team | null
    asTeam: Team | null
  }
  loading: LoadingState
  error: string | null
}

const INITIAL_STATE: TeamState = {
  data: {
    teams: null,
    current: null,
    asTeam: null,
  },
  loading: [],
  error: null,
}

type TeamReducerAction =
  | CreateTeamRequestAction
  | CreateTeamSuccessAction
  | CreateTeamFailureAction
  | FetchTeamsRequestAction
  | FetchTeamsSuccessAction
  | FetchTeamsFailureAction
  | SelectCurrentTeamRequestAction
  | SelectCurrentTeamSuccessAction
  | SelectCurrentTeamFailureAction
  | ImpersonateTeamRequestAction
  | ImpersonateTeamSuccessAction
  | ImpersonateTeamFailureAction
  | DeleteTeamRequestAction
  | DeleteTeamSuccessAction
  | DeleteTeamFailureAction
  | CreateTeamMemberRequestAction
  | CreateTeamMemberSuccessAction
  | CreateTeamMemberFailureAction
  | UpdateTeamMemberRoleRequestAction
  | UpdateTeamMemberRoleSuccessAction
  | UpdateTeamMemberRoleFailureAction
  | ResendTeamInvitationRequestAction
  | ResendTeamInvitationSuccessAction
  | ResendTeamInvitationFailureAction
  | DeleteTeamMemberRequestAction
  | DeleteTeamMemberSuccessAction
  | DeleteTeamMemberFailureAction
  | UpdateTeamRequestAction
  | UpdateTeamSuccessAction
  | UpdateTeamFailureAction

export function teamReducer(
  state: TeamState = INITIAL_STATE,
  action: TeamReducerAction,
): TeamState {
  switch (action.type) {
    case SELECT_CURRENT_TEAM_REQUEST:
    case FETCH_TEAMS_REQUEST:
    case CREATE_TEAM_REQUEST:
    case DELETE_TEAM_REQUEST:
    case CREATE_TEAM_MEMBER_REQUEST:
    case UPDATE_TEAM_REQUEST:
    case UPDATE_TEAM_MEMBER_ROLE_REQUEST:
    case RESEND_TEAM_INVITATION_REQUEST:
    case DELETE_TEAM_MEMBER_REQUEST: {
      return {
        ...state,
        error: null,
        loading: loadingReducer(state.loading, action),
      }
    }
    case SELECT_CURRENT_TEAM_SUCCESS: {
      const { team } = action.payload

      return {
        ...state,
        error: null,
        loading: loadingReducer(state.loading, action),
        data: {
          ...state.data,
          current: team,
        },
      }
    }
    case DELETE_TEAM_SUCCESS: {
      const {
        team: { id: deletedTeamId },
      } = action.payload

      // remove team from current state data
      const teamsExceptDeleted = (state.data.teams || []).filter(
        (_team) => _team.id !== deletedTeamId,
      )

      // the first of the remaining teams is the new current (if any)
      const nextCurrentTeam =
        teamsExceptDeleted.length >= 1 ? teamsExceptDeleted[0] : null

      return {
        ...state,
        loading: loadingReducer(state.loading, action),
        error: null,
        data: {
          ...state.data,
          teams: teamsExceptDeleted,
          current: nextCurrentTeam,
        },
      }
    }
    case IMPERSONATE_TEAM_REQUEST:
    case IMPERSONATE_TEAM_FAILURE:
      return {
        ...state,
        loading: loadingReducer(state.loading, action),
      }
    case IMPERSONATE_TEAM_SUCCESS: {
      const {
        payload: { team },
      } = action

      const previousAsTeam = state.data.asTeam
      const currentTeams = state.data.teams || []

      let updatedAsTeam: Team | null
      let updatedTeams: Team[]

      if (team === null) {
        // unset asTeam
        updatedAsTeam = null

        // remove previous asTeam, if any
        updatedTeams = currentTeams.filter(
          (team_) => team_.id !== previousAsTeam?.id,
        )
      } else {
        // new asTeam, set it
        updatedAsTeam = team

        // update or insert asTeam on current teams state
        updatedTeams = [...(state.data.teams || [])]

        const asTeamIndex = updatedTeams.findIndex(
          (team_) => team_.id === team.id,
        )
        if (asTeamIndex > -1) {
          // found asTeam -> update item
          updatedTeams[asTeamIndex] = team
        } else {
          // not found -> insert (in first position)
          updatedTeams.unshift(team)
        }
      }

      return {
        ...state,
        loading: loadingReducer(state.loading, action),
        data: {
          ...state.data,
          asTeam: updatedAsTeam,
          current: updatedAsTeam || state.data.current,
          teams: updatedTeams,
        },
      }
    }
    case CREATE_TEAM_MEMBER_SUCCESS:
    case UPDATE_TEAM_MEMBER_ROLE_SUCCESS:
    case DELETE_TEAM_MEMBER_SUCCESS:
    case CREATE_TEAM_SUCCESS:
    case UPDATE_TEAM_SUCCESS:
    case RESEND_TEAM_INVITATION_SUCCESS: {
      const { team } = action.payload

      // update or insert team on current state
      const currentTeams = [...(state.data.teams || [])]
      const i = currentTeams.findIndex((_team) => _team.id === team.id)
      if (i > -1) {
        // found -> update item
        currentTeams[i] = team
      } else {
        // not found -> insert
        currentTeams.push(team)
      }

      return {
        ...state,
        loading: loadingReducer(state.loading, action),
        error: null,
        data: {
          ...state.data,
          teams: currentTeams,
          current: team,
        },
      }
    }
    case FETCH_TEAMS_SUCCESS: {
      const {
        payload: { teams },
      } = action

      // if user belongs to one or more teams
      //  if currentTeamId is stored, and it's included -> select it
      //     is not included -> select the first team in the response
      // if no teams -> keep 'current' team as 'null'
      const currentTeamId = retrieveCurrentTeamId()

      const storedSelectedTeam = teams.find((team) => team.id === currentTeamId)
      const firstTeamResult = teams.length > 0 ? teams[0] : null

      const teamToSelect = storedSelectedTeam || firstTeamResult

      return {
        ...state,
        loading: loadingReducer(state.loading, action),
        error: null,
        data: {
          ...state.data,
          teams: action.payload.teams,
          current: teamToSelect,
        },
      }
    }
    case SELECT_CURRENT_TEAM_FAILURE: {
      const {
        team: { id: selectedTeamId },
        error,
      } = action.payload

      // failed to select a team, remove it from current teams state too
      const teamsExceptFailed = (state.data.teams || []).filter(
        (_team) => _team.id !== selectedTeamId,
      )

      return {
        ...state,
        loading: loadingReducer(state.loading, action),
        error,
        data: {
          ...state.data,
          teams: teamsExceptFailed,
        },
      }
    }
    case CREATE_TEAM_MEMBER_FAILURE:
    case UPDATE_TEAM_MEMBER_ROLE_FAILURE:
    case RESEND_TEAM_INVITATION_FAILURE:
    case DELETE_TEAM_MEMBER_FAILURE:
    case FETCH_TEAMS_FAILURE:
    case CREATE_TEAM_FAILURE:
    case UPDATE_TEAM_FAILURE:
    case DELETE_TEAM_FAILURE: {
      return {
        ...state,
        loading: loadingReducer(state.loading, action),
        error: action.payload.error,
      }
    }
    default: {
      return state
    }
  }
}
