import React from 'react'
import { useTranslation } from 'react-i18next'

import { Alert } from '@pluggyai/ui'
import { Grid, Header, Loader } from 'semantic-ui-react'

import { Section } from '../Section'
import { Props } from './ListSection.types'

import './ListSection.css'

const ListSection = <T extends unknown>({
  data,
  isLoading,
  error,
  title,
  description,
  renderElement,
  EmptyPlaceholder,
  NewComponentButton,
  SecondaryButton,
}: Props<T>) => {
  const { t } = useTranslation()
  const hasData = data.length > 0

  return (
    <Section className="ListSection">
      <Grid>
        <Grid.Row>
          <div className="list-header-container">
            <Header as="h2" floated={'left'}>
              {title}
            </Header>
            <div className={'list-header-actions'}>
              {NewComponentButton}
              {SecondaryButton}
            </div>
          </div>
          {error !== null && error !== undefined && (
            <Alert size={'medium'} type={'error'} message={error} />
          )}
        </Grid.Row>
        <Grid.Row className={'list-section-items'}>
          {isLoading ? (
            <div className="widget-section-loader">
              <Loader size="big" active />
            </div>
          ) : (
            <>
              {description}
              {hasData ? (
                <>
                  {data.map((item, i) => (
                    <div key={`${title}-${i}`} className={'list-item'}>
                      {renderElement(item, i)}
                    </div>
                  ))}
                </>
              ) : EmptyPlaceholder !== undefined ? (
                <div className={'list-item'}>{EmptyPlaceholder}</div>
              ) : (
                <div className="list-empty">
                  <div>
                    {t('listSection.empty', {
                      modelNamePluralized: title.toLowerCase(),
                    })}
                  </div>
                  {NewComponentButton}
                </div>
              )}
            </>
          )}
        </Grid.Row>
      </Grid>
    </Section>
  )
}

/*
 Note: casting memo result 'as typeof ListSection' as an easy fix
 to support generic <T> data in ListSection component
 */
export default React.memo(ListSection) as typeof ListSection
