import { memo, useCallback, useState } from 'react'
import { useTranslation } from 'react-i18next'

import { SearchBox } from '@pluggyai/ui'

import { TrackEventName } from '../../modules/analytics/events'
import { track } from '../../modules/analytics/utils'
import { Page } from '../Page/'
import { ExecutionsDataContainer } from './ExecutionsDataContainer'
import { ItemDataContainer } from './ItemDataContainer'
import { Props } from './ItemExecutionsPage.types'
import { isValidUuid } from './utils'

import './ItemExecutionsPage.css'

const ItemExecutionsPage = ({ item, onFetchItem }: Props): JSX.Element => {
  const { t } = useTranslation('translation', {
    keyPrefix: 'item-executions-page',
  })

  const [inputValue, setInputValue] = useState('')
  const handleSearchChange = useCallback(
    (event: React.ChangeEvent<HTMLInputElement>) => {
      const {
        target: { value },
      } = event

      setInputValue(value)
      if (!isValidUuid(value)) {
        return
      }

      track(TrackEventName.ITEM_EXECUTIONS_SEARCHED)
      onFetchItem(value)
    },
    [onFetchItem],
  )

  const handleClear = useCallback(() => {
    setInputValue('')
  }, [])

  const handleSearchBoxClick = useCallback(() => {
    track(TrackEventName.ITEM_EXECUTIONS_SEARCH_BOX_CLICKED)
  }, [])

  return (
    <Page className={'ItemExecutionsPage'}>
      <div className={'item-with-executions-container'}>
        <h2 className={'title'}>{t('title')}</h2>
        <p className={'subtitle'}>{t('subtitle')}</p>
        <SearchBox
          size={'medium'}
          className={'search-box'}
          onChange={handleSearchChange}
          value={inputValue}
          placeholder={t('placeholder')}
          onClick={handleSearchBoxClick}
          onClear={handleClear}
        />
        <ItemDataContainer
          item={item && item.id === inputValue ? item : null}
        />
        <ExecutionsDataContainer
          executions={item && item.id === inputValue ? item.executions : []}
        />
      </div>
    </Page>
  )
}

export default memo(ItemExecutionsPage)
