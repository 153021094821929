import { memo } from 'react'
import { useTranslation } from 'react-i18next'

import { formatRate } from '../../../modules/stats/utils'
import { Stat } from '../Stat'
import type { Props } from './ConnectionStats.types'

import './ConnectionStats.css'

const defaultItemsStatsReport = {
  totalItems: 0,
  pluggyServiceRate: 0,
  successRate: 0,
  totalItemsIncrementPercentage: 0,
  successRateDelta: 0,
  pluggyServiceRateDelta: 0,
}

const ConnectionStats = ({ itemsStatsReport, startDate, endDate }: Props) => {
  const { t } = useTranslation()

  const {
    totalItems,
    pluggyServiceRate,
    successRate,
    totalItemsIncrementPercentage,
    successRateDelta,
    pluggyServiceRateDelta,
  } = itemsStatsReport || defaultItemsStatsReport

  const statsCardsData: {
    title: string
    value: string
    change: number
    description?: undefined
  }[] = [
    {
      title: t('items-stats.total-items.title'),
      value: String(totalItems),
      change: totalItemsIncrementPercentage,
    },
    {
      title: t('items-stats.success-rate.title'),
      description: t('items-stats.success-rate.description'),
      value: totalItems === 0 ? '-' : formatRate(successRate),
      change: successRateDelta,
    },
    {
      title: t('items-stats.pluggy-service-rate.title'),
      description: t('items-stats.pluggy-service-rate.description'),
      value: totalItems === 0 ? '-' : formatRate(pluggyServiceRate),
      change: pluggyServiceRateDelta,
    },
  ]

  return (
    <div className={'ConnectionStats'}>
      {statsCardsData.map(({ title, value, change, description }) => (
        <Stat
          key={title}
          title={title}
          value={value}
          description={description}
          trend={{
            change,
            period: {
              startDate,
              endDate,
            },
          }}
        />
      ))}
    </div>
  )
}

export default memo(ConnectionStats)
