import i18n from 'i18next'

import { store } from '../../modules/store'
import {
  MemberInvitation,
  TEAM_ROLES,
  TeamFields,
} from '../../modules/team/types'
import { getUser } from '../../modules/user/selectors'
import { isValidEmail, isValidUrl } from '../../utils/validation'

const validations: Record<
  keyof TeamFields,
  (fieldName: string, value: unknown) => string | undefined
> = {
  imageUrl: (fieldName: string, value: unknown): string | undefined => {
    if (typeof value !== 'string') {
      throw new Error('Not a string value')
    }
    if (value.length > 0 && !isValidUrl(value)) {
      return i18n.t('team.form.error.url', {
        fieldName,
      })
    }
    return undefined
  },
  name: (fieldName: string, value: unknown): string | undefined => {
    if (typeof value !== 'string') {
      throw new Error('Not a string value')
    }
    if (value.length === 0) {
      return i18n.t('team.form.error.empty', {
        fieldName,
      })
    }
    const maxLength = 20
    if (value.length > maxLength) {
      return i18n.t('team.form.error.max-length', {
        fieldName,
        length: maxLength,
      })
    }
    return undefined
  },
  members: (fieldName: string, value: unknown): string | undefined => {
    const membersInvitations = value as MemberInvitation[]
    if (!value || membersInvitations.length === 0) {
      // no members to invite, OK because it's optional
      return undefined
    }

    if (
      membersInvitations.length === 1 &&
      membersInvitations[0].email.length === 0
    ) {
      // no email included, OK because it's optional
      return undefined
    }

    // get current User from state, to validate not trying to self-send an invitation
    const user = getUser(store.getState())
    if (!user) {
      throw new Error('User is unexpectedly not defined')
    }

    const errors = membersInvitations.map(({ email: memberEmail, role }, i) => {
      if (memberEmail.length === 0) {
        return i18n.t('team.form.error.empty', {
          fieldName,
        })
      }

      if (!isValidEmail(memberEmail)) {
        return i18n.t('team.form.error.email', {
          fieldName,
        })
      }

      if (user.email === memberEmail) {
        return i18n.t('team.form.error.self-invite', {
          fieldName,
        })
      }

      // check if value was already included in the list
      const previousEmails = membersInvitations
        .slice(0, i)
        .map((prevValue) => prevValue.email)
      if (previousEmails.includes(memberEmail)) {
        return i18n.t('team.form.error.list-repeated', {
          fieldName,
        })
      }

      if (!TEAM_ROLES.includes(role)) {
        return i18n.t('team.form.error.invalid-value', {
          fieldName,
        })
      }

      return undefined
    })

    return errors.join(',')
  },
}

export function validateTeamField(
  fieldName: keyof TeamFields,
  value: unknown,
): string | undefined {
  const fieldLabel = i18n.t(`team.form.field.${fieldName}.label`)
  return validations[fieldName](fieldLabel, value)
}
