import { connect } from 'react-redux'

import {
  FETCH_CUSTOMIZATION_REQUEST,
  UPDATE_CUSTOMIZATION_REQUEST,
} from '../../../modules/customization/actions'
import {
  getCustomization,
  getLoading,
  isCurrentUserFreeSubscription,
  isLoadingType,
} from '../../../modules/customization/selectors'
import {
  UPLOAD_LOGO_IMAGE_REQUEST,
  uploadLogoImageRequest,
} from '../../../modules/logoImage/actions'
import {
  isLoadingType as isLoadingLogoImageType,
  getLoading as getLogoImageLoading,
} from '../../../modules/logoImage/selectors'
import { RootState } from '../../../modules/reducer'
import { getCurrentTeam, isUserTeamRole } from '../../../modules/team/selectors'
import GeneralCustomizationForm from './GeneralCustomizationForm'
import {
  MapDispatchProps,
  MapStateProps,
  MapDispatch,
} from './GeneralCustomizationForm.types'

const mapState = (state: RootState): MapStateProps => ({
  isLoading:
    isLoadingType(getLoading(state), FETCH_CUSTOMIZATION_REQUEST) ||
    isLoadingType(getLoading(state), UPDATE_CUSTOMIZATION_REQUEST),
  isFreeSubscription: isCurrentUserFreeSubscription(state),
  isTeamOwnerOrAdminRole:
    !getCurrentTeam(state) || isUserTeamRole(state, ['OWNER', 'ADMIN']),
  customization: getCustomization(state),
  isLoadingLogoImageUpload: isLoadingLogoImageType(
    getLogoImageLoading(state),
    UPLOAD_LOGO_IMAGE_REQUEST,
  ),
})

const mapDispatch = (dispatch: MapDispatch): MapDispatchProps => ({
  onUploadLogoImageFile: (logoImageFile: File) =>
    dispatch(uploadLogoImageRequest(logoImageFile)),
})

export default connect(mapState, mapDispatch)(GeneralCustomizationForm)
