import { memo, useCallback } from 'react'
import { useTranslation } from 'react-i18next'
import { Link } from 'react-router-dom'

import { Button, Header } from '@pluggyai/ui'

import { TrackEventName } from '../../../../../modules/analytics/events'
import { track } from '../../../../../modules/analytics/utils'
import { locations } from '../../../../../modules/routing/locations'

import './TeamBillingSection.css'

const EVENT_LOCATION =
  'TeamSettingsPage TeamSubscriptionSection TeamBillingSection'

const TeamBillingSection = () => {
  const { t } = useTranslation()

  const seeReportI18nKey = 'settings.team.billing.action'
  const seeReportText = t(seeReportI18nKey)

  const handleRequestReportButtonClick = useCallback(() => {
    track(TrackEventName.LINK_CLICKED, {
      i18nKey: seeReportI18nKey,
      text: seeReportText,
      location: EVENT_LOCATION,
    })
  }, [seeReportText])

  return (
    <div className="TeamBillingSection">
      <div className={'heading'}>
        <div>
          <Header as={'h3'}>{t('settings.team.billing.header')}</Header>
        </div>
      </div>

      <div className={'content'}>
        <div className={'text'}>{t('settings.team.billing.text')}</div>
        <Link to={locations.billing()}>
          <Button
            primary
            className={'request-report'}
            onClick={handleRequestReportButtonClick}
          >
            {seeReportText}
          </Button>
        </Link>
      </div>
    </div>
  )
}

export default memo(TeamBillingSection)
