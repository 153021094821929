import { connect } from 'react-redux'

import { getError as getConnectTokenError } from '../../modules/connectToken/selectors'
import {
  fetchConnectorsRequest,
  fetchCustomizationRequest,
  updateCustomizationRequest,
} from '../../modules/customization/actions'
import {
  getConnectors,
  getCustomization,
  isCurrentUserFreeSubscription,
} from '../../modules/customization/selectors'
import {
  ConnectorIdsCustomizationFields,
  GeneralCustomizationFields,
} from '../../modules/customization/types'
import { UPLOAD_LOGO_IMAGE_REQUEST } from '../../modules/logoImage/actions'
import {
  getTemporaryLogoImageUrl,
  isLoadingType,
  getLoading,
} from '../../modules/logoImage/selectors'
import { RootState } from '../../modules/reducer'
import { getCurrentTeam, isUserTeamRole } from '../../modules/team/selectors'
import CustomizationPage from './CustomizationPage'
import {
  MapDispatch,
  MapDispatchProps,
  MapStateProps,
} from './CustomizationPage.types'

const mapState = (state: RootState): MapStateProps => ({
  isTeamOwnerOrAdminRole:
    !getCurrentTeam(state) || isUserTeamRole(state, ['OWNER', 'ADMIN']),
  isFreeSubscription: isCurrentUserFreeSubscription(state),
  customization: getCustomization(state),
  connectors: getConnectors(state),
  currentTeam: getCurrentTeam(state),
  connectTokenError: getConnectTokenError(state),
  isLoadingLogoImageUpload: isLoadingType(
    getLoading(state),
    UPLOAD_LOGO_IMAGE_REQUEST,
  ),
  temporaryLogoImageUrl: getTemporaryLogoImageUrl(state),
})

const mapDispatch = (dispatch: MapDispatch): MapDispatchProps => ({
  onFetchCustomization: () => dispatch(fetchCustomizationRequest()),
  onFetchConnectors: () => dispatch(fetchConnectorsRequest()),
  onUpdate: (
    updateCustomizationFields: GeneralCustomizationFields &
      ConnectorIdsCustomizationFields,
  ) => dispatch(updateCustomizationRequest(updateCustomizationFields)),
})

export default connect(mapState, mapDispatch)(CustomizationPage)
