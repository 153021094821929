import { action } from 'typesafe-actions'

import { NoCodeFields } from './types'

/**
 * Go to Production Request Submit
 */

export const SUBMIT_NO_CODE_REQUEST = '[Request] Submit No Code'
export const SUBMIT_NO_CODE_SUCCESS = '[Success] Submit No Code'
export const SUBMIT_NO_CODE_FAILURE = '[Failure] Submit No Code'

export const submitNoCodeRequest = (fields: NoCodeFields) =>
  action(SUBMIT_NO_CODE_REQUEST, fields)
export const submitNoCodeSuccess = () => action(SUBMIT_NO_CODE_SUCCESS)
export const submitNoCodeFailure = (error: string) =>
  action(SUBMIT_NO_CODE_FAILURE, { error })

export type SubmitNoCodeRequestAction = ReturnType<typeof submitNoCodeRequest>
export type SubmitNoCodeSuccessAction = ReturnType<typeof submitNoCodeSuccess>
export type SubmitNoCodeFailureAction = ReturnType<typeof submitNoCodeFailure>
