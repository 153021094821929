import { memo, useCallback, useMemo, useState } from 'react'
import { useTranslation } from 'react-i18next'

import { Input } from '@pluggyai/ui'
import { Option } from '@pluggyai/ui/dist/components/Dropdown/Dropdown.types'

import { COMPANY_ROLES } from '../../modules/user/types'
import {
  CompanyRoleOption,
  PlatformOption,
  Props,
} from './CompanyRoleInput.types'
import {
  buildPlatformOptions,
  formatCustomRole,
  mapRolesToOption,
} from './utils'

import './CompanyRoleInput.css'

const CompanyRoleInput = ({
  onRoleChange,
  onPlatformsChange,
  companyRoleError,
  platformsError,
  initialValue,
}: Props) => {
  const { t } = useTranslation()

  const rolesOptions: Option[] = mapRolesToOption(COMPANY_ROLES)

  const initialValueOption = rolesOptions.find(({ id }) => id === initialValue)

  const [selectedCompanyRole, setSelectedCompanyRole] = useState<
    Option | undefined
  >(initialValueOption)

  const [selectedPlatforms, setSelectedPlatforms] = useState<
    Option[] | undefined
  >()

  const [otherCompanyRole, setOtherCompanyRole] = useState('')

  const handleDropdownChange = useCallback(
    ({ value }: { value: Option | Option[] }) => {
      const option = value as CompanyRoleOption
      const { id } = option

      setSelectedCompanyRole(option)

      if (id === 'OTHER') {
        // if user selects 'OTHER' role, set the company role value
        // as undefined to make user define it in the text input
        onRoleChange(undefined)
        return
      }

      if (id !== 'DEVELOPER') {
        setSelectedPlatforms(undefined)
        onPlatformsChange(undefined)
      }

      onRoleChange(id)
    },
    [onPlatformsChange, onRoleChange],
  )

  const handleOtherRoleChange = useCallback(
    ({ value }: { value: string }) => {
      setOtherCompanyRole(value)

      const customRole = value.trim()

      if (customRole === '') {
        // if user clears the text input, set the company role value to undefined
        onRoleChange(undefined)
        return
      }

      onRoleChange(formatCustomRole(customRole))
    },
    [onRoleChange],
  )

  const handleSelectedPlatformsChange = ({
    value,
  }: {
    value?: Option[] | Option
  }) => {
    if (!value || value.length === 0) {
      return
    }

    const currentPlatforms = value as PlatformOption

    setSelectedPlatforms(currentPlatforms)
    onPlatformsChange(currentPlatforms.map(({ id }) => id))
  }

  const isOtherRoleSelected = selectedCompanyRole?.id === 'OTHER'

  const platformsOptionGroups = useMemo(buildPlatformOptions, [])
  return (
    <div className={'CompanyRoleInput'}>
      <Input
        label={t('team.onboarding.form.field.company-role.label')}
        className={'company-role-input'}
        type={'select'}
        options={rolesOptions}
        value={selectedCompanyRole}
        onChange={handleDropdownChange}
        error={!isOtherRoleSelected && companyRoleError}
      />

      {isOtherRoleSelected && (
        <Input
          className={'company-role-input'}
          type={'text'}
          label={t('team.onboarding.form.field.other-role.label')}
          value={otherCompanyRole}
          onChange={handleOtherRoleChange}
          error={platformsError}
        />
      )}
      {selectedCompanyRole?.id === 'DEVELOPER' && (
        <Input
          type={'select'}
          label={t('team.onboarding.form.field.platforms.label')}
          className={'platforms'}
          options={platformsOptionGroups}
          onChange={handleSelectedPlatformsChange}
          value={selectedPlatforms}
          error={platformsError}
          multiple
        />
      )}
    </div>
  )
}

export default memo(CompanyRoleInput)
