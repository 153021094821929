import { memo } from 'react'

import { MagnifyingGlassIcon } from '../../Icon'
import { Props } from './DataPlaceholder.types'

import './DataPlaceholder.css'

const DataPlaceholder = ({ message }: Props) => (
  <div className={'DataPlaceholder'}>
    <MagnifyingGlassIcon />
    <p className={'message'}>{message}</p>
  </div>
)

export default memo(DataPlaceholder)
