import React, { useCallback, useEffect, useState } from 'react'
import { useTranslation } from 'react-i18next'

import { Alert, Button } from '@pluggyai/ui'
import { Modal } from 'semantic-ui-react'

import { TrackEventName } from '../../modules/analytics/events'
import { track } from '../../modules/analytics/utils'
import {
  isMembersInvitationsArray,
  TeamFields,
  TeamFieldsErrors,
} from '../../modules/team/types'
import { isStringsArray } from '../../utils/validation'
import { CloseIcon } from '../Icon'
import { TeamForm, validateTeamField } from '../TeamForm'
import { Props } from './TeamCreateModal.types'

import './TeamCreateModal.css'

const initialTeamFields = (): TeamFields => ({
  name: '',
  imageUrl: '',
  members: [
    {
      email: '',
      role: 'MEMBER',
    },
  ],
})

const TeamCreateModal = ({
  userTeamMemberRole,
  isLoading,
  error,
  onClose,
  onCreateTeam,
}: Props) => {
  const { t } = useTranslation()

  const [submit, setSubmit] = useState(false)
  const [formErrors, setFormErrors] = useState<TeamFieldsErrors>({})
  const [teamValues, setTeamValues] = useState(initialTeamFields)

  const trackFormValidationError = useCallback((errors: TeamFieldsErrors) => {
    track(TrackEventName.FORM_VALIDATION_ERRORS, {
      fields: Object.keys(errors),
      location: 'TeamCreateModal',
    })
  }, [])

  const trackCreateSubmit = useCallback(() => {
    track(TrackEventName.CTA_NEW_TEAM_MODAL_CREATE)
  }, [])

  const trackCloseClick = useCallback(() => {
    track(TrackEventName.CTA_NEW_TEAM_MODAL_CLOSE)
  }, [])

  const trackCancelClick = useCallback(() => {
    track(TrackEventName.CTA_NEW_TEAM_MODAL_CANCEL)
  }, [])

  const close = useCallback(() => {
    setFormErrors({})
    setTeamValues(initialTeamFields())
    onClose()
  }, [onClose])

  const handleOnClose = useCallback(() => {
    trackCloseClick()
    close()
  }, [close, trackCloseClick])

  const handleOnCancel = useCallback(() => {
    trackCancelClick()
    close()
  }, [close, trackCancelClick])

  const handleValuesChange = useCallback(
    (fieldName: keyof TeamFields, value: unknown) => {
      // clear field error, if any
      if (formErrors[fieldName]) {
        delete formErrors[fieldName]
        setFormErrors({ ...formErrors })
      }
      // set field value
      setTeamValues({ ...teamValues, [fieldName]: value })
    },
    [teamValues, formErrors],
  )

  const handleSubmit = useCallback(
    (event: React.FormEvent) => {
      if (isLoading) {
        // is already loading/submitting, wait
        return
      }

      trackCreateSubmit()
      event.preventDefault()

      // validate fields values
      // TODO: each field should "register" and define it's own validator? to support custom types per field
      const currentFormErrors: TeamFieldsErrors = {}
      let errorsCount = 0
      for (const [field, value] of Object.entries(teamValues)) {
        let fieldError: string | string[] | undefined
        if (isStringsArray(value)) {
          const valueJoined = value.join(',')
          const errorsJoined = validateTeamField(
            field as keyof TeamFields,
            valueJoined,
          )
          const fieldErrors = errorsJoined?.split(',')
          if (fieldErrors?.some((fieldError_) => fieldError_.length > 0)) {
            errorsCount++
          }

          fieldError = fieldErrors
        } else if (isMembersInvitationsArray(value)) {
          const errorsJoined = validateTeamField(
            field as keyof TeamFields,
            value,
          )
          const fieldErrors = errorsJoined?.split(',')
          if (fieldErrors?.some((fieldError_) => fieldError_.length > 0)) {
            errorsCount++
          }

          fieldError = fieldErrors
        } else {
          fieldError = validateTeamField(field as keyof TeamFields, value)

          if (fieldError) {
            errorsCount++
          }
        }

        if (fieldError) {
          // using 'as any' here as a hack to support both 'string' or 'string[]'
          // fieldError values.
          // TODO rework/improve this, maybe using separated validations per field
          currentFormErrors[field as keyof TeamFields] = fieldError as any
        }
      }

      setFormErrors(currentFormErrors)
      if (errorsCount > 0) {
        // got errors, can't proceed
        trackFormValidationError(currentFormErrors)
        return
      }

      setSubmit(true)
      onCreateTeam(teamValues)
    },
    [
      teamValues,
      onCreateTeam,
      isLoading,
      trackCreateSubmit,
      trackFormValidationError,
    ],
  )

  useEffect(() => {
    // check if we are done, and close modal if no errors
    const isDone = submit && error === null && !isLoading
    if (isDone) {
      handleOnClose()
    }
  }, [error, isLoading, submit, handleOnClose])

  return (
    <Modal
      className={'TeamCreateModal'}
      onClose={handleOnClose}
      open={true}
      closeIcon={<CloseIcon />}
    >
      <Modal.Header>
        {t('team.form.title')}
        <p>{t('team.form.subtitle')}</p>
        {error !== null && (
          <Alert size={'medium'} type={'error'} message={error} />
        )}
      </Modal.Header>
      <Modal.Content>
        <TeamForm
          onSubmit={handleSubmit}
          onFieldChange={handleValuesChange}
          values={teamValues}
          errors={formErrors}
          isSubmitting={isLoading}
          userTeamMemberRole={userTeamMemberRole}
        />
      </Modal.Content>
      <Modal.Actions>
        <Button secondary onClick={handleOnCancel}>
          {t('team.form.action.cancel')}
        </Button>
        <Button primary onClick={handleSubmit} isLoading={isLoading}>
          {t('team.form.action.create')}
        </Button>
      </Modal.Actions>
    </Modal>
  )
}

export default React.memo(TeamCreateModal)
