import { memo, useEffect } from 'react'
import { Trans, useTranslation } from 'react-i18next'

import { Alert, Button } from '@pluggyai/ui'

import { PluggyLogo } from '../../LoadingPage/PluggyLogo'
import { Props } from './LoginErrorPage.types'
import { extractEmail } from './utils'

import './LoginErrorPage.css'

const LoginErrorPage = ({ error, auth0Error, onAuth0Logout }: Props) => {
  const { t } = useTranslation()

  let i18nKey = 'loginError.message.generic'
  let email: string | undefined

  let isEmailVerificationPendingError = false

  if (auth0Error) {
    // parse auth0 error message to our own i18n messages
    const { message } = auth0Error
    if (message.includes('Please verify your email')) {
      email = extractEmail(message)
      i18nKey = `loginError.message.email-not-verified${
        email ? '_with-email' : ''
      }`
      isEmailVerificationPendingError = true
    }
    if (message === 'Invalid state') {
      i18nKey = 'loginError.message.email-not-verified'
    }
  }

  useEffect(() => {
    // hotfix to remove the '/callback?error=...' state part from the current URL,
    // without reloading the page.
    // this way, the user can reload the page after resolving the errors
    // (such as loggin in), and it will correctly re-load the auth0 state.
    // For example if the email was verified, it will proceed to the next App steps.
    window.history.replaceState(
      null,
      document.title,
      new URL(window.location.href).origin,
    )
  }, [])

  return (
    <div className={'LoginErrorPage'}>
      {isEmailVerificationPendingError ? (
        <div className={'verify-email-message'}>
          <Button onClick={onAuth0Logout} className={'logout-button'}>
            {t('loginError.action.logout')}
          </Button>
          <PluggyLogo />
          <p>{t(i18nKey, { email })}</p>
        </div>
      ) : (
        <>
          <Alert
            message={
              <Trans
                i18nKey={i18nKey}
                values={{
                  message: error || auth0Error?.message,
                }}
              />
            }
            type={'error'}
            size={'medium'}
          />
          <Button onClick={onAuth0Logout}>
            {t('loginError.action.logout')}
          </Button>
        </>
      )}
    </div>
  )
}

export default memo(LoginErrorPage)
