import React from 'react'

import Icon from '../Icon'

import './CopyIcon.css'

type Props = {
  onClick?: () => void
  className?: string
}

const CopyIcon = ({ onClick, className }: Props) => (
  <Icon
    className={`CopyIcon ${className || ''}`}
    onClick={onClick}
    width="20"
    height="20"
    viewBox="0 0 20 20"
    fill="none"
  >
    <path
      id="Shape"
      fillRule="evenodd"
      clipRule="evenodd"
      d="M12.1667 3H5.16667C4.52208 3 4 3.58734 4 4.3125V13.5H5.16667V4.3125H12.1667V3ZM14.4222 5.33333H7.57778C6.89022 5.33333 6.33333 5.91407 6.33333 6.62963V15.7037C6.33333 16.4193 6.89022 17 7.57778 17H14.4222C15.1092 17 15.6667 16.4193 15.6667 15.7037V6.62963C15.6667 5.91407 15.1092 5.33333 14.4222 5.33333ZM7.5 6.5H14.5V15.8333H7.5V6.5Z"
      fill="#111111"
    />
  </Icon>
)

export default React.memo(CopyIcon)
