import { connect } from 'react-redux'

import { auth0LogoutRequest } from '../../../modules/auth0/actions'
import { getError } from '../../../modules/auth0/selectors'
import { RootState } from '../../../modules/reducer'
import LoginErrorPage from './LoginErrorPage'
import {
  MapDispatch,
  MapDispatchProps,
  MapStateProps,
} from './LoginErrorPage.types'

const mapState = (state: RootState): MapStateProps => ({
  error: getError(state),
})

const mapDispatch = (dispatch: MapDispatch): MapDispatchProps => ({
  onAuth0Logout: () => dispatch(auth0LogoutRequest()),
})

export default connect(mapState, mapDispatch)(LoginErrorPage)
