import { memo, useCallback } from 'react'
import { useTranslation } from 'react-i18next'

import { Input, Radio } from '@pluggyai/ui'
// TODO: export in pluggy-ui index
import { Option } from '@pluggyai/ui/dist/components/Dropdown/Dropdown.types'

import { TrackEventName } from '../../../modules/analytics/events'
import { track } from '../../../modules/analytics/utils'
import { ConnectorFilterOption, Props } from './ConnectorsFilter.types'
import { buildConnectorsFilterOptionGroups } from './utils'

import './ConnectorsFilter.css'

const ConnectorsFilter = ({
  disabled,
  connectors,
  selectedConnector,
  onChange,
  showSandbox,
}: Props) => {
  const { t } = useTranslation()

  const handleDropdownChange = useCallback(
    ({ value }: { value: Option | Option[] }) => {
      // Note: fn type 'Option | Option[]' is neccesary to match the type of
      //  pluggy-ui Input.type='select' signature,
      //  We type-cast it below as a workaround (TODO this needs improvement in pluggy-ui component API)
      const valueAsConnectorOption = value as ConnectorFilterOption

      track(TrackEventName.ITEMS_CHART_CONNECTOR_FILTER_CHANGE, {
        previous: { selectedConnector },
        value: {
          selectedConnector: valueAsConnectorOption,
        },
      })

      onChange({ selectedConnector: valueAsConnectorOption })
    },
    [onChange, selectedConnector],
  )

  const handleToggleSandboxFilter = useCallback(() => {
    track(TrackEventName.ITEMS_CHART_SANDBOX_FILTER_CHANGE, {
      previous: { showSandbox },
      value: {
        selectedConnector: !showSandbox,
      },
    })

    onChange({
      showSandbox: !showSandbox,
    })
  }, [onChange, showSandbox])

  return (
    <Input
      label={t('dailyItems.connectors-filter.label')}
      className={'ConnectorsFilter'}
      type={'select'}
      value={selectedConnector}
      onChange={handleDropdownChange}
      options={buildConnectorsFilterOptionGroups(connectors)}
      disabled={disabled}
    >
      <div className={'sandbox-filter'}>
        <Radio
          checkbox
          label={t('dailyItems.sandbox-filter.label')}
          checked={showSandbox}
          onMouseDown={handleToggleSandboxFilter}
        />
      </div>
    </Input>
  )
}

export default memo(ConnectorsFilter)
