import { AxiosResponse } from 'axios'
import i18next from 'i18next'
import { all, call, put, select, takeEvery } from 'redux-saga/effects'

import { ConnectTokenService } from '../../lib/api/ConnectTokenService'
import { getAuth0AccessToken } from '../auth0/selectors'
import { getCurrentTeam } from '../team/selectors'
import { Team } from '../team/types'
import {
  fetchConnectTokenFailure,
  fetchConnectTokenSuccess,
  FETCH_CONNECT_TOKEN_REQUEST,
} from './actions'
import { ConnectToken, ConnectTokenRequest } from './types'

function* handleFetchConnectToken() {
  try {
    const accessToken: string = yield select(getAuth0AccessToken)
    if (!accessToken) {
      throw new Error('No access token in state, can not connect token')
    }
    const connectTokenService: ConnectTokenService = new ConnectTokenService(
      accessToken,
    )

    const currentTeam: Team | null = yield select(getCurrentTeam)

    const params: ConnectTokenRequest = {}

    if (currentTeam) {
      params.teamId = currentTeam.id
    }

    const { data }: AxiosResponse<ConnectToken> = yield call(() =>
      connectTokenService.createConnectToken(params),
    )

    yield put(fetchConnectTokenSuccess(data))
  } catch (error) {
    const errorMessage = i18next.t('connect-token.error.fetch')
    yield put(fetchConnectTokenFailure(errorMessage))
  }
}

export function* connectTokenSaga() {
  yield all([takeEvery(FETCH_CONNECT_TOKEN_REQUEST, handleFetchConnectToken)])
}
