import React, { useCallback } from 'react'
import { useTranslation } from 'react-i18next'

import { Popup } from 'semantic-ui-react'

import { buildPluggyDemoApplicationUrl } from '../../../modules/application/utils'
import { ArrowLink } from '../../ArrowLink'
import { Props } from './ApplicationDemoPreviewLink.types'

export const DEMO_LINK_MAX_DEVELOPMENT_APPLICATION_ITEMS = 100

const ApplicationDemoPreviewLink = ({
  application,
  user,
  onClick,
  label,
  href,
}: Props) => {
  const { t } = useTranslation()
  const { enabled } = application
  const previewDemoUrl =
    href || buildPluggyDemoApplicationUrl(application, user)

  const linkDisabledMessage: string | undefined = !enabled
    ? t(
        'application.header.demoPreviewLink.disabled-message.application-disabled',
      )
    : undefined

  const handleClick = useCallback(
    (event: React.MouseEvent<HTMLAnchorElement>) => {
      if (linkDisabledMessage) {
        // prevent href navigation if disabled
        event.preventDefault()
        event.stopPropagation()
        return
      }

      onClick?.(application)
    },
    [linkDisabledMessage, application, onClick],
  )

  return (
    <Popup
      className={'ApplicationDemoPreviewLink-Popup'}
      position={'bottom center'}
      content={linkDisabledMessage}
      disabled={!linkDisabledMessage}
      /* note: wrapping children into a div to properly hide/show the Popup even if 'disabled'
            related issue: https://github.com/Semantic-Org/Semantic-UI-React/issues/1413 */
      trigger={
        <div className={'ApplicationDemoPreviewLinkPopup'}>
          <ArrowLink
            label={label || t('application.header.demoPreviewLink.text')}
            href={linkDisabledMessage ? '' : previewDemoUrl}
            disabled={Boolean(linkDisabledMessage)}
            onClick={handleClick}
          />
        </div>
      }
    />
  )
}

export default React.memo(ApplicationDemoPreviewLink)
