import { connect } from 'react-redux'

import { RootState } from '../../modules/reducer'
import { getCurrentTeam } from '../../modules/team/selectors'
import { teamHasWebhooksCreated } from '../../modules/webhook/selectors'
import {
  FETCH_WEBHOOK_EVENTS_REQUEST,
  fetchWebhookEventsRequest,
} from '../../modules/webhookEvents/actions'
import {
  getWebhookEvents,
  getLoading,
  isLoadingType,
  getWebhookEventsFilters,
  getError,
} from '../../modules/webhookEvents/selectors'
import { WebhookEventsFilters } from '../../modules/webhookEvents/types'
import WebhookEventsPage from './WebhookEventsPage'
import {
  MapDispatch,
  MapDispatchProps,
  MapStateProps,
} from './WebhookEventsPage.types'

const mapState = (state: RootState): MapStateProps => ({
  webhookEvents: getWebhookEvents(state),
  loading: isLoadingType(getLoading(state), FETCH_WEBHOOK_EVENTS_REQUEST),
  teamHasWebhooksCreated: teamHasWebhooksCreated(state),
  webhookEventsFilters: getWebhookEventsFilters(state),
  error: getError(state),
  team: getCurrentTeam(state),
})

const mapDispatch = (dispatch: MapDispatch): MapDispatchProps => ({
  onFetchWebhookEvents: (webhookEventsFilters: WebhookEventsFilters) =>
    dispatch(fetchWebhookEventsRequest(webhookEventsFilters)),
})

export default connect(mapState, mapDispatch)(WebhookEventsPage)
