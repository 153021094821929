import React from 'react'
import { useTranslation } from 'react-i18next'

import { Popup } from '@pluggyai/ui'

import './StateIndicator.css'

type Props = {
  enabled: boolean
}

const StateIndicator = ({ enabled }: Props) => {
  const { t } = useTranslation()

  const displayText = enabled
    ? t('application.header.state.active')
    : t('application.header.state.disabled')

  return (
    <div className="StateIndicator">
      {!enabled && (
        <Popup
          position="top center"
          trigger={<div className={'dot disabled'} />}
        >
          {displayText}
        </Popup>
      )}
    </div>
  )
}

export default React.memo(StateIndicator)
