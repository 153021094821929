import { AxiosResponse } from 'axios'

import { ItemWithExecutions } from '../../modules/item/types'
import { BaseService } from './BaseService'

export class ItemService extends BaseService {
  async getItem(
    itemId: string,
    teamId: string,
  ): Promise<AxiosResponse<ItemWithExecutions>> {
    return await this.getServiceInstance().get(this.urls.GET_ITEM, {
      params: { itemId, teamId },
    })
  }
}
