import { connect } from 'react-redux'

import { RootState } from '../../../../modules/reducer'
import {
  DELETE_TEAM_MEMBER_REQUEST,
  deleteTeamMemberRequest,
  FETCH_TEAMS_REQUEST,
  resendTeamInvitationRequest,
  UPDATE_TEAM_MEMBER_ROLE_REQUEST,
  updateTeamMemberRoleRequest,
} from '../../../../modules/team/actions'
import {
  getCurrentTeam,
  getCurrentUserTeamRole,
  getLoading,
  isLoadingType,
  isUserTeamRole,
} from '../../../../modules/team/selectors'
import {
  Team,
  TeamInvitation,
  TeamMember,
  TeamRole,
} from '../../../../modules/team/types'
import { getIsUserActionsRequired } from '../../../../modules/user/selectors'
import TeamMembersSection from './TeamMembersSection'
import {
  MapDispatch,
  MapDispatchProps,
  MapStateProps,
} from './TeamMembersSection.types'

const mapState = (state: RootState): MapStateProps => ({
  team: getCurrentTeam(state),
  isLoading:
    isLoadingType(getLoading(state), FETCH_TEAMS_REQUEST) ||
    isLoadingType(getLoading(state), UPDATE_TEAM_MEMBER_ROLE_REQUEST) ||
    isLoadingType(getLoading(state), DELETE_TEAM_MEMBER_REQUEST),
  isOwnerOrAdminRole: isUserTeamRole(state, ['OWNER', 'ADMIN']),
  userTeamMemberRole: getCurrentUserTeamRole(state),
  isFeaturesLimited:
    !isUserTeamRole(state, ['OWNER', 'ADMIN']) ||
    getIsUserActionsRequired(state),
})

const mapDispatch = (dispatch: MapDispatch): MapDispatchProps => ({
  onResendTeamInvitation: (team: Team, teamInvitation: TeamInvitation) =>
    dispatch(resendTeamInvitationRequest(team, teamInvitation)),
  onDeleteTeamMember: (team: Team, teamMember: TeamMember | TeamInvitation) =>
    dispatch(deleteTeamMemberRequest(team, teamMember)),
  onTeamMemberRoleUpdate: (
    team: Team,
    teamMember: TeamMember | TeamInvitation,
    role: TeamRole,
  ) => dispatch(updateTeamMemberRoleRequest(team, teamMember, role)),
})

export default connect(mapState, mapDispatch)(TeamMembersSection)
