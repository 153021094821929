import { connect } from 'react-redux'

import { RootState } from '../../../../modules/reducer'
import {
  getCurrentTeam,
  getCurrentUserTeamRole,
} from '../../../../modules/team/selectors'
import TeamSubscriptionSection from './TeamSubscriptionSection'
import {
  MapDispatch,
  MapDispatchProps,
  MapStateProps,
} from './TeamSubscriptionSection.types'

const mapState = (state: RootState): MapStateProps => ({
  team: getCurrentTeam(state),
  userTeamMemberRole: getCurrentUserTeamRole(state),
})

const mapDispatch = (_dispatch: MapDispatch): MapDispatchProps => ({})

export default connect(mapState, mapDispatch)(TeamSubscriptionSection)
