import React from 'react'
import { useTranslation } from 'react-i18next'

import { Popup } from 'semantic-ui-react'

import { Props } from './IsDisabledPopup.types'

import './IsDisabledPopup.css'

const IsDisabledPopup = ({
  isFreeSubscription,
  isTeamMemberRole,
  children,
  className,
  messagePosition,
}: Props) => {
  const { t } = useTranslation()

  let popupMessage

  if (isTeamMemberRole) {
    popupMessage = t('customization.message.team-member-features-limited')
  } else if (isFreeSubscription) {
    popupMessage = t('customization.message.paid-subscription-required')
  }

  const userHasAllPermissions = !isFreeSubscription && !isTeamMemberRole

  return (
    <Popup
      className={'IsDisabledPopup-Popup'}
      position={messagePosition || 'top right'}
      content={popupMessage}
      disabled={userHasAllPermissions}
      /* note: wrapping children into a div to properly hide/show the Popup even if 'disabled'
            related issue: https://github.com/Semantic-Org/Semantic-UI-React/issues/1413 */
      trigger={
        <div className={`IsDisabledPopup ${className || ''}`}>{children}</div>
      }
    />
  )
}

export default React.memo(IsDisabledPopup)
