import { connect } from 'react-redux'

import { RootState } from '../../../modules/reducer'
import { getTheme } from '../../../modules/user/selectors'
import DailyChart from './DailyChart'
import {
  MapDispatch,
  MapDispatchProps,
  MapStateProps,
} from './DailyChart.types'

const mapState = (state: RootState): MapStateProps => ({
  theme: getTheme(state),
})

const mapDispatch = (_dispatch: MapDispatch): MapDispatchProps => ({
  // nothing yet
})

export default connect(mapState, mapDispatch)(DailyChart)
