import { ExecutionStatus } from 'pluggy-js'

import { ItemsTypeValue } from '../../components/Stats/ItemsTypeFilters'
import { ItemExecutionResultType } from '../../components/Stats/Report/Report.types'
import {
  DailyChartDarkThemeColors,
  DailyChartLightThemeColors,
} from '../../lib/constants/colors'
import { sumArray } from '../../utils/array'
import { ITEMS_STATS_EXECUTION_STATUS_GROUPS } from './constants'
import { ExecutionStatusReport, ItemStatsReport } from './types'

/**
 * Helper function to format a rate (0-1) number to a percentage with no decimal places
 * @param rate
 * @returns string
 */
export function formatRate(rate: number | undefined): string {
  if (rate === undefined) {
    return '-'
  }

  return `${(rate * 100).toFixed(0)}%`
}

/**
 * Helper function to format a rate (0-1) number to a percentage with two decimal places and a plus sign if positive
 * @param rate
 * @returns string
 */
export function formatPercentageOfChange(rate: number): string {
  return `${rate < 0 ? '' : '+'}${(rate * 100).toFixed(2)}%`
}

/** returns item stats report grouped by groups
 * groups can be seen above
 * @param itemStatsReport
 */
export function getItemStatsTypesGroupsReport(
  itemStatsReport: ItemStatsReport | undefined,
  colors: typeof DailyChartLightThemeColors | typeof DailyChartDarkThemeColors,
): ItemExecutionResultType[] {
  const objectsReports = ITEMS_STATS_EXECUTION_STATUS_GROUPS.map(
    ({ name, colorName, executionStatuses: types }) => {
      const groupStats = Object.entries(itemStatsReport || {}).filter(
        ([type]) =>
          isItemStatsReportFieldExecutionStatus(type) &&
          types.includes(mapItemStatsReportFieldToExecutionStatus(type)),
      )

      return {
        name,
        color: colors[colorName],
        value: sumArray(groupStats.map(([, value]) => value)),
        detail: Object.fromEntries(groupStats),
      }
    },
  )

  return objectsReports
}

export function mapExecutionStatusGroupsToExecutionStatuses(
  executionStatusGroups?: ItemsTypeValue[],
): ExecutionStatus[] | undefined {
  if (!executionStatusGroups) {
    return undefined
  }

  return executionStatusGroups.flatMap(
    (group) =>
      ITEMS_STATS_EXECUTION_STATUS_GROUPS.find(
        (groupType) => groupType.name === group,
      )?.executionStatuses ?? [],
  )
}

const ItemStatsReportFieldToExectutionStatus: Record<
  keyof ExecutionStatusReport,
  ExecutionStatus
> = {
  totalSuccessItems: 'SUCCESS',
  totalPartialSuccessItems: 'PARTIAL_SUCCESS',
  totalInvalidCredentialsItems: 'INVALID_CREDENTIALS',
  totalInvalidCredentialsMfaItems: 'INVALID_CREDENTIALS_MFA',
  totalUserAccountNeedsActionItems: 'ACCOUNT_NEEDS_ACTION',
  totalUserInputTimeoutItems: 'USER_INPUT_TIMEOUT',
  totalAccountLockedItems: 'ACCOUNT_LOCKED',
  totalSiteNotAvailableItems: 'SITE_NOT_AVAILABLE',
  totalConnectionErrorItems: 'CONNECTION_ERROR',
  totalErrorItems: 'ERROR',
  totalAlreadyLoggedInItems: 'ALREADY_LOGGED_IN',
  totalUserAuthorizationNotGrantedItems: 'USER_AUTHORIZATION_NOT_GRANTED',
  totalUserAuthorizationPendingItems: 'USER_AUTHORIZATION_PENDING',
}

function isItemStatsReportFieldExecutionStatus(
  field: string,
): field is keyof ExecutionStatusReport {
  return Object.keys(ItemStatsReportFieldToExectutionStatus).includes(field)
}

function mapItemStatsReportFieldToExecutionStatus(
  field: keyof ExecutionStatusReport,
): ExecutionStatus {
  return ItemStatsReportFieldToExectutionStatus[field]
}
