import { connect } from 'react-redux'

import {
  submitGoToProductionRequest,
  SUBMIT_GO_TO_PRODUCTION_REQUEST,
} from '../../modules/goToProduction/actions'
import {
  getLoading,
  isLoadingType,
} from '../../modules/goToProduction/selectors'
import { GoToProductionFields } from '../../modules/goToProduction/types'
import { RootState } from '../../modules/reducer'
import { getCurrentTeam, isUserTeamRole } from '../../modules/team/selectors'
import GoToProductionModal from './GoToProductionModal'
import {
  MapDispatch,
  MapDispatchProps,
  MapStateProps,
} from './GoToProductionModal.types'

const mapState = (state: RootState): MapStateProps => ({
  isOwnerOrAdminRole:
    !getCurrentTeam(state) || isUserTeamRole(state, ['OWNER', 'ADMIN']),
  loading: isLoadingType(getLoading(state), SUBMIT_GO_TO_PRODUCTION_REQUEST),
})

const mapDispatch = (dispatch: MapDispatch): MapDispatchProps => ({
  onSubmit: (fields: GoToProductionFields) =>
    dispatch(submitGoToProductionRequest(fields)),
})

export default connect(mapState, mapDispatch)(GoToProductionModal)
