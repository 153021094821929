import { action } from 'typesafe-actions'

import { ItemWithExecutions } from './types'

/// Fetch Item request

export const FETCH_ITEM_REQUEST = '[Request] Fetch Item'
export const FETCH_ITEM_SUCCESS = '[Success] Fetch Item'
export const FETCH_ITEM_FAILURE = '[Failure] Fetch Item'

export const fetchItemRequest = (itemId: string) =>
  action(FETCH_ITEM_REQUEST, itemId)
export const fetchItemSuccess = (item: ItemWithExecutions) =>
  action(FETCH_ITEM_SUCCESS, { item })
export const fetchItemFailure = (error: Error, itemId: string) =>
  action(FETCH_ITEM_FAILURE, { error, itemId })

export type FetchItemRequestAction = ReturnType<typeof fetchItemRequest>
export type FetchItemSuccessAction = ReturnType<typeof fetchItemSuccess>
export type FetchItemFailureAction = ReturnType<typeof fetchItemFailure>
