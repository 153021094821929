import { all, put, takeEvery } from 'redux-saga/effects'

import { ActivityService } from '../../lib/api/ActivityService'
import {
  FETCH_ACTIVITY_REQUEST,
  fetchActivityFailure,
  FetchActivityRequestAction,
  fetchActivitySuccess,
} from './actions'
import { ActivityItem } from './types'

function* handleFetchActivityRequest(_action: FetchActivityRequestAction) {
  try {
    // TODO: replace mock data with actual request backend is implemented
    const activityItems: ActivityItem[] = []
    yield put(fetchActivitySuccess(activityItems))
  } catch (error) {
    if (ActivityService.isServerError(error)) {
      const { code, message } = ActivityService.parseError(error)
      yield put(fetchActivityFailure(`${code} - ${message}`))
      return
    }
    yield put(fetchActivityFailure(error.message))
  }
}

export function* activitySaga() {
  yield all([takeEvery(FETCH_ACTIVITY_REQUEST, handleFetchActivityRequest)])
}
