import { Team } from '../team/types'
import { User } from '../user/types'

export type UpdateCustomizationValues = {
  welcomeText?: string
  logoImageUrl?: string | null
  primaryColor?: string | null
  borderRadius?: number | null
  connectorIds?: number[]
  welcomeStepButtonKey?: WelcomeStepButtonKey
  connectFormStepButtonKey?: ConnectFormStepButtonKey
}

const WELCOME_STEP_BUTTON_KEY_OPTIONS = [
  'CONTINUE',
  'GET_STARTED',
  'AGREE',
] as const

const CONNECT_FORM_STEP_BUTTON_KEY_OPTIONS = [
  'CONNECT',
  'CONNECT_MY_ACCOUNT',
  'SUBMIT',
] as const

export type WelcomeStepButtonKey =
  typeof WELCOME_STEP_BUTTON_KEY_OPTIONS[number]

export type ConnectFormStepButtonKey =
  typeof CONNECT_FORM_STEP_BUTTON_KEY_OPTIONS[number]

export type Customization = {
  id: string
  logoImageUrl: string | null
  primaryColor: string | null
  welcomeText: string | null
  borderRadius: string | null
  connectorIds: number[] | null
  user: User | null
  team: Team | null
  welcomeStepButtonKey: WelcomeStepButtonKey
  connectFormStepButtonKey: ConnectFormStepButtonKey
}

export type GeneralCustomizationFields = {
  welcomeText?: string
  logoImageUrl?: string
  primaryColor?: string
  borderRadius?: string
  welcomeStepButtonKey?: WelcomeStepButtonKey
  connectFormStepButtonKey?: ConnectFormStepButtonKey
}

export type ConnectorIdsCustomizationFields = {
  connectorIds?: number[]
}

export type GeneralCustomizationFieldsErrors = Partial<
  Omit<GeneralCustomizationFields, '_type'>
>

/**
 * Exhaustive list of all Connector keys and their IDs.
 * This list might get outdated as we continue to add more in pluggy-api,
 * so we have to regularly update it.
 *
 *  TODO 1: when connectors sagas implemented (https://pluggy.atlassian.net/browse/DVP-165), move this to that module
 *  TODO 2: We migrate this to the SDKs such as pluggy-js?
 */
export enum ConnectorId {
  AR_PERSONAL_PLUGGY_BANK = 0,
  AR_PERSONAL_PLUGGY_BANK_MFA_1STEP = 1,
  BR_PERSONAL_PLUGGY_BANK = 2,
  AR_PERSONAL_PLUGGY_BANK_MFA_2STEP = 3,
  BR_PERSONAL_PLUGGY_BANK_MFA_1STEP = 4,
  BR_PERSONAL_PLUGGY_BANK_MFA_2STEP = 5,
  AR_PERSONAL_PLUGGY_BANK_MFA_1_2_STEP = 6,
  AR_PERSONAL_PLUGGY_BANK_MFA_1_2_STEP_MULTI_ACCOUNTS = 7,
  AR_BUSINESS_BBVA = 100,
  AR_PERSONAL_BBVA = 101,
  AR_BUSINESS_BANCO_DE_CORDOBA = 102,
  AR_BUSINESS_BANCO_DE_COMERCIO = 103,
  AR_BUSINESS_BANCO_PROVINCIA_DE_NEUQUEN = 104,
  AR_BUSINESS_BANCO_NACION = 105,
  AR_BUSINESS_BANCO_DE_CORRIENTES = 106,
  AR_BUSINESS_BANCO_SAN_JUAN = 107,
  AR_BUSINESS_BANCO_ROELA = 108,
  AR_BUSINESS_BANCO_INDUSTRIAL = 109,
  AR_BUSINESS_BANCO_PROVINCIA = 110,
  AR_BUSINESS_BANCO_PATAGONIA = 111,
  AR_BUSINESS_BANCO_GALICIA = 112,
  AR_BUSINESS_BANCO_MACRO = 113,
  AR_BUSINESS_BANCO_DEL_CHACO = 114,
  AR_BUSINESS_BANCO_DEL_CHUBUT = 115,
  AR_BUSINESS_BANCO_CIUDAD = 116,
  AR_PERSONAL_MERCADOPAGO = 117,
  AR_BUSINESS_SUPERVIELLE = 118,
  AR_BUSINESS_REBANKING_MULTIFINANZAS = 119,
  AR_BUSINESS_SANTANDER = 120,
  BR_PERSONAL_ITAU = 201,
  BR_INVESTMENT_XP = 202,
  BR_PERSONAL_BRADESCO = 203,
  BR_PERSONAL_MODALMAIS = 204,
  BR_INVESTMENT_RICO_INVESTIMENTOS = 205,
  BR_PERSONAL_MERCADOPAGO = 206,
  BR_INVESTMENT_EASYNVEST = 207,
  BR_PERSONAL_SANTANDER = 208,
  BR_BUSINESS_BRADESCO = 209,
  BR_INVESTMENT_ORAMA_INVESTIMENTOS = 210,
  BR_PERSONAL_BANCO_DO_BRASIL = 211,
  BR_PERSONAL_NUBANK = 212,
  BR_INVESTMENT_GENIAL_INVESTIMENTOS = 213,
  BR_INVESTMENT_BTG_PACTUAL = 214,
  BR_PERSONAL_BANCO_INTER_PERSONAS = 215,
  BR_BUSINESS_CAIXA = 216,
  BR_BUSINESS_BANCO_DO_BRASIL = 217,
  BR_BUSINESS_ITAU = 218,
  BR_PERSONAL_CAIXA = 219,
  BR_INVESTMENT_AGORA = 220,
  BR_BUSINESS_SANTANDER = 221,
  BR_INVESTMENT_B3_CEI = 222,
  BR_INVESTMENT_CLEAR = 223,
  BR_PERSONAL_AILOS = 224,
  BR_BUSINESS_INTER = 225,
  BR_PERSONAL_C6_BANK = 226,
  BR_BUSINESS_SICREDI = 227,
  MX_BUSINESS_SANTANDER = 301,
  MX_BUSINESS_BBVA = 302,
}
