import React from 'react'

import Icon from '../Icon'

import './InfoIcon.css'

type Props = {
  onClick?: () => void
}

const InfoIcon = ({ onClick }: Props) => (
  <Icon
    className={'InfoIcon'}
    onClick={onClick}
    width="14"
    height="14"
    viewBox="0 0 14 14"
    fill="none"
  >
    <svg
      width="14"
      height="14"
      viewBox="0 0 14 14"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M7 1.3125C3.85888 1.3125 1.3125 3.85888 1.3125 7C1.3125 10.1412 3.85888 12.6875 7 12.6875C10.1412 12.6875 12.6875 10.1412 12.6875 7C12.6875 3.85888 10.1412 1.3125 7 1.3125ZM0 7C0 3.134 3.134 0 7 0C10.866 0 14 3.134 14 7C14 10.866 10.866 14 7 14C3.134 14 0 10.866 0 7ZM7.875 9.625C7.875 10.1083 7.48325 10.5 7 10.5C6.51676 10.5 6.125 10.1083 6.125 9.625C6.125 9.14174 6.51676 8.75 7 8.75C7.48325 8.75 7.875 9.14174 7.875 9.625ZM6.05572 5.32473C6.12616 5.18383 6.22153 5.0629 6.3523 4.97572C6.47896 4.89128 6.67733 4.8125 7 4.8125C7.24478 4.8125 7.4838 4.88824 7.64531 5.00938C7.7893 5.11737 7.875 5.25883 7.875 5.46875C7.875 5.62329 7.83929 5.69958 7.80748 5.74731C7.76779 5.80684 7.69933 5.87267 7.56565 5.9618C7.49881 6.00636 7.42689 6.04966 7.3391 6.10235L7.33373 6.10557L7.33372 6.10558C7.25173 6.15477 7.15491 6.21286 7.05977 6.27629C6.86532 6.40594 6.633 6.58623 6.45394 6.85485C6.25292 7.15643 6.33443 7.56387 6.63601 7.7649C6.93759 7.96592 7.34503 7.88441 7.54605 7.58283C7.58574 7.52328 7.6542 7.45744 7.78788 7.36831C7.85471 7.32376 7.92663 7.28045 8.01441 7.22778L8.01985 7.22452L8.0199 7.22449C8.10185 7.17532 8.19863 7.11725 8.29372 7.05385C8.48816 6.92422 8.72049 6.74395 8.89954 6.47535C9.08652 6.19495 9.1875 5.86108 9.1875 5.46875C9.1875 4.80367 8.87215 4.28888 8.43281 3.95938C8.01099 3.64301 7.48438 3.5 7 3.5C6.44767 3.5 5.98979 3.63996 5.62426 3.88365C5.26284 4.1246 5.03008 4.44117 4.88178 4.73777C4.71969 5.06194 4.85109 5.45613 5.17527 5.61822C5.49944 5.7803 5.89363 5.6489 6.05572 5.32473Z"
        fill="#F5F5F5"
      />
    </svg>
  </Icon>
)

export default React.memo(InfoIcon)
