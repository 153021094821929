import React, { useCallback, useEffect, useRef, useState } from 'react'
import { useTranslation } from 'react-i18next'

import { Container, Dropdown, Image, Menu } from 'semantic-ui-react'

import { TrackEventName } from '../../modules/analytics/events'
import { track } from '../../modules/analytics/utils'
import { locations } from '../../modules/routing/locations'
import { Team } from '../../modules/team/types'
import { isUserActionsRequired } from '../../modules/user/utils'
import useWindowDimensions from '../../utils/hooks/useWindowSize'
import { Avatar } from '../Avatar'
import { DropdownOpenIcon, PlusIcon } from '../Icon'
import { TeamCreateModal } from '../TeamCreateModal'
import { DocumentationLinks } from './DocumentationLinks'
import { FloatingMenu } from './FloatingMenu'
import { LogoutItem } from './LogoutItem'
import { Props } from './Navbar.types'
import { NavigationItems } from './NavigationItems'
import { StatusPageItem } from './StatusPageItem'
import { SubscriptionTag } from './SubscriptionTag'
import { ThemeSwitchItem } from './ThemeSwitchItem'
import { UserSettingsItem } from './UserSettingsItem'

import './Navbar.css'

const PAGE_LINKS_HORIZONTAL_POSITIONS: Record<string, number> = {
  [locations.overview()]: 0,
  [locations.events()]: 80,
  [locations.executions()]: 130,
  [locations.applications()]: 180,
  [locations.customization()]: 500,
  [locations.settings()]: 700,
  [locations.noCode()]: 900,
}

const COMPACT_LAYOUT_MAX_WIDTH = 1350

const Navbar = ({
  user,
  teams,
  currentTeam,
  location,
  onLogout,
  onNavigate,
  onSelectCurrentProfile,
}: Props) => {
  const { t } = useTranslation()

  const pageTabsScrollRef = useRef<HTMLDivElement>(null)

  const { pathname: activeItem } = location

  const centerActiveItem = useCallback((name: string) => {
    if (!pageTabsScrollRef.current) {
      return
    }
    // because in mobile view, all page link buttons don't fit into the screen
    // and we have a horizontal scroll area, we want the active page link to be centered
    pageTabsScrollRef.current.scrollLeft = PAGE_LINKS_HORIZONTAL_POSITIONS[name]
  }, [])

  useEffect(() => {
    if (!activeItem) {
      return
    }
    centerActiveItem(activeItem)
  }, [activeItem, centerActiveItem])

  const handleLogoImageClick = useCallback(
    (event: React.MouseEvent<HTMLAnchorElement>) => {
      event.preventDefault()
      onNavigate(locations.root())
    },
    [onNavigate],
  )

  const [createTeamModalOpen, setCreateTeamModalOpen] = useState(false)

  const handleOnCreateTeamModalOpen = useCallback(() => {
    setCreateTeamModalOpen(true)
    track(TrackEventName.CTA_NEW_TEAM, {
      location: 'Navbar',
    })
  }, [])

  const handleOnCreateTeamModalClose = useCallback(() => {
    setCreateTeamModalOpen(false)
  }, [])

  const handleOnTeamProfileSelect = useCallback(
    (team: Team) => {
      if (currentTeam?.id === team.id) {
        // team was already selected, just return
        return
      }
      onSelectCurrentProfile(team)
    },
    [currentTeam, onSelectCurrentProfile],
  )

  const { width } = useWindowDimensions()
  // this breakpoint was determined by checking where do
  // the navbar elements overlap
  const isCompactLayout = width < COMPACT_LAYOUT_MAX_WIDTH

  const [isTeamDropdownOpen, setIsTeamDropdownOpen] = useState(false)

  const handleToggleTeamDropdownVisibility = useCallback(() => {
    setIsTeamDropdownOpen((isOpen) => !isOpen)
  }, [])

  const handleCloseTeamDropdown = useCallback(
    () => setIsTeamDropdownOpen(false),
    [],
  )
  return (
    <Menu className={'Navbar'}>
      <Menu className={'top-row'}>
        <Container>
          <Menu.Menu position={'left'}>
            <Menu.Item header className={'logo-item'}>
              <Image
                className={'logo'}
                as={'a'}
                onClick={handleLogoImageClick}
                href={locations.root()}
                src="/logo192.png"
                alt="Pluggy logo"
              />
            </Menu.Item>
            {createTeamModalOpen && (
              <TeamCreateModal onClose={handleOnCreateTeamModalClose} />
            )}
            {currentTeam && (
              <Dropdown
                className={`current-profile ${
                  !isTeamDropdownOpen ? 'closed' : ''
                }`}
                item
                onClick={handleToggleTeamDropdownVisibility}
                onClose={handleCloseTeamDropdown}
                trigger={
                  <>
                    <Avatar pictureUrl={currentTeam.imageUrl} />
                    <span className={'profile-name ellipsis'}>
                      {currentTeam.name}
                    </span>
                    <SubscriptionTag team={currentTeam} />
                  </>
                }
                icon={<DropdownOpenIcon />}
              >
                <Dropdown.Menu direction={'left'}>
                  <Dropdown.Header>
                    {t('navbar.profile-selector.header.teams')}
                  </Dropdown.Header>
                  {teams?.map((team: Team, i) => (
                    <Dropdown.Item
                      key={`${team.name}_${i}`}
                      className={`profile-option ${
                        currentTeam.id === team.id ? 'selected' : ''
                      }`}
                      onClick={() => handleOnTeamProfileSelect(team)}
                    >
                      <Avatar pictureUrl={team.imageUrl} />
                      <span className={'team-name ellipsis'}>{team.name}</span>
                      <SubscriptionTag team={team} />
                    </Dropdown.Item>
                  ))}
                  <Dropdown.Divider />
                  <Dropdown.Item
                    className={'create-team'}
                    onClick={handleOnCreateTeamModalOpen}
                    disabled={user ? isUserActionsRequired(user) : false}
                  >
                    {t('navbar.profile-selector.action.new-team')}
                    <PlusIcon />
                  </Dropdown.Item>
                </Dropdown.Menu>
              </Dropdown>
            )}

            {!isCompactLayout && (
              <NavigationItems
                activeItem={activeItem}
                onNavigate={onNavigate}
              />
            )}
          </Menu.Menu>
          {isCompactLayout ? (
            // Personal Menu (Mobile version)
            <FloatingMenu onLogout={onLogout} onNavigate={onNavigate} />
          ) : (
            // Personal Menu (Desktop version)
            <Menu.Menu position={'right'} className={'borderless'}>
              <DocumentationLinks />
              <Dropdown
                item
                simple
                icon={null}
                inline
                className={'profile-options borderless'}
                trigger={
                  <div>
                    <Avatar pictureUrl={user?.imageUrl} />
                  </div>
                }
              >
                <Dropdown.Menu
                  size={'large'}
                  className={'borderless account-menu'}
                >
                  <StatusPageItem as={Dropdown.Item} />
                  <UserSettingsItem
                    as={Dropdown.Item}
                    onNavigate={onNavigate}
                  />
                  <ThemeSwitchItem as={Dropdown.Item} />
                  <LogoutItem as={Dropdown.Item} onLogout={onLogout} />
                </Dropdown.Menu>
              </Dropdown>
            </Menu.Menu>
          )}
        </Container>
      </Menu>
      {isCompactLayout && (
        <Container nav>
          <div className="bottom-nav" ref={pageTabsScrollRef}>
            <NavigationItems onNavigate={onNavigate} activeItem={activeItem} />
          </div>
        </Container>
      )}
    </Menu>
  )
}
export default React.memo(Navbar)
