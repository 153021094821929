import type { Webhook } from 'pluggy-sdk'

/**
 * Sorts webhooks by creation date in ascending order
 * @param webhooks
 * @returns webhooks
 */
export function sortByCreatedAtAscending(webhooks: Webhook[] | null) {
  if (!webhooks) {
    return null
  }
  return [...webhooks].sort(
    (webhook1: Webhook, webhook2: Webhook) =>
      new Date(webhook1.createdAt).getTime() -
      new Date(webhook2.createdAt).getTime(),
  )
}
