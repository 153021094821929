import { memo, useCallback, useState } from 'react'
import { useTranslation } from 'react-i18next'

import { Button } from '@pluggyai/ui'
import { Modal } from 'semantic-ui-react'

import { TrackEventName } from '../../modules/analytics/events'
import { track } from '../../modules/analytics/utils'
import {
  GoToProductionFields,
  GoToProductionFieldsErrors,
} from '../../modules/goToProduction/types'
import { GoToProductionForm } from '../GoToProductionForm'
import { validateRequestProductionFields } from '../GoToProductionForm/utils'
import { CloseIcon } from '../Icon'
import { Props } from './GoToProductionModal.types'

import './GoToProductionModal.css'

const initialValues: GoToProductionFields = {
  companyName: '',
  contactInfo: '',
}

const GoToProductionModal = ({
  children,
  isOwnerOrAdminRole,
  loading,
  onModalOpen,
  onSubmit,
}: Props) => {
  const { t } = useTranslation()

  const [open, setOpen] = useState(false)
  const [formErrors, setFormErrors] = useState<GoToProductionFieldsErrors>({})
  const [values, setValues] = useState<GoToProductionFields>(initialValues)

  const cancelButtonI18nKey = 'team.go-to-production.form.actions.cancel'
  const cancelButtonText = t(cancelButtonI18nKey)

  const handleClose = useCallback(() => {
    setOpen(false)
    setFormErrors({})
  }, [])

  const handleOpen = useCallback(() => {
    setOpen(true)
    onModalOpen()
  }, [onModalOpen])

  const handleValuesChange = useCallback(
    (fieldName: keyof GoToProductionFields, value: unknown) => {
      // clear field error, if any
      if (formErrors[fieldName]) {
        delete formErrors[fieldName]
        setFormErrors({ ...formErrors })
      }
      // set field value
      const newValues = { ...values, [fieldName]: value }
      setValues(newValues)
    },
    [formErrors, values],
  )
  const trackFormValidationError = useCallback(
    (errors: GoToProductionFieldsErrors) => {
      track(TrackEventName.FORM_VALIDATION_ERRORS, {
        fields: Object.keys(errors),
        location: 'ApplicationsPage GoToProductionForm',
      })
    },
    [],
  )

  const formSubmit = useCallback(
    (values_: GoToProductionFields) => {
      // validate fields values
      const currentFormErrors: GoToProductionFieldsErrors = {}
      let errorsCount = 0
      for (const [field, value] of Object.entries(values_)) {
        const fieldError: string | undefined = validateRequestProductionFields(
          field as keyof GoToProductionFields,
          value,
        )

        if (fieldError) {
          errorsCount++
        }

        if (fieldError) {
          currentFormErrors[field as keyof GoToProductionFields] =
            fieldError as string
        }
      }

      setFormErrors(currentFormErrors)
      if (errorsCount > 0) {
        // got errors, can't proceed
        trackFormValidationError(currentFormErrors)
        return
      }

      onSubmit(values)

      setValues(initialValues)
    },
    [onSubmit, trackFormValidationError, values],
  )

  const handleGoToProductionFormSubmit = useCallback(
    (event: React.FormEvent) => {
      event.preventDefault()

      formSubmit({ ...values })
    },
    [formSubmit, values],
  )

  const handleCloseIconClick = useCallback(() => {
    track(TrackEventName.ICON_CLICKED, {
      location: 'ApplicationsPage GoToProductionForm',
      icon: 'Close Icon',
    })
    handleClose()
  }, [handleClose])

  const handleCancel = useCallback(() => {
    track(TrackEventName.BUTTON_CLICKED, {
      location: 'ApplicationsPage GoToProductionForm',
      text: cancelButtonText,
      i18nKey: cancelButtonI18nKey,
    })
    handleClose()
  }, [cancelButtonText, handleClose])

  return (
    <Modal
      className={'GoToProductionModal'}
      onClose={handleCloseIconClick}
      open={open}
      closeIcon={<CloseIcon />}
      trigger={
        <Button
          primary
          onClick={handleOpen}
          className={'go-to-production-button'}
          disabled={!isOwnerOrAdminRole}
        >
          {children}
        </Button>
      }
    >
      <Modal.Header>
        {t('team.go-to-production.form.title')}
        <p className={'subtitle'}>{t('team.go-to-production.form.subtitle')}</p>
      </Modal.Header>
      <Modal.Content>
        <GoToProductionForm
          values={values}
          errors={formErrors}
          onFieldChange={handleValuesChange}
          onSubmit={handleGoToProductionFormSubmit}
          loading={loading}
        />
      </Modal.Content>
      <Modal.Actions>
        <Button secondary onClick={handleCancel}>
          {cancelButtonText}
        </Button>
        <Button
          primary
          onClick={handleGoToProductionFormSubmit}
          loading={loading}
        >
          {t('team.go-to-production.form.actions.contact-sales')}
        </Button>
      </Modal.Actions>
    </Modal>
  )
}

export default memo(GoToProductionModal)
