import React, { useCallback, useState } from 'react'
import { useTranslation } from 'react-i18next'

import { Button } from '@pluggyai/ui'

import { TrackEventName } from '../../../modules/analytics/events'
import { track } from '../../../modules/analytics/utils'
import { Avatar } from '../../Avatar'
import { NoOwnersLeftErrorModal } from '../../SettingsPage/TeamSettingsPage/TeamMembersSection/NoOwnersLeftErrorModal'
import { ExitTeamModal } from '../ExitTeamModal'
import { Props } from './TeamItem.types'

import './TeamItem.css'

const TeamItem = ({ team, user, isUserOnlyTeamOwner, onExitTeam }: Props) => {
  const { t } = useTranslation()

  const { imageUrl } = team

  const [isExitTeamModalOpen, setIsExitTeamModalOpen] = useState(false)
  const [isNoOwnersLeftErrorModalOpen, setIsNoOwnersLeftErrorModalOpen] =
    useState(false)

  const exitTeamModalButtonI18nKey = 'user.team-list.action.exit'
  const exitTeamModalButtonText = t(exitTeamModalButtonI18nKey)

  const handleExitButtonClick = useCallback(() => {
    track(TrackEventName.BUTTON_CLICKED, {
      location: 'UserSettingsPage TeamsList',
      text: exitTeamModalButtonText,
      i18nKey: exitTeamModalButtonI18nKey,
    })

    if (isUserOnlyTeamOwner(team)) {
      // is only team owner, show error
      setIsNoOwnersLeftErrorModalOpen(true)
      return
    }
    setIsExitTeamModalOpen(true)
  }, [exitTeamModalButtonText, isUserOnlyTeamOwner, team])

  const handleCloseExitTeamModal = useCallback(() => {
    setIsExitTeamModalOpen(false)
  }, [])

  const handleExitTeam = useCallback(() => {
    if (!user) {
      return
    }

    const member = team.members.find((_member) => _member.id === user.id)

    if (!member) {
      return
    }

    onExitTeam(team, member)
    handleCloseExitTeamModal()
  }, [handleCloseExitTeamModal, onExitTeam, team, user])

  const handleNoOwnersLeftErrorModalGoBack = useCallback(
    ({ text, i18nKey }: { text: string; i18nKey: string }) => {
      track(TrackEventName.BUTTON_CLICKED, {
        location: 'UserSettingsPage TeamsList',
        text,
        i18nKey,
      })

      setIsNoOwnersLeftErrorModalOpen(false)
    },
    [],
  )

  const handleCloseNoOwnersLeftErrorModal = useCallback(() => {
    track(TrackEventName.ICON_CLICKED, {
      location: 'UserSettingsPage TeamsList',
      icon: 'CloseIcon',
    })
    setIsNoOwnersLeftErrorModalOpen(false)
  }, [])

  return (
    <div className={'TeamItem'}>
      <Avatar className={'team-picture'} pictureUrl={imageUrl} />

      <h5 className={'team-name'}>{team.name}</h5>

      <Button onClick={handleExitButtonClick} text>
        {exitTeamModalButtonText}
      </Button>

      <ExitTeamModal
        isOpen={isExitTeamModalOpen}
        onCancel={handleCloseExitTeamModal}
        onExit={handleExitTeam}
      />

      {isNoOwnersLeftErrorModalOpen && (
        <NoOwnersLeftErrorModal
          onGoBack={handleNoOwnersLeftErrorModalGoBack}
          onClose={handleCloseNoOwnersLeftErrorModal}
        />
      )}
    </div>
  )
}

export default React.memo(TeamItem)
