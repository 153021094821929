import { loadingReducer } from '../loading/reducer'
import { LoadingState } from '../loading/types'
import {
  FETCH_CONNECT_TOKEN_FAILURE,
  FETCH_CONNECT_TOKEN_REQUEST,
  FETCH_CONNECT_TOKEN_SUCCESS,
  FetchConnectTokenFailureAction,
  FetchConnectTokenSuccessAction,
  FetchConnectTokenRequestAction,
} from './actions'

export type ConnectTokenState = {
  data: {
    connectToken?: string
  } | null
  loading: LoadingState
  error: string | null
}

const INITIAL_STATE: ConnectTokenState = {
  data: null,
  loading: [],
  error: null,
}

type ExecutionReducerAction =
  | FetchConnectTokenSuccessAction
  | FetchConnectTokenRequestAction
  | FetchConnectTokenFailureAction

export function connectTokenReducer(
  state: ConnectTokenState = INITIAL_STATE,
  action: ExecutionReducerAction,
): ConnectTokenState {
  switch (action.type) {
    case FETCH_CONNECT_TOKEN_REQUEST:
      return {
        ...state,
        error: null,
        loading: loadingReducer(state.loading, action),
      }
    case FETCH_CONNECT_TOKEN_SUCCESS:
      const { connectToken } = action.payload

      return {
        ...state,
        loading: loadingReducer(state.loading, action),
        error: null,
        data: { connectToken },
      }

    case FETCH_CONNECT_TOKEN_FAILURE:
      return {
        ...state,
        loading: loadingReducer(state.loading, action),
        error: action.payload.error,
        data: { connectToken: undefined },
      }
    default:
      return state
  }
}
