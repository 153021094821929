import { memo } from 'react'

import { ChevronDownIcon } from '../../Icon/ChevronDownIcon'
import { ChevronUpIcon } from '../../Icon/ChevronUpIcon'
import { Props } from './AccordionItem.types'

import './AccordionItem.css'

const AccordionItem = ({ children, title, onSelect, active }: Props) => (
  <div className={`AccordionItem ${active ? 'active' : ''}`}>
    <button className={'header'} onClick={onSelect}>
      <h3>{title}</h3>
      {active ? <ChevronUpIcon /> : <ChevronDownIcon />}
    </button>
    <div className="content">{children}</div>
  </div>
)

export default memo(AccordionItem)
