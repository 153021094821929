import React, { useCallback } from 'react'
import { useTranslation } from 'react-i18next'

import { Alert, Button } from '@pluggyai/ui'
import { Modal } from 'semantic-ui-react'

import { TrackEventName } from '../../../../../../modules/analytics/events'
import { track } from '../../../../../../modules/analytics/utils'
import { CloseIcon } from '../../../../../Icon'
import { Props } from './WebhookDeleteConfirmModal.types'

const WebhookDeleteConfirmModal = ({
  isLoading,
  error,
  onClose,
  onDeleteWebhook,
}: Props) => {
  const { t } = useTranslation()

  const cancelButton18nKey =
    'application.page.webhooks.delete.modal.action.cancel'
  const cancelButtonLabel = t(cancelButton18nKey)

  const deleteButtonI18nKey =
    'application.page.webhooks.delete.modal.action.delete'
  const deleteButtonLabel = t(deleteButtonI18nKey)

  const handleDeleteButtonClick = useCallback(() => {
    track(TrackEventName.BUTTON_CLICKED, {
      location: 'ApplicationWebhooks WebhookDeleteConfirmModal',
      i18nKey: deleteButtonI18nKey,
      text: deleteButtonLabel,
    })

    onDeleteWebhook()
    onClose()
  }, [deleteButtonLabel, onClose, onDeleteWebhook])

  const handleCancelButtonClick = useCallback(() => {
    track(TrackEventName.BUTTON_CLICKED, {
      location: 'ApplicationWebhooks WebhookDeleteConfirmModal',
      i18nKey: cancelButton18nKey,
      text: cancelButtonLabel,
    })

    onClose()
  }, [cancelButtonLabel, onClose])

  const handleClose = useCallback(() => {
    track(TrackEventName.MODAL_CLOSED, {
      location: 'ApplicationWebhooks WebhookDeleteConfirmModal',
    })

    onClose()
  }, [onClose])

  return (
    <Modal
      className={'WebhookDeleteConfirmModal'}
      onClose={handleClose}
      open={true}
      closeIcon={<CloseIcon />}
    >
      <Modal.Header>
        {t('application.page.webhooks.delete.modal.title')}
        {error !== null && (
          <Alert size={'medium'} type={'error'} message={error} />
        )}
      </Modal.Header>
      <Modal.Content>
        {t('application.page.webhooks.delete.modal.message')}
      </Modal.Content>
      <Modal.Actions>
        <Button onClick={handleCancelButtonClick} loading={isLoading}>
          {cancelButtonLabel}
        </Button>
        <Button primary onClick={handleDeleteButtonClick} loading={isLoading}>
          {deleteButtonLabel}
        </Button>
      </Modal.Actions>
    </Modal>
  )
}

export default React.memo(WebhookDeleteConfirmModal)
