import React from 'react'

import Icon from '../Icon'

import './MagnifyingGlassIcon.css'

const MagnifyingGlassIcon = () => (
  <Icon
    className={'MagnifyingGlassIcon'}
    width="40"
    height="40"
    viewBox="0 0 40 40"
    fill="none"
  >
    <g clipPath="url(#clip0_443_1703)">
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M39.4366 36.7147L30.8654 28.1457C36.6077 20.9635 35.7386 10.5479 28.8854 4.41683C22.0343 -1.71651 11.5832 -1.42762 5.07869 5.0724C-1.42574 11.5724 -1.7213 22.0191 4.40981 28.8747C10.5387 35.7301 20.9565 36.6036 28.1409 30.8659L36.7143 39.4367C37.1986 39.9213 37.9097 40.1124 38.572 39.9347C39.2366 39.7567 39.7566 39.239 39.9343 38.5747C40.112 37.9101 39.9232 37.2036 39.4366 36.7147ZM17.3387 30.8059C11.8876 30.8101 6.97204 27.5279 4.88538 22.4946C2.7987 17.4613 3.95204 11.6657 7.80538 7.8124C11.6587 3.95905 17.4543 2.80794 22.4898 4.8946C27.5254 6.97905 30.8054 11.8946 30.8032 17.3435C30.7986 24.7768 24.772 30.8036 17.3387 30.8059Z"
        fill="#4D4D4D"
      />
    </g>
    <defs>
      <clipPath id="clip0_443_1703">
        <rect width="40" height="40" fill="white" />
      </clipPath>
    </defs>
  </Icon>
)

export default React.memo(MagnifyingGlassIcon)
