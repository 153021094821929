export enum TrackEventName {
  USER_LOGGED_IN = 'User Logged In',
  FEEDBACK_SUBMITTED = 'Feedback Submitted',
  LINK_CLICKED = 'Link Clicked',
  BUTTON_CLICKED = 'Button Clicked',
  MODAL_OPENED = 'Modal Opened',
  MODAL_CLOSED = 'Modal Closed',
  ICON_CLICKED = 'Icon Clicked',
  GO_TO_PRODUCTION_REQUEST_SUBMITTED = 'Go To Production Request Submitted',
  NO_CODE_LANDING_REQUEST_SUBMITTED = 'No Code Landing Request Submitted',
  TAG_CLICKED = 'Tag Clicked',
  CHECKBOX_CLICKED = 'Checkbox Clicked',
  TOGGLE_CLICKED = 'Toggle Clicked',

  // TODO: refactor events: https://pluggy.atlassian.net/browse/DVP-149

  CTA_NEW_APPLICATION = 'CTA "New Application" Clicked',
  CTA_NEW_TEAM = 'CTA "New Team" Clicked',
  ITEMS_CHART_TIME_SELECTOR_CHANGE = 'Items Chart time selector Changed',
  ITEMS_CHART_TYPE_FILTER_CHANGE = 'Items Chart type filter Changed',
  ITEMS_CHART_APPLICATION_FILTER_CHANGE = 'Items Chart application filter Changed',
  ITEMS_CHART_CONNECTOR_FILTER_CHANGE = 'Items Chart connector filter Changed',
  ITEMS_CHART_SANDBOX_FILTER_CHANGE = 'Items Chart sandbox filter Changed',
  ITEMS_CHART_COMPONENT_CLICK = 'Items Chart Component Clicked',
  CTA_APPLICATIONS_VIEW_ALL = 'CTA "View more" (ApplicationsList) Clicked',
  LINK_APPLICATION_MORE = 'Link "More" (Application) Clicked',
  CTA_APPLICATION_GO_TO_DEMO = 'Icon ↗ (Application) Clicked',
  CTA_APPLICATION_GO_TO_NO_CODE_DASHBOARD = 'Icon ↗ (Application) Clicked (No Code)',
  ICON_APPLICATION_CLIENT_ID_COPY = 'Icon "Copy" Client Id (Application) Clicked',
  ICON_APPLICATION_CLIENT_SECRET_SHOW = 'Icon "Show" Client (Application) Clicked',
  ICON_APPLICATION_CLIENT_SECRET_COPY = 'Icon "Copy" Client Secret (Application) Clicked',
  FORM_VALIDATION_ERRORS = 'Form Validation Error(s)',
  LINK_APPLICATION_ADD_DOMAIN = 'Link "Add new domain" (Application) Clicked',
  ICON_APPLICATION_REMOVE_DOMAIN = 'Icon "Remove domain" (Application) Clicked',
  APPLICATION_DETAILS_UPDATE_SUBMIT = 'Application Update Submitted',
  CTA_APPLICATION_ENABLE_DISABLE = 'CTA "Enable/Disable" (Application) Clicked',
  CTA_APPLICATION_DELETE = 'CTA "Delete" (Application) Clicked',
  CTA_APPLICATION_DELETE_MODAL_CONFIRM = 'CTA "Confirm" (Application Delete Modal) Clicked',
  LINK_TEAM_INVITE_MEMBERS = 'Link "Invite new members" Clicked',
  CTA_TEAM_INVITE_MEMBERS_MODAL_CONFIRM = 'CTA "Confirm" (Team Invite Members Modal) Clicked',
  CTA_NEW_TEAM_MODAL_CREATE = 'CTA "Create" (New Team modal) Clicked',
  CTA_NEW_TEAM_MODAL_CANCEL = 'CTA "Cancel" (New Team modal) Clicked',
  CTA_NEW_TEAM_MODAL_CLOSE = 'Icon "Close" (New Team modal) Clicked',
  CTA_VERIFICATION_WIDGET_CLOSE = 'Icon "Close" (Verification Widget) Clicked',
  LINK_RESEND_VERIFICATION_EMAIL = 'Link "Resend" (Verification Widget) Clicked',
  LINK_VERIFICATION_TERMS_OPEN = 'Open Terms Modal',
  CTA_VERIFICATION_TERMS_MODAL_ACCEPT = 'CTA "Accept" (Terms Modal) Clicked',
  CTA_VERIFICATION_TERMS_MODAL_CANCEL = 'CTA "Cancel" (Terms Modal) Clicked',
  CTA_VERIFICATION_TERMS_MODAL_CLOSE = 'CTA "Close" (Terms Modal) Clicked',

  TEAM_MEMBER_ROLE_CHANGE = 'Team Member role Changed',
  TEAM_INVITATION_ROLE_CHANGE = 'Team Invitation role Changed',
  TEAM_DETAILS_UPDATE_SUBMIT = 'Team Update Submitted',

  CTA_TEAM_MEMBER_DELETE = 'CTA "Delete" (Team Member) Clicked',
  CTA_TEAM_INVITATION_DELETE = 'CTA "Delete" (Team Invitation) Clicked',

  CTA_TEAM_DELETE = 'CTA "Delete" (Team) Clicked',
  CTA_TEAM_DELETE_MODAL_CONFIRM = 'CTA "Confirm" (Team Delete Modal) Clicked',

  CUSTOMIZATION_DETAILS_UPDATE_SUBMIT = 'Customization Update Submitted',
  CUSTOMIZATION_CONNECTOR_TYPES_CHANGE = 'Customization Connector Types Filter Changed',

  PLUGGY_CONNECT_WIDGET_EVENT = 'Pluggy Connect Widget Event',

  WEBHOOK_EVENTS_DATE_FILTER_CHANGE = 'Webhook Events Date Filter Changed',
  WEBHOOK_EVENTS_EVENT_FILTER_CHANGE = 'Webhook Events Event Filter Changed',
  WEBHOOK_EVENTS_SEARCH_CHANGE = 'Webhook Events Search Changed',
  WEBHOOK_EVENTS_PAGE_CHANGE = 'Webhook Events Page Changed',

  ITEM_EXECUTIONS_SEARCH_BOX_CLICKED = 'Item Executions Search Box Clicked',
  ITEM_EXECUTIONS_SEARCHED = 'Item Executions Searched',
}
