import type { Connector } from 'pluggy-js'
import { AnyAction } from 'redux'

import { LoadingState } from '../loading/types'
import { RootState } from '../reducer'
import { getCurrentTeam } from '../team/selectors'
import { isFreeSubscription } from '../team/types'
import { CustomizationState } from './reducer'
import { Customization } from './types'

export const getState = (state: RootState): CustomizationState =>
  state.customization as CustomizationState
export const getData = (state: RootState) => getState(state).data
export const getLoading = (state: RootState) => getState(state).loading
export const getError = (state: RootState) => getState(state).error

export const isLoadingType = (state: LoadingState, type: string): boolean =>
  state.some((action: AnyAction) => action.type === type)

export const isCurrentUserFreeSubscription = (state: RootState): boolean => {
  const currentTeam = getCurrentTeam(state)
  if (!currentTeam) {
    // no team, consider as 'FREE'
    return true
  }
  const { subscription } = currentTeam
  return isFreeSubscription(subscription)
}

export const getCustomization = (state: RootState): Customization | null =>
  getData(state).customization

export const getConnectors = (state: RootState): Connector[] | null =>
  getData(state).connectors

export const getConnectorsError = (state: RootState): string | null =>
  getError(state).connectors
