import { connect } from 'react-redux'

import {
  FETCH_APPLICATION_REQUEST,
  FETCH_APPLICATIONS_REQUEST,
  fetchApplicationRequest,
} from '../../modules/application/actions'
import {
  getData as getApplications,
  getError as getApplicationsError,
  getLoading as getApplicationsLoading,
  isLoadingApplicationSelector,
  isLoadingType,
} from '../../modules/application/selectors'
import { RootState } from '../../modules/reducer'
import {
  FETCH_ITEMS_STATS_REQUEST,
  fetchItemsStatsRequest,
} from '../../modules/stats/actions'
import {
  getApplicationsItemsStatsByApplicationId,
  getLoading as getStatsLoading,
} from '../../modules/stats/selectors'
import { ItemsStatsRequest } from '../../modules/stats/types'
import ApplicationsList from './ApplicationsList'
import {
  MapDispatch,
  MapDispatchProps,
  MapStateProps,
} from './ApplicationsList.types'

const mapState = (state: RootState): MapStateProps => ({
  error: getApplicationsError(state),
  isLoadingApplications:
    isLoadingType(getApplicationsLoading(state), FETCH_APPLICATIONS_REQUEST) ||
    isLoadingType(getStatsLoading(state), FETCH_ITEMS_STATS_REQUEST),
  isLoadingApplication: isLoadingApplicationSelector(
    state,
    FETCH_APPLICATION_REQUEST,
  ),
  applications: getApplications(state),
  itemsStatsByApplicationId: getApplicationsItemsStatsByApplicationId(state),
})

const mapDispatch = (dispatch: MapDispatch): MapDispatchProps => ({
  onFetchApplication: (id: string) => dispatch(fetchApplicationRequest(id)),
  onFetchItemStats: (filterableStats?: ItemsStatsRequest) =>
    dispatch(fetchItemsStatsRequest(filterableStats)),
})

export default connect(mapState, mapDispatch)(ApplicationsList)
