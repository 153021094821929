import { memo } from 'react'

const AgilityIcon = () => (
  <svg
    width="24"
    height="24"
    viewBox="0 0 40 40"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M5 11.6667H35V20C31.6667 26.6667 26.6667 30 20 30C13.3333 30 8.33333 26.6667 5 20V11.6667Z"
      stroke="#EBE8F1"
      fill="#271158"
      strokeWidth="3"
      strokeLinejoin="round"
    />
    <path
      d="M27.5 28.3333L26.6667 36.6666H13.3333L12.5 28.3333"
      stroke="#EBE8F1"
      strokeWidth="3"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M18.3333 20H21.6666"
      stroke="#EBE8F1"
      strokeWidth="3"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M13.3333 3.33331V9.99998"
      stroke="#EBE8F1"
      strokeWidth="3"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M26.6667 3.33331V9.99998"
      stroke="#EBE8F1"
      strokeWidth="3"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
  </svg>
)

export default memo(AgilityIcon)
