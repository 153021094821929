import { connect } from 'react-redux'

import { RootState } from '../../modules/reducer'
import { getTeams } from '../../modules/team/selectors'
import Page from './Page'
import { MapDispatch, MapDispatchProps, MapStateProps } from './Page.types'

const mapState = (state: RootState): MapStateProps => ({
  teams: getTeams(state),
})

const mapDispatch = (_dispatch: MapDispatch): MapDispatchProps => ({
  // nothing here, yet
})

export default connect(mapState, mapDispatch)(Page)
