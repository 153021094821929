import * as React from 'react'

import Icon from '../Icon'

import './ChevronUpIcon.css'

type Props = {
  onClick?: () => void
}

const ChevronUpIcon = ({ onClick }: Props) => (
  <Icon
    width="20"
    height="20"
    viewBox="0 0 20 20"
    fill="none"
    className={'ChevronUpIcon'}
    onClick={onClick}
  >
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M4.18498 13.8815C3.93287 14.1506 3.52439 14.1506 3.27228 13.8815C3.02035 13.6122 3.02035 13.1757 3.27228 12.9066L9.627 6.11865C9.87893 5.84934 10.2876 5.84934 10.5397 6.11865L16.8942 12.9066C17.1464 13.1757 17.1464 13.6122 16.8942 13.8815C16.6423 14.1506 16.2337 14.1506 15.9815 13.8815L10.0834 7.58088L4.18498 13.8815Z"
      fill="#111111"
    />
  </Icon>
)

export default React.memo(ChevronUpIcon)
