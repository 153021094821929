import { captureException } from '@sentry/react'
import { AxiosResponse } from 'axios'
import { push } from 'connected-react-router'
import i18next from 'i18next'
import { all, call, put, select, takeEvery } from 'redux-saga/effects'

import { ApplicationService } from '../../lib/api/ApplicationService'
import { isArrayEqual } from '../../utils/array'
import { isStringsArray } from '../../utils/validation'
import { getAuth0AccessToken } from '../auth0/selectors'
import { addNotificationAction } from '../notification/actions'
import { NotificationOptions } from '../notification/types'
import { locations } from '../routing/locations'
import { getCurrentTeam } from '../team/selectors'
import { Team } from '../team/types'
import { fetchWebhooksRequest } from '../webhook/actions'
import {
  CREATE_APPLICATION_REQUEST,
  createApplicationFailure,
  CreateApplicationRequestAction,
  createApplicationSuccess,
  DELETE_APPLICATION_REQUEST,
  deleteApplicationFailure,
  DeleteApplicationRequestAction,
  deleteApplicationSuccess,
  FETCH_APPLICATION_REQUEST,
  FETCH_APPLICATIONS_REQUEST,
  fetchApplicationFailure,
  FetchApplicationRequestAction,
  fetchApplicationsFailure,
  FetchApplicationsRequestAction,
  fetchApplicationsSuccess,
  fetchApplicationSuccess,
  UPDATE_APPLICATION_ENABLED_REQUEST,
  UPDATE_APPLICATION_REQUEST,
  updateApplicationEnabledFailure,
  UpdateApplicationEnabledRequestAction,
  updateApplicationEnabledSuccess,
  updateApplicationFailure,
  UpdateApplicationRequestAction,
  updateApplicationSuccess,
} from './actions'
import { getApplication } from './selectors'
import {
  Application,
  CreateApplicationRequest,
  UpdateApplicationRequest,
} from './types'

function isAllowedOriginsEmpty(origin: string) {
  return origin.length === 0
}

function* handleFetchApplicationsRequest(
  _action: FetchApplicationsRequestAction,
) {
  try {
    const accessToken: string = yield select(getAuth0AccessToken)
    const applicationService = new ApplicationService(accessToken)

    const currentTeam: Team | null = yield select(getCurrentTeam)
    const { data: applications }: AxiosResponse<Application[]> = yield call(
      () => applicationService.getApplications(currentTeam?.id),
    )

    yield put(fetchApplicationsSuccess(applications))

    for (const { id, enabled } of applications) {
      if (!enabled) {
        // If the application is disabled, we don't fech the webhooks
        // because the resource isn't available for disabled applications
        // and request will fail
        continue
      }
      yield put(fetchWebhooksRequest(id))
    }
  } catch (error) {
    const errorMessage = i18next.t('applications.error.fetch')
    yield put(fetchApplicationsFailure(errorMessage))
  }
}

function* handleFetchApplicationRequest(action: FetchApplicationRequestAction) {
  try {
    const accessToken: string = yield select(getAuth0AccessToken)
    const applicationService = new ApplicationService(accessToken)
    const { id } = action.payload
    const { data: application }: AxiosResponse<Application> = yield call(() =>
      applicationService.getApplication(id),
    )

    yield put(fetchApplicationSuccess(application))
  } catch (error) {
    const errorMessage = i18next.t('application.error.fetch')
    yield put(fetchApplicationFailure(errorMessage))
  }
}

function* handleCreateApplicationRequest(
  action: CreateApplicationRequestAction,
) {
  const { createApplicationFields, isProductionApplication, webhookSetup } =
    action.payload

  const createApplicationEnvironment = isProductionApplication
    ? 'PRODUCTION'
    : 'DEVELOPMENT'

  // map application form fields to create request body
  const applicationFields: CreateApplicationRequest = {
    allowedOrigins: createApplicationFields.allowedOrigins.filter(
      (origin) => !isAllowedOriginsEmpty(origin),
    ),
    name: createApplicationFields.name,
    shortDescription: createApplicationFields.shortDescription,
    environment: createApplicationEnvironment,
  }

  try {
    const accessToken: string = yield select(getAuth0AccessToken)
    const applicationService = new ApplicationService(accessToken)
    const currentTeam: Team | null = yield select(getCurrentTeam)
    if (currentTeam) {
      applicationFields.teamId = currentTeam.id
    }
    // submit create request
    const { data: application }: AxiosResponse<Application> = yield call(() =>
      applicationService.createApplication(applicationFields),
    )
    yield put(createApplicationSuccess(application))
    if (webhookSetup) {
      // if webhook setup is enabled, redirect to application page
      yield put(push(locations.application(application.id)))
      return
    }
    yield put(
      addNotificationAction({
        title: i18next.t('application.success.create.title'),
        message: i18next.t('application.success.create.message'),
        level: 'succeed',
      }),
    )
  } catch (error) {
    const errorMessage = i18next.t('application.error.create')
    yield put(createApplicationFailure(errorMessage))
  }
}

function* handleUpdateApplicationRequest(
  action: UpdateApplicationRequestAction,
) {
  const { id, updateApplicationFields } = action.payload
  // map application form fields to update request body
  const updatedApplicationFields: UpdateApplicationRequest = {
    allowedOrigins: updateApplicationFields.allowedOrigins.filter(
      (origin) => !isAllowedOriginsEmpty(origin),
    ),
    name: updateApplicationFields.name,
    shortDescription: updateApplicationFields.shortDescription,
  }

  try {
    const accessToken: string = yield select(getAuth0AccessToken)
    const applicationService = new ApplicationService(accessToken)
    const currentApplication: Application = yield select(getApplication)

    // submit update request
    const { data: application }: AxiosResponse<Application> = yield call(() =>
      applicationService.updateApplication(id, updatedApplicationFields),
    )
    yield put(updateApplicationSuccess(application))

    const updatedApplicationKeys = Object.keys(updatedApplicationFields)
    // iterate in all keys of the object and if there is a delta
    // return a toast
    for (const key of updatedApplicationKeys) {
      const updatedValue =
        updatedApplicationFields[key as keyof UpdateApplicationRequest]
      const currentValue = currentApplication[key as keyof Application]

      let fieldUpdated: boolean
      if (isStringsArray(updatedValue) && isStringsArray(currentValue)) {
        fieldUpdated = !isArrayEqual(updatedValue, currentValue)
      } else {
        fieldUpdated = updatedValue !== currentValue
      }

      if (!fieldUpdated) {
        // if the field is not updated we skip it from iteration
        continue
      }
      yield put(
        addNotificationAction({
          title: i18next.t(`application.success.update.${key}.title`),
          message: i18next.t(`application.success.update.${key}.message`),
          duration: 4000,
          level: 'succeed',
        }),
      )
    }
  } catch (error) {
    const notificationOptions: NotificationOptions = {
      title: i18next.t('application.error.update.title'),
      message: i18next.t('application.error.update.message'),
      duration: 4000,
      level: 'error',
    }
    yield put(addNotificationAction(notificationOptions))
    error.message = `Could not update application: ${error.message}`
    captureException(error, {
      contexts: {
        error: {
          action,
          object: error,
          notificationOptions,
        },
      },
    })
    yield put(updateApplicationFailure(error.message))
  }
}

function* handleDeleteApplicationRequest(
  action: DeleteApplicationRequestAction,
) {
  const { id: applicationId } = action.payload

  try {
    const accessToken: string = yield select(getAuth0AccessToken)
    const applicationService = new ApplicationService(accessToken)
    // submit delete request
    yield call(() => applicationService.deleteApplication(applicationId))
    yield put(deleteApplicationSuccess(applicationId))
    yield put(
      addNotificationAction({
        title: i18next.t('application.success.delete.title'),
        message: i18next.t('application.success.delete.message'),
        level: 'succeed',
      }),
    )
  } catch (error) {
    const errorMessage = i18next.t('application.error.delete')
    yield put(deleteApplicationFailure(errorMessage))
  }
}

function* handleUpdateApplicationEnabledRequest(
  action: UpdateApplicationEnabledRequestAction,
) {
  const { id: applicationId, enabled } = action.payload

  try {
    const accessToken: string = yield select(getAuth0AccessToken)
    const applicationService = new ApplicationService(accessToken)
    // submit application enabled update request
    const { data: application }: AxiosResponse<Application> = yield call(() =>
      applicationService.updateApplication(applicationId, { enabled }),
    )
    yield put(updateApplicationEnabledSuccess(application))
    yield put(
      addNotificationAction({
        title: i18next.t(
          enabled
            ? 'application.success.update-enabled.title'
            : 'application.success.update-disabled.title',
        ),
        message: i18next.t(
          enabled
            ? 'application.success.update-enabled.message'
            : 'application.success.update-disabled.message',
        ),
        level: 'succeed',
      }),
    )
  } catch (error) {
    const errorMessage = enabled
      ? i18next.t('application.error.update-enabled')
      : i18next.t('application.error.update-disabled')
    yield put(updateApplicationEnabledFailure(errorMessage))
  }
}

export function* applicationSaga() {
  yield all([
    takeEvery(FETCH_APPLICATIONS_REQUEST, handleFetchApplicationsRequest),
    takeEvery(FETCH_APPLICATION_REQUEST, handleFetchApplicationRequest),
    takeEvery(UPDATE_APPLICATION_REQUEST, handleUpdateApplicationRequest),
    takeEvery(CREATE_APPLICATION_REQUEST, handleCreateApplicationRequest),
    takeEvery(DELETE_APPLICATION_REQUEST, handleDeleteApplicationRequest),
    takeEvery(
      UPDATE_APPLICATION_ENABLED_REQUEST,
      handleUpdateApplicationEnabledRequest,
    ),
  ])
}
