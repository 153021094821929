import React, { useCallback } from 'react'
import { useTranslation } from 'react-i18next'

import { locations } from '../../../modules/routing/locations'
import { Props } from './UserSettingsItem.types'

const userSettingsLocation = locations.user()

const UserSettingsItem = ({ as: Wrapper, onNavigate }: Props): JSX.Element => {
  const { t } = useTranslation()

  const handleUserSettingsItemClick = useCallback(
    (
      event:
        | React.MouseEvent<HTMLDivElement>
        | React.MouseEvent<HTMLAnchorElement>,
    ) => {
      event.preventDefault()
      onNavigate(userSettingsLocation)
    },
    [onNavigate],
  )

  return (
    <Wrapper
      className={'UserSettingsItem'}
      as={'a'}
      href={userSettingsLocation}
      onClick={handleUserSettingsItemClick}
    >
      {t('navbar.profile-options.action.user-settings')}
    </Wrapper>
  )
}

export default React.memo(UserSettingsItem)
