import { action } from 'typesafe-actions'

import { GoToProductionFields } from './types'

/**
 * Go to Production Request Submit
 */

export const SUBMIT_GO_TO_PRODUCTION_REQUEST =
  '[Request] Submit Go to Production'
export const SUBMIT_GO_TO_PRODUCTION_SUCCESS =
  '[Success] Submit Go to Production'
export const SUBMIT_GO_TO_PRODUCTION_FAILURE =
  '[Failure] Submit Go to Production'

export const submitGoToProductionRequest = (fields: GoToProductionFields) =>
  action(SUBMIT_GO_TO_PRODUCTION_REQUEST, fields)
export const submitGoToProductionSuccess = () =>
  action(SUBMIT_GO_TO_PRODUCTION_SUCCESS)
export const submitGoToProductionFailure = (error: string) =>
  action(SUBMIT_GO_TO_PRODUCTION_FAILURE, { error })

export type SubmitGoToProductionRequestAction = ReturnType<
  typeof submitGoToProductionRequest
>
export type SubmitGoToProductionSuccessAction = ReturnType<
  typeof submitGoToProductionSuccess
>
export type SubmitGoToProductionFailureAction = ReturnType<
  typeof submitGoToProductionFailure
>
