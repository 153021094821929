import { loadingReducer } from '../loading/reducer'
import { LoadingState } from '../loading/types'
import {
  AUTH0_LOGIN_FAILURE,
  AUTH0_LOGIN_REQUEST,
  AUTH0_LOGIN_SUCCESS,
  AUTH0_LOGOUT_FAILURE,
  AUTH0_LOGOUT_REQUEST,
  AUTH0_LOGOUT_SUCCESS,
  Auth0LoginFailureAction,
  Auth0LoginRequestAction,
  Auth0LoginSuccessAction,
  Auth0LogoutFailureAction,
  Auth0LogoutRequestAction,
  Auth0LogoutSuccessAction,
} from './actions'
import { Auth0Result } from './types'

export type Auth0State = {
  data: {
    auth0: Auth0Result | null
  }
  loading: LoadingState
  error: string | null
}

const INITIAL_STATE: Auth0State = {
  data: {
    auth0: null,
  },
  loading: [],
  error: null,
}

type Auth0LoginReducerAction =
  | Auth0LoginRequestAction
  | Auth0LoginSuccessAction
  | Auth0LoginFailureAction
  | Auth0LogoutRequestAction
  | Auth0LogoutSuccessAction
  | Auth0LogoutFailureAction

export function auth0Reducer(
  state: Auth0State = INITIAL_STATE,
  action: Auth0LoginReducerAction,
): Auth0State {
  switch (action.type) {
    case AUTH0_LOGIN_REQUEST:
    case AUTH0_LOGOUT_REQUEST:
      return {
        ...state,
        loading: loadingReducer(state.loading, action),
      }
    case AUTH0_LOGIN_SUCCESS:
      return {
        ...state,
        loading: loadingReducer(state.loading, action),
        data: {
          auth0: action.payload.auth0Result,
        },
        error: null,
      }
    case AUTH0_LOGIN_FAILURE:
    case AUTH0_LOGOUT_FAILURE:
      return {
        ...state,
        loading: loadingReducer(state.loading, action),
        error: action.payload.error,
      }
    case AUTH0_LOGOUT_SUCCESS: {
      return {
        ...state,
        loading: loadingReducer(state.loading, action),
        data: {
          auth0: null,
        },
        error: null,
      }
    }
    default:
      return state
  }
}
