import React from 'react'
import { useTranslation } from 'react-i18next'

import { Input } from '@pluggyai/ui'
import { Form } from 'semantic-ui-react'

import { AllowedUrlsInput } from './AllowedUrlsInput'
import { Props } from './ApplicationForm.types'

const ApplicationForm = ({
  onSubmit,
  values,
  onFieldChange,
  errors,
  isSubmitting,
  isEditAllowed,
  onAddNewAllowedUrlInput,
  onRemoveAllowedUrlInput,
  isDemoApplication,
  isEdit,
}: Props) => {
  const { t } = useTranslation()

  const isInputDisabled = isSubmitting || !isEditAllowed || isDemoApplication

  return (
    <Form onSubmit={onSubmit}>
      <Input
        label={t('application.form.field.name.label')}
        type="text"
        onChange={({ value }) => onFieldChange('name', value)}
        value={values['name']}
        error={errors['name']}
        disabled={isInputDisabled}
      />
      <Input
        label={t('application.form.field.shortDescription.label')}
        type="text"
        onChange={({ value }) => onFieldChange('shortDescription', value)}
        value={values['shortDescription']}
        error={errors['shortDescription']}
        disabled={isInputDisabled}
      />

      {/* if it's demo application, not show allowed origins */}
      {!isDemoApplication && isEdit && (
        <AllowedUrlsInput
          value={values['allowedOrigins']}
          errors={errors['allowedOrigins']}
          isSubmitting={isSubmitting}
          onFieldChange={onFieldChange}
          isEditAllowed={isEditAllowed}
          onAddNewAllowedUrlInput={onAddNewAllowedUrlInput}
          onRemoveAllowedUrlInput={onRemoveAllowedUrlInput}
          isEdit={isEdit}
        />
      )}
      {/* Hidden input to allow submitting with "enter" key */}
      <input type="submit" style={{ display: 'none' }} />
    </Form>
  )
}

export default React.memo(ApplicationForm)
