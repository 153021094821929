import React from 'react'
import { useTranslation } from 'react-i18next'

import { Divider, Header } from 'semantic-ui-react'

import { Section } from '../../../Section'
import { Props } from './TeamDangerZone.types'
import { TeamDeleteWidget } from './TeamDeleteWidget'

import './TeamDangerZone.css'

const TeamDangerZone = ({ team, isOwnerRole }: Props) => {
  const { t } = useTranslation()

  if (!team || !isOwnerRole) {
    return null
  }

  return (
    <>
      <Divider className={'full-width'} />
      <Section className={'TeamDangerZone'}>
        <Header as={'h3'}>{t('settings.team.danger.header')}</Header>
        <div className={'widgets-section'}>
          <TeamDeleteWidget team={team} />
        </div>
      </Section>
    </>
  )
}

export default React.memo(TeamDangerZone)
