// redux container

import { connect } from 'react-redux'

import { getApplication } from '../../../../../modules/application/selectors'
import { RootState } from '../../../../../modules/reducer'
import {
  getCurrentTeam,
  isUserTeamRole,
} from '../../../../../modules/team/selectors'
import WebhooksDisabledPopup from './WebhooksDisabledPopup'
import {
  MapStateProps,
  MapDispatch,
  MapDispatchProps,
} from './WebhooksDisabledPopup.types'

const mapState = (state: RootState): MapStateProps => ({
  isTeamMemberRole: Boolean(
    getCurrentTeam(state) && isUserTeamRole(state, ['MEMBER']),
  ),
  isApplicationEnabled: Boolean(getApplication(state)?.enabled),
})

const mapDispatch = (_dispatch: MapDispatch): MapDispatchProps => ({})

export default connect(mapState, mapDispatch)(WebhooksDisabledPopup)
