import { connect } from 'react-redux'

import {
  CREATE_APPLICATION_REQUEST,
  createApplicationRequest,
} from '../../modules/application/actions'
import {
  getError as getApplicationsError,
  getLoading as isLoadingApplications,
  isLoadingType,
} from '../../modules/application/selectors'
import { ApplicationFields } from '../../modules/application/types'
import { RootState } from '../../modules/reducer'
import { getCurrentTeam, isUserTeamRole } from '../../modules/team/selectors'
import { getIsUserActionsRequired } from '../../modules/user/selectors'
import CreateApplicationButton from './CreateApplicationButton'
import {
  MapDispatch,
  MapDispatchProps,
  MapStateProps,
} from './CreateApplicationButton.types'

const mapState = (state: RootState): MapStateProps => ({
  isOwnerOrAdminRole:
    !getCurrentTeam(state) || isUserTeamRole(state, ['OWNER', 'ADMIN']),
  error: getApplicationsError(state),
  isWaitingResponse: isLoadingType(
    isLoadingApplications(state),
    CREATE_APPLICATION_REQUEST,
  ),
  isFeaturesLimited: getIsUserActionsRequired(state),
})

const mapDispatch = (dispatch: MapDispatch): MapDispatchProps => ({
  onCreate: (
    createApplicationFields: ApplicationFields,
    isProductionApplication?: boolean,
    webhookSetup?: boolean,
  ) =>
    dispatch(
      createApplicationRequest(
        createApplicationFields,
        isProductionApplication,
        webhookSetup,
      ),
    ),
})

export default connect(mapState, mapDispatch)(CreateApplicationButton)
