import {
  Option,
  OptionGroup,
} from '@pluggyai/ui/dist/components/Dropdown/Dropdown.types'
import i18next from 'i18next'

import {
  BACKEND_PLATFORMS,
  MOBILE_PLATFORMS,
  WEB_PLATFORMS,
} from '../../lib/constants/platforms'
import { CompanyRole } from '../../modules/user/types'

export function mapRolesToOption(roles: CompanyRole[]): Option[] {
  return roles.map((role: CompanyRole) => ({
    name: i18next.t(`team.onboarding.form.field.company-role.value.${role}`),
    id: role,
  }))
}

/**
 * Helper function to format all custom role options by adding the prefix 'OTHER_'
 * @param role - a string with the custom role
 * @returns string
 */
export function formatCustomRole(role: string): string {
  return `OTHER_${role}`
}

/**
 *
 * @param groupLabel - the group label shown in the dropdown as a section header
 * @param platforms - a string array of platforms
 * @returns Option Group
 */
function mapPlatformsToOptionGroup(
  groupLabel: string,
  platforms: string[],
): OptionGroup {
  return {
    label: groupLabel,
    options: platforms.map((technology) => ({
      name: technology,
      id: `${groupLabel.toUpperCase()}_${technology}`,
    })),
  }
}

export function buildPlatformOptions(): OptionGroup[] {
  const frontendPlatformsOptionGroup = mapPlatformsToOptionGroup(
    'Web',
    WEB_PLATFORMS,
  )

  const mobilePlatformsOptionGroup = mapPlatformsToOptionGroup(
    'Mobile',
    MOBILE_PLATFORMS,
  )

  const backendPlatformsOptionGroup = mapPlatformsToOptionGroup(
    'Backend',
    BACKEND_PLATFORMS,
  )

  return [
    frontendPlatformsOptionGroup,
    mobilePlatformsOptionGroup,
    backendPlatformsOptionGroup,
  ]
}
