import { AxiosResponse } from 'axios'
import i18next from 'i18next'
import type { Webhook } from 'pluggy-sdk'
import { all, call, put, select, takeEvery } from 'redux-saga/effects'

import { WebhookService } from '../../lib/api/WebhookService'
import { getAuth0AccessToken } from '../auth0/selectors'
import { addNotificationAction } from '../notification/actions'
import {
  CREATE_WEBHOOK_REQUEST,
  CreateWebhookRequestAction,
  DELETE_WEBHOOK_REQUEST,
  DeleteWebhookRequestAction,
  FETCH_WEBHOOKS_REQUEST,
  FetchWebhooksRequestAction,
  UPDATE_WEBHOOK_REQUEST,
  UpdateWebhookRequestAction,
  createWebhookFailure,
  createWebhookSuccess,
  deleteWebhookFailure,
  deleteWebhookSuccess,
  fetchWebhooksFailure,
  fetchWebhooksSuccess,
  updateWebhookSuccess,
} from './actions'

function* handleFetchWebhooks(action: FetchWebhooksRequestAction) {
  const {
    payload: { applicationId },
  } = action

  try {
    const accessToken: string = yield select(getAuth0AccessToken)
    const webhookService = new WebhookService(accessToken)

    const { data: webhooks }: AxiosResponse<Webhook[]> = yield call(() =>
      webhookService.getWebhooks(applicationId),
    )

    yield put(fetchWebhooksSuccess(webhooks, applicationId))
  } catch (error) {
    yield put(fetchWebhooksFailure(error.message))
  }
}

function* handleCreateWebhook(action: CreateWebhookRequestAction) {
  const {
    payload: { applicationId, webhook },
  } = action

  try {
    const accessToken: string = yield select(getAuth0AccessToken)
    const webhookService = new WebhookService(accessToken)

    const { data: newWebhook }: AxiosResponse<Webhook> = yield call(() =>
      webhookService.createWebhook(applicationId, webhook),
    )

    yield put(createWebhookSuccess(newWebhook, applicationId))
    yield put(
      addNotificationAction({
        title: i18next.t('webhook.success.create.title'),
        message: i18next.t('webhook.success.create.message'),
        level: 'succeed',
      }),
    )
  } catch (error) {
    yield put(createWebhookFailure(error.message))
    yield put(
      addNotificationAction({
        title: i18next.t('webhook.failure.create.title'),
        message: i18next.t('webhook.failure.create.message'),
        level: 'error',
      }),
    )
  }
}

function* handleDeleteWebhook(action: DeleteWebhookRequestAction) {
  const {
    payload: { id, applicationId },
  } = action

  try {
    const accessToken: string = yield select(getAuth0AccessToken)
    const webhookService = new WebhookService(accessToken)

    yield call(() => webhookService.deleteWebhook(applicationId, id))
    yield put(deleteWebhookSuccess(id, applicationId))

    yield put(
      addNotificationAction({
        title: i18next.t('webhook.success.delete.title'),
        message: i18next.t('webhook.success.delete.message'),
        level: 'succeed',
      }),
    )
  } catch (error) {
    yield put(deleteWebhookFailure(error))
    yield put(
      addNotificationAction({
        title: i18next.t('webhook.failure.delete.title'),
        message: i18next.t('webhook.failure.delete.message'),
        level: 'error',
      }),
    )
  }
}

function* handleUpdateWebhook(action: UpdateWebhookRequestAction) {
  const {
    payload: { applicationId, webhook, id },
  } = action

  try {
    const accessToken: string = yield select(getAuth0AccessToken)
    const webhookService = new WebhookService(accessToken)

    const { data: newWebhook }: AxiosResponse<Webhook> = yield call(() =>
      webhookService.updateWebhook(applicationId, id, webhook),
    )

    yield put(updateWebhookSuccess(newWebhook, applicationId))

    if (webhook.enabled === true) {
      yield put(
        addNotificationAction({
          title: i18next.t('webhook.success.enable.title'),
          message: i18next.t('webhook.success.enable.message'),
          level: 'succeed',
        }),
      )
    } else if (webhook.enabled === false) {
      yield put(
        addNotificationAction({
          title: i18next.t('webhook.success.disable.title'),
          message: i18next.t('webhook.success.disable.message'),
          level: 'succeed',
        }),
      )
    }
  } catch (error) {
    yield put(createWebhookFailure(error.message))
    yield put(
      addNotificationAction({
        title: i18next.t('webhook.failure.update.title'),
        message: i18next.t('webhook.failure.update.message'),
        level: 'error',
      }),
    )
  }
}

export function* webhookSaga() {
  yield all([
    takeEvery(FETCH_WEBHOOKS_REQUEST, handleFetchWebhooks),
    takeEvery(CREATE_WEBHOOK_REQUEST, handleCreateWebhook),
    takeEvery(DELETE_WEBHOOK_REQUEST, handleDeleteWebhook),
    takeEvery(UPDATE_WEBHOOK_REQUEST, handleUpdateWebhook),
  ])
}
