import { connect } from 'react-redux'

import {
  FETCH_APPLICATION_REQUEST,
  UPDATE_APPLICATION_ENABLED_REQUEST,
  updateApplicationEnabledRequest,
} from '../../../../modules/application/actions'
import {
  getApplication,
  getError,
  isLoadingApplicationSelector,
} from '../../../../modules/application/selectors'
import { RootState } from '../../../../modules/reducer'
import ApplicationDisableWidget from './ApplicationDisableWidget'
import {
  MapDispatch,
  MapDispatchProps,
  MapStateProps,
} from './ApplicationDisableWidget.types'

const mapState = (state: RootState): MapStateProps => ({
  error: getError(state),
  isLoading: isLoadingApplicationSelector(state, [
    FETCH_APPLICATION_REQUEST,
    UPDATE_APPLICATION_ENABLED_REQUEST,
  ])(getApplication(state)),
})

const mapDispatch = (dispatch: MapDispatch): MapDispatchProps => ({
  onUpdateApplicationEnabled: (id: string, enabled: boolean) =>
    dispatch(updateApplicationEnabledRequest(id, enabled)),
})

export default connect(mapState, mapDispatch)(ApplicationDisableWidget)
