import React from 'react'
import { useTranslation } from 'react-i18next'

import { Divider, Header } from 'semantic-ui-react'

import { Section } from '../../Section'
import { UserDeleteWidget } from './UserDeleteWidget'

import './UserDangerZone.css'

const UserDangerZone = () => {
  const { t } = useTranslation()

  return (
    <>
      <Divider className={'full-width'} />
      <Section className={'UserDangerZone'}>
        <Header as={'h3'}>{t('settings.user.danger.header')}</Header>
        <div className={'widgets-section'}>
          <UserDeleteWidget />
        </div>
      </Section>
    </>
  )
}

export default React.memo(UserDangerZone)
