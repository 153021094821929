import { connect } from 'react-redux'

import { push } from 'connected-react-router'

import { getData as getApplications } from '../../modules/application/selectors'
import { Application } from '../../modules/application/types'
import { RootState } from '../../modules/reducer'
import { locations } from '../../modules/routing/locations'
import CreateWebhookButton from './CreateWebhookButton'
import {
  MapDispatch,
  MapDispatchProps,
  MapStateProps,
} from './CreateWebhookButton.types'

const mapState = (state: RootState): MapStateProps => ({
  applications: getApplications(state),
})

const mapDispatch = (dispatch: MapDispatch): MapDispatchProps => ({
  onSelectApplication: (applicationId: Application['id']) =>
    dispatch(push(locations.application(applicationId))),
})

export default connect(mapState, mapDispatch)(CreateWebhookButton)
