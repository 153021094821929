import { memo } from 'react'

const SecurityIcon = () => (
  <svg
    width="24"
    height="24"
    viewBox="0 0 40 40"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      className="icon-fill"
      fillRule="evenodd"
      clipRule="evenodd"
      d="M19.8095 3.90425C19.9334 3.8646 20.0666 3.8646 20.1905 3.90425L33.3155 8.10425C33.5742 8.18707 33.75 8.42772 33.75 8.6995V17.4996C33.75 20.8954 33.0633 24.1654 31.1065 27.148C29.1478 30.1337 25.804 33.0018 20.1928 35.3918C20.0717 35.4432 19.9284 35.4432 19.8072 35.3918C14.196 33.0018 10.8524 30.1337 8.89342 27.148C6.93663 24.1654 6.25 20.8954 6.25 17.4996V8.6995C6.25 8.42772 6.42565 8.18707 6.68453 8.10425L19.8095 3.90425ZM21.875 19.498C22.9959 18.8496 23.75 17.6377 23.75 16.2497C23.75 14.1786 22.0711 12.4997 20 12.4997C17.9289 12.4997 16.25 14.1786 16.25 16.2497C16.25 17.6377 17.0041 18.8496 18.125 19.498V25.6248C18.125 26.6602 18.9645 27.4998 20 27.4998C21.0355 27.4998 21.875 26.6602 21.875 25.6248V19.498Z"
      strokeWidth={0}
    />
    <path
      className="icon-stroke"
      fillRule="evenodd"
      clipRule="evenodd"
      d="M21.3334 0.332657C20.4661 0.055131 19.5339 0.0551295 18.6666 0.332655L5.5416 4.53265C3.72957 5.1125 2.5 6.79695 2.5 8.6995V17.4996C2.5 21.4147 3.29807 25.4557 5.758 29.205C8.21578 32.9512 12.2209 36.2365 18.3377 38.8418C19.3977 39.2933 20.6023 39.2933 21.6623 38.8418C27.779 36.2365 31.7843 32.9512 34.242 29.205C36.702 25.4557 37.5 21.4147 37.5 17.4996V8.6995C37.5 6.79695 36.2705 5.1125 34.4585 4.53265L21.3334 0.332657ZM19.8095 3.90425C19.9334 3.8646 20.0666 3.8646 20.1905 3.90425L33.3155 8.10425C33.5742 8.18707 33.75 8.42772 33.75 8.6995V17.4996C33.75 20.8954 33.0633 24.1654 31.1065 27.148C29.1478 30.1337 25.804 33.0018 20.1928 35.3918C20.0716 35.4432 19.9284 35.4432 19.8072 35.3918C14.1959 33.0018 10.8524 30.1337 8.89342 27.148C6.93663 24.1654 6.25 20.8954 6.25 17.4996V8.6995C6.25 8.42772 6.42565 8.18707 6.68453 8.10425L19.8095 3.90425ZM23.75 16.2497C23.75 17.6377 22.9959 18.8496 21.875 19.498V25.6248C21.875 26.6602 21.0355 27.4998 20 27.4998C18.9645 27.4998 18.125 26.6602 18.125 25.6248V19.498C17.0041 18.8496 16.25 17.6377 16.25 16.2497C16.25 14.1786 17.9289 12.4997 20 12.4997C22.0711 12.4997 23.75 14.1786 23.75 16.2497Z"
      strokeWidth={1}
    />
  </svg>
)

export default memo(SecurityIcon)
