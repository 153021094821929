import React, { useCallback, useMemo, useState } from 'react'
import { useTranslation } from 'react-i18next'

import { Button, Input } from '@pluggyai/ui'
import { Option } from '@pluggyai/ui/dist/components/Dropdown/Dropdown.types'
import { Modal } from 'semantic-ui-react'

import { TrackEventName } from '../../modules/analytics/events'
import { track } from '../../modules/analytics/utils'
import { CloseIcon } from '../Icon'
import { ApplicationFilterOption } from '../Stats/ApplicationFilters/ApplicationFilters.types'
import { Props } from './CreateWebhookButton.types'

import './CreateWebhookButton.css'

const CreateWebhookButton = ({
  children,
  applications,
  onSelectApplication,
  ...triggerButtonProps
}: Props) => {
  const { t } = useTranslation()
  const [open, setOpen] = useState(false)

  const [selectedApplication, setSelectedApplication] =
    useState<ApplicationFilterOption>()

  const cancelButtonI18nKey = 'webhook.form.action.cancel'
  const cancelButtonText = t(cancelButtonI18nKey)
  const setupButtonI18nKey = 'webhook.form.action.setup'
  const setupButtonText = t(setupButtonI18nKey)

  const location = 'CreateWebhookModal'

  const applicationOptions: Option[] = useMemo(
    () =>
      applications
        ?.filter(({ environment }) => environment !== 'DEMO')
        .map(({ id, name }) => ({
          id,
          name,
        })) || [],
    [applications],
  )

  const handleSelectedApplicationOptionChange = useCallback(
    ({ value }: { value: Option | Option[] }) => {
      const selectedApplicationOption = value as ApplicationFilterOption

      setSelectedApplication(selectedApplicationOption)
    },
    [],
  )

  const trackModalClose = useCallback(() => {
    track(TrackEventName.ICON_CLICKED, {
      location,
      icon: 'CloseIcon',
    })
  }, [])

  const trackCancelClick = useCallback(() => {
    track(TrackEventName.BUTTON_CLICKED, {
      location,
      text: cancelButtonText,
      i18nKey: cancelButtonI18nKey,
    })
  }, [cancelButtonText])

  const handleCancel = useCallback(() => {
    setOpen(false)
    trackCancelClick()
  }, [trackCancelClick])

  const handleClose = useCallback(() => {
    setOpen(false)
    trackModalClose()
  }, [trackModalClose])

  const handleOpen = useCallback(() => {
    setOpen(true)
  }, [])

  const buttonClassName = [triggerButtonProps.className]
    .filter(Boolean)
    .join(' ')

  const handleSetupButtonClick = useCallback(() => {
    if (!selectedApplication) {
      return
    }

    track(TrackEventName.BUTTON_CLICKED, {
      location,
      text: setupButtonText,
      i18nKey: setupButtonI18nKey,
    })

    onSelectApplication(selectedApplication.id)
  }, [onSelectApplication, selectedApplication, setupButtonText])

  return (
    <Modal
      className={'CreateWebhookButton'}
      onClose={handleClose}
      open={open}
      closeIcon={<CloseIcon />}
      trigger={
        <div className={'create-team-action'}>
          <Button
            onClick={handleOpen}
            {...{
              ...triggerButtonProps,
              className: buttonClassName,
            }}
          >
            {children}
          </Button>
        </div>
      }
    >
      <Modal.Header>
        {t('webhook-events.page.create-modal.title')}
        <p>{t('webhook-events.page.create-modal.description')}</p>
      </Modal.Header>
      <Modal.Content>
        <Input
          className={'select-application'}
          type={'select'}
          label={t('webhook-events.page.create-modal.select-application.label')}
          options={applicationOptions}
          onChange={handleSelectedApplicationOptionChange}
          value={selectedApplication}
        />
      </Modal.Content>
      <Modal.Actions>
        <Button secondary onClick={handleCancel}>
          {cancelButtonText}
        </Button>
        <Button
          primary
          onClick={handleSetupButtonClick}
          disabled={!selectedApplication}
        >
          {setupButtonText}
        </Button>
      </Modal.Actions>
    </Modal>
  )
}

export default React.memo(CreateWebhookButton)
