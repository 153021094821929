import React from 'react'

import { FreeTrialAlert } from '../../FreeTrialAlert'
import { WelcomeWidget } from '../../WelcomeWidget'
import { TeamDangerZone } from './TeamDangerZone'
import { TeamDetailsSection } from './TeamDetailsSection'
import { TeamMembersSection } from './TeamMembersSection'
import { TeamSubscriptionSection } from './TeamSubscriptionSection'

import './TeamSettingsPage.css'

const TeamSettingsPage = () => {
  return (
    <>
      <WelcomeWidget />
      <FreeTrialAlert />
      <TeamSubscriptionSection />
      <TeamDetailsSection />
      <TeamMembersSection />
      <TeamDangerZone />
    </>
  )
}

export default React.memo(TeamSettingsPage)
