import { ConnectorId } from '../../modules/customization/types'

export const PERSONAL_BANK_TYPE_IDS_ORDER: number[] = [
  ConnectorId.BR_PERSONAL_NUBANK,
  ConnectorId.BR_PERSONAL_ITAU,
  ConnectorId.BR_PERSONAL_BRADESCO,
  ConnectorId.BR_PERSONAL_BANCO_DO_BRASIL,
  ConnectorId.BR_PERSONAL_SANTANDER,
  ConnectorId.BR_PERSONAL_CAIXA,
  ConnectorId.BR_PERSONAL_BANCO_INTER_PERSONAS,
  ConnectorId.BR_PERSONAL_C6_BANK,
  ConnectorId.BR_PERSONAL_MERCADOPAGO,
  ConnectorId.BR_PERSONAL_MODALMAIS,
  ConnectorId.BR_PERSONAL_AILOS,
]

export const BUSINESS_BANK_TYPE_IDS_ORDER: number[] = [
  ConnectorId.BR_BUSINESS_SANTANDER,
  ConnectorId.BR_BUSINESS_ITAU,
  ConnectorId.BR_BUSINESS_BANCO_DO_BRASIL,
  ConnectorId.BR_BUSINESS_BRADESCO,
  ConnectorId.BR_BUSINESS_CAIXA,
  ConnectorId.BR_BUSINESS_INTER,
]

export const INVESTMENT_BANK_ORDER_TYPE_IDS_ORDER: number[] = [
  ConnectorId.BR_INVESTMENT_XP,
  ConnectorId.BR_INVESTMENT_BTG_PACTUAL,
  ConnectorId.BR_INVESTMENT_B3_CEI,
  ConnectorId.BR_INVESTMENT_EASYNVEST,
  ConnectorId.BR_INVESTMENT_GENIAL_INVESTIMENTOS,
  ConnectorId.BR_INVESTMENT_RICO_INVESTIMENTOS,
  ConnectorId.BR_INVESTMENT_CLEAR,
  ConnectorId.BR_INVESTMENT_AGORA,
  ConnectorId.BR_INVESTMENT_ORAMA_INVESTIMENTOS,
]
