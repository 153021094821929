import React from 'react'

import Icon from '../Icon'

import './MenuIcon.css'

type Props = {
  onClick?: () => void
  className?: string
}

const MenuIcon = ({ onClick, className }: Props) => (
  <Icon
    className={`MenuIcon ${className || ''}`}
    onClick={onClick}
    width="20"
    height="20"
    viewBox="0 0 20 20"
    fill="none"
  >
    <path
      d="M2 15H18"
      stroke="#F5F5F5"
      strokeWidth="2"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M2 10H18"
      stroke="#F5F5F5"
      strokeWidth="2"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M2 5H18"
      stroke="#F5F5F5"
      strokeWidth="2"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
  </Icon>
)

export default React.memo(MenuIcon)
