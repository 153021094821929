import React, { useCallback } from 'react'
import { useTranslation } from 'react-i18next'

import { Button } from '@pluggyai/ui'
import { Modal } from 'semantic-ui-react'

import { TrackEventName } from '../../../modules/analytics/events'
import { track } from '../../../modules/analytics/utils'
import { CloseIcon } from '../../Icon'
import { Props } from './ExitTeamModal.types'

import './ExitTeamModal.css'

const ExitTeamModal = ({ onExit, onCancel, isOpen }: Props): JSX.Element => {
  const { t } = useTranslation()

  const exitButtonI18nKey = 'user.team-list.exit-team-modal.action.exit'
  const exitButttonText = t(exitButtonI18nKey)

  const cancelButtonI18nKey = 'user.team-list.exit-team-modal.action.cancel'
  const cancelButtonText = t(cancelButtonI18nKey)

  const handleExitTeamButtonClick = useCallback(() => {
    track(TrackEventName.BUTTON_CLICKED, {
      location: 'UserSettingsPage ExitTeamModal',
      text: exitButttonText,
      i18nKey: exitButtonI18nKey,
    })
    onExit()
  }, [exitButttonText, onExit])

  const handleCancelButtonClick = useCallback(() => {
    track(TrackEventName.BUTTON_CLICKED, {
      location: 'UserSettingsPage ExitTeamModal',
      text: cancelButtonText,
      i18nKey: cancelButtonI18nKey,
    })
    onCancel()
  }, [cancelButtonText, onCancel])

  return (
    <Modal
      className={'ExitTeamModal'}
      open={isOpen}
      closeIcon={<CloseIcon />}
      onClose={onCancel}
    >
      <Modal.Header>{t('user.team-list.exit-team-modal.header')}</Modal.Header>
      <Modal.Content>
        <p className={'message'}>
          {t('user.team-list.exit-team-modal.message')}
        </p>
      </Modal.Content>
      <Modal.Actions>
        <Button secondary onClick={handleCancelButtonClick}>
          {cancelButtonText}
        </Button>
        <Button primary onClick={handleExitTeamButtonClick}>
          {exitButttonText}
        </Button>
      </Modal.Actions>
    </Modal>
  )
}

export default React.memo(ExitTeamModal)
