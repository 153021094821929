import { startOfDay } from 'date-fns'

import { loadingReducer } from '../loading/reducer'
import { LoadingState } from '../loading/types'
import {
  CHANGE_WEBHOOK_EVENTS_FILTERS,
  ChangeWebhookEventsFiltersAction,
  FETCH_WEBHOOK_EVENTS_FAILURE,
  FETCH_WEBHOOK_EVENTS_REQUEST,
  FETCH_WEBHOOK_EVENTS_SUCCESS,
  FetchWebhookEventsFailureAction,
  FetchWebhookEventsRequestAction,
  FetchWebhookEventsSuccessAction,
} from './actions'
import { WebhookEventsFilters, WebhookEvent } from './types'

export type WebhookEventsState = {
  data: {
    results: WebhookEvent[] | null
    totalPages: number
    filters: WebhookEventsFilters
  }
  loading: LoadingState
  error: string | null
}

const startOfToday = startOfDay(new Date())

export const DEFAULT_WEBHOOK_EVENTS_FILTERS: WebhookEventsFilters = {
  event: 'all',
  createdAtStartDate: startOfToday,
  createdAtEndDate: null,
  search: '',
  page: 1,
}

const INITIAL_STATE: WebhookEventsState = {
  data: {
    results: null,
    totalPages: 0,
    filters: DEFAULT_WEBHOOK_EVENTS_FILTERS,
  },
  loading: [],
  error: null,
}

type WebhooksEventsReducerAction =
  | FetchWebhookEventsRequestAction
  | FetchWebhookEventsSuccessAction
  | FetchWebhookEventsFailureAction
  | ChangeWebhookEventsFiltersAction

export function webhookEventsReducer(
  state: WebhookEventsState = INITIAL_STATE,
  action: WebhooksEventsReducerAction,
): WebhookEventsState {
  switch (action.type) {
    case FETCH_WEBHOOK_EVENTS_REQUEST:
      return {
        ...state,
        error: null,
        loading: loadingReducer(state.loading, action),
      }
    case FETCH_WEBHOOK_EVENTS_SUCCESS:
      const { totalPages, results } = action.payload

      return {
        ...state,
        loading: loadingReducer(state.loading, action),
        error: null,
        data: {
          ...state.data,
          totalPages,
          results,
        },
      }
    case FETCH_WEBHOOK_EVENTS_FAILURE:
      return {
        ...state,
        loading: loadingReducer(state.loading, action),
        error: action.payload.error,
      }
    case CHANGE_WEBHOOK_EVENTS_FILTERS:
      const { request: filters } = action.payload
      return {
        ...state,
        loading: loadingReducer(state.loading, action),
        data: {
          ...state.data,
          filters: {
            ...state.data.filters,
            ...filters,
            page: filters.page === undefined ? 1 : filters.page,
          },
        },
      }

    default:
      return state
  }
}
