import React, { useCallback, useState } from 'react'
import { Trans, useTranslation } from 'react-i18next'

import { Alert, Button, Input } from '@pluggyai/ui'
import { Form, Modal } from 'semantic-ui-react'

import { TrackEventName } from '../../../../modules/analytics/events'
import { track } from '../../../../modules/analytics/utils'
import { CloseIcon } from '../../../Icon'
import { Props } from './UserDeleteConfirmModal.types'

import './UserDeleteConfirmModal.css'

const UserDeleteConfirmModal = ({
  user,
  isLoading,
  error,
  onClose,
  onDeleteUser,
}: Props) => {
  const { t } = useTranslation()

  const { name } = user
  const [confirmUserName, setConfirmTeamName] = useState('')

  const confirmButtonI18nKey = 'settings.user.danger.delete.modal.action'
  const confirmButtonText = t(confirmButtonI18nKey)

  const isUserNameConfirmed = confirmUserName === name.trim()

  const handleSubmit = useCallback(() => {
    track(TrackEventName.BUTTON_CLICKED, {
      location: 'UserSettingsPage UserDeleteConfirmModal',
      text: confirmButtonText,
      i18nKey: confirmButtonI18nKey,
    })

    if (!isUserNameConfirmed) {
      return
    }
    onDeleteUser()
    // close the modal
    onClose()
  }, [confirmButtonText, isUserNameConfirmed, onClose, onDeleteUser])

  const handleOnTeamNameChange = useCallback(({ value }) => {
    setConfirmTeamName(value)
  }, [])

  return (
    <Modal
      className={'UserDeleteConfirmModal'}
      onClose={onClose}
      open={true}
      closeIcon={<CloseIcon />}
    >
      <Modal.Header>
        {t('settings.user.danger.delete.modal.header')}
        {error !== null ? (
          <Alert size={'medium'} type={'error'} message={error} />
        ) : (
          <Alert
            type={'warning'}
            size={'medium'}
            message={t('settings.user.danger.delete.modal.warning')}
          />
        )}
      </Modal.Header>
      <Modal.Content>
        <Trans
          i18nKey="settings.user.danger.delete.modal.content"
          values={{ name }}
        />
        <Form onSubmit={handleSubmit}>
          <Input
            type={'text'}
            label={t('settings.user.danger.delete.modal.confirm.label')}
            value={confirmUserName}
            onChange={handleOnTeamNameChange}
            disabled={isLoading}
          />
        </Form>
      </Modal.Content>
      <Modal.Actions>
        <Button
          primary
          fluid
          onClick={handleSubmit}
          disabled={!isUserNameConfirmed}
          loading={isLoading}
        >
          {confirmButtonText}
        </Button>
      </Modal.Actions>
    </Modal>
  )
}

export default React.memo(UserDeleteConfirmModal)
