import React, { useEffect } from 'react'
import { Redirect } from 'react-router-dom'

import { useAuth0 } from '@auth0/auth0-react'

import { LoadingPage } from '../LoadingPage'
import { Props } from './CallbackPage.types'
import { LoginErrorPage } from './LoginErrorPage'

const CallbackPage = ({
  isLoading,
  rootLocation,
  error,
  teams,
  currentTeam,
  onAuth0Login,
}: Props) => {
  const {
    user: auth0User,
    error: auth0Error,
    getIdTokenClaims,
    getAccessTokenSilently,
  } = useAuth0()

  useEffect(() => {
    if (auth0User) {
      onAuth0Login(getIdTokenClaims, getAccessTokenSilently)
    }
  }, [onAuth0Login, auth0User, getIdTokenClaims, getAccessTokenSilently])

  const pageError = auth0Error || error

  if (pageError) {
    return <LoginErrorPage auth0Error={auth0Error || null} />
  }

  const currentTeamLoading = !currentTeam && teams?.length !== 0

  if (!auth0User || isLoading || !teams || currentTeamLoading) {
    return <LoadingPage />
  }

  return <Redirect to={rootLocation} />
}

export default React.memo(CallbackPage)
