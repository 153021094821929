import { differenceInDays } from 'date-fns'
import { Connector } from 'pluggy-js'

import {
  BUSINESS_BANK_TYPE_IDS_ORDER,
  INVESTMENT_BANK_ORDER_TYPE_IDS_ORDER,
  PERSONAL_BANK_TYPE_IDS_ORDER,
} from '../../../lib/constants/connectorsIdsOrder'

const CONNECTOR_IDS_BY_USAGE_ORDER: number[] = [
  ...PERSONAL_BANK_TYPE_IDS_ORDER,
  ...BUSINESS_BANK_TYPE_IDS_ORDER,
  ...INVESTMENT_BANK_ORDER_TYPE_IDS_ORDER,
]

/**
 * match a list of ids with the actual connectors list
 * to get a custom sort
 *
 * @param connectors - connectors list to sort
 *
 * @returns - connectors list sorted by usage
 */
export function sortConnectorsByUsage(connectors: Connector[]): Connector[] {
  // sort the connectors array matching with the ids array
  const connectorsSorted = [...connectors].sort((connector1, connector2) => {
    const connector1Index = CONNECTOR_IDS_BY_USAGE_ORDER.indexOf(connector1.id)
    const connector2Index = CONNECTOR_IDS_BY_USAGE_ORDER.indexOf(connector2.id)

    // if both connectors are not in the custom order, keep their original order
    if (connector1Index === -1 && connector2Index === -1) {
      return 0
    }
    // if only connector '1' is not in the custom order, move it after connector '2'
    if (connector1Index === -1) {
      return 1
    }
    // if only connector '2' is not in the custom order, move it before connector '1'
    if (connector2Index === -1) {
      return -1
    }

    // if both connectors are in the custom order, sort them based on their index in the custom order
    return connector1Index - connector2Index
  })

  return connectorsSorted
}

const CONNECTOR_NEW_LABEL_MAX_TIME_DAYS = 30

export function isNewConnector(connector: Connector): boolean {
  const currentDate = new Date()

  const daysSinceConnectorCreatedAtDate = differenceInDays(
    currentDate,
    new Date(connector.createdAt),
  )

  return daysSinceConnectorCreatedAtDate < CONNECTOR_NEW_LABEL_MAX_TIME_DAYS
}

export function isOpenFinanceConnector(connector: Connector): boolean {
  return connector.isOpenFinance
}

export function areConnectorsNewAndInType(
  connectors: Connector[] | null,
  connectorType: Connector['type'],
): boolean {
  if (!connectors) {
    return false
  }

  return connectors
    .filter((connector) => connector.type === connectorType)
    .some((connector) => isNewConnector(connector))
}

const CONNECTORS_TYPE_ORDER = ['PERSONAL_BANK', 'BUSINESS_BANK', 'INVESTMENT']

/**
 * Sort the connectors by their type.
 * If the type is not in CONNECTORS_TYPE_ORDER, it will be sorted at the end.
 * If the type is in CONNECTORS_TYPE_ORDER, they will be sorted by the order of the array.
 * If the type is the same, sort by creation date.
 */
function getConnectorsSortedByType(
  connectors: Connector[] | null,
): Connector[] | null {
  if (connectors === null) {
    return null
  }

  return [...connectors].sort((connector1, connector2) => {
    const connector1Index = CONNECTORS_TYPE_ORDER.indexOf(connector1.type)
    const connector2Index = CONNECTORS_TYPE_ORDER.indexOf(connector2.type)

    // If the connectors are the same, sort by created date
    if (connector1Index === connector2Index) {
      return connector1.createdAt > connector2.createdAt ? -1 : 1
    }

    // If connector1 is not in the list, push it to the bottom
    if (connector1Index === -1) {
      return 1
    }

    // If connector2 is not in the list, push it to the bottom
    if (connector2Index === -1) {
      return -1
    }

    return connector1Index - connector2Index
  })
}

export function getConnectorTypes(
  connectors: Connector[] | null,
): Connector['type'][] {
  return [
    ...new Set(getConnectorsSortedByType(connectors)?.map(({ type }) => type)),
  ]
}
