const IS_LANDING_USER_KEY = 'is-landing-user'
const PLUGGY_LANDING_PAGE_URL = 'https://pluggy.ai'

export function saveIsComingFromLandingPageInStorage(): void {
  const { referrer } = document

  if (!referrer.includes(PLUGGY_LANDING_PAGE_URL)) {
    // not comes from landing -> just return
    return
  }

  window.localStorage.setItem(IS_LANDING_USER_KEY, 'true')
}

export function isComingFromLandingPage(): boolean {
  return !!window.localStorage.getItem(IS_LANDING_USER_KEY)
}

export function removeIsComingFromLandingPageFromStorage(): void {
  window.localStorage.removeItem(IS_LANDING_USER_KEY)
}
