import { connect } from 'react-redux'

import { RootState } from '../../../../modules/reducer'
import {
  FETCH_TEAMS_REQUEST,
  UPDATE_TEAM_REQUEST,
  updateTeamRequest,
} from '../../../../modules/team/actions'
import {
  getCurrentTeam,
  getCurrentUserTeamRole,
  getLoading,
  isLoadingType,
  isUserTeamRole,
} from '../../../../modules/team/selectors'
import { TeamFields } from '../../../../modules/team/types'
import { getIsUserActionsRequired } from '../../../../modules/user/selectors'
import TeamDetailsSection from './TeamDetailsSection'
import {
  MapDispatch,
  MapDispatchProps,
  MapStateProps,
} from './TeamDetailsSection.types'

const mapState = (state: RootState): MapStateProps => ({
  team: getCurrentTeam(state),
  userTeamMemberRole: getCurrentUserTeamRole(state),
  isLoading: isLoadingType(getLoading(state), FETCH_TEAMS_REQUEST),
  isSubmitting: isLoadingType(getLoading(state), UPDATE_TEAM_REQUEST),
  isFeaturesLimited:
    !isUserTeamRole(state, ['OWNER', 'ADMIN']) ||
    getIsUserActionsRequired(state),
})

const mapDispatch = (dispatch: MapDispatch): MapDispatchProps => ({
  onUpdateTeam: (id: string, fields: TeamFields) =>
    dispatch(updateTeamRequest(id, fields)),
})

export default connect(mapState, mapDispatch)(TeamDetailsSection)
