import React, { useCallback } from 'react'
import { useTranslation } from 'react-i18next'

import { Radio } from '@pluggyai/ui'

import { TrackEventName } from '../../../modules/analytics/events'
import { track } from '../../../modules/analytics/utils'
import { Props } from './ThemeSwitchItem.types'

import './ThemeSwitchItem.css'

const ThemeSwitch = ({ as: Wrapper, theme, onThemeChange }: Props) => {
  const { t } = useTranslation()

  const handleSwitchChange = useCallback(
    (
      _event: React.FormEvent<HTMLInputElement>,
      { checked }: { checked?: boolean },
    ) => {
      track(TrackEventName.TOGGLE_CLICKED, {
        location: 'NavBar ThemeSwitchItem',
        newTheme: checked ? 'dark' : 'light',
      })

      onThemeChange(checked ? 'dark' : 'light')
    },
    [onThemeChange],
  )

  return (
    <div className={'ThemeSwitchItem'}>
      <Wrapper>
        <div className={'container'}>
          <p className={'label'}>
            {t('navbar.profile-options.action.dark-theme')}
          </p>
          <Radio
            type={'checkbox'}
            toggle
            checked={theme !== 'light'}
            onChange={handleSwitchChange}
          />
        </div>
      </Wrapper>
    </div>
  )
}

export default React.memo(ThemeSwitch)
