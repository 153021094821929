import { AxiosResponse } from 'axios'

import {
  WebhookEventsRequest,
  WebhookEventsResponse,
} from '../../modules/webhookEvents/types'
import { BaseService } from './BaseService'

export class EventsService extends BaseService {
  async getWebhookEvents(
    params: WebhookEventsRequest & { teamId?: string },
  ): Promise<AxiosResponse<WebhookEventsResponse>> {
    return await this.getServiceInstance().get(this.urls.GET_WEBHOOK_EVENTS, {
      params,
    })
  }
}
