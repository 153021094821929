import { AxiosResponse } from 'axios'

import { ActivityItem } from '../../modules/activity/types'
import { BaseService } from './BaseService'

export class ActivityService extends BaseService {
  async getActivityLogs(): Promise<AxiosResponse<ActivityItem[]>> {
    return await this.getServiceInstance().get(this.urls.GET_ACTIVITY)
  }
}
