import { memo } from 'react'
import { Trans, useTranslation } from 'react-i18next'

import { format, isToday } from 'date-fns'
import { enUS, ptBR } from 'date-fns/locale'
import { Popup } from 'semantic-ui-react'

import { formatPercentageOfChange } from '../../../modules/stats/utils'
import { InfoIcon } from '../../Icon'
import { TrendArrowDownIcon } from '../../Icon/TrendArrowDownIcon'
import { TrendArrowUpIcon } from '../../Icon/TrendArrowUpIcon'
import type { Props } from './Stat.types'

import './Stat.css'

const Stat = ({
  title,
  value,
  description,
  trend: {
    change,
    period: { startDate, endDate },
  },
}: Props) => {
  const {
    i18n: { language },
  } = useTranslation()

  const isPositive = change >= 0

  return (
    <div className={'Stat'}>
      {description && (
        <Popup
          content={description}
          on="hover"
          position={'top left'}
          mouseLeaveDelay={500}
          trigger={
            <div className={'tooltip'}>
              <InfoIcon />
            </div>
          }
        />
      )}
      <div className={'stat-container'}>
        <h2 className={'title'}>{title}</h2>
        <div className={'value-container'}>
          <h1 className={'value'}>{value}</h1>{' '}
          <div className={'trend'}>
            <div className={`change ${isPositive ? 'positive' : 'negative'}`}>
              {formatPercentageOfChange(change)} <div className="icon"></div>
              {isPositive ? <TrendArrowUpIcon /> : <TrendArrowDownIcon />}
            </div>
            <div className="period">
              <Trans
                i18nKey={`items-stats.stats-cards.date-range-label.${
                  isToday(endDate) ? 'without-end-date' : 'with-end-date'
                }`}
                values={{
                  startDate: format(startDate, 'MMM dd', {
                    locale: language === 'en' ? enUS : ptBR,
                  }),
                  endDate: format(endDate, 'MMM dd', {
                    locale: language === 'en' ? enUS : ptBR,
                  }),
                }}
              />
            </div>
          </div>
        </div>
      </div>
    </div>
  )
}

export default memo(Stat)
