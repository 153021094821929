import { connect } from 'react-redux'

import { RootState } from '../../../modules/reducer'
import { getTeams } from '../../../modules/team/selectors'
import TeamsList from './TeamsList'
import { MapDispatch, MapDispatchProps, MapStateProps } from './TeamsList.types'

const mapState = (state: RootState): MapStateProps => ({
  teams: getTeams(state),
})

const mapDispatch = (_dispatch: MapDispatch): MapDispatchProps => ({})

export default connect(mapState, mapDispatch)(TeamsList)
