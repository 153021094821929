import { memo, useEffect } from 'react'
import { useTranslation } from 'react-i18next'

import { Alert } from '@pluggyai/ui'
import { Divider, Loader } from 'semantic-ui-react'

import { FreeTrialAlert } from '../FreeTrialAlert'
import { Page } from '../Page'
import { WelcomeWidget } from '../WelcomeWidget'
import { ApplicationDangerZone } from './ApplicationDangerZone'
import { ApplicationDetails } from './ApplicationDetails'
import { Props } from './ApplicationPage.types'
import { DevelopmentItemUsageAlert } from './DevelopmentItemUsageAlert'

import './ApplicationPage.css'

const DEVELOPMENT_APP_ITEMS_ALERT_THRESHOLD = 85

const ApplicationPage = ({
  application,
  isLoading,
  onFetchApplicationStats,
  onGoToApplications,
  canAccessDangerZone,
  statsError,
  applicationItemsCount,
  teamCanGoToProduction,
}: Props) => {
  // fetch application stats on mount
  useEffect(() => {
    if (!application) {
      // no application, just return
      return
    }
    const { id: applicationId } = application
    onFetchApplicationStats({ applicationId, sandbox: false })
  }, [onFetchApplicationStats, application])

  useEffect(() => {
    if (!isLoading && !application) {
      // no loading & no application -> go back to applications page
      onGoToApplications()
    }
  }, [isLoading, application, onGoToApplications])

  const { t } = useTranslation()

  const showDevelopmentItemUsageAlert =
    !teamCanGoToProduction &&
    application?.environment === 'DEVELOPMENT' &&
    // TODO this itemsCount should be excluding sandbox & deleted (https://pluggy.atlassian.net/browse/RAM-235)
    applicationItemsCount !== null &&
    // TODO this should be (team.subscription.itemsLimit || ITEMS_LIMIT_DEFAULT) - 15% ! (https://pluggy.atlassian.net/browse/RAM-235)
    //  we should match Demo impl: https://github.com/pluggyai/demo/blob/1181176b79dd7bbb976591d76b8aa180bf5bb757/src/modules/application/selectors.ts#L32-L42
    //  and: https://github.com/pluggyai/demo/blob/1181176b79dd7bbb976591d76b8aa180bf5bb757/src/modules/application/utils.ts#L1-L32
    applicationItemsCount >= DEVELOPMENT_APP_ITEMS_ALERT_THRESHOLD

  return (
    <Page className={'ApplicationPage'}>
      {statsError && (
        <Alert
          type={'error'}
          message={t('application.error.stats')}
          size={'medium'}
        />
      )}
      {showDevelopmentItemUsageAlert && <DevelopmentItemUsageAlert />}
      <WelcomeWidget />
      <FreeTrialAlert />
      {isLoading && !application && (
        <div className={'widget-section-loader'}>
          <Loader size="big" active />
        </div>
      )}
      {application && (
        <>
          <ApplicationDetails application={application} />

          {canAccessDangerZone && (
            <>
              <Divider className="fit" />
              <ApplicationDangerZone application={application} />
            </>
          )}
        </>
      )}
    </Page>
  )
}

export default memo(ApplicationPage)
