import type { Webhook } from 'pluggy-sdk'
import { AnyAction } from 'redux'

import {
  getApplicationId,
  getData as getApplications,
} from '../application/selectors'
import { LoadingState } from '../loading/types'
import { RootState } from '../reducer'
import { WebhooksState } from './reducer'

export const getState = (state: RootState): WebhooksState =>
  state.webhook as WebhooksState
export const getData = (state: RootState) => getState(state).data
export const getLoading = (state: RootState) => getState(state).loading
export const getError = (state: RootState) => getState(state).error

export const isLoadingType = (state: LoadingState, type: string): boolean =>
  state.some((action: AnyAction) => action.type === type)

export const getApplicationWebhooks = (state: RootState): Webhook[] | null => {
  const applicationId = getApplicationId(state)

  const webhooksByApplicationId = getData(state)

  if (!applicationId || !webhooksByApplicationId) {
    return null
  }

  return webhooksByApplicationId[applicationId]
}

export const teamHasWebhooksCreated = (state: RootState): boolean => {
  const webhooksByApplicationId = getData(state)

  if (!webhooksByApplicationId) {
    return false
  }

  const applications = getApplications(state)

  if (!applications) {
    return false
  }

  const teamWebhooks = Object.entries(webhooksByApplicationId)
    .filter(([applicationId, _]) =>
      applications.map(({ id }) => id).includes(applicationId),
    )
    .flatMap(([, webhooks]) => webhooks)

  return teamWebhooks.length > 0
}
