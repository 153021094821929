import type { Connector } from 'pluggy-js'

import { getFirstCapitalizedLetter } from '../../../../utils/array'

export function getConnectorLetter(connector: Connector | undefined): string {
  if (!connector) {
    // no connector just return
    return ''
  }
  const { name } = connector
  if (name.startsWith('Banco')) {
    return getFirstCapitalizedLetter(name.substring(6, name.length))
  }
  return connector.name[0].toUpperCase()
}
