import { loadingReducer } from '../loading/reducer'
import { LoadingState } from '../loading/types'
import {
  CREATE_APPLICATION_FAILURE,
  CREATE_APPLICATION_REQUEST,
  CREATE_APPLICATION_SUCCESS,
  CreateApplicationFailureAction,
  CreateApplicationRequestAction,
  CreateApplicationSuccessAction,
  DELETE_APPLICATION_FAILURE,
  DELETE_APPLICATION_REQUEST,
  DELETE_APPLICATION_SUCCESS,
  DeleteApplicationFailureAction,
  DeleteApplicationRequestAction,
  DeleteApplicationSuccessAction,
  FETCH_APPLICATION_FAILURE,
  FETCH_APPLICATION_REQUEST,
  FETCH_APPLICATION_SUCCESS,
  FETCH_APPLICATIONS_FAILURE,
  FETCH_APPLICATIONS_REQUEST,
  FETCH_APPLICATIONS_SUCCESS,
  FetchApplicationFailureAction,
  FetchApplicationRequestAction,
  FetchApplicationsFailureAction,
  FetchApplicationsRequestAction,
  FetchApplicationsSuccessAction,
  FetchApplicationSuccessAction,
  UPDATE_APPLICATION_ENABLED_FAILURE,
  UPDATE_APPLICATION_ENABLED_REQUEST,
  UPDATE_APPLICATION_ENABLED_SUCCESS,
  UPDATE_APPLICATION_FAILURE,
  UPDATE_APPLICATION_REQUEST,
  UPDATE_APPLICATION_SUCCESS,
  UpdateApplicationEnabledFailureAction,
  UpdateApplicationEnabledRequestAction,
  UpdateApplicationEnabledSuccessAction,
  UpdateApplicationFailureAction,
  UpdateApplicationRequestAction,
  UpdateApplicationSuccessAction,
} from './actions'
import { Application } from './types'

export type ApplicationState = {
  data: Application[] | null
  loading: LoadingState
  error: string | null
}

const INITIAL_STATE: ApplicationState = {
  data: null,
  loading: [],
  error: null,
}

type ApplicationReducerAction =
  | FetchApplicationsRequestAction
  | FetchApplicationsSuccessAction
  | FetchApplicationsFailureAction
  | FetchApplicationRequestAction
  | FetchApplicationSuccessAction
  | FetchApplicationFailureAction
  | CreateApplicationRequestAction
  | CreateApplicationSuccessAction
  | CreateApplicationFailureAction
  | UpdateApplicationRequestAction
  | UpdateApplicationSuccessAction
  | UpdateApplicationFailureAction
  | DeleteApplicationRequestAction
  | DeleteApplicationSuccessAction
  | DeleteApplicationFailureAction
  | UpdateApplicationEnabledRequestAction
  | UpdateApplicationEnabledSuccessAction
  | UpdateApplicationEnabledFailureAction

export function applicationReducer(
  state: ApplicationState = INITIAL_STATE,
  action: ApplicationReducerAction,
): ApplicationState {
  switch (action.type) {
    case FETCH_APPLICATIONS_REQUEST:
    case FETCH_APPLICATION_REQUEST:
    case CREATE_APPLICATION_REQUEST:
    case UPDATE_APPLICATION_REQUEST:
    case DELETE_APPLICATION_REQUEST:
    case UPDATE_APPLICATION_ENABLED_REQUEST:
      return {
        ...state,
        error: null,
        loading: loadingReducer(state.loading, action),
      }
    case FETCH_APPLICATIONS_SUCCESS:
      return {
        ...state,
        loading: loadingReducer(state.loading, action),
        error: null,
        data: action.payload.applications,
      }
    case CREATE_APPLICATION_SUCCESS:
    case FETCH_APPLICATION_SUCCESS:
    case UPDATE_APPLICATION_SUCCESS:
    case UPDATE_APPLICATION_ENABLED_SUCCESS:
      const { application } = action.payload

      // update or insert application on current state
      const currentData = [...(state.data || [])]
      const i = currentData.findIndex(
        (_application) => _application.id === application.id,
      )
      if (i > -1) {
        // found -> update item
        currentData[i] = application
      } else {
        // not found -> insert
        currentData.push(application)
      }

      return {
        ...state,
        loading: loadingReducer(state.loading, action),
        error: null,
        data: currentData,
      }
    case DELETE_APPLICATION_SUCCESS: {
      const { id: deletedApplicationId } = action.payload

      // remove application from current state data
      const applicationsExceptDeleted = (state.data || []).filter(
        (_application) => _application.id !== deletedApplicationId,
      )

      return {
        ...state,
        loading: loadingReducer(state.loading, action),
        error: null,
        data: applicationsExceptDeleted,
      }
    }
    case CREATE_APPLICATION_FAILURE:
    case FETCH_APPLICATION_FAILURE:
    case FETCH_APPLICATIONS_FAILURE:
    case UPDATE_APPLICATION_FAILURE:
    case UPDATE_APPLICATION_ENABLED_FAILURE:
    case DELETE_APPLICATION_FAILURE:
      return {
        ...state,
        loading: loadingReducer(state.loading, action),
        error: action.payload.error,
      }
    default:
      return state
  }
}
