export enum DailyChartLightThemeColors {
  LIGHT_GREY = '#8f8f8f',
  HOVER = '#e0e0e0e0',
  TOOLTIP = '#ffffff',
  GREEN = '#98b054',
  LIGHT_BLUE = '#8ac5c9',
  BLUE = '#3f85ed',
  PURPLE = '#7f5acc',
  PINK = '#ea69b6',
  RED = '#d03939',
}

export enum DailyChartDarkThemeColors {
  LIGHT_GREY = '#8f8f8f',
  HOVER = '#3C3C3C',
  TOOLTIP = '#121212',
  GREEN = '#c3da83',
  LIGHT_BLUE = '#8ac5c9',
  BLUE = '#3f85ed',
  PURPLE = '#7f5acc',
  PINK = '#ea69b6',
  RED = '#d03939',
}

export type DailyChartColor =
  | 'LIGHT_GREY'
  | 'HOVER'
  | 'TOOLTIP'
  | 'GREEN'
  | 'LIGHT_BLUE'
  | 'BLUE'
  | 'PURPLE'
  | 'PINK'
  | 'RED'
