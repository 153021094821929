import { connect } from 'react-redux'

import { RootState } from '../../../../../../modules/reducer'
import { DELETE_WEBHOOK_REQUEST } from '../../../../../../modules/webhook/actions'
import {
  getError,
  getLoading,
  isLoadingType,
} from '../../../../../../modules/webhook/selectors'
import WebhookDeleteConfirmModal from './WebhookDeleteConfirmModal'
import {
  MapDispatch,
  MapDispatchProps,
  MapStateProps,
} from './WebhookDeleteConfirmModal.types'

const mapState = (state: RootState): MapStateProps => ({
  error: getError(state),
  isLoading: isLoadingType(getLoading(state), DELETE_WEBHOOK_REQUEST),
})

const mapDispatch = (_dispatch: MapDispatch): MapDispatchProps => ({})

export default connect(mapState, mapDispatch)(WebhookDeleteConfirmModal)
