import { memo } from 'react'
import { useTranslation } from 'react-i18next'

import { Tag } from '../../Tag'
import { Props } from './EnvironmentTag.types'

import './EnvironmentTag.css'

const EnvironmentTag = ({ environment, size = 'medium' }: Props) => {
  const { t } = useTranslation()

  const environmentLowerCase = environment.toLowerCase()

  return (
    <Tag
      text={t(
        `application.page.header.environment.${size}.${environmentLowerCase}`,
      )}
      type={'info'}
      className={`EnvironmentTag ${environmentLowerCase}`}
    />
  )
}
export default memo(EnvironmentTag)
