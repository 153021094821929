import { loadingReducer } from '../loading/reducer'
import { LoadingState } from '../loading/types'
import {
  FetchItemFailureAction,
  FetchItemRequestAction,
  FetchItemSuccessAction,
  FETCH_ITEM_FAILURE,
  FETCH_ITEM_REQUEST,
  FETCH_ITEM_SUCCESS,
} from './actions'
import { ItemWithExecutions } from './types'

export type ItemState = {
  data: ItemWithExecutions | null
  loading: LoadingState
  error: Error | null
}

const INITIAL_STATE: ItemState = {
  data: null,
  loading: [],
  error: null,
}

type NotificationReducerAction =
  | FetchItemRequestAction
  | FetchItemSuccessAction
  | FetchItemFailureAction

export function itemReducer(
  state: ItemState = INITIAL_STATE,
  action: NotificationReducerAction,
): ItemState {
  switch (action.type) {
    case FETCH_ITEM_REQUEST:
      return {
        ...state,
        error: null,
        loading: loadingReducer(state.loading, action),
      }

    case FETCH_ITEM_SUCCESS:
      const { item } = action.payload
      return {
        ...state,
        data: item,
      }

    case FETCH_ITEM_FAILURE: {
      const { error } = action.payload
      return {
        ...state,
        error,
      }
    }
    default:
      return state
  }
}
