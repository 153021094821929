import { useTranslation } from 'react-i18next'

import { Dropdown, Menu } from 'semantic-ui-react'

type Props = { as: typeof Dropdown.Item | typeof Menu.Item }

const StatusPageItem = ({ as: Wrapper }: Props): JSX.Element => {
  const { t } = useTranslation()

  return (
    <Wrapper
      as={'a'}
      href={'https://status.pluggy.ai/'}
      target={'_blank'}
      rel={'noopener noreferrer'}
      className={'status-page-link'}
    >
      {t('navbar.profile-options.action.status-page')}
    </Wrapper>
  )
}

export { StatusPageItem }
