import { ExecutionStatus } from 'pluggy-js'

import { DailyChartColor } from '../../lib/constants/colors'

export const ITEMS_STATS_EXECUTION_STATUS_GROUPS: {
  name: string
  executionStatuses: ExecutionStatus[]
  colorName: DailyChartColor
}[] = [
  /* 1. Total or Partial Success */
  {
    name: 'totalOrPartialSuccess',
    executionStatuses: [
      /* 1.1 Success (everything went well) */
      'SUCCESS',
      /* 1.2 Partial Success (some producs were retrieved) */
      'PARTIAL_SUCCESS',
    ],
    colorName: 'GREEN',
  },
  /* 2. User login errors (user's fault) */
  {
    name: 'userLoginErrors',
    executionStatuses: [
      'INVALID_CREDENTIALS',
      'INVALID_CREDENTIALS_MFA',
      'ACCOUNT_NEEDS_ACTION',
      'USER_INPUT_TIMEOUT',
      'ACCOUNT_LOCKED',
      'ALREADY_LOGGED_IN',
      'USER_AUTHORIZATION_NOT_GRANTED',
      'USER_AUTHORIZATION_PENDING',
    ],
    colorName: 'BLUE',
  },
  /* 3. Connectivity Errors (institution's fault) */
  {
    name: 'connectivityErrors',
    executionStatuses: [
      //  * 3.1 Site not avaliable
      'SITE_NOT_AVAILABLE',
      //  * 3.2 Connection error
      'CONNECTION_ERROR',
    ],
    colorName: 'PINK',
  },
  /* 4. Unexpected Error (pluggy's fault) */
  {
    name: 'unexpectedErrors',
    executionStatuses: ['ERROR'],
    colorName: 'RED',
  },
]
