import { AnyAction } from 'redux'

import { LoadingState } from '../loading/types'
import { RootState } from '../reducer'
import { AUTH0_LOGIN_REQUEST } from './actions'
import { Auth0State } from './reducer'
import { Auth0Result } from './types'

export const getState = (state: RootState): Auth0State =>
  state.auth0 as Auth0State
export const getData = (state: RootState) => getState(state).data
export const getLoading = (state: RootState) => getState(state).loading
export const getError = (state: RootState) => getState(state).error

export const isLoadingType: (state: LoadingState, type: string) => boolean = (
  state,
  type,
) => state.some((action: AnyAction) => action.type === type)

export const isAuth0LoggingIn = (state: RootState) =>
  isLoadingType(getLoading(state), AUTH0_LOGIN_REQUEST)

export const getAuth0 = (state: RootState): Auth0Result | null =>
  getData(state).auth0

export const isAuth0LoggedIn = (state: RootState): boolean =>
  Boolean(getAuth0(state)?.idToken)

export const getAuth0Email = (state: RootState): string | undefined =>
  getAuth0(state)?.idToken.email

export const getAuth0AccessToken = (state: RootState): string | undefined =>
  getAuth0(state)?.accessToken

export const getAuth0Picture = (state: RootState): string | undefined =>
  getAuth0(state)?.idToken.picture
