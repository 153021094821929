import { connect } from 'react-redux'

import { RootState } from '../../../modules/reducer'
import { deleteTeamMemberRequest } from '../../../modules/team/actions'
import { isUserOnlyTeamOwner } from '../../../modules/team/selectors'
import { getUser } from '../../../modules/user/selectors'
import TeamItem from './TeamItem'
import { MapDispatch, MapDispatchProps, MapStateProps } from './TeamItem.types'

const mapState = (state: RootState): MapStateProps => ({
  isUserOnlyTeamOwner: (team) => isUserOnlyTeamOwner(state, team),
  user: getUser(state),
})

const mapDispatch = (dispatch: MapDispatch): MapDispatchProps => ({
  onExitTeam: (team, member) => dispatch(deleteTeamMemberRequest(team, member)),
})

export default connect(mapState, mapDispatch)(TeamItem)
