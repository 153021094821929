import { ConnectorsStatsRequest } from '../../../modules/stats/types'

export const timeValues = ['24h', '24d'] as const

export type TimeFilterValue = typeof timeValues[number]

export const timeValueDaysMap: Record<
  string,
  ConnectorsStatsRequest['fromDay']
> = {
  '24h': '1',
  '24d': '24',
}

export type Props = {
  onTimeFilterChange: (value: TimeFilterValue) => void
  value: TimeFilterValue
  isDisabled?: boolean
}
