import { loadingReducer } from '../loading/reducer'
import { LoadingState } from '../loading/types'
import {
  SUBMIT_GO_TO_PRODUCTION_FAILURE,
  SUBMIT_GO_TO_PRODUCTION_REQUEST,
  SUBMIT_GO_TO_PRODUCTION_SUCCESS,
  SubmitGoToProductionFailureAction,
  SubmitGoToProductionRequestAction,
  SubmitGoToProductionSuccessAction,
} from './actions'

export type GoToProductionState = {
  data: string[]
  loading: LoadingState
  error: string | null
}

const INITIAL_STATE: GoToProductionState = {
  data: [],
  loading: [],
  error: null,
}

type GoToProductionReducerAction =
  | SubmitGoToProductionRequestAction
  | SubmitGoToProductionSuccessAction
  | SubmitGoToProductionFailureAction

export function goToProductionReducer(
  state: GoToProductionState = INITIAL_STATE,
  action: GoToProductionReducerAction,
): GoToProductionState {
  switch (action.type) {
    case SUBMIT_GO_TO_PRODUCTION_REQUEST:
      return {
        ...state,
        loading: loadingReducer(state.loading, action),
        error: null,
      }
    case SUBMIT_GO_TO_PRODUCTION_FAILURE:
      return {
        ...state,
        loading: loadingReducer(state.loading, action),
        error: action.payload.error,
      }
    case SUBMIT_GO_TO_PRODUCTION_SUCCESS:
      return {
        ...state,
        loading: loadingReducer(state.loading, action),
        error: null,
      }
    default:
      return state
  }
}
