import { connect } from 'react-redux'

import { getData as getApplications } from '../../../modules/application/selectors'
import { RootState } from '../../../modules/reducer'
import NoWebhooksCreatedSection from './NoWebhooksCreatedSection'
import {
  MapDispatchProps,
  MapStateProps,
  MapDispatch,
} from './NoWebhooksCreatedSection.types'

const mapState = (state: RootState): MapStateProps => ({
  applications: getApplications(state),
})

const mapDispatch = (_dispatch: MapDispatch): MapDispatchProps => ({})

export default connect(mapState, mapDispatch)(NoWebhooksCreatedSection)
