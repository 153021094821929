import type { Webhook, CreateWebhook, UpdateWebhook } from 'pluggy-sdk'
import { action } from 'typesafe-actions'

import { Application } from '../application/types'

/// Fetch Webhooks
export const FETCH_WEBHOOKS_REQUEST = '[Request] Fetch Webhooks'
export const FETCH_WEBHOOKS_SUCCESS = '[Success] Fetch Webhooks'
export const FETCH_WEBHOOKS_FAILURE = '[Failure] Fetch Webhooks'

export const fetchWebhooksRequest = (applicationId: Application['id']) =>
  action(FETCH_WEBHOOKS_REQUEST, { applicationId })
export const fetchWebhooksSuccess = (
  webhooks: Webhook[],
  applicationId: Application['id'],
) => action(FETCH_WEBHOOKS_SUCCESS, { webhooks, applicationId })
export const fetchWebhooksFailure = (error: string) =>
  action(FETCH_WEBHOOKS_FAILURE, { error })

export type FetchWebhooksRequestAction = ReturnType<typeof fetchWebhooksRequest>
export type FetchWebhooksSuccessAction = ReturnType<typeof fetchWebhooksSuccess>
export type FetchWebhooksFailureAction = ReturnType<typeof fetchWebhooksFailure>

/// Create Webhook
export const CREATE_WEBHOOK_REQUEST = '[Request] Create Webhook'
export const CREATE_WEBHOOK_SUCCESS = '[Success] Create Webhook'
export const CREATE_WEBHOOK_FAILURE = '[Failure] Create Webhook'

export const createWebhookRequest = (
  applicationId: Application['id'],
  webhook: CreateWebhook,
) => action(CREATE_WEBHOOK_REQUEST, { applicationId, webhook })
export const createWebhookSuccess = (
  webhook: Webhook,
  applicationId: Application['id'],
) => action(CREATE_WEBHOOK_SUCCESS, { webhook, applicationId })
export const createWebhookFailure = (error: string) =>
  action(CREATE_WEBHOOK_FAILURE, { error })

export type CreateWebhookRequestAction = ReturnType<typeof createWebhookRequest>
export type CreateWebhookSuccessAction = ReturnType<typeof createWebhookSuccess>
export type CreateWebhookFailureAction = ReturnType<typeof createWebhookFailure>

/// Update Webhook
export const UPDATE_WEBHOOK_REQUEST = '[Request] Update Webhook'
export const UPDATE_WEBHOOK_SUCCESS = '[Success] Update Webhook'
export const UPDATE_WEBHOOK_FAILURE = '[Failure] Update Webhook'

export const updateWebhookRequest = (
  id: Webhook['id'],
  applicationId: Application['id'],
  webhook: UpdateWebhook,
) => action(UPDATE_WEBHOOK_REQUEST, { id, applicationId, webhook })
export const updateWebhookSuccess = (
  webhook: Webhook,
  applicationId: Application['id'],
) => action(UPDATE_WEBHOOK_SUCCESS, { webhook, applicationId })
export const updateWebhookFailure = (error: string) =>
  action(UPDATE_WEBHOOK_FAILURE, { error })

export type UpdateWebhookRequestAction = ReturnType<typeof updateWebhookRequest>
export type UpdateWebhookSuccessAction = ReturnType<typeof updateWebhookSuccess>
export type UpdateWebhookFailureAction = ReturnType<typeof updateWebhookFailure>

/// Delete Webhook
export const DELETE_WEBHOOK_REQUEST = '[Request] Delete Webhook'
export const DELETE_WEBHOOK_SUCCESS = '[Success] Delete Webhook'
export const DELETE_WEBHOOK_FAILURE = '[Failure] Delete Webhook'

export const deleteWebhookRequest = (
  id: Webhook['id'],
  applicationId: Application['id'],
) => action(DELETE_WEBHOOK_REQUEST, { id, applicationId })
export const deleteWebhookSuccess = (
  webhookId: Webhook['id'],
  applicationId: Application['id'],
) => action(DELETE_WEBHOOK_SUCCESS, { webhookId, applicationId })
export const deleteWebhookFailure = (error: string) =>
  action(DELETE_WEBHOOK_FAILURE, { error })

export type DeleteWebhookRequestAction = ReturnType<typeof deleteWebhookRequest>
export type DeleteWebhookSuccessAction = ReturnType<typeof deleteWebhookSuccess>
export type DeleteWebhookFailureAction = ReturnType<typeof deleteWebhookFailure>
