import { connect } from 'react-redux'

import type { Webhook, UpdateWebhook, CreateWebhook } from 'pluggy-sdk'

import { getApplication } from '../../../../modules/application/selectors'
import { Application } from '../../../../modules/application/types'
import { RootState } from '../../../../modules/reducer'
import {
  getCurrentTeam,
  isUserTeamRole,
} from '../../../../modules/team/selectors'
import {
  CREATE_WEBHOOK_REQUEST,
  createWebhookRequest,
  deleteWebhookRequest,
  updateWebhookRequest,
} from '../../../../modules/webhook/actions'
import {
  getApplicationWebhooks,
  getError,
  getLoading,
  isLoadingType,
} from '../../../../modules/webhook/selectors'
import ApplicationWebhooks from './ApplicationWebhooks'
import {
  MapDispatch,
  MapDispatchProps,
  MapStateProps,
} from './ApplicationWebhooks.types'

const mapState = (state: RootState): MapStateProps => ({
  webhooks: getApplicationWebhooks(state),
  userCanCreateAndEditWebhooks: Boolean(
    getCurrentTeam(state) &&
      isUserTeamRole(state, ['OWNER', 'ADMIN']) &&
      getApplication(state)?.enabled,
  ),
  loading: isLoadingType(getLoading(state), CREATE_WEBHOOK_REQUEST),
  error: getError(state),
})

const mapDispatch = (dispatch: MapDispatch): MapDispatchProps => ({
  onUpdate: (
    id: Webhook['id'],
    applicationId: Application['id'],
    webhook: UpdateWebhook,
  ) => {
    dispatch(updateWebhookRequest(id, applicationId, webhook))
  },
  onCreate: (applicationId: Application['id'], webhook: CreateWebhook) => {
    dispatch(createWebhookRequest(applicationId, webhook))
  },
  onDelete: (id: Webhook['id'], applicationId: Application['id']) => {
    dispatch(deleteWebhookRequest(id, applicationId))
  },
})

export default connect(mapState, mapDispatch)(ApplicationWebhooks)
