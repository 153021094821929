import { connect } from 'react-redux'

import { RootState } from '../../../modules/reducer'
import { getUser } from '../../../modules/user/selectors'
import ApplicationDemoPreviewLink from './ApplicationDemoPreviewLink'
import {
  MapDispatch,
  MapDispatchProps,
  MapStateProps,
} from './ApplicationDemoPreviewLink.types'

const mapState = (state: RootState): MapStateProps => ({
  user: getUser(state),
})

const mapDispatch = (_dispatch: MapDispatch): MapDispatchProps => ({
  // nothing here, yet
})

export default connect(mapState, mapDispatch)(ApplicationDemoPreviewLink)
