import { WebAuth as Auth0WebAuth } from 'auth0-js'

import { clearAuth0Authentication } from '../modules/auth0/storage'
import { locations } from '../modules/routing/locations'

const {
  REACT_APP_AUTH0_DOMAIN = '',
  REACT_APP_AUTH0_CLIENTID = '',
  REACT_APP_AUTH0_API_AUDIENCE = '',
} = process.env

const auth0Client = new Auth0WebAuth({
  domain: REACT_APP_AUTH0_DOMAIN,
  clientID: REACT_APP_AUTH0_CLIENTID,
  audience: REACT_APP_AUTH0_API_AUDIENCE,
  redirectUri: new URL(locations.auth0Callback(), document.baseURI).href,
  responseType: 'id_token token',
  scope: 'openid profile email',
})

/**
 * Initiates Auth0 logout flow, redirecting to the auth0 logout endpoint.
 * Afterwards, a redirect to 'returnTo' is issued
 */
export function signOut(): void {
  // clear auth0 session from storage
  clearAuth0Authentication()

  const rootUri = new URL(locations.root(), document.baseURI).href
  auth0Client.logout({
    returnTo: rootUri,
    clientID: REACT_APP_AUTH0_CLIENTID,
  })
}
