import React, { useCallback, useEffect, useState } from 'react'
import { useTranslation } from 'react-i18next'

import { Button, Radio } from '@pluggyai/ui'
import { Divider, Header, Loader } from 'semantic-ui-react'

import { TrackEventName } from '../../../../modules/analytics/events'
import { track } from '../../../../modules/analytics/utils'
import { Props } from './ApplicationDisableWidget.types'

import './ApplicationDisableWidget.css'

const ApplicationDisableWidget = ({
  application,
  isLoading,
  onUpdateApplicationEnabled,
}: Props) => {
  const { t } = useTranslation()

  const { name, id, enabled } = application

  const trackEnabledUpdateClick = useCallback(
    (newEnabled: boolean) => {
      track(TrackEventName.CTA_APPLICATION_ENABLE_DISABLE, {
        applicationId: id,
        value: newEnabled,
        previous: enabled,
      })
    },
    [id, enabled],
  )

  const [confirmChecked, setConfirmChecked] = useState(false)
  const handleOnConfirmCheckClick = useCallback(
    () => setConfirmChecked((value) => !value),
    [],
  )

  const handleOnToggleEnabledActionClick = useCallback(() => {
    const newEnabled = !enabled
    onUpdateApplicationEnabled(id, newEnabled)
    trackEnabledUpdateClick(newEnabled)
  }, [id, enabled, onUpdateApplicationEnabled, trackEnabledUpdateClick])

  useEffect(() => {
    // on submit/fetching, ensure confirm check value is reset to false
    if (isLoading && confirmChecked) {
      setConfirmChecked(false)
    }
  }, [isLoading, confirmChecked])

  return (
    <div className={'ApplicationDisableWidget'}>
      {isLoading ? (
        <div className={'widget-section-loader'}>
          <Loader size="big" active />
        </div>
      ) : (
        <>
          <div className={'pluggy card application-disable'}>
            <Header as={'h5'} className={'application-disable-header'}>
              {t(
                enabled
                  ? 'application.page.danger.disable.header'
                  : 'application.page.danger.enable.header',
              )}
            </Header>
            {/* TODO display 'error' message <Alert> */}
            <p>
              {t(
                enabled
                  ? 'application.page.danger.disable.content'
                  : 'application.page.danger.enable.content',
              )}
            </p>
            <Radio
              checked={confirmChecked}
              onClick={handleOnConfirmCheckClick}
              label={t(
                enabled
                  ? 'application.page.danger.disable.confirm'
                  : 'application.page.danger.enable.confirm',
                { name },
              )}
            />
            <Divider />
            <div className={'action-buttons'}>
              <Button
                primary
                onClick={handleOnToggleEnabledActionClick}
                disabled={!confirmChecked}
              >
                {t(
                  enabled
                    ? 'application.page.danger.disable.action'
                    : 'application.page.danger.enable.action',
                )}
              </Button>
            </div>
          </div>
        </>
      )}
    </div>
  )
}

export default React.memo(ApplicationDisableWidget)
