import React from 'react'

import { ArrowIcon } from '../Icon'

import './ArrowLink.css'

type Props = {
  label: string
  href: string
  onClick?: (event: React.MouseEvent<HTMLAnchorElement>) => void
  disabled?: boolean
}

const ArrowLink = ({ label, onClick, href, disabled }: Props) => {
  return (
    <div className={'ArrowLink'}>
      <a
        href={href}
        className={`application-open-link ${disabled ? 'disabled' : ''}`}
        target="_blank"
        rel="noopener noreferrer"
        onClick={onClick}
      >
        {label}
        <ArrowIcon className={`link ${disabled ? 'disabled' : ''}`} />
      </a>
    </div>
  )
}

export default React.memo(ArrowLink)
