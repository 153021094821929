import React, { useCallback, useEffect, useState } from 'react'
import { useTranslation } from 'react-i18next'

import { Alert, Button } from '@pluggyai/ui'
import { Modal, Popup } from 'semantic-ui-react'

import { TrackEventName } from '../../modules/analytics/events'
import { track } from '../../modules/analytics/utils'
import {
  ApplicationFields,
  ApplicationFieldsErrors,
} from '../../modules/application/types'
import { isStringsArray } from '../../utils/validation'
import { ApplicationForm, validateField } from '../ApplicationForm'
import { CloseIcon } from '../Icon'
import { Props } from './CreateApplicationButton.types'

import './CreateApplicationButton.css'

const initialApplicationFields: ApplicationFields = {
  name: '',
  shortDescription: '',
  allowedOrigins: [''],
}

const CreateApplicationButton = ({
  isOwnerOrAdminRole,
  isFeaturesLimited,
  error,
  isWaitingResponse,
  isProductionApplication,
  webhookSetup,
  onCreate,
  children,
  onModalOpen,
  ...triggerButtonProps
}: Props) => {
  const { t } = useTranslation()
  const [open, setOpen] = useState(false)
  const [submit, setSubmit] = useState(false)
  const [formErrors, setFormErrors] = useState<ApplicationFieldsErrors>({})
  const [applicationValues, setApplicationValues] = useState(
    initialApplicationFields,
  )

  const cancelButtonI18nKey = 'application.form.action.cancel'
  const cancelButtonText = t(cancelButtonI18nKey)
  const createButtonI18nKey = 'application.form.action.create'
  const createButtonText = t(createButtonI18nKey)

  const location = 'CreateApplicationModal'

  const trackOnModalClose = useCallback(() => {
    track(TrackEventName.ICON_CLICKED, {
      location,
      isProductionApplication,
      icon: 'CloseIcon',
    })
  }, [isProductionApplication])

  const trackOnCancelClick = useCallback(() => {
    track(TrackEventName.BUTTON_CLICKED, {
      location,
      isProductionApplication,

      text: cancelButtonText,
      i18nKey: cancelButtonI18nKey,
    })
  }, [cancelButtonText, isProductionApplication])

  const trackOnCreateClick = useCallback(() => {
    track(TrackEventName.BUTTON_CLICKED, {
      location,
      isProductionApplication,

      text: createButtonText,
      i18nKey: createButtonI18nKey,
    })
  }, [createButtonText, isProductionApplication])

  const trackFormValidationError = useCallback(
    (errors: ApplicationFieldsErrors) => {
      track(TrackEventName.FORM_VALIDATION_ERRORS, {
        fields: Object.keys(errors),
        location,
        isProductionApplication,
      })
    },
    [isProductionApplication],
  )

  const trackAddNewAllowedUrlInput = useCallback(() => {
    track(TrackEventName.LINK_APPLICATION_ADD_DOMAIN, {
      location,
      isProductionApplication,
    })
  }, [isProductionApplication])

  const trackRemoveAllowedUrlInput = useCallback(() => {
    track(TrackEventName.ICON_APPLICATION_REMOVE_DOMAIN, {
      location,
      isProductionApplication,
    })
  }, [isProductionApplication])

  const handleCancel = useCallback(() => {
    setOpen(false)
    setFormErrors({})
    trackOnCancelClick()
  }, [trackOnCancelClick])

  const handleClose = useCallback(() => {
    setOpen(false)
    setFormErrors({})
    trackOnModalClose()
  }, [trackOnModalClose])

  const showFeaturesLimitedPopup = isFeaturesLimited
  const createApplicationDisabled =
    isWaitingResponse || isFeaturesLimited || !isOwnerOrAdminRole

  const handleOpen = useCallback(() => {
    if (createApplicationDisabled) {
      return
    }

    onModalOpen?.()

    setOpen(true)
  }, [createApplicationDisabled, onModalOpen])

  const handleValuesChange = useCallback(
    (fieldName: keyof ApplicationFields, value: string | string[]) => {
      if (formErrors[fieldName]) {
        delete formErrors[fieldName]
        setFormErrors({ ...formErrors })
      }
      setApplicationValues({ ...applicationValues, [fieldName]: value })
    },
    [applicationValues, formErrors],
  )

  const handleSubmit = useCallback(
    (event: React.FormEvent) => {
      trackOnCreateClick()
      event.preventDefault()

      // validate fields values
      const currentFormErrors: ApplicationFieldsErrors = {}
      let errorsCount = 0
      for (const [field, value] of Object.entries(applicationValues)) {
        let fieldError: string | string[] | undefined
        if (isStringsArray(value)) {
          const valueJoined = value.join(',')
          const errorsJoined = validateField(
            field as keyof ApplicationFields,
            valueJoined,
          )
          const fieldErrors = errorsJoined?.split(',')
          if (fieldErrors?.some((fieldError_) => fieldError_.length > 0)) {
            errorsCount++
          }

          fieldError = fieldErrors
        } else {
          fieldError = validateField(field as keyof ApplicationFields, value)

          if (fieldError) {
            errorsCount++
          }
        }

        if (fieldError) {
          // using 'as any' here as a hack to support both 'string' or 'string[]'
          // fieldError values.
          // TODO rework/improve this, maybe using separated validations per field
          currentFormErrors[field as keyof ApplicationFields] =
            fieldError as any
        }
      }

      setFormErrors(currentFormErrors)
      if (errorsCount > 0) {
        // got errors, can't proceed
        trackFormValidationError(currentFormErrors)
        return
      }

      setSubmit(true)
      onCreate(applicationValues, isProductionApplication, webhookSetup)
    },
    [
      trackOnCreateClick,
      onCreate,
      applicationValues,
      isProductionApplication,
      webhookSetup,
      trackFormValidationError,
    ],
  )

  useEffect(() => {
    // check if we are done, and close modal if no errors
    const isDone = submit && error === null && !isWaitingResponse
    if (isDone) {
      handleClose()
    }
  }, [error, isWaitingResponse, submit, handleClose])

  const buttonClassName = [
    createApplicationDisabled ? 'disabled' : undefined,
    triggerButtonProps.className,
  ]
    .filter(Boolean)
    .join(' ')

  return (
    <Modal
      className={'CreateApplicationButton'}
      onClose={handleClose}
      open={open}
      closeIcon={<CloseIcon />}
      trigger={
        <div className={'create-team-action'}>
          <Popup
            className={'create-team-action-popup'}
            content={t('stepsToSuccess.disabledAction')}
            on="hover"
            disabled={!showFeaturesLimitedPopup}
            mouseLeaveDelay={500}
            position={'bottom center'}
            trigger={
              <Button
                onClick={handleOpen}
                {...{
                  ...triggerButtonProps,
                  className: buttonClassName,
                }}
              >
                {children}
              </Button>
            }
          />
        </div>
      }
    >
      <Modal.Header>
        {isProductionApplication ? (
          <>
            {t('application.form.production.title')}
            <p>{t('application.form.production.subtitle')}</p>
          </>
        ) : webhookSetup ? (
          <>
            {t('application.form.webhook-setup.title')}
            <p>{t('application.form.webhook-setup.subtitle')}</p>
          </>
        ) : (
          <>
            {t('application.form.development.title')}
            <p>{t('application.form.development.subtitle')}</p>
          </>
        )}
        {error !== null && (
          <Alert size={'medium'} type={'error'} message={error} />
        )}
      </Modal.Header>
      <Modal.Content>
        <ApplicationForm
          onSubmit={handleSubmit}
          onFieldChange={handleValuesChange}
          values={applicationValues}
          errors={formErrors}
          isSubmitting={isWaitingResponse}
          isEditAllowed={true}
          onAddNewAllowedUrlInput={trackAddNewAllowedUrlInput}
          onRemoveAllowedUrlInput={trackRemoveAllowedUrlInput}
        />
      </Modal.Content>
      <Modal.Actions>
        <Button secondary onClick={handleCancel}>
          {cancelButtonText}
        </Button>
        <Button primary onClick={handleSubmit} disabled={isWaitingResponse}>
          {createButtonText}
        </Button>
      </Modal.Actions>
    </Modal>
  )
}

export default React.memo(CreateApplicationButton)
