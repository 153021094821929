import {
  WEBHOOK_EVENT_COLUMNS,
  WebhookEvent,
} from '../../modules/webhookEvents/types'

/**
 * Build webhook events table column headers based on webhook event filter fields
 *
 * @param eventsFilter webhook event filter event field i.e item/created
 */
export function buildWebhookEventsColumnHeaders(
  eventsFilter: WebhookEvent['event'],
): string[] {
  // get subject from webhook event filter event field
  const [subject] = eventsFilter.split('/')

  const subjectIdColumnNumber = WEBHOOK_EVENT_COLUMNS.indexOf('subjectId')

  const dynamicColumns: string[] = [...WEBHOOK_EVENT_COLUMNS]

  if (['item', 'connector'].includes(subject)) {
    // when subject is item or connector, change subjectId column name to itemId or connectorId
    dynamicColumns[subjectIdColumnNumber] = `${subject}Id`
  }

  return dynamicColumns
}
