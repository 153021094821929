import { User } from './types'

/**
 * Helper function to check if User has pending required actions
 */
export function isUserActionsRequired(user: User): boolean {
  // TODO: add requirement of emailVerified back after we integrate our own Email provider.
  //  https://pluggy.atlassian.net/browse/DVP-128
  return !user.termsAccepted // || !user.emailVerified
}
