import { hotjar } from 'react-hotjar'

import { User } from '../modules/user/types'

const { REACT_APP_HOTJAR_ID: hotjarId = '' } = process.env

// This is only needed if Hotjar ever updates the Tracking Code and needs to discontinue older ones.
// Knowing which version our site includes allows hotjar team to contact us and inform.
const HOTJAR_SNIPPET_VERSION = 6
/**
 * helper function to init hotjar
 * @returns
 */
export function initHotjar() {
  if (!hotjarId) {
    console.warn('Hotjar has not been enabled')
    return
  }

  // note: initialize receives a number, so we need to convert the string to a number
  hotjar.initialize(parseInt(hotjarId), HOTJAR_SNIPPET_VERSION)
}

export function updateHotjarState(path: string) {
  if (!hotjarId) {
    return
  }

  hotjar.stateChange(path)
}

/**
 * add user related data into hotjar user recording
 * @param userId
 * @returns
 */
export function identifyHotjarUser(user: User) {
  if (!hotjarId) {
    return
  }
  const { id: userId, companyRole, name, email, createdAt } = user

  hotjar.identify(userId, {
    name,
    email,
    created_at: createdAt,
    user_company_role: companyRole,
  })
}
