import React, { useCallback } from 'react'
import { useTranslation } from 'react-i18next'

import { Button, Form, Input } from '@pluggyai/ui'

import { openHubspotChat } from '../../lib/hubspot'
import { TrackEventName } from '../../modules/analytics/events'
import { track } from '../../modules/analytics/utils'
import { Props } from './GoToProductionForm.types'

import './GoToProductionForm.css'

const GoToProductionForm = ({
  values,
  errors,
  loading,
  onSubmit,
  onFieldChange,
}: Props) => {
  const { t } = useTranslation()

  const goToProductionContactLinkI18nKey =
    'team.go-to-production.form.contact-us.link'
  const goToProductionContactLinkText = t(goToProductionContactLinkI18nKey)

  const handleGoToProductionContactLinkClick = useCallback(() => {
    openHubspotChat('team.go-to-production')

    track(TrackEventName.LINK_CLICKED, {
      location: 'GoToProductionForm',
      text: goToProductionContactLinkText,
      i18nKey: goToProductionContactLinkI18nKey,
    })
  }, [goToProductionContactLinkText, goToProductionContactLinkI18nKey])

  return (
    <Form className={'GoToProductionForm'} onSubmit={onSubmit}>
      <Input
        label={t('team.go-to-production.form.field.companyName.label')}
        type="text"
        onChange={({ value }) => onFieldChange('companyName', value)}
        value={values.companyName}
        error={errors.companyName}
        disabled={loading}
      />

      <p className={'contact-subtitle'}>
        {t('team.go-to-production.form.contact-us.title')}
      </p>
      <p className={'contact-text'}>
        {t('team.go-to-production.form.contact-us.text')}{' '}
        <Button link onClick={handleGoToProductionContactLinkClick}>
          {goToProductionContactLinkText}
        </Button>
      </p>

      <Input
        label={t('team.go-to-production.form.field.contactInfo.label')}
        type="text"
        onChange={({ value }) => onFieldChange('contactInfo', value)}
        value={values.contactInfo}
        error={errors.contactInfo}
        disabled={loading}
      />

      {/* Hidden input to allow submitting with "enter" key */}
      <input type="submit" style={{ display: 'none' }} />
    </Form>
  )
}

export default React.memo(GoToProductionForm)
