import React from 'react'
import { useTranslation } from 'react-i18next'

import { Props } from './EmptyChartMessage.types'

import './EmptyChartMessage.css'

const EmptyChartMessage = ({ teamOrUserHasItemsConnected }: Props) => {
  const { t } = useTranslation()

  return (
    <div className="EmptyChartMessage">
      <div className={'empty-chart-message-content'}>
        {teamOrUserHasItemsConnected
          ? t('dailyItems.no-items-for-selection')
          : t('dailyItems.team-or-user-has-no-items')}
      </div>
    </div>
  )
}

export default React.memo(EmptyChartMessage)
