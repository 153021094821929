import { useEffect } from 'react'

import PluggyUiDarkTheme from '@pluggyai/ui/lib/dark-theme'
import PluggyUiLightTheme from '@pluggyai/ui/lib/light-theme'

import { Props } from './Theme.types'

const Theme = ({ theme, children }: Props): JSX.Element => {
  useEffect(() => {
    if (theme === 'dark') {
      document.body.classList.add('dark')
      document.body.classList.remove('light')
    } else {
      document.body.classList.add('light')
      document.body.classList.remove('dark')
    }
  }, [theme])

  return (
    <>
      <style>
        {theme === 'light' ? PluggyUiLightTheme : PluggyUiDarkTheme}
      </style>

      {children}
    </>
  )
}

export default Theme
