import * as React from 'react'

import Icon from '../Icon'

import './ChevronDownIcon.css'

type Props = {
  onClick?: () => void
  className?: string
}

const ChevronDownIcon = ({ onClick, className }: Props) => (
  <Icon
    width="20"
    height="20"
    viewBox="0 0 20 20"
    fill="none"
    className={`ChevronDownIcon ${className}`}
    onClick={onClick}
  >
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M4.18503 6.1185C3.93292 5.84938 3.52444 5.84938 3.27232 6.1185C3.02039 6.3878 3.02039 6.82433 3.27232 7.09345L9.62704 13.8813C9.87898 14.1507 10.2876 14.1507 10.5397 13.8813L16.8943 7.09345C17.1464 6.82433 17.1464 6.3878 16.8943 6.1185C16.6424 5.84938 16.2337 5.84938 15.9816 6.1185L10.0834 12.4191L4.18503 6.1185Z"
      fill="#111111"
    />
  </Icon>
)

export default React.memo(ChevronDownIcon)
