import { connect } from 'react-redux'

import { RootState } from '../../modules/reducer'
import {
  UPDATE_USER_TERMS_ACCEPTED_REQUEST,
  updateUserTermsAcceptedRequest,
} from '../../modules/user/actions'
import {
  getLoading,
  getUserError,
  isLoadingType,
} from '../../modules/user/selectors'
import TermsAndConditionsModal from './TermsAndConditionsModal'
import {
  MapDispatch,
  MapDispatchProps,
  MapStateProps,
} from './TermsAndConditionsModal.types'

const mapState = (state: RootState): MapStateProps => ({
  error: getUserError(state),
  isLoading: isLoadingType(
    getLoading(state),
    UPDATE_USER_TERMS_ACCEPTED_REQUEST,
  ),
})

const mapDispatch = (dispatch: MapDispatch): MapDispatchProps => ({
  onAcceptTermsRequest: () => dispatch(updateUserTermsAcceptedRequest()),
})

export default connect(mapState, mapDispatch)(TermsAndConditionsModal)
