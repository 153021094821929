import React, { useCallback, useEffect, useMemo, useState } from 'react'
import { useTranslation } from 'react-i18next'

import { Input } from '@pluggyai/ui'
import { Loader } from 'semantic-ui-react'

import { TrackEventName } from '../../modules/analytics/events'
import { track } from '../../modules/analytics/utils'
import { DailyItemStatsReport } from '../../modules/stats/types'
import { ApplicationEmptyPlaceholder } from '../ApplicationsList/ApplicationEmptyPlaceholder'
import { ApplicationFilters } from './ApplicationFilters'
import { ApplicationFilterOption } from './ApplicationFilters/ApplicationFilters.types'
import { ConnectionStats } from './ConnectionStats'
import { ConnectorsFilter } from './ConnectorsFilter'
import { DailyChart } from './DailyChart'
import { ItemsTypeFilters, ItemsTypeValue } from './ItemsTypeFilters'
import { Report } from './Report'
import { Props } from './Stats.types'
import { mapDailyItemStatsData } from './utils'

import './Stats.css'

const TODAY_DATE = new Date()

const Stats = ({
  teamOrUserHasItemsConnected,
  isApplicationsEmpty,
  isLoading,
  applications,
  user,
  team,
  onItemStatsFiltersChange,
  itemsStatsFilters,
  itemStats,
}: Props) => {
  const { t } = useTranslation()

  const { application, selectedExecutionStatusGroups, startDate, endDate } =
    itemsStatsFilters

  const [applicationDailyItemsStats, setApplicationDailyItemsStats] = useState<
    DailyItemStatsReport[] | undefined
  >()

  const dailyItemStatsData = applicationDailyItemsStats
    ? mapDailyItemStatsData(applicationDailyItemsStats, {
        startDate,
        endDate,
      })
    : []

  const isFirstTimeLoading = isLoading && !itemStats

  const disabled = !teamOrUserHasItemsConnected

  // wrapping with useMemo to prevent the dependencies of handleTimeFiltersChange from changing
  const teamOrUserCreationDate = useMemo(
    () =>
      team !== null
        ? new Date(team.createdAt)
        : user !== null
        ? new Date(user.createdAt)
        : undefined,
    [team, user],
  )

  const initializeCurrentItemStats = useCallback(() => {
    setApplicationDailyItemsStats(itemStats?.dailyItemStats)
  }, [itemStats?.dailyItemStats])

  useEffect(() => {
    if (!user || !team) {
      // mounted but 'user' or 'team  still not retrieved -> don't request stats, yet
      return
    }

    // Set timeout use as hack to avoid legend overlap with graph.
    // the issue is closed but the bug remains https://github.com/recharts/recharts/issues/172#issuecomment-915968909
    setTimeout(initializeCurrentItemStats, 0)
  }, [initializeCurrentItemStats, team, user])

  const handleOnItemsTypeFilterChange = useCallback(
    (value: ItemsTypeValue[]) => {
      track(TrackEventName.ITEMS_CHART_TYPE_FILTER_CHANGE, {
        previous: selectedExecutionStatusGroups,
        value,
      })

      onItemStatsFiltersChange({
        selectedExecutionStatusGroups: value,
      })
    },
    [selectedExecutionStatusGroups, onItemStatsFiltersChange],
  )

  const handleNewApplicationModalOpen = useCallback(() => {
    track(TrackEventName.CTA_NEW_APPLICATION, {
      location: 'ApplicationsPage Stats DailyItems EmptyApplicationPlaceholder',
    })
  }, [])

  const handleApplicationFilterChange = useCallback(
    (value: ApplicationFilterOption) => {
      if (value !== application) {
        // there was a value defined & now setting a different one -> track change
        track(TrackEventName.ITEMS_CHART_APPLICATION_FILTER_CHANGE, {
          previous: application,
          value,
        })
      }

      onItemStatsFiltersChange({ application: value })
    },
    [onItemStatsFiltersChange, application],
  )

  const handleTimeFiltersChange = useCallback(
    ({
      startDate: startDate_,
      endDate: endDate_,
    }: {
      startDate: Date
      endDate: Date
    }) => {
      track(TrackEventName.ITEMS_CHART_TIME_SELECTOR_CHANGE, {
        previous: { startDate, endDate },
        value: {
          startDate: startDate_,
          endDate: endDate_,
          maximumDate: TODAY_DATE,
          minimumDate: teamOrUserCreationDate,
        },
      })

      onItemStatsFiltersChange({
        startDate: startDate_,
        endDate: endDate_,
      })
    },
    [endDate, onItemStatsFiltersChange, startDate, teamOrUserCreationDate],
  )

  const applicationEmptyPlaceholderActionI18nKey =
    'dailyItems.empty-applications.action'
  const applicationEmptyPlaceholderActionText = t(
    applicationEmptyPlaceholderActionI18nKey,
  )

  const handleStatsContentClick = useCallback(
    (event: React.MouseEvent<HTMLDivElement>) => {
      const target = event.target as HTMLElement

      track(TrackEventName.ITEMS_CHART_COMPONENT_CLICK, {
        elementClassName: target.className,
        elementTagName: target.tagName,
        textContent: target.textContent,
        location: 'OverviewPage Stats',
      })
    },
    [],
  )

  return (
    <div className={'Stats'}>
      {/* disabling eslint rule due to click handler used for analytics */}
      {/* we dont care about making the action accessible */}
      {/* eslint-disable-next-line jsx-a11y/click-events-have-key-events, jsx-a11y/no-static-element-interactions */}
      <div className={'stats-content'} onClick={handleStatsContentClick}>
        <div className={'stats-header'}>
          <div className={'stats-title-container'}>
            <h2 className={'stats-title'}>{t('items-stats.title')}</h2>
          </div>
          <div className={'stats-filters'}>
            <Input
              type="date-picker"
              startDate={startDate}
              endDate={endDate}
              onChange={handleTimeFiltersChange}
              disabled={disabled}
              maxDate={TODAY_DATE}
              className={'date-filter'}
              minDate={teamOrUserCreationDate}
            />
            <ApplicationFilters
              applications={applications}
              selectedApplication={application}
              onChange={handleApplicationFilterChange}
              disabled={disabled}
            />
            <ItemsTypeFilters
              currentValue={selectedExecutionStatusGroups}
              onChange={handleOnItemsTypeFilterChange}
              disabled={disabled}
              selectedTypes={selectedExecutionStatusGroups}
            />

            <ConnectorsFilter disabled={disabled} />
          </div>
        </div>
        <div className={'stats-container'}>
          {isFirstTimeLoading ? (
            <div className={'widget-section-loader'}>
              <Loader size="medium" active />
            </div>
          ) : isApplicationsEmpty ? (
            <ApplicationEmptyPlaceholder
              onNewApplicationModalOpen={handleNewApplicationModalOpen}
              messageText={t('dailyItems.empty-applications.message')}
              actionText={applicationEmptyPlaceholderActionText}
              actionI18nKey={applicationEmptyPlaceholderActionI18nKey}
            />
          ) : (
            <>
              <ConnectionStats />
              <DailyChart
                currentItemsType={selectedExecutionStatusGroups}
                dailyItemStatsData={dailyItemStatsData}
                teamOrUserHasItemsConnected={teamOrUserHasItemsConnected}
              />

              <Report
                teamOrUserHasItemsConnected={teamOrUserHasItemsConnected}
              />
            </>
          )}
        </div>
      </div>
    </div>
  )
}

export default React.memo(Stats)
