import React, { useCallback } from 'react'
import { useTranslation } from 'react-i18next'

import { buildPluggyNoCodeDashboardApplicationUrl } from '../../../modules/application/utils'
import { ArrowLink } from '../../ArrowLink'
import { Props } from './ApplicationNoCodeDashboardLink.types'

export const DEMO_LINK_MAX_DEVELOPMENT_APPLICATION_ITEMS = 100

const ApplicationNoCodeDashboardLink = ({ application, onClick }: Props) => {
  const { t } = useTranslation()
  const url = buildPluggyNoCodeDashboardApplicationUrl(application)

  const handleClick = useCallback(
    (_event: React.MouseEvent<HTMLAnchorElement>) => {
      onClick?.(application)
    },
    [application, onClick],
  )

  return (
    <div>
      <ArrowLink
        label={t('application.header.noCodeDashboardLink.text')}
        href={url}
        onClick={handleClick}
      />
    </div>
  )
}

export default React.memo(ApplicationNoCodeDashboardLink)
