import { memo } from 'react'
import { useTranslation } from 'react-i18next'

import { formatDateTime } from '../../../lib/date'
import { DataPlaceholder } from '../DataPlaceholder'
import { Props } from './ItemDataContainer.types'
import { ItemFieldContainer } from './ItemFieldContainer'

import './ItemDataContainer.css'

const ItemDataContainer = ({ item }: Props) => {
  const { t } = useTranslation('translation', {
    keyPrefix: 'item-executions-page.item-data-container',
  })

  return (
    <div className={'ItemDataContainer'}>
      <p className={'item-data-title'}>{t('title')}</p>
      {!item ? (
        <DataPlaceholder message={t('empty-data-message')} />
      ) : (
        <div className={'data-container'}>
          <div className={'row'}>
            <div className={'column'}>
              <ItemFieldContainer
                title={t('rows.status')}
                value={item.status}
              />
              <ItemFieldContainer
                title={t('rows.client-user-id')}
                value={item.clientUserId ?? '-'}
              />
            </div>
            <div className={'column'}>
              <ItemFieldContainer
                title={t('rows.execution-status')}
                value={item.executionStatus}
              />
              <ItemFieldContainer
                title={t('rows.connector-id')}
                value={item.connector.id}
              />
            </div>
            <div className={'column'}>
              <ItemFieldContainer
                title={t('rows.created-at')}
                value={formatDateTime(item.createdAt, 'dd MMMM')}
              />
              <ItemFieldContainer
                title={t('rows.connector-name')}
                value={item.connector.name}
              />
            </div>
            <div className={'column'}>
              <ItemFieldContainer
                title={t('rows.last-updated-at')}
                value={
                  item.lastUpdatedAt
                    ? formatDateTime(item.lastUpdatedAt, 'dd MMMM')
                    : '-'
                }
              />
            </div>
          </div>
        </div>
      )}
    </div>
  )
}

export default memo(ItemDataContainer)
