import ReactDOM from 'react-dom'
import { Provider } from 'react-redux'

import { Auth0Provider } from '@auth0/auth0-react'
import { ConnectedRouter } from 'connected-react-router'

import { FreeTrialExpiredModal } from './components/FreeTrialExpiredModal'
import { Routes } from './components/Routes'
import { Theme } from './components/Theme'
import { Toasts } from './components/Toasts'
import { locations } from './modules/routing/locations'
import { history, store } from './modules/store'
import './setup'
import './themes'
import './utils/i18n'

import './index.css'

const {
  REACT_APP_AUTH0_DOMAIN = '',
  REACT_APP_AUTH0_CLIENTID = '',
  REACT_APP_AUTH0_API_AUDIENCE = '',
} = process.env

const component = (
  <Provider store={store}>
    <Auth0Provider
      domain={REACT_APP_AUTH0_DOMAIN}
      clientId={REACT_APP_AUTH0_CLIENTID}
      audience={REACT_APP_AUTH0_API_AUDIENCE}
      redirectUri={new URL(locations.auth0Callback(), document.baseURI).href}
      // list Application desired API permissions in the 'scope'
      // auth-api backend will check and confirm which Auth0 permissions can be actually granted to the user
      scope={'read:users:data:all write:users:data:all'}
    >
      <Theme>
        <ConnectedRouter history={history}>
          <Routes />
          <Toasts />
          <FreeTrialExpiredModal />
        </ConnectedRouter>
      </Theme>
    </Auth0Provider>
  </Provider>
)

ReactDOM.render(component, document.getElementById('root'))
