import { useState, useEffect } from 'react'

type WindowDimensions = {
  width: number
  height: number
}

function getWindowDimensions(): WindowDimensions {
  const { innerWidth: width, innerHeight: height } = window
  return {
    width,
    height,
  }
}

/**
 * hook to get window size and actively listen for changes in the window size.
 * source: StackOverflow answer https://stackoverflow.com/a/38685016
 * @returns {WindowDimensions} window dimensions
 */
export default function useWindowDimensions(): WindowDimensions {
  const [windowDimensions, setWindowDimensions] = useState(
    getWindowDimensions(),
  )

  useEffect(() => {
    function handleResize() {
      setWindowDimensions(getWindowDimensions())
    }

    window.addEventListener('resize', handleResize)
    return () => window.removeEventListener('resize', handleResize)
  }, [])

  return windowDimensions
}
