import React, { useCallback, useState } from 'react'
import { Trans, useTranslation } from 'react-i18next'

import { Alert, Button, Input } from '@pluggyai/ui'
import { Form, Modal } from 'semantic-ui-react'

import { TrackEventName } from '../../../../../modules/analytics/events'
import { track } from '../../../../../modules/analytics/utils'
import { CloseIcon } from '../../../../Icon'
import { Props } from './TeamDeleteConfirmModal.types'

import './TeamDeleteConfirmModal.css'

const TeamDeleteConfirmModal = ({
  team,
  isLoading,
  error,
  onClose,
  onDeleteTeam,
}: Props) => {
  const { t } = useTranslation()

  const { name, id } = team
  const [confirmTeamName, setConfirmTeamName] = useState('')

  // TODO: this is a workaround to make it easier to delete the team,
  // should we don't allow users to have a team name
  // with spaces? https://pluggy.atlassian.net/browse/DVP-163
  const isTeamNameConfirmed = confirmTeamName === name.trim()

  const trackDeleteSubmit = useCallback(() => {
    track(TrackEventName.CTA_TEAM_DELETE_MODAL_CONFIRM, {
      teamId: id,
    })
  }, [id])

  const handleSubmit = useCallback(() => {
    if (!isTeamNameConfirmed) {
      return
    }
    onDeleteTeam(team)
    trackDeleteSubmit()
    // close the modal
    onClose()
  }, [team, isTeamNameConfirmed, onDeleteTeam, onClose, trackDeleteSubmit])

  const handleOnTeamNameChange = useCallback(({ value }) => {
    setConfirmTeamName(value)
  }, [])

  return (
    <Modal
      className={'TeamDeleteConfirmModal'}
      onClose={onClose}
      open={true}
      closeIcon={<CloseIcon />}
    >
      <Modal.Header>
        {t('settings.team.danger.delete.modal.header')}
        {error !== null ? (
          <Alert size={'medium'} type={'error'} message={error} />
        ) : (
          <Alert
            type={'warning'}
            size={'medium'}
            message={t('settings.team.danger.delete.modal.warning')}
          />
        )}
      </Modal.Header>
      <Modal.Content>
        <Trans
          i18nKey="settings.team.danger.delete.modal.content"
          values={{ name }}
        />
        <Form onSubmit={handleSubmit}>
          <Input
            type={'text'}
            label={t('settings.team.danger.delete.modal.confirm.label')}
            value={confirmTeamName}
            onChange={handleOnTeamNameChange}
            disabled={isLoading}
          />
        </Form>
      </Modal.Content>
      <Modal.Actions>
        <Button
          primary
          fluid
          onClick={handleSubmit}
          disabled={!isTeamNameConfirmed}
          loading={isLoading}
        >
          {t('settings.team.danger.delete.modal.action')}
        </Button>
      </Modal.Actions>
    </Modal>
  )
}

export default React.memo(TeamDeleteConfirmModal)
