import { captureException } from '@sentry/react'
import { push } from 'connected-react-router'
import i18next from 'i18next'
import { all, call, put, select, takeEvery } from 'redux-saga/effects'

import { TrackEventName } from '../analytics/events'
import { track } from '../analytics/utils'
import { addNotificationAction } from '../notification/actions'
import { NotificationOptions } from '../notification/types'
import { locations } from '../routing/locations'
import { getCurrentTeam } from '../team/selectors'
import { Team } from '../team/types'
import { getUser } from '../user/selectors'
import { User } from '../user/types'
import {
  SUBMIT_GO_TO_PRODUCTION_FAILURE,
  SUBMIT_GO_TO_PRODUCTION_REQUEST,
  SUBMIT_GO_TO_PRODUCTION_SUCCESS,
  submitGoToProductionFailure,
  SubmitGoToProductionFailureAction,
  SubmitGoToProductionRequestAction,
  submitGoToProductionSuccess,
} from './actions'

const THREE_SECONDS = 3000

function* handleSubmitGoToProductionRequest(
  action: SubmitGoToProductionRequestAction,
) {
  const { companyName, contactInfo } = action.payload

  try {
    const user: User | null = yield select(getUser)

    const team: Team | null = yield select(getCurrentTeam)

    yield call(
      () =>
        // we create a promise to make the function async to properly manage the loading state
        new Promise<void>((resolve, reject) => {
          setTimeout(() => {
            // if the call does not resolve in at in 3 seconds, we reject the promise
            reject('Segment request timed out and the form was not submitted')
          }, THREE_SECONDS)

          track(
            TrackEventName.GO_TO_PRODUCTION_REQUEST_SUBMITTED,
            {
              companyName,
              contactInfo,
              user,
              team,
            },
            undefined,
            resolve,
          )
        }),
    )

    yield put(submitGoToProductionSuccess())
  } catch (error) {
    const notificationOptions: NotificationOptions = {
      level: 'error',
      title: i18next.t('team.go-to-production.form.toast.error.title'),
      message: i18next.t('team.go-to-production.form.toast.error.message'),
      duration: 4000,
    }
    yield put(addNotificationAction(notificationOptions))

    error.message = `Could not submit go to production: ${error.message}`
    captureException(error, {
      contexts: {
        error: {
          action,
          object: error,
          notificationOptions,
        },
      },
    })

    yield put(submitGoToProductionFailure(error.message))
  }
}

function* handleSubmitGoToProductionSuccess() {
  yield put(
    addNotificationAction({
      title: i18next.t('team.go-to-production.form.toast.success.title'),
      message: i18next.t('team.go-to-production.form.toast.success.message'),
      duration: 4000,
    }),
  )
  // navigate to applications page
  yield put(push(locations.applications()))
}

function* handleSubmitGoToProductionFailure(
  _action: SubmitGoToProductionFailureAction,
) {
  // navigate to applications page
  yield put(push(locations.applications()))
}

export function* goToProductionSaga() {
  yield all([
    takeEvery(
      SUBMIT_GO_TO_PRODUCTION_REQUEST,
      handleSubmitGoToProductionRequest,
    ),
    takeEvery(
      SUBMIT_GO_TO_PRODUCTION_SUCCESS,
      handleSubmitGoToProductionSuccess,
    ),
    takeEvery(
      SUBMIT_GO_TO_PRODUCTION_FAILURE,
      handleSubmitGoToProductionFailure,
    ),
  ])
}
