import { sub } from 'date-fns'

import { getAllApplicationsFilterOption } from '../../components/Stats/ApplicationFilters/utils'
import { getAllConnectorsFilterOption } from '../../components/Stats/ConnectorsFilter/utils'
import { itemsStatsTypesGroupsNames } from '../../components/Stats/ItemsTypeFilters/ItemsTypeFilters.types'
import { Application } from '../application/types'
import { loadingReducer } from '../loading/reducer'
import { LoadingState } from '../loading/types'
import {
  FETCH_ITEMS_STATS_FAILURE,
  FETCH_ITEMS_STATS_REQUEST,
  FETCH_ITEMS_STATS_SUCCESS,
  FETCH_CONNECTORS_STATS_FAILURE,
  FetchItemsStatsFailureAction,
  FetchItemsStatsRequestAction,
  FetchItemsStatsSuccessAction,
  FetchConnectorsStatsRequestAction,
  FetchConnectorsStatsSuccessAction,
  FetchConnectorsStatsFailureAction,
  FETCH_CONNECTORS_STATS_REQUEST,
  FETCH_CONNECTORS_STATS_SUCCESS,
  FETCH_TOTAL_ITEMS_STATS_REQUEST,
  FETCH_TOTAL_ITEMS_STATS_SUCCESS,
  FETCH_TOTAL_ITEMS_STATS_FAILURE,
  FetchTotalItemsStatsSuccessAction,
  FetchTotalItemsStatsRequestAction,
  FetchTotalItemsStatsFailureAction,
  CHANGE_ITEM_STATS_FILTERS,
  ChangeItemStatsFiltersAction,
} from './actions'
import { ConnectorsStats, ItemsStats, ItemStatsFilters } from './types'

const ITEMS_STATS_ONE_MONTH_AGO_DATE = sub(new Date(), { months: 1 })

const TODAY_DATE = new Date()

export type StatsState = {
  data: {
    connectorsStats?: ConnectorsStats
    itemsStatsByApplicationId?: Record<
      Application['id'],
      ItemsStats | undefined
    >
    totalItemStats?: ItemsStats
    itemStatsReport: {
      filters: ItemStatsFilters
      data?: ItemsStats
    }
  }
  loading: LoadingState
  error: string | null
}

export const INITIAL_ITEM_STATS_FILTERS: ItemStatsFilters = {
  application: getAllApplicationsFilterOption(),
  showSandbox: false,
  startDate: ITEMS_STATS_ONE_MONTH_AGO_DATE,
  endDate: TODAY_DATE,
  selectedExecutionStatusGroups: itemsStatsTypesGroupsNames,
  selectedConnector: getAllConnectorsFilterOption(),
}

const INITIAL_STATE: StatsState = {
  data: {
    itemStatsReport: {
      filters: INITIAL_ITEM_STATS_FILTERS,
    },
  },
  loading: [],
  error: null,
}

type ExecutionReducerAction =
  | FetchItemsStatsSuccessAction
  | FetchItemsStatsRequestAction
  | FetchItemsStatsFailureAction
  | FetchTotalItemsStatsSuccessAction
  | FetchTotalItemsStatsRequestAction
  | FetchTotalItemsStatsFailureAction
  | FetchConnectorsStatsRequestAction
  | FetchConnectorsStatsSuccessAction
  | FetchConnectorsStatsFailureAction
  | ChangeItemStatsFiltersAction

export function statsReducer(
  state: StatsState = INITIAL_STATE,
  action: ExecutionReducerAction,
): StatsState {
  switch (action.type) {
    case FETCH_CONNECTORS_STATS_REQUEST:
    case FETCH_ITEMS_STATS_REQUEST:
    case FETCH_TOTAL_ITEMS_STATS_REQUEST:
      return {
        ...state,
        error: null,
        loading: loadingReducer(state.loading, action),
      }
    case FETCH_ITEMS_STATS_SUCCESS:
      const {
        applicationId,
        value: itemsStats,
        isOverviewPageItemsStats,
      } = action.payload

      return {
        ...state,
        loading: loadingReducer(state.loading, action),
        error: null,
        data: {
          ...state.data,
          ...(isOverviewPageItemsStats
            ? {
                itemStatsReport: {
                  ...state.data.itemStatsReport,
                  data: itemsStats,
                },
              }
            : applicationId && {
                itemsStatsByApplicationId: {
                  ...state.data.itemsStatsByApplicationId,
                  [applicationId]: itemsStats,
                },
              }),
        },
      }
    case FETCH_TOTAL_ITEMS_STATS_SUCCESS:
      const { value: totalItemStats } = action.payload

      return {
        ...state,
        loading: loadingReducer(state.loading, action),
        error: null,
        data: { ...state.data, totalItemStats },
      }
    case FETCH_CONNECTORS_STATS_SUCCESS:
      return {
        ...state,
        loading: loadingReducer(state.loading, action),
        error: null,
        data: {
          ...state.data,
          connectorsStats: action.payload.response,
        },
      }
    case FETCH_CONNECTORS_STATS_FAILURE:
    case FETCH_ITEMS_STATS_FAILURE:
    case FETCH_TOTAL_ITEMS_STATS_FAILURE:
      return {
        ...state,
        loading: loadingReducer(state.loading, action),
        error: action.payload.error,
      }
    case CHANGE_ITEM_STATS_FILTERS:
      const { request: filters } = action.payload
      return {
        ...state,
        loading: loadingReducer(state.loading, action),
        data: {
          ...state.data,
          itemStatsReport: {
            ...state.data.itemStatsReport,
            filters: {
              ...state.data.itemStatsReport.filters,
              ...filters,
            },
          },
        },
      }

    default:
      return state
  }
}
