import { format, isSameDay, isToday } from 'date-fns'

import { DailyItemStatsReport } from '../../modules/stats/types'
import { TimeFilterValue } from './Stats.types'

const TODAY_DATE = new Date(Date.now())

/**
 * Helper to build a chart data placeholder array, for the range of dates
 *
 * @param range - object specifying the range of days to include in the array
 * @returns - array of dates
 */
function buildDatesRange({ startDate, endDate }: TimeFilterValue): Date[] {
  const datesRange: Date[] = []

  const date = new Date(startDate)

  while (date <= endDate) {
    datesRange.push(new Date(date))
    date.setDate(date.getDate() + 1)
  }

  return datesRange
}

const emptyDailyItemStat: DailyItemStatsReport = {
  total: 0,
  totalOrPartialSuccess: 0,
  userLoginErrors: 0,
  connectivityErrors: 0,
  unexpectedErrors: 0,
  day: '',
}

/**
 * Helper function to return a data array of DailyItemStatsReport[],
 * filling-in dates in the currentTimeFilter with empty data entries,
 * so those are displayed in the DailyItems chart x-axis as well.
 *
 * @param dailyItemStats - actual source data
 * @param currentTimeFilter - the currently selected time filter value
 */
export function mapDailyItemStatsData(
  dailyItemStats: DailyItemStatsReport[],
  currentTimeFilter: TimeFilterValue,
): DailyItemStatsReport[] {
  const datesRange = buildDatesRange(currentTimeFilter)

  const itemStatsData: DailyItemStatsReport[] = []

  const showHourlyStats = datesRange.length === 1

  if (showHourlyStats) {
    const currentHour = TODAY_DATE.getHours()

    const hourLimit = isToday(datesRange[0]) ? currentHour : 24

    for (let hour = 0; hour < hourLimit; hour++) {
      itemStatsData[hour] = { ...emptyDailyItemStat, day: `${hour}h` }
    }

    for (const stat of dailyItemStats) {
      const hour = new Date(stat.day).getHours()

      if (hour > hourLimit) {
        continue
      }

      itemStatsData[hour] = { ...stat, day: `${hour}h` }
    }
    return itemStatsData
  }
  for (const xDayDate of datesRange) {
    const formattedDate = format(xDayDate, 'dd MMM')
    let currentStat: DailyItemStatsReport | undefined = undefined

    // find the stat for the current day

    for (const stat of dailyItemStats) {
      const statDate = new Date(stat.day.slice(0, -1))
      if (!isSameDay(statDate, xDayDate)) {
        // stat is not same day, ignore.
        continue
      }
      currentStat = stat
    }

    itemStatsData.push({
      ...(currentStat || emptyDailyItemStat),
      day: formattedDate,
    })
  }

  return itemStatsData
}
