import { IdToken } from '@auth0/auth0-react'
import { captureException } from '@sentry/react'
import { all, call, put, takeEvery, takeLatest } from 'redux-saga/effects'

import { signOut } from '../../lib/auth0'
import { clearHubspotConversationSession } from '../../lib/hubspot'
import { shutdown as intercomShutdown } from '../../lib/intercom'
import { addSentryUserData } from '../../lib/sentry'
import { fetchUserRequest } from '../user/actions'
import {
  AUTH0_LOGIN_FAILURE,
  AUTH0_LOGIN_REQUEST,
  AUTH0_LOGIN_SUCCESS,
  AUTH0_LOGOUT_REQUEST,
  AUTH0_LOGOUT_SUCCESS,
  auth0loginFailure,
  Auth0LoginRequestAction,
  auth0LoginSuccess,
  Auth0LoginSuccessAction,
  auth0LogoutFailure,
  Auth0LogoutRequestAction,
  auth0LogoutSuccess,
} from './actions'
import { storeAuth0AuthenticationResult } from './storage'
import { Auth0Result } from './types'

function* handleAuth0LoginRequest(action: Auth0LoginRequestAction) {
  const { getIdTokenClaims, getAccessTokenSilently } = action.payload
  try {
    const [idToken, accessToken]: [IdToken, string] = yield call(() =>
      Promise.all([getIdTokenClaims(), getAccessTokenSilently()]),
    )

    const auth0Result: Auth0Result = {
      idToken,
      accessToken,
    }
    yield put(auth0LoginSuccess(auth0Result))
  } catch (error) {
    error.message = `Auth0 login failed: ${error.message}`
    captureException(error, {
      level: 'error',
      contexts: {
        error: {
          action,
          object: error,
        },
      },
      fingerprint: [error.message],
    })
    yield put(auth0loginFailure(error.message))
  }
}

function* handleAuth0LoginSuccess(action: Auth0LoginSuccessAction) {
  const { auth0Result } = action.payload
  // store auth0 result for later retrieval
  storeAuth0AuthenticationResult(auth0Result)

  // track user info in Sentry
  const {
    idToken: { email, name, given_name },
  } = auth0Result
  addSentryUserData({
    email,
    name,
    givenName: given_name,
  })

  // trigger auth-api authentication request
  yield put(fetchUserRequest(auth0Result))
}

function handleAuth0LoginFailure() {
  localStorage.clear()
}

function* handleAuth0LogoutRequest(action: Auth0LogoutRequestAction) {
  try {
    yield clearHubspotConversationSession()
    signOut()
    yield put(auth0LogoutSuccess())
  } catch (error) {
    error.message = `Auth0 logout error: ${error.message}`
    captureException(error, {
      contexts: {
        error: {
          action,
          object: error,
        },
      },
    })
    yield put(auth0LogoutFailure(error.message))
  }
}

function handleAuth0LogoutSuccess() {
  intercomShutdown()
  localStorage.clear()
}

export function* auth0Saga() {
  yield all([
    takeLatest(AUTH0_LOGIN_REQUEST, handleAuth0LoginRequest),
    takeLatest(AUTH0_LOGIN_SUCCESS, handleAuth0LoginSuccess),
    takeLatest(AUTH0_LOGOUT_REQUEST, handleAuth0LogoutRequest),
    takeLatest(AUTH0_LOGOUT_SUCCESS, handleAuth0LogoutSuccess),
    takeEvery(AUTH0_LOGIN_FAILURE, handleAuth0LoginFailure),
  ])
}
