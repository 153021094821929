import { captureException } from '@sentry/react'
import axios, { AxiosResponse } from 'axios'
import { push } from 'connected-react-router'
import i18next from 'i18next'
import {
  all,
  call,
  put,
  select,
  take,
  takeEvery,
  takeLatest,
} from 'redux-saga/effects'
import { UAParser } from 'ua-parser-js'

import { BaseService } from '../../lib/api/BaseService'
import { UserService } from '../../lib/api/UserService'
import { identifyHotjarUser } from '../../lib/hotjar'
import {
  identifyNewContactInHubspot,
  identifyUserInHubspotConversation,
  loadHubspotConversationsWidget,
  loadHubspotScript,
} from '../../lib/hubspot'
import { identifyUserInIntercom } from '../../lib/intercom'
import { fetchActivityRequest } from '../activity/actions'
import { TrackEventName } from '../analytics/events'
import { identify, identifyUserInSegment, track } from '../analytics/utils'
import { fetchApplicationsRequest } from '../application/actions'
import { auth0LogoutRequest } from '../auth0/actions'
import { getAuth0AccessToken } from '../auth0/selectors'
import { fetchCustomizationRequest } from '../customization/actions'
import { addNotificationAction } from '../notification/actions'
import { NotificationOptions } from '../notification/types'
import { locations } from '../routing/locations'
import {
  changeItemStatsFilters,
  fetchTotalItemsStatsRequest,
} from '../stats/actions'
import { INITIAL_ITEM_STATS_FILTERS } from '../stats/reducer'
import { fetchTeamsRequest } from '../team/actions'
import {
  DELETE_USER_REQUEST,
  DELETE_USER_SUCCESS,
  deleteUserFailure,
  DeleteUserRequestAction,
  deleteUserSuccess,
  FETCH_USER_FAILURE,
  FETCH_USER_REQUEST,
  FETCH_USER_SUCCESS,
  fetchUserFailure,
  FetchUserRequestAction,
  fetchUserSuccess,
  FetchUserSuccessAction,
  IMPERSONATE_USER_FAILURE,
  IMPERSONATE_USER_REQUEST,
  IMPERSONATE_USER_SUCCESS,
  impersonateUserFailure,
  ImpersonateUserFailureAction,
  impersonateUserRequest,
  ImpersonateUserRequestAction,
  impersonateUserSuccess,
  ImpersonateUserSuccessAction,
  RESEND_VERIFICATION_EMAIL_REQUEST,
  resendVerificationEmailFailure,
  resendVerificationEmailSuccess,
  setShowUserActionsRequired,
  UPDATE_USER_REQUEST,
  UPDATE_USER_TERMS_ACCEPTED_REQUEST,
  UPDATE_USER_TERMS_ACCEPTED_SUCCESS,
  updateUserFailure,
  UpdateUserRequestAction,
  updateUserSuccess,
  updateUserTermsAcceptedFailure,
  updateUserTermsAcceptedSuccess,
} from './actions'
import { getUser } from './selectors'
import { removeIsComingFromLandingPageFromStorage } from './storage'
import { User } from './types'
import { isUserActionsRequired } from './utils'

function* handleFetchUserRequest(action: FetchUserRequestAction) {
  const { auth0Result } = action.payload
  const { accessToken } = auth0Result
  try {
    if (!accessToken) {
      throw new Error(
        'Can not retrieve Pluggy Dashboard User: Missing Auth0 access token',
      )
    }

    const userService = new UserService(accessToken)

    const { data: user, status }: AxiosResponse<User> = yield call(() =>
      userService.postUserLogin(),
    )

    // load Hubspot script
    // Note: we do it *after* login to prevent showing it
    //  before/during the redirect to Auth0 authentication page
    // Also: we *always* load it to ensure the identify call below is sent,
    //  since only it will be triggered on the initial register request,
    //  but if Cookies consent is not given it won't be sent, so we re-load the
    //  script to continue prompting the user for consent.
    try {
      yield loadHubspotScript()
    } catch (error) {
      error.message = `Failed to load Hubspot script: ${error.message}`
      captureException(error)
    }

    const { hubspotVisitorIdToken } = user

    if (typeof hubspotVisitorIdToken === 'string') {
      // hubspot visitor ID token present, identify user and load chat widget
      identifyUserInHubspotConversation(user, hubspotVisitorIdToken)
      loadHubspotConversationsWidget().catch((error) => {
        error.message = `Failed to load Hubspot Conversations widget: ${error.message}`
        captureException(error)
      })
    }

    if (status === 201) {
      // new user, add contact in Hubspot
      identifyNewContactInHubspot(auth0Result)
    }

    yield put(fetchUserSuccess(user))
  } catch (error) {
    error.message = `Failed POST /user/login: ${error.message}`
    captureException(error, {
      contexts: {
        error: {
          action,
          object: error,
        },
      },
    })

    yield put(fetchUserFailure(error.message))
  }
}

function* handleFetchUserSuccess(action: FetchUserSuccessAction) {
  const { user } = action.payload

  // check if we are impersonating
  const queryParams = new URLSearchParams(window.location.search)
  const asEmailValue = queryParams.get('as') || queryParams.get('asEmail')
  const asEmail = !asEmailValue
    ? undefined
    : asEmailValue === 'null'
    ? null
    : asEmailValue.replace(/\s/g, '+')
  let asUser: User | null = null

  if (typeof asEmail !== 'undefined') {
    // perform impersonate request & wait for result
    yield put(impersonateUserRequest(asEmail))
    const result: ImpersonateUserSuccessAction | ImpersonateUserFailureAction =
      yield take([IMPERSONATE_USER_SUCCESS, IMPERSONATE_USER_FAILURE])
    if (result.type === IMPERSONATE_USER_SUCCESS) {
      // success
      asUser = result.payload.user
    }
  }

  // add identity to Analytics & external providers
  identifyUserInSegment(user, asUser)

  // get user agent from user navigator
  const {
    navigator: { userAgent },
  } = window

  const parser = new UAParser(userAgent)
  // get data from the user agent
  const { browser, device, os } = parser.getResult()

  yield call(() =>
    track(TrackEventName.USER_LOGGED_IN, {
      browser,
      device,
      os,
      asUserEmail: asUser?.email,
    }),
  )

  if (!asUser) {
    // load User data into Intercom messaging
    identifyUserInIntercom(user)

    // load User data into Hotjar
    identifyHotjarUser(user)
  }

  // check if user has pending actions and store in state
  const userActionsRequired: boolean = isUserActionsRequired(asUser || user)
  yield put(setShowUserActionsRequired(userActionsRequired))

  // fetch user teams data
  yield put(fetchTeamsRequest())

  // user already logged, we can remove the is_landing_user flag, so if another user signs up,
  // it will not be considered as a landing user (if they don't come from there)
  removeIsComingFromLandingPageFromStorage()
}

function* handleFetchUserFailure() {
  // on failed user login, trigger auth0 logout to restart login flow
  yield put(auth0LogoutRequest())
}

function* handleImpersonateUserRequest(action: ImpersonateUserRequestAction) {
  const {
    payload: { email: asEmail },
  } = action

  if (asEmail === null) {
    // reset impersonation
    yield put(impersonateUserSuccess(null))
    BaseService.setAsEmailHeader(undefined)
    yield put(
      addNotificationAction({
        title: i18next.t('user.success.impersonateStop.title'),
        message: i18next.t('user.success.impersonateStop.message', {
          email: 'null',
        }),
        level: 'info',
      }),
    )
    return
  }

  try {
    BaseService.setAsEmailHeader(asEmail)

    const accessToken: string = yield select(getAuth0AccessToken)
    const userService = new UserService(accessToken)

    const { data: asUser }: AxiosResponse<User> = yield call(() =>
      userService.postUserLogin(),
    )

    // success
    yield put(impersonateUserSuccess(asUser))
    yield put(
      addNotificationAction({
        title: i18next.t('user.success.impersonate.title'),
        message: i18next.t('user.success.impersonate.message', {
          email: asEmail,
        }),
        level: 'succeed',
      }),
    )
  } catch (error) {
    // clear asEmailHeader
    BaseService.setAsEmailHeader(undefined)

    // parse & display error response
    let errorMessage: string = error.message
    let errorStatus: number | undefined = undefined
    if (axios.isAxiosError(error) && error.response) {
      ;({
        data: { message: errorMessage },
        status: errorStatus,
      } = error.response as {
        data: { message: string }
        status: number
      })
    }

    // failure
    yield put(impersonateUserFailure(errorMessage))
    const notificationOptions: NotificationOptions = {
      title: i18next.t('user.error.impersonate.title'),
      message: i18next.t('user.error.impersonate.message', {
        email: asEmail,
        message: errorMessage,
        status: errorStatus,
      }),
      level: 'error',
    }
    yield put(addNotificationAction(notificationOptions))
  }
}

export function* fetchCurrentProfileData() {
  // profile-related data requests
  // to have some initial data already loaded early in the state
  // it will be refreshed later on as navigation to other pages/components occurs
  yield put(fetchApplicationsRequest())
  yield put(fetchActivityRequest())
  yield put(fetchCustomizationRequest())
  yield put(fetchTotalItemsStatsRequest())

  // fetch initial overview chart item stats data
  yield put(changeItemStatsFilters(INITIAL_ITEM_STATS_FILTERS))
}

function* handleUpdateUserTermsAcceptedRequest() {
  try {
    const accessToken: string = yield select(getAuth0AccessToken)
    const userService = new UserService(accessToken)

    const { data: user }: AxiosResponse<User> = yield call(() =>
      userService.updateUser({ termsAccepted: true }),
    )

    yield put(updateUserTermsAcceptedSuccess(user))
  } catch (error) {
    yield put(updateUserTermsAcceptedFailure(error.message))
  }
}

function* handleUpdateUserTermsAcceptedSuccess() {
  // refresh applications data
  yield put(fetchApplicationsRequest())
}

function* handleUserResendVerificationMailRequest() {
  try {
    const accessToken: string = yield select(getAuth0AccessToken)
    const userService = new UserService(accessToken)

    yield call(() => userService.resendVerificationEmail())
    yield put(resendVerificationEmailSuccess())
  } catch (error) {
    const errorMessage = i18next.t(
      'stepsToSuccess.item.validateEmail.error.resend',
    )
    yield put(resendVerificationEmailFailure(errorMessage))
  }
}

function* handleUpdateUserRequest(action: UpdateUserRequestAction) {
  const { fields } = action.payload
  const { platforms } = fields

  const platformsSortedAscending = platforms ? [...platforms].sort() : undefined

  const { companyRole, uiState } = fields

  if (uiState?.theme !== undefined) {
    const user: User = yield select(getUser)

    // only in the case of theme change, trigger success
    // to inmediatly update the theme in the UI
    // and handle the save to the backend later

    yield put(
      updateUserSuccess({
        ...user,
        uiState: { ...user.uiState, theme: uiState.theme },
      }),
    )
  }
  try {
    const accessToken: string = yield select(getAuth0AccessToken)
    const userService = new UserService(accessToken)

    const { data: user }: AxiosResponse<User> = yield call(() =>
      userService.updateUser({
        ...fields,
        platforms: platformsSortedAscending,
      }),
    )

    yield put(updateUserSuccess(user))

    if (companyRole !== undefined) {
      // is updating user.companyRole -> track it & redirect to root
      identify(undefined, { companyRole })
      yield put(push(locations.root()))
      return
    }

    if (uiState !== undefined) {
      // is updating user.uiState -> don't show notifications
      return
    }

    yield put(
      addNotificationAction({
        title: i18next.t('user.success.update.title'),
        message: i18next.t('user.success.update.message'),
        duration: 4000,
        level: 'succeed',
      }),
    )
  } catch (error) {
    error.message = `Could not update user: ${error.message}`
    const notificationOptions: NotificationOptions = {
      title: i18next.t('user.error.update.title'),
      message: i18next.t('user.error.update.message'),
      duration: 4000,
      level: 'error',
    }
    yield put(addNotificationAction(notificationOptions))
    captureException(error, {
      contexts: {
        error: {
          action,
          object: error,
          notificationOptions,
        },
      },
    })

    yield put(updateUserFailure(error.message))
  }
}

function* handleDeleteUserRequest(action: DeleteUserRequestAction) {
  try {
    const accessToken: string = yield select(getAuth0AccessToken)
    const userService = new UserService(accessToken)

    yield call(() => userService.deleteUser())

    yield put(deleteUserSuccess())
  } catch (error) {
    error.message = `Could not delete user: ${error.message}`
    const notificationOptions: NotificationOptions = {
      title: i18next.t('user.error.delete.title'),
      message: i18next.t('user.error.delete.message'),
      duration: 4000,
      level: 'error',
    }
    yield put(addNotificationAction(notificationOptions))
    captureException(error, {
      contexts: {
        error: {
          action,
          object: error,
          notificationOptions,
        },
      },
    })

    yield put(deleteUserFailure(error.message))
  }
}

function* handleDeleteUserSuccess() {
  yield put(push(locations.logout()))
}

export function* userSaga() {
  yield all([
    takeEvery(FETCH_USER_REQUEST, handleFetchUserRequest),
    takeEvery(FETCH_USER_SUCCESS, handleFetchUserSuccess),
    takeEvery(FETCH_USER_FAILURE, handleFetchUserFailure),
    takeEvery(IMPERSONATE_USER_REQUEST, handleImpersonateUserRequest),
    takeEvery(
      UPDATE_USER_TERMS_ACCEPTED_REQUEST,
      handleUpdateUserTermsAcceptedRequest,
    ),
    takeEvery(
      UPDATE_USER_TERMS_ACCEPTED_SUCCESS,
      handleUpdateUserTermsAcceptedSuccess,
    ),
    takeLatest(
      RESEND_VERIFICATION_EMAIL_REQUEST,
      handleUserResendVerificationMailRequest,
    ),
    takeEvery(DELETE_USER_REQUEST, handleDeleteUserRequest),
    takeEvery(DELETE_USER_SUCCESS, handleDeleteUserSuccess),
    takeEvery(UPDATE_USER_REQUEST, handleUpdateUserRequest),
  ])
}
