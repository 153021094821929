import { Auth0Result } from '../modules/auth0/types'
import { isComingFromLandingPage } from '../modules/user/storage'

type HubspotConversation = {
  // id of the conversation
  conversationId: string
}

type HubspotConversationEventPayload = {
  // Emitted when a new conversation has been successfully started
  conversationStarted: {
    // details about the conversation that was started
    conversation: HubspotConversation
  }
  // Emitted when a new conversation has been successfully closed.
  // Note: This event fires when the conversation is marked as closed
  // from the conversations inbox, and is unrelated to the user
  // minimizing or closing the chat widget.
  conversationClosed: {
    // Details about the conversation that was closed
    conversation: HubspotConversation
  }
  // Emitted when the number of conversations in the widget
  // with any unread messages changes
  unreadConversationCountChanged: {
    // The new total of conversations in the widget with any unread messages
    unreadCount: number
  }
  // Emitted when the visitor is associated with a contact in the CRM
  contactAssociated: {
    // A confirmation message that the visitor has been associated with a contact
    message: string
  }
  // Emitted as soon as the user interacts with the widget
  userInteractedWithWidget: {
    // A confirmation message that the user has interacted with the widget
    message: string
  }
  // Emitted when the widget iframe has loaded
  widgetLoaded: {
    // Confirmation message that the widget iframe has loaded
    message: string
  }
  // Emitted when the widget is closed
  widgetClosed: {
    // Confirmation message that the widget is closed
    message: string
  }
  // Emitted when the user clicks the quick reply button in a bot conversation
  quickReplyButtonClick: {
    // name/id of the quick reply
    name: string
    // wether it's a multi-select options reply
    multiSelect: boolean
    // text displayed to the user
    value: string[]
  }
}

type HubSpotConversations = {
  widget: {
    /**
     * Load the widget for the first time on the page. If the widget is currently loaded, subsequent calls to this method are no-ops.
     * This method is only necessary if you set the loadImmediately flag to false. Otherwise, the widget will load itself automatically.
     *
     * @param {{ widgetOpen?: boolean }} options.widgetOpen - Whether the widget should load in an open state (default: false)
     */
    load: (options?: { widgetOpen?: boolean }) => void
    /**
     * Refresh and re-render the widget's information, given the current page URL.
     * If you house the chat widget on a single-page application, this method
     * can be useful for refreshing the widget on route changes.
     * This allows you to specify different chatflows on different page routes.
     * If widget.refresh() is called on a route where there is no chatflow,
     * and the user isn't engaged in a conversation, the widget will be removed.
     *
     * @param {{ openToNewThread?: boolean }} - Whether to force a new thread to be created (default: false)
     */
    refresh: (options?: { openToNewThread?: boolean }) => void
    /**
     * Open the widget. If the widget is already open or isn't currently loaded, this is a no-op.
     */
    open: () => void
    /**
     * Open the widget. If the widget is already open or isn't currently loaded, this is a no-op.
     */
    close: () => void
    /**
     * Remove the widget from the page. If the widget is not present on the page, this is a no-op.
     * To display the widget again, a full page refresh will have to occur,
     * or one can invoke widget.load().
     */
    remove: () => void
    /**
     * Returns an object containing properties related to widget status.
     * @returns {loaded: boolean} status - Whether the widget iframe has loaded or not.
     */
    status: () => { loaded: boolean }
  }
  on: <T extends keyof HubspotConversationEventPayload>(
    eventName: T,
    callback: (payload: HubspotConversationEventPayload[T]) => void,
  ) => void
  off: <T extends keyof HubspotConversationEventPayload>(
    eventName: T,
    callback: (payload: HubspotConversationEventPayload[T]) => void,
  ) => void
  /**
   * The chat widget creates several cookies to preserve its state across site visits and page refreshes.
   * These cookies are scoped to the domain of the page hosting the widget, and are used to support the following features:
   * - Referencing historical conversations
   * - Persisting the open state of the chat widget across page loads
   * - Persisting the open state of the welcome message across page loads
   * - The clear method can be used to delete these cookies, returning the widget to its default state on subsequent loads.
   *
   * The following cookies are cleared with this method:
   * - 'messagesUtk'
   * - 'hs-messages-is-open'
   * - 'hs-messages-hide-welcome-message'
   *
   * @see more info: https://knowledge.hubspot.com/reports/what-cookies-does-hubspot-set-in-a-visitor-s-browser
   *
   * @param {{resetWidget: boolean}} options - pass {resetWidget:true} to clear all chat related cookies, remove the widget from the page, and create a new instance of the chat widget.
   */
  clear: (options?: { resetWidget: boolean }) => void
}

declare global {
  interface Window {
    _hsq?: unknown[]
    HubSpotConversations?: HubSpotConversations
    // callbacks called by Hubspot script when HS Conversationsis loaded
    hsConversationsOnReady?: (() => void)[]
    hsConversationsSettings: {
      // Whether the widget should implicitly load or wait until the
      // widget.load() method is called. Default: true
      loadImmediately?: boolean
      // Where the widget should be embedded in the page.
      // If a selector (e.g. #some-id) is provided, the widget
      // will be embedded inline within that DOM node.
      // It will always be open until it is removed via widget.remove()
      inlineEmbedSelector?: string
      // Control behavior of the cookie banner for all chatflows on the page:
      //  false - use the setting from chatflows (default)
      //  true - enable cookie banners when the widget is loaded
      //  'ON_WIDGET_LOAD' - same as true: enable cookie banners when the widget is loaded
      //  'ON_EXIT_INTENT' - enable cookie banners when the user exhibits an exit intent
      // Note that this field used to be a Boolean. It can now accommodate both
      // the original Boolean values and the updated enum values.
      enableWidgetCookieBanner?: boolean | 'ON_WIDGET_LOAD' | 'ON_EXIT_INTENT'
      // Whether the upload attachment button should be hidden in the chat
      // widget. Default: false
      disableAttachment?: boolean
      // Determines whether to automatically prevent focusing on the widget's
      // input field after an inline embedded widget is initially loaded
      // on a page. Default: false
      disableInitialInputFocus?: boolean
      // hubspot Visitor Identification API token, to identify user in chatflow
      identificationToken?: string
      // email used to identify the user in the chatflow (must also provide identificationToken)
      identificationEmail?: string
    }
  }
}

const hubspotCookieBannerCss = `
  div#hs-eu-cookie-confirmation.hs-cookie-notification-position-bottom {
    width: calc(100vw - 2 * var(--container-side-margin)) !important;
    font-family: 'Work Sans' !important;
    left: 50vw;
    transform: translateX(calc(-50vw + var(--container-side-margin)));
    margin-bottom: 32px;
    border: 1px solid #B1B1B1 !important;
    box-sizing: border-box !important;
    box-shadow: 4px 4px 10px rgba(0, 0, 0, 0.1) !important;
    border-radius: 10px !important;
    color: var(--grey-50) !important;
  }

  div#hs-eu-cookie-confirmation div#hs-eu-cookie-confirmation-inner {
    display: flex;
    border-radius: 10px !important;
    margin: 0;
    max-width: unset;
    padding-left: 32px;
    padding-right: 24px;
    padding-top: 8px;
    padding-bottom: 16px;
    margin: 0 !important;
  }

  div#hs-eu-cookie-confirmation,
  div#hs-eu-cookie-confirmation-inner {
    background-color: var(--grey-800) !important;
  }

  div#hs-eu-cookie-confirmation div#hs-eu-cookie-confirmation-inner div#hs-en-cookie-confirmation-buttons-area {
    margin-left: 8px !important;
    margin-right: 8px !important;
    margin-top: 8px !important;
  }

  div#hs-eu-cookie-confirmation div#hs-eu-cookie-confirmation-inner a#hs-eu-confirmation-button {
    /* Button text */
    width: 120px;
    height: 37px;
    margin: 0 !important;

    font-weight: 500;
    font-size: 14px;
    line-height: 16px;

    display: flex;
    justify-content: center;
    border-radius: 10px;
  }

  div#hs-eu-cookie-confirmation div#hs-eu-cookie-confirmation-inner div#hs-eu-policy-wording {
    display: flex;
    flex: 1;
    line-height: 14.08px;

    margin-top: 8px !important;
    margin-right: 8px !important;
    margin-bottom: 0 !important;

    flex-direction: column;
    align-items: flex-start;
  }

  div#hs-eu-cookie-confirmation div#hs-eu-cookie-confirmation-inner p {
    color: var(--grey-50);
    margin: 0;
  }

  div#hs-eu-cookie-confirmation div#hs-eu-cookie-confirmation-inner p:not(:first-child) {
    margin-top: 8px;
  }

  div#hs-eu-cookie-confirmation div#hs-eu-cookie-confirmation-inner a#hs-eu-confirmation-button {
    transition: 0.2s ease;
    -webkit-transition: 0.2s ease;
    -moz-transition: 0.2s ease;
    -o-transition: 0.2s ease;
    -ms-transition: 0.2s ease;
  }

  div#hs-eu-cookie-confirmation div#hs-eu-cookie-confirmation-inner a#hs-eu-confirmation-button:hover {
    background-color: var(--primary-hover-button) !important;
    border-color: var(--primary-hover-button) !important;
    transition: 0.2s ease;
    -webkit-transition: 0.2s ease;
    -moz-transition: 0.2s ease;
    -o-transition: 0.2s ease;
    -ms-transition: 0.2s ease;
  }


  @media only screen and (max-width: 767px) {
    /* Wrap text and button to 2 rows */
    div#hs-eu-cookie-confirmation div#hs-eu-cookie-confirmation-inner {
      flex-wrap: wrap;
    }

    div#hs-eu-cookie-confirmation div#hs-eu-cookie-confirmation-inner div#hs-eu-policy-wording {
      flex-basis: 100%;
    }

    div#hs-eu-cookie-confirmation div#hs-eu-cookie-confirmation-inner div#hs-en-cookie-confirmation-buttons-area {
      justify-content: flex-end;
      flex: 1;
    }
  }
`

/**
 * Add custom Hubspot Cookies banner styles, which override the default Hubspot styles.
 * We need to do it like this because Hubspot load their own styles inside the
 * document body, even with some !imporant tags, so we have to insert this
 * after it.
 */
function addHubspotStylesToDom(): void {
  const styleSheet = document.createElement('style')
  styleSheet.innerHTML = hubspotCookieBannerCss
  document.body.appendChild(styleSheet)
}

export async function loadHubspotScript(): Promise<void> {
  const existingHubspotScript = document.querySelector(
    'script#hs-script-loader',
  )
  if (existingHubspotScript) {
    // Hubspot already loaded, ignore
    return
  }

  // load Hubspot cookie banner CSS in DOM
  addHubspotStylesToDom()

  // Hubspot Conversations initial config
  // don't immediately load HS Conversations widget,
  // we load it manually after identifying the user
  window.hsConversationsSettings = {
    loadImmediately: false,
  }

  await new Promise<void>(function (resolve, reject) {
    // Create script element and set attributes
    const scriptTag = document.createElement('script')
    scriptTag.src = '//js.hs-scripts.com/7886217.js'
    scriptTag.id = 'hs-script-loader'
    scriptTag.async = true

    // Resolve the promise once the script is loaded
    scriptTag.addEventListener('load', () => {
      resolve()
    })

    // Catch any errors while loading the script
    scriptTag.addEventListener('error', (error) => {
      reject(new Error(`Hubspot script failed to load. ${error}`))
    })

    document.body.appendChild(scriptTag)
  })
}

/**
 * Helper to identify a registered user as a Hubspot Contact
 * @see https://developers.hubspot.com/docs/api/events/tracking-code
 *
 * @param auth0Result
 */
export function identifyNewContactInHubspot(auth0Result: Auth0Result): void {
  const {
    idToken: { email, family_name, name, given_name },
  } = auth0Result

  const _hsq = (window._hsq = window._hsq || [])

  const contact: Record<string, unknown> = {
    email,
    // custom properties to help identify this contact origin
    sign_up_from_landing: isComingFromLandingPage(),
    hs_analytics_source_data_2: 'Dashboard Sign up',
  }

  const firstName = given_name || name
  if (firstName !== email) {
    // google-auth has 'given_name' as first name, github has 'name' as full name
    // auth0 uses 'email' as name too
    contact.firstname = firstName
  }

  if (family_name) {
    // google-auth is the only that provides 'family_name' as last name
    contact.lastname = family_name
  }

  _hsq.push(['identify', contact])
}

/**
 * Helper to await for the HubSpotConversations instance to be available in the window object
 * @returns {Promise<void>}
 */
async function waitForHubspotConversationsInstance(): Promise<HubSpotConversations> {
  const { HubSpotConversations } = window
  if (HubSpotConversations) {
    return HubSpotConversations
  }

  // not yet loaded, wait for it
  return new Promise((resolve, reject) => {
    window.hsConversationsOnReady = [
      ...(window.hsConversationsOnReady || []),
      () =>
        window.HubSpotConversations
          ? resolve(window.HubSpotConversations)
          : reject(
              new Error(
                'hsConversationsOnReady event fired, but HubSpotConversations instance not present',
              ),
            ),
    ]
  })
}

export async function loadHubspotConversationsWidget(): Promise<void> {
  const hubSpotConversations = await waitForHubspotConversationsInstance()
  hubSpotConversations.widget.load()
}

export function identifyUserInHubspotConversation(
  { email }: { email: string },
  hubspotVisitorIdToken: string,
): void {
  window.hsConversationsSettings = {
    identificationEmail: email,
    identificationToken: hubspotVisitorIdToken,
  }
}

export async function clearHubspotConversationSession(): Promise<void> {
  const hubSpotConversations = await waitForHubspotConversationsInstance()
  hubSpotConversations.clear({ resetWidget: true })
}

/**
 * Query param used to match and select the Hubspot Chatflow
 */
const HUBSPOT_CHATFLOW_QUERY_PARAM = 'hs_chat'

function updateUrlHubspotChatQueryParam(chatflowKey: string | null) {
  const url = new URL(window.location.href)
  const { searchParams } = url

  if (chatflowKey) {
    searchParams.set(HUBSPOT_CHATFLOW_QUERY_PARAM, chatflowKey)
  } else {
    // no chatflow key, remove to reset to the base flow
    searchParams.delete(HUBSPOT_CHATFLOW_QUERY_PARAM)
  }

  // update URL with the specified chatflow query param
  window.history.replaceState(null, '', url.toString())
}

export async function openHubspotChat(chatflowKey?: string): Promise<void> {
  const HubSpotConversations = await waitForHubspotConversationsInstance()

  updateUrlHubspotChatQueryParam(chatflowKey || null)

  // refresh widget, to trigger the specified chatflow
  HubSpotConversations.widget.refresh({ openToNewThread: true })

  // set timer of 1000ms before calling widget.open(), to prevent flickering
  setTimeout(() => {
    HubSpotConversations.widget.open()
    // clear query param, to reset to the base flow the next time
    updateUrlHubspotChatQueryParam(null)
  }, 1000)

  // listen to the 'widgetClosed' event, to clear the chatflow for the next time
  HubSpotConversations.on('widgetClosed', function clearWidgetChatflow() {
    // clear query param, to reset to the base flow
    updateUrlHubspotChatQueryParam(null)

    // call refresh to clear the chatflow
    HubSpotConversations.widget.refresh()

    // remove the 'widgetClosed' handler
    HubSpotConversations.off('widgetClosed', clearWidgetChatflow)
  })
}
