import React, { useCallback } from 'react'
import { useTranslation } from 'react-i18next'

import { Tag } from '@pluggyai/ui'

import { TrackEventName } from '../../../modules/analytics/events'
import { track } from '../../../modules/analytics/utils'
import {
  Props,
  TimeFilterValue,
  timeValues,
} from './ConnectorsStatusTimeFilter.types'
import { mapTimeValueToI18nKey } from './utils'

import './ConnectorsStatusTimeFilter.css'

const ConnectorsStatusTimeFilter = ({
  onTimeFilterChange,
  value,
  isDisabled,
}: Props) => {
  const { t } = useTranslation()

  const handleTimeFilterClick = useCallback(
    (timeValue: TimeFilterValue, i18nText: string, text: string) => {
      track(TrackEventName.TAG_CLICKED, {
        i18nText,
        text,
        value,
      })

      onTimeFilterChange(timeValue)
    },

    [onTimeFilterChange, value],
  )
  return (
    <div className={'ConnectorsStatusTimeFilter'}>
      {timeValues.map((timeValue, i) => {
        const tagI18nKey = mapTimeValueToI18nKey(timeValue)
        const tagText = t(tagI18nKey)

        return (
          <Tag
            className={'time-filter-tag'}
            key={`${timeValue}_${i}`}
            onClick={() =>
              handleTimeFilterClick(timeValue, tagI18nKey, tagText)
            }
            text={tagText}
            isSelected={value === timeValue}
            selectable
            disabled={isDisabled}
          />
        )
      })}
    </div>
  )
}

export default React.memo(ConnectorsStatusTimeFilter)
