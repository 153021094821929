import { connect } from 'react-redux'

import { fetchItemRequest } from '../../modules/item/actions'
import { getItem } from '../../modules/item/selectors'
import { RootState } from '../../modules/reducer'
import ItemExecutionsPage from './ItemExecutionsPage'
import {
  MapDispatch,
  MapDispatchProps,
  MapStateProps,
} from './ItemExecutionsPage.types'

const mapState = (state: RootState): MapStateProps => ({
  item: getItem(state),
})

const mapDispatch = (dispatch: MapDispatch): MapDispatchProps => ({
  onFetchItem: (itemId: string) => dispatch(fetchItemRequest(itemId)),
})

export default connect(mapState, mapDispatch)(ItemExecutionsPage)
