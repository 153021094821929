import { memo } from 'react'

import Icon from '../Icon'

import './TrendArrowUpIcon.css'

type Props = {
  onClick?: () => void
  className?: string
}

const TrendArrowUpIcon = ({ className }: Props) => (
  <Icon
    className={`TrendArrowUpIcon ${className || ''}`}
    width="15"
    height="11"
    viewBox="0 0 15 11"
    fill="none"
  >
    <path
      d="M5.56072 5.85769C4.76773 6.67949 4.00923 7.46676 3.24999 8.25442C2.6001 8.92696 1.95021 9.59848 1.30032 10.269C1.08773 10.4874 0.831269 10.5549 0.542193 10.4668C0.253116 10.3788 0.0810205 10.1665 0.0172812 9.86659C-0.0442085 9.57481 0.0633984 9.33541 0.260615 9.12937C1.16196 8.19712 2.06256 7.26461 2.96241 6.33184C3.62955 5.6417 4.29719 4.95195 4.96532 4.26259C5.33164 3.88622 5.75569 3.88428 6.1205 4.25677C6.62742 4.77411 7.13096 5.29534 7.63112 5.82044C7.68774 5.87942 7.72336 5.95974 7.76348 6.02182L11.9763 1.65828C11.9163 1.6544 11.8585 1.6478 11.8004 1.64741C11.313 1.64741 10.8256 1.65246 10.3382 1.64431C10.0161 1.63927 9.77351 1.47747 9.64116 1.17443C9.51481 0.884978 9.5568 0.60406 9.75364 0.357287C9.82425 0.265826 9.91422 0.192372 10.0166 0.14259C10.119 0.0928074 10.2311 0.0680275 10.3442 0.0701613C11.511 0.0674453 12.6808 0.0651172 13.8491 0.0713254C14.2904 0.0736534 14.6169 0.420533 14.6184 0.876442C14.6222 2.07927 14.6222 3.28209 14.6184 4.48492C14.6184 4.94199 14.2724 5.29973 13.8532 5.29585C13.434 5.29197 13.1108 4.95053 13.1033 4.49229C13.0962 4.00107 13.1015 3.50947 13.1015 3.01786V2.81415L13.0332 2.76837C12.9908 2.83751 12.9434 2.90327 12.8915 2.96509C11.3997 4.51169 9.90687 6.05713 8.41287 7.6014C8.02406 8.00299 7.60375 8.00376 7.21644 7.60489C6.70778 7.08082 6.19999 6.55507 5.69308 6.02764C5.64958 5.98224 5.61471 5.92637 5.56072 5.85769Z"
      fill="#5CB865"
    />
  </Icon>
)

export default memo(TrendArrowUpIcon)
