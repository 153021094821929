import i18n from 'i18next'

import {
  ItemFilterOption,
  itemsStatsTypesGroupsNames,
  ItemsTypeValue,
} from './ItemsTypeFilters.types'

function itemsTypeFilterToString(itemsTypeValue: ItemsTypeValue): string {
  return i18n.t(`items-stats.legend.${itemsTypeValue}`)
}

export function mapItemsTypesToOptions(
  itemsTypesFiltersList: typeof itemsStatsTypesGroupsNames[number][],
): ItemFilterOption[] {
  return itemsTypesFiltersList.map((itemTypeFilter) => ({
    name: itemsTypeFilterToString(itemTypeFilter),
    id: itemTypeFilter,
  }))
}
