import { Connector, Item } from 'pluggy-js'
import type { PageResponse, Webhook } from 'pluggy-sdk'

import { Application } from '../application/types'

export type WebhookEventsFilters = {
  event: Webhook['event']
  createdAtStartDate: Date
  createdAtEndDate: Date | null
  search: Application['clientId'] | Item['id'] | string
  page: number
}

export type WebhookEventsRequest = {
  event?: Webhook['event']
  createdAtStartDate?: string
  createdAtEndDate?: string
  search?: Application['clientId'] | Item['id'] | string
  page: number
}

export type WebhookEventsFiltersChangeRequest = Partial<WebhookEventsFilters>

export type WebhookEvent = {
  createdAt: Date
  event: Webhook['event']
  url: string
  subjectId: Item['id'] | Connector['id']
  completedAt: Date | null
  retries: number
}

export type WebhookEventResponse = {
  createdAt: string
  event: Webhook['event']
  url: string
  subjectId: Item['id'] | Connector['id']
  completedAt: string | null
  retries: number
}

export type WebhookEventsResponse = PageResponse<WebhookEventResponse>

export const WEBHOOK_EVENT_COLUMNS: (keyof WebhookEvent)[] = [
  'createdAt',
  'event',
  'url',
  'subjectId',
  'completedAt',
  'retries',
]
