import { connect } from 'react-redux'

import { RootState } from '../../modules/reducer'
import { getError as getStatsError } from '../../modules/stats/selectors'
import OverviewPage from './OverviewPage'
import {
  MapDispatch,
  MapDispatchProps,
  MapStateProps,
} from './OverviewPage.types'

const mapState = (state: RootState): MapStateProps => ({
  statsError: getStatsError(state),
})

const mapDispatch = (_dispatch: MapDispatch): MapDispatchProps => ({
  // nothing here, yet
})

export default connect(mapState, mapDispatch)(OverviewPage)
