import React from 'react'

import Icon from '../Icon'

import './EditIcon.css'

type Props = {
  onClick?: () => void
  className?: string
}

const EditIcon = ({ onClick, className }: Props) => (
  <Icon
    className={`EditIcon ${className || ''}`}
    onClick={onClick}
    width="20"
    height="20"
    viewBox="0 0 20 20"
    fill="none"
  >
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M12.9517 3.50944C13.6309 2.83018 14.7321 2.83019 15.4114 3.50944L16.4906 4.58862C17.1698 5.26787 17.1698 6.36916 16.4906 7.04842L7.93335 15.6056C7.72433 15.8146 7.4655 15.9669 7.18128 16.0481L3.95024 16.9712C3.68994 17.0457 3.40978 16.973 3.21835 16.7816C3.02693 16.5902 2.95433 16.3101 3.0287 16.0498L3.95186 12.8187C4.03306 12.5345 4.18536 12.2756 4.39438 12.0666L12.9517 3.50944ZM14.3572 4.56364C14.2601 4.46661 14.1028 4.46661 14.0058 4.56364L12.7509 5.81851L14.1815 7.24909L15.4364 5.99422C15.5334 5.89718 15.5334 5.73986 15.4364 5.64282L14.3572 4.56364ZM13.1273 8.30329L11.6967 6.87272L5.44858 13.1208C5.41872 13.1507 5.39696 13.1876 5.38535 13.2283L4.83081 15.1692L6.77171 14.6146C6.81231 14.6031 6.84928 14.5813 6.87915 14.5514L13.1273 8.30329Z"
      fill="#121212"
    />
  </Icon>
)

export default React.memo(EditIcon)
