import React, { useCallback } from 'react'
import { useTranslation } from 'react-i18next'

import {
  PLUGGY_API_REFERENCE_DOCS_URL,
  PLUGGY_QUICKSTART_URL,
} from '../../lib/constants/urls'
import { TrackEventName } from '../../modules/analytics/events'
import { track } from '../../modules/analytics/utils'
import { ArrowLink } from '../ArrowLink'
import { CloseIcon } from '../Icon'
import { Props } from './WelcomeWidget.types'

import './WelcomeWidget.css'

const WelcomeWidget = ({
  userHasClosedWidget,
  userCreatedAfterFeatureRelease,
  onClose: onSaveUserHasClosedWidget,
}: Props) => {
  const { t } = useTranslation()

  const titleI18nKey = 'welcome-widget.title'
  const titleText = t(titleI18nKey)

  const quickStartLinkLabelI18nKey = 'welcome-widget.links.quickstart.label'
  const quickStartLinkLabelText = t(quickStartLinkLabelI18nKey)

  const quickStartLinkDescriptionI18nKey =
    'welcome-widget.links.quickstart.description'
  const quickStartLinkDescriptionText = t(quickStartLinkDescriptionI18nKey)

  const apiReferenceLinkLabelI18nKey =
    'welcome-widget.links.api-reference.label'
  const apiReferenceLinkLabelText = t(apiReferenceLinkLabelI18nKey)

  const apiReferenceLinkDescriptionI18nKey =
    'welcome-widget.links.api-reference.description'
  const apiReferenceLinkDescriptionText = t(apiReferenceLinkDescriptionI18nKey)

  const handleQuickstartLinkClick = useCallback(() => {
    track(TrackEventName.LINK_CLICKED, {
      i18nKey: quickStartLinkLabelI18nKey,
      text: quickStartLinkLabelText,
      linkTo: PLUGGY_QUICKSTART_URL,
      location: 'WelcomeWidget',
    })
  }, [quickStartLinkLabelText])

  const handleApiReferenceLinkClick = useCallback(() => {
    track(TrackEventName.LINK_CLICKED, {
      i18nKey: apiReferenceLinkLabelText,
      text: apiReferenceLinkLabelText,
      linkTo: PLUGGY_API_REFERENCE_DOCS_URL,
      location: 'WelcomeWidget',
    })
  }, [apiReferenceLinkLabelText])

  const handleCloseClick = useCallback(() => {
    track(TrackEventName.ICON_CLICKED, {
      location: 'WelcomeWidget',
      icon: 'CloseIcon',
    })

    onSaveUserHasClosedWidget()
  }, [onSaveUserHasClosedWidget])

  if (userHasClosedWidget || !userCreatedAfterFeatureRelease) {
    return null
  }

  return (
    <div className={'WelcomeWidget'}>
      <div className={'section welcome'}>
        <h4 className="title">{titleText}</h4>
        <h4 className="emoji">👋</h4>
      </div>
      <div className="links">
        <div className={'section'}>
          <ArrowLink
            href={PLUGGY_QUICKSTART_URL}
            label={quickStartLinkLabelText}
            onClick={handleQuickstartLinkClick}
          />
          <p>{quickStartLinkDescriptionText}</p>
        </div>
        <div className={'section'}>
          <ArrowLink
            href={PLUGGY_API_REFERENCE_DOCS_URL}
            label={apiReferenceLinkLabelText}
            onClick={handleApiReferenceLinkClick}
          />
          <p>{apiReferenceLinkDescriptionText}</p>
        </div>
      </div>
      <div className={'steps-dismiss-action'}>
        <CloseIcon onClick={handleCloseClick} />
      </div>
    </div>
  )
}

export default React.memo(WelcomeWidget)
