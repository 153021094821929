import i18n from 'i18next'

import { PLUGGY_DEMO_ORIGIN_URL } from '../../lib/constants/urls'
import { ApplicationFields } from '../../modules/application/types'
import { isValidDomain } from '../../utils/validation'
import { isDefaultPluggyDemoOrigin } from './AllowedUrlsInput/utils'

const validations: Record<
  keyof ApplicationFields,
  (fieldName: string, value: string) => string | undefined
> = {
  allowedOrigins: (fieldName: string, value: string): string | undefined => {
    const originsArray = value.split(',')

    const onlyEmptyOrigin =
      originsArray.length === 1 && originsArray[0].length === 0

    const onlyPluggyDemoOriginAndEmptyOrigin =
      originsArray.length === 2 &&
      originsArray[0] === PLUGGY_DEMO_ORIGIN_URL &&
      originsArray[1].length === 0

    if (onlyEmptyOrigin || onlyPluggyDemoOriginAndEmptyOrigin) {
      // there's only one empty value, which is the placeholder
      // or there's both the placeholder and Pluggy Demo Origin -> don't throw errors.
      return undefined
    }

    const errors = originsArray.map((origin, i) => {
      if (origin.length === 0) {
        return i18n.t('application.form.error.empty', {
          fieldName,
        })
      }

      if (!isValidDomain(origin)) {
        return i18n.t('application.form.error.domain', {
          fieldName,
        })
      }

      // check if value was already included in the list
      if (
        originsArray
          .map(
            //don't count default Pluggy Demo origin set by the backend
            (allowedOrigin, i_) =>
              isDefaultPluggyDemoOrigin(allowedOrigin, i_)
                ? undefined
                : allowedOrigin,
          )
          .slice(0, i)
          .includes(origin)
      ) {
        return i18n.t('application.form.error.list-repeated', {
          fieldName,
        })
      }

      return undefined
    })

    return errors.join(',')
  },
  shortDescription: (fieldName: string, value: string): string | undefined => {
    if (value.length === 0) {
      return i18n.t('application.form.error.empty', {
        fieldName,
      })
    }
    const maxLength = 255
    if (value.length > maxLength) {
      return i18n.t('application.form.error.max-length', {
        fieldName,
        length: maxLength,
      })
    }
    return undefined
  },
  name: (fieldName: string, value: string): string | undefined => {
    if (value.length === 0) {
      return i18n.t('application.form.error.empty', {
        fieldName,
      })
    }
    const maxLength = 100
    if (value.length > maxLength) {
      return i18n.t('application.form.error.max-length', {
        fieldName,
        length: maxLength,
      })
    }
    return undefined
  },
}

export function validateField(
  fieldName: keyof ApplicationFields,
  value: string,
): string | undefined {
  const fieldLabel = i18n.t(`application.form.field.${fieldName}.label`)
  return validations[fieldName](fieldLabel, value)
}
