import { User } from '../user/types'

export const TEAM_ROLES = ['OWNER', 'ADMIN', 'MEMBER'] as const
export type TeamRole = typeof TEAM_ROLES[number]

export type TeamMember = User & {
  teamUser: {
    role: TeamRole
  }
}

/**
 * Helper type-guard function to decide if a team 'member' is
 * actually a TeamMember, or a TeamInvitation
 * @param member
 */
export function isTeamMember(
  member: TeamMember | TeamInvitation,
): member is TeamMember {
  return typeof (member as TeamMember).teamUser !== 'undefined'
}

export type TeamInvitation = Pick<
  User,
  'email' | 'id' | 'updatedAt' | 'createdAt'
> & {
  role: TeamRole
}

/**
 * Note: this type should be updated as new products are
 * added in pluggy-connectors -> @pluggyai/commons
 */
const PRODUCT_TYPES = [
  'ACCOUNTS',
  'CREDIT_CARDS',
  'TRANSACTIONS',
  'PAYMENT_DATA',
  'INVESTMENTS',
  'INVESTMENTS_TRANSACTIONS',
  'IDENTITY',
  'BROKERAGE_NOTE',
  'OPPORTUNITIES',
] as const
type ProductType = typeof PRODUCT_TYPES[number]

/**
 * Note: this type should be updated as new custom features are added in pluggy-api
 */
const FEATURE_TYPES = [
  'CATEGORIZATION',
  'CREATE_ITEMS_API',
  'ALL_CONNECTORS',
  'CUSTOM_COMPANY_DEVICE_NAME',
] as const
export type FeatureType = typeof FEATURE_TYPES[number]

const SUBSCRIPTION_PLANS = [
  // the basic subscription, with limited features
  'FREE',
  // the FREE subscription, plus some FULL paid features, but not all of them
  'FREE_PLUS',
  // Has all features enabled, but with an expiration date, then is set back to FREE
  'TRIAL',
  // Basic paid subscription with most features/products
  'FULL',
  // 'Full' subscription with some or all of the additional optional features
  'FULL_PLUS',
] as const

type SubscriptionPlan = typeof SUBSCRIPTION_PLANS[number]

export type Subscription = {
  features: FeatureType[]
  products: ProductType[]
  production: boolean
  freeTrialExpiresAt?: string | null
  extraFreeTrialExpiresAt?: string | null
  plan: SubscriptionPlan
}

/**
 * Helper to determine if subscription is considered 'FREE'
 * which means only the minimum free products are included
 *
 * @param subscription
 */
export function isFreeSubscription(subscription: Subscription): boolean {
  return subscription.plan === 'FREE' || subscription.plan === 'FREE_PLUS'
}

type SubscriptionPlanName = 'Pro' | 'Sandbox' | 'Trial'

const subscriptionPlanNameMap: Record<SubscriptionPlan, SubscriptionPlanName> =
  {
    FREE: 'Sandbox',
    FREE_PLUS: 'Sandbox',
    TRIAL: 'Trial',
    FULL: 'Pro',
    FULL_PLUS: 'Pro',
  }

export function mapSubscriptionToPlanName(
  subscription: Subscription,
): SubscriptionPlanName {
  return subscriptionPlanNameMap[subscription.plan]
}

export type Team = {
  id: string
  name: string
  imageUrl: string | null
  members: TeamMember[]
  invitations: TeamInvitation[]
  subscription: Subscription
  createdAt: string
}

/**
 * Helper to determine if Teams has a 'pro' ('FULL' or superior) subscription
 * @param team
 */
export function isTeamProSubscription(team: Team): boolean {
  return !isFreeSubscription(team.subscription)
}

export type TeamFields = {
  name: string
  imageUrl: string
  members: MemberInvitation[]
}

export type TeamFieldsErrors = {
  name?: string
  imageUrl?: string
  members?: string[]
}

export type CreateTeamRequest = {
  name: string
  imageUrl?: string
  members?: MemberInvitation[]
}

export type CreateTeamMemberRequest = MemberInvitation

export type MemberInvitation = {
  email: string
  role: TeamRole
}

export type UpdateTeamRequest = {
  name: string
  imageUrl: string | null
}

export type UpdateTeamMemberRoleRequest = {
  role: TeamRole
}

export type UpdateTeamInvitationRoleRequest = {
  role: TeamRole
}

export function isMembersInvitationsArray(
  values: unknown,
): values is MemberInvitation[] {
  return (
    Array.isArray(values) &&
    values.every(
      (value) =>
        typeof value.email === 'string' && typeof value.role === 'string',
    )
  )
}
