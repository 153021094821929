import { AnyAction } from 'redux'

import { LoadingState } from '../loading/types'
import { RootState } from '../reducer'
import { WebhookEventsState } from './reducer'
import { WebhookEvent, WebhookEventsFilters } from './types'

export const getState = (state: RootState): WebhookEventsState =>
  state.webhookEvents
export const getLoading = (state: RootState) => getState(state).loading
export const getError = (state: RootState) => getState(state).error

export const isLoadingType: (state: LoadingState, type: string) => boolean = (
  state,
  type,
) => state.some((action: AnyAction) => action.type === type)

export const getData = (state: RootState) => getState(state).data

export const getWebhookEventsFilters = (
  state: RootState,
): WebhookEventsFilters => getData(state).filters

export const getWebhookEvents = (state: RootState): WebhookEvent[] | null =>
  getData(state).results

export const getPageNumber = (state: RootState): number =>
  getData(state).filters.page

export const getTotalPages = (state: RootState): number =>
  getData(state).totalPages
