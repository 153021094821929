import { connect } from 'react-redux'

import { RootState } from '../../../modules/reducer'
import { getCurrentTeam, isUserTeamRole } from '../../../modules/team/selectors'
import ApplicationClientKeys from './ApplicationClientKeys'
import {
  MapDispatch,
  MapDispatchProps,
  MapStateProps,
} from './ApplicationClientKeys.types'

const mapState = (state: RootState): MapStateProps => ({
  canReadClientSecret:
    !getCurrentTeam(state) || isUserTeamRole(state, ['OWNER', 'ADMIN']),
})

const mapDispatch = (_dispatch: MapDispatch): MapDispatchProps => ({
  // nothing here, yet
})

export default connect(mapState, mapDispatch)(ApplicationClientKeys)
