import { memo } from 'react'

import { Props } from './ItemFieldContainer.types'

import './ItemFieldContainer.css'

const ItemFieldContainer = ({ title, value }: Props) => (
  <div className={'ItemFieldContainer'}>
    <p className={'data-title'}>{title}</p>
    <p className={'data-value'}>{value}</p>
  </div>
)

export default memo(ItemFieldContainer)
