import { connect } from 'react-redux'

import { RootState } from '../../modules/reducer'
import { currentTeamCanGoToProduction } from '../../modules/team/selectors'
import GoToProductionButton from './GoToProductionButton'
import {
  MapDispatch,
  MapDispatchProps,
  MapStateProps,
} from './GoToProductionButton.types'

const mapState = (state: RootState): MapStateProps => ({
  currentTeamSignedContract: currentTeamCanGoToProduction(state),
})

const mapDispatch = (_dispatch: MapDispatch): MapDispatchProps => ({})

export default connect(mapState, mapDispatch)(GoToProductionButton)
