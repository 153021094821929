import { action } from 'typesafe-actions'

import {
  MemberInvitation,
  Team,
  TeamFields,
  TeamInvitation,
  TeamMember,
  TeamRole,
} from './types'

/// Fetch Teams request

export const FETCH_TEAMS_REQUEST = '[Request] Fetch Teams'
export const FETCH_TEAMS_SUCCESS = '[Success] Fetch Teams'
export const FETCH_TEAMS_FAILURE = '[Failure] Fetch Teams'

export const fetchTeamsRequest = () => action(FETCH_TEAMS_REQUEST)
export const fetchTeamsSuccess = (teams: Team[]) =>
  action(FETCH_TEAMS_SUCCESS, { teams })
export const fetchTeamsFailure = (error: string) =>
  action(FETCH_TEAMS_FAILURE, { error })

export type FetchTeamsRequestAction = ReturnType<typeof fetchTeamsRequest>
export type FetchTeamsSuccessAction = ReturnType<typeof fetchTeamsSuccess>
export type FetchTeamsFailureAction = ReturnType<typeof fetchTeamsFailure>

/// Select Team action

export const SELECT_CURRENT_TEAM_REQUEST = '[Request] Select Current Profile'
export const SELECT_CURRENT_TEAM_SUCCESS = '[Success] Select Current Profile'
export const SELECT_CURRENT_TEAM_FAILURE = '[Failure] Select Current Profile'

export const selectCurrentTeamRequest = (team: Team) =>
  action(SELECT_CURRENT_TEAM_REQUEST, {
    team,
  })
export const selectCurrentTeamSuccess = (team: Team) =>
  action(SELECT_CURRENT_TEAM_SUCCESS, { team })
export const selectCurrentTeamFailure = (error: string, team: Team) =>
  action(SELECT_CURRENT_TEAM_FAILURE, { error, team })

export type SelectCurrentTeamRequestAction = ReturnType<
  typeof selectCurrentTeamRequest
>
export type SelectCurrentTeamSuccessAction = ReturnType<
  typeof selectCurrentTeamSuccess
>
export type SelectCurrentTeamFailureAction = ReturnType<
  typeof selectCurrentTeamFailure
>

/// Impersonate Team Request

export const IMPERSONATE_TEAM_REQUEST = '[Request] Impersonate Team'
export const IMPERSONATE_TEAM_SUCCESS = '[Success] Impersonate Team'
export const IMPERSONATE_TEAM_FAILURE = '[Failure] Impersonate Team'

export const impersonateTeamRequest = (teamId: string | null) =>
  action(IMPERSONATE_TEAM_REQUEST, { teamId })
export const impersonateTeamSuccess = (team: Team | null) =>
  action(IMPERSONATE_TEAM_SUCCESS, { team })
export const impersonateTeamFailure = (error: string) =>
  action(IMPERSONATE_TEAM_FAILURE, { error })

export type ImpersonateTeamRequestAction = ReturnType<
  typeof impersonateTeamRequest
>
export type ImpersonateTeamSuccessAction = ReturnType<
  typeof impersonateTeamSuccess
>
export type ImpersonateTeamFailureAction = ReturnType<
  typeof impersonateTeamFailure
>

/// Create Team request

export const CREATE_TEAM_REQUEST = '[Request] Create Team'
export const CREATE_TEAM_SUCCESS = '[Success] Create Team'
export const CREATE_TEAM_FAILURE = '[Failure] Create Team'

export const createTeamRequest = (createTeamFields: TeamFields) =>
  action(CREATE_TEAM_REQUEST, {
    createTeamFields,
  })
export const createTeamSuccess = (team: Team) =>
  action(CREATE_TEAM_SUCCESS, { team })
export const createTeamFailure = (error: string) =>
  action(CREATE_TEAM_FAILURE, { error })

export type CreateTeamRequestAction = ReturnType<typeof createTeamRequest>
export type CreateTeamSuccessAction = ReturnType<typeof createTeamSuccess>
export type CreateTeamFailureAction = ReturnType<typeof createTeamFailure>

/// Delete Team request

export const DELETE_TEAM_REQUEST = '[Request] Delete Team'
export const DELETE_TEAM_SUCCESS = '[Success] Delete Team'
export const DELETE_TEAM_FAILURE = '[Failure] Delete Team'

export const deleteTeamRequest = (team: Team) =>
  action(DELETE_TEAM_REQUEST, {
    team,
  })
export const deleteTeamSuccess = (team: Team) =>
  action(DELETE_TEAM_SUCCESS, { team })
export const deleteTeamFailure = (error: string) =>
  action(DELETE_TEAM_FAILURE, { error })

export type DeleteTeamRequestAction = ReturnType<typeof deleteTeamRequest>
export type DeleteTeamSuccessAction = ReturnType<typeof deleteTeamSuccess>
export type DeleteTeamFailureAction = ReturnType<typeof deleteTeamFailure>

/// Create Team Member request

export const CREATE_TEAM_MEMBER_REQUEST = '[Request] Create Team Member'
export const CREATE_TEAM_MEMBER_SUCCESS = '[Success] Create Team Member'
export const CREATE_TEAM_MEMBER_FAILURE = '[Failure] Create Team Member'

export const createTeamMemberRequest = (
  teamId: string,
  createTeamMembersFields: MemberInvitation[],
) =>
  action(CREATE_TEAM_MEMBER_REQUEST, {
    id: teamId,
    createTeamMembersFields,
  })
export const createTeamMemberSuccess = (team: Team) =>
  action(CREATE_TEAM_MEMBER_SUCCESS, { team })
export const createTeamMemberFailure = (error: string) =>
  action(CREATE_TEAM_MEMBER_FAILURE, { error })

export type CreateTeamMemberRequestAction = ReturnType<
  typeof createTeamMemberRequest
>
export type CreateTeamMemberSuccessAction = ReturnType<
  typeof createTeamMemberSuccess
>
export type CreateTeamMemberFailureAction = ReturnType<
  typeof createTeamMemberFailure
>

/// Update Team request

export const UPDATE_TEAM_REQUEST = '[Request] Update Team'
export const UPDATE_TEAM_SUCCESS = '[Success] Update Team'
export const UPDATE_TEAM_FAILURE = '[Failure] Update Team'

export const updateTeamRequest = (id: string, updateTeamFields: TeamFields) =>
  action(UPDATE_TEAM_REQUEST, { id, updateTeamFields })
export const updateTeamSuccess = (team: Team) =>
  action(UPDATE_TEAM_SUCCESS, { team })
export const updateTeamFailure = (error: string) =>
  action(UPDATE_TEAM_FAILURE, { error })

export type UpdateTeamRequestAction = ReturnType<typeof updateTeamRequest>
export type UpdateTeamSuccessAction = ReturnType<typeof updateTeamSuccess>
export type UpdateTeamFailureAction = ReturnType<typeof updateTeamFailure>

/// Update Team Member Role request

export const UPDATE_TEAM_MEMBER_ROLE_REQUEST =
  '[Request] Update Team Member Role'
export const UPDATE_TEAM_MEMBER_ROLE_SUCCESS =
  '[Success] Update Team Member Role'
export const UPDATE_TEAM_MEMBER_ROLE_FAILURE =
  '[Failure] Update Team Member Role'

export const updateTeamMemberRoleRequest = (
  team: Team,
  teamMember: TeamMember | TeamInvitation,
  role: TeamRole,
) =>
  action(UPDATE_TEAM_MEMBER_ROLE_REQUEST, {
    team,
    teamMember,
    role,
  })
export const updateTeamMemberRoleSuccess = (team: Team) =>
  action(UPDATE_TEAM_MEMBER_ROLE_SUCCESS, { team })
export const updateTeamMemberRoleFailure = (error: string) =>
  action(UPDATE_TEAM_MEMBER_ROLE_FAILURE, { error })

export type UpdateTeamMemberRoleRequestAction = ReturnType<
  typeof updateTeamMemberRoleRequest
>
export type UpdateTeamMemberRoleSuccessAction = ReturnType<
  typeof updateTeamMemberRoleSuccess
>
export type UpdateTeamMemberRoleFailureAction = ReturnType<
  typeof updateTeamMemberRoleFailure
>

/// Delete Team Member request

export const DELETE_TEAM_MEMBER_REQUEST = '[Request] Delete Team Member'
export const DELETE_TEAM_MEMBER_SUCCESS = '[Success] Delete Team Member'
export const DELETE_TEAM_MEMBER_FAILURE = '[Failure] Delete Team Member'

export const deleteTeamMemberRequest = (
  team: Team,
  teamMember: TeamMember | TeamInvitation,
) =>
  action(DELETE_TEAM_MEMBER_REQUEST, {
    team,
    teamMember,
  })
export const deleteTeamMemberSuccess = (team: Team) =>
  action(DELETE_TEAM_MEMBER_SUCCESS, { team })
export const deleteTeamMemberFailure = (error: string) =>
  action(DELETE_TEAM_MEMBER_FAILURE, { error })

export type DeleteTeamMemberRequestAction = ReturnType<
  typeof deleteTeamMemberRequest
>
export type DeleteTeamMemberSuccessAction = ReturnType<
  typeof deleteTeamMemberSuccess
>
export type DeleteTeamMemberFailureAction = ReturnType<
  typeof deleteTeamMemberFailure
>

/// Resend Team Invitation request

export const RESEND_TEAM_INVITATION_REQUEST = '[Request] Resend Team Invitation'
export const RESEND_TEAM_INVITATION_SUCCESS = '[Success] Resend Team Invitation'
export const RESEND_TEAM_INVITATION_FAILURE = '[Failure] Resend Team Invitation'

export const resendTeamInvitationRequest = (
  team: Team,
  teamInvitation: TeamInvitation,
) =>
  action(RESEND_TEAM_INVITATION_REQUEST, {
    team,
    teamInvitation,
  })
export const resendTeamInvitationSuccess = (
  team: Team,
  teamInvitation: TeamInvitation,
) => action(RESEND_TEAM_INVITATION_SUCCESS, { team, teamInvitation })
export const resendTeamInvitationFailure = (error: string) =>
  action(RESEND_TEAM_INVITATION_FAILURE, { error })

export type ResendTeamInvitationRequestAction = ReturnType<
  typeof resendTeamInvitationRequest
>
export type ResendTeamInvitationSuccessAction = ReturnType<
  typeof resendTeamInvitationSuccess
>
export type ResendTeamInvitationFailureAction = ReturnType<
  typeof resendTeamInvitationFailure
>
