import { memo, useCallback, useState } from 'react'
import { useTranslation } from 'react-i18next'

import { TrackEventName } from '../../modules/analytics/events'
import { track } from '../../modules/analytics/utils'
import { ActionNeededContainer } from '../ActionNeededContainer'
import { CompanyRoleInput } from '../CompanyRoleInput'
import {
  validateCompanyRoleErrors,
  validatePlatformsErrors,
} from '../OnboardingPage/utils'
import {
  CompanyRoleFormErrors,
  PlatformsFormErrors,
  Props,
} from './CompanyRolePage.types'

import './CompanyRolePage.css'

const CompanyRolePage = ({
  loading,
  error,
  onUpdateUser,
  companyRole,
  userAcceptedTerms,
}: Props) => {
  const { t } = useTranslation()

  const [userCompanyRole, setUserCompanyRole] = useState<string | undefined>(
    companyRole || undefined,
  )

  const [developerUserPlatforms, setDeveloperUserPlatforms] = useState<
    string[] | undefined
  >()

  const [formErrors, setFormErrors] = useState<
    CompanyRoleFormErrors & PlatformsFormErrors
  >({})

  const continueButtonI18nKey = 'user.company-role.form.action.continue'
  const continueButtonText = t(continueButtonI18nKey)

  const trackFormValidationError = useCallback(
    (errors: CompanyRoleFormErrors) => {
      track(TrackEventName.FORM_VALIDATION_ERRORS, {
        fields: Object.keys(errors),
        location: 'CompanyRolePage',
      })
    },
    [],
  )

  const handleCompanyRoleChange = useCallback(
    (role?: string) => {
      // clear field error, if any
      if (formErrors['companyRole']) {
        delete formErrors['companyRole']
      }
      setUserCompanyRole(role)
    },
    [formErrors],
  )

  const handleSubmitButtonClick = useCallback(
    (event: React.FormEvent) => {
      event.preventDefault()

      if (loading) {
        // is already loading, wait
        return
      }
      track(TrackEventName.BUTTON_CLICKED, {
        location: 'CompanyRolePage',
        text: continueButtonText,
        i18nKey: continueButtonI18nKey,
      })

      const platformsErrors =
        userCompanyRole === 'DEVELOPER'
          ? validatePlatformsErrors(developerUserPlatforms)
          : undefined

      const currentFormErrors: CompanyRoleFormErrors & PlatformsFormErrors = {
        companyRole: validateCompanyRoleErrors(userCompanyRole),
        platforms: platformsErrors,
      }

      setFormErrors(currentFormErrors)

      if (
        currentFormErrors.companyRole !== undefined ||
        currentFormErrors.platforms !== undefined
      ) {
        // got errors, can't proceed
        trackFormValidationError(currentFormErrors)
        return
      }

      // when user is asked company role because it has it set as OTHER,
      // the terms and conditions are already accepted.
      onUpdateUser({
        termsAccepted: userAcceptedTerms ? undefined : true,
        companyRole: userCompanyRole,
        platforms:
          userCompanyRole === 'DEVELOPER' ? developerUserPlatforms : undefined,
      })
    },
    [
      loading,
      continueButtonText,
      userCompanyRole,
      onUpdateUser,
      userAcceptedTerms,
      developerUserPlatforms,
      trackFormValidationError,
    ],
  )

  const handlePlatformsChange = useCallback(
    (platforms?: string[]) => {
      // clear field error, if any
      if (formErrors['platforms']) {
        delete formErrors['platforms']
      }

      setDeveloperUserPlatforms(platforms)
    },
    [formErrors],
  )

  const hasFormErrors =
    Object.values(formErrors).filter((value) => value).length > 0

  return (
    <ActionNeededContainer
      page={'CompanyRolePage'}
      error={error}
      disabled={hasFormErrors || !userCompanyRole}
      loading={loading}
      onSubmitButtonClick={handleSubmitButtonClick}
      title={t(
        `user.company-role.form.title.${
          userAcceptedTerms ? 'terms-accepted' : 'terms-not-accepted'
        }`,
      )}
      subtitle={t(
        `user.company-role.form.subtitle.${
          userAcceptedTerms ? 'terms-accepted' : 'terms-not-accepted'
        }`,
      )}
      buttonText={continueButtonText}
      showConsentSection={!userAcceptedTerms}
    >
      <CompanyRoleInput
        initialValue={companyRole ? companyRole : undefined}
        onRoleChange={handleCompanyRoleChange}
        onPlatformsChange={handlePlatformsChange}
        companyRoleError={formErrors.companyRole}
        platformsError={formErrors.platforms}
      />
    </ActionNeededContainer>
  )
}

export default memo(CompanyRolePage)
