import React, { memo, useCallback, useRef, useState } from 'react'
import { Trans, useTranslation } from 'react-i18next'

import { Button, Header, Image, Input, Radio } from '@pluggyai/ui'

import { openHubspotChat } from '../../lib/hubspot'
import { TrackEventName } from '../../modules/analytics/events'
import { track } from '../../modules/analytics/utils'
import { Page } from '../Page'
import AgilityIcon from './icons/AgilityIcon'
import FacilitateIcon from './icons/FacilitateIcon'
import InsightsIcon from './icons/InsightsIcon'
import SecurityIcon from './icons/SecurityIcon'
import { Props } from './NoCodePage.types'

import './NoCodePage.css'

const NoCodePage = ({ onSubmit, theme }: Props) => {
  const { t } = useTranslation()
  const [companyName, setCompanyName] = useState('')
  const [isRequestInsightsChecked, setIsRequestInsightsChecked] =
    useState(false)

  const contactFormRef = useRef<HTMLDivElement>(null)

  const contactSectionDescriptionI18nKey =
    'no-code-page.callout.contact-section.description'

  const iAmInterestedButtonI18nKey = 'no-code-page.action.i-am-interested'
  const iAmInterestedButtonText = t(iAmInterestedButtonI18nKey)

  const handleImInterestedClick = useCallback(() => {
    track(TrackEventName.BUTTON_CLICKED, {
      location: 'NoCodePage',
      text: iAmInterestedButtonText,
      i18nKey: iAmInterestedButtonI18nKey,
    })

    contactFormRef.current?.scrollIntoView()
  }, [iAmInterestedButtonText])

  const handleNoCodeContactLinkClick = useCallback(
    (event: React.MouseEvent) => {
      openHubspotChat('no-code-page.callout.contact-section')

      track(TrackEventName.LINK_CLICKED, {
        location: 'NoCodePage',
        text: (event.target as HTMLAnchorElement).textContent,
        i18nKey: contactSectionDescriptionI18nKey,
      })
    },
    [],
  )

  const handleRequestInsightsClick = useCallback(() => {
    setIsRequestInsightsChecked((previous) => !previous)
  }, [])

  const submitI18nKey =
    'no-code-page.callout.contact-section.form.action.submit'
  const submitText = t(submitI18nKey)

  const isSubmitDisabled = companyName === ''

  const handleSubmit = useCallback(() => {
    if (isSubmitDisabled) {
      return
    }

    track(TrackEventName.BUTTON_CLICKED, {
      location: 'NoCodePage',
      text: submitText,
      i18nKey: submitI18nKey,
    })

    onSubmit({ companyName, insightsRequested: isRequestInsightsChecked })
  }, [
    companyName,
    isRequestInsightsChecked,
    isSubmitDisabled,
    onSubmit,
    submitText,
  ])

  const handleCompanyNameChange = useCallback(({ value }) => {
    setCompanyName(value)
  }, [])

  return (
    <Page className={'NoCodePage'}>
      <div className={'main-call-to-action'}>
        <div className={'left'}>
          <Header>
            {t('no-code-page.header.open-finance')}{' '}
            <span>{t('no-code-page.header.no-code')}</span>
          </Header>
          <p>{t('no-code-page.description')}</p>
          <div>
            <Button primary onClick={handleImInterestedClick}>
              {iAmInterestedButtonText}
            </Button>
          </div>
        </div>
        <Image src={`/no-code-${theme}.png`} />
      </div>

      <div className={'callouts'}>
        <div className={'callout'}>
          <AgilityIcon />
          <Header>{t('no-code-page.callout.agility.title')}</Header>
          <p>{t('no-code-page.callout.agility.description')} </p>
        </div>
        <div className={'callout'}>
          <FacilitateIcon />
          <Header>{t('no-code-page.callout.ease.title')}</Header>
          <p>{t('no-code-page.callout.ease.description')}</p>
        </div>
        <div className={'callout'}>
          <SecurityIcon />
          <Header>{t('no-code-page.callout.security.title')}</Header>
          <p>{t('no-code-page.callout.security.description')}</p>
        </div>
        <div className={'callout'}>
          <InsightsIcon />
          <Header>{t('no-code-page.callout.insights.title')}</Header>
          <p>{t('no-code-page.callout.insights.description')}</p>
        </div>
      </div>
      <div className={'contact-section'} ref={contactFormRef}>
        <Header>{t('no-code-page.callout.contact-section.header')}</Header>
        <p className={'contact-description'}>
          <Trans
            i18nKey={contactSectionDescriptionI18nKey}
            components={{
              a: <Button link onClick={handleNoCodeContactLinkClick} />,
            }}
          />
        </p>

        <Radio
          type="checkbox"
          className={'insights-checkbox'}
          onChange={handleRequestInsightsClick}
          checked={isRequestInsightsChecked}
          label={t('no-code-page.callout.contact-section.form.checkbox.label')}
        />
        <div className={'input-section'}>
          <Input
            type={'text'}
            label={t(
              'no-code-page.callout.contact-section.form.companyName.label',
            )}
            value={companyName}
            onChange={handleCompanyNameChange}
            name={'companyName'}
          />

          <Button onClick={handleSubmit} disabled={isSubmitDisabled} primary>
            {submitText}
          </Button>
        </div>
      </div>
      <Header className={'bottom-header'}>
        {t('no-code-page.callout.bottom-header')}
      </Header>
    </Page>
  )
}

export default memo(NoCodePage)
