import { connect } from 'react-redux'

import {
  getApplication,
  getApplicationItemsCount,
} from '../../../modules/application/selectors'
import { RootState } from '../../../modules/reducer'
import { currentTeamCanGoToProduction } from '../../../modules/team/selectors'
import DevelopmentItemUsageAlert from './DevelopmentItemUsageAlert'
import {
  MapDispatch,
  MapDispatchProps,
  MapStateProps,
} from './DevelopmentItemUsageAlert.types'

const mapState = (state: RootState): MapStateProps => {
  const application = getApplication(state)

  return {
    applicationItemsCount:
      application && getApplicationItemsCount(state, application),
    teamCanGoToProduction: currentTeamCanGoToProduction(state),
  }
}

const mapDispatch = (_dispatch: MapDispatch): MapDispatchProps => ({})

export default connect(mapState, mapDispatch)(DevelopmentItemUsageAlert)
