import { AxiosResponse } from 'axios'
import type { Webhook, CreateWebhook, UpdateWebhook } from 'pluggy-sdk'

import { BaseService } from './BaseService'

export class WebhookService extends BaseService {
  async getWebhooks(applicationId: string): Promise<AxiosResponse<Webhook[]>> {
    const updateApplicationUrl = this.replaceUrlParams(this.urls.GET_WEBHOOKS, {
      applicationId,
    })
    return await this.getServiceInstance().get(updateApplicationUrl)
  }

  async createWebhook(
    applicationId: string,
    webhook: CreateWebhook,
  ): Promise<AxiosResponse<Webhook>> {
    const createWebhookUrl = this.replaceUrlParams(this.urls.POST_WEBHOOK, {
      applicationId,
    })
    return await this.getServiceInstance().post(createWebhookUrl, webhook)
  }

  async updateWebhook(
    applicationId: string,
    webhookId: string,
    webhook: UpdateWebhook,
  ): Promise<AxiosResponse<Webhook>> {
    const updateWebhookUrl = this.replaceUrlParams(this.urls.PATCH_WEBHOOK, {
      applicationId,
      webhookId,
    })
    return await this.getServiceInstance().patch(updateWebhookUrl, webhook)
  }

  async deleteWebhook(applicationId: string, webhookId: string): Promise<void> {
    const deleteWebhookUrl = this.replaceUrlParams(this.urls.DELETE_WEBHOOK, {
      webhookId,
      applicationId,
    })
    return await this.getServiceInstance().delete(deleteWebhookUrl)
  }
}
