const UUID_REGEX =
  /^[0-9a-f]{8}-[0-9a-f]{4}-[0-9a-f]{4}-[0-9a-f]{4}-[0-9a-f]{12}$/

/**
 * helper function to validate if a string is a valid uuid
 * @param uuid - uuid to validate
 * @returns - if the uuid is valid
 */
export function isValidUuid(uuid: string): boolean {
  return UUID_REGEX.test(uuid)
}
