import { connect } from 'react-redux'

import { RootState } from '../../../modules/reducer'
import {
  updateUserRequest,
  UPDATE_USER_REQUEST,
} from '../../../modules/user/actions'
import {
  getLoading,
  getUser,
  getUserError,
  isLoadingType,
} from '../../../modules/user/selectors'
import { UpdateUserFields } from '../../../modules/user/types'
import UserDetailsSection from './UserDetailsSection'
import {
  MapDispatch,
  MapDispatchProps,
  MapStateProps,
} from './UserDetailsSection.types'

const mapState = (state: RootState): MapStateProps => ({
  user: getUser(state),
  error: getUserError(state),
  isLoading: isLoadingType(getLoading(state), UPDATE_USER_REQUEST),
})

const mapDispatch = (dispatch: MapDispatch): MapDispatchProps => ({
  onUpdateUser: (fields: UpdateUserFields) =>
    dispatch(updateUserRequest(fields)),
})

export default connect(mapState, mapDispatch)(UserDetailsSection)
