import React from 'react'

import Icon from '../Icon'

import './WarningIcon.css'

type Props = {
  className?: string
  onClick?: () => void
}

const WarningIcon = ({ className, onClick }: Props) => (
  <Icon
    className={`WarningIcon ${className || ''}`}
    onClick={onClick}
    width="20"
    height="20"
    viewBox="0 0 20 20"
    fill="none"
  >
    <svg
      width="20"
      height="20"
      viewBox="0 0 20 20"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M9.99996 11.5238C9.79044 11.5238 9.61901 11.3524 9.61901 11.1429V6.76191C9.61901 6.55239 9.79044 6.38096 9.99996 6.38096C10.2095 6.38096 10.3809 6.55239 10.3809 6.76191V11.1429C10.3809 11.3524 10.2095 11.5238 9.99996 11.5238ZM9.99994 13.6191C9.67613 13.6191 9.42852 13.3714 9.42852 13.0476C9.42852 12.7238 9.67613 12.4762 9.99994 12.4762C10.3238 12.4762 10.5714 12.7238 10.5714 13.0476C10.5714 13.3714 10.3238 13.6191 9.99994 13.6191ZM10 17C6.13333 17 3 13.8667 3 10C3 6.13333 6.13333 3 10 3C13.8667 3 17 6.13333 17 10C17 13.8667 13.8667 17 10 17M2 10C2 14.4187 5.58133 18 10 18C14.4187 18 18 14.4187 18 10C18 5.58133 14.4187 2 10 2C5.58133 2 2 5.58133 2 10Z"
        fill="#F5F5F5"
      />
    </svg>
  </Icon>
)

export default React.memo(WarningIcon)
