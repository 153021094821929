import { action } from 'typesafe-actions'

import {
  ConnectorsStats,
  ConnectorsStatsRequest,
  ItemsStats,
  ItemsStatsRequest,
  ItemStatsFiltersChangeRequest,
} from './types'

/// Fetch Items Stats Request

export const FETCH_ITEMS_STATS_REQUEST = '[Request] Fetch Items Stats'
export const FETCH_ITEMS_STATS_SUCCESS = '[Success] Fetch Items Stats'
export const FETCH_ITEMS_STATS_FAILURE = '[Failure] Fetch Items Stats'

export const fetchItemsStatsRequest = (request?: ItemsStatsRequest) =>
  action(FETCH_ITEMS_STATS_REQUEST, { request })
export const fetchItemsStatsSuccess = (
  value: ItemsStats,
  applicationId: ItemsStatsRequest['applicationId'],
  isOverviewPageItemsStats?: boolean,
) =>
  action(FETCH_ITEMS_STATS_SUCCESS, {
    value,
    applicationId,
    isOverviewPageItemsStats,
  })
export const fetchItemsStatsFailure = (error: string) =>
  action(FETCH_ITEMS_STATS_FAILURE, { error })

export type FetchItemsStatsRequestAction = ReturnType<
  typeof fetchItemsStatsRequest
>
export type FetchItemsStatsSuccessAction = ReturnType<
  typeof fetchItemsStatsSuccess
>
export type FetchItemsStatsFailureAction = ReturnType<
  typeof fetchItemsStatsFailure
>

/// Fetch Total Items Stats Request

export const FETCH_TOTAL_ITEMS_STATS_REQUEST =
  '[Request] Fetch Total Items Stats'
export const FETCH_TOTAL_ITEMS_STATS_SUCCESS =
  '[Success] Fetch Total Items Stats'
export const FETCH_TOTAL_ITEMS_STATS_FAILURE =
  '[Failure] Fetch Total Items Stats'

export const fetchTotalItemsStatsRequest = () =>
  action(FETCH_TOTAL_ITEMS_STATS_REQUEST)
export const fetchTotalItemsStatsSuccess = (value: ItemsStats) =>
  action(FETCH_TOTAL_ITEMS_STATS_SUCCESS, {
    value,
  })
export const fetchTotalItemsStatsFailure = (error: string) =>
  action(FETCH_TOTAL_ITEMS_STATS_FAILURE, { error })

export type FetchTotalItemsStatsRequestAction = ReturnType<
  typeof fetchTotalItemsStatsRequest
>
export type FetchTotalItemsStatsSuccessAction = ReturnType<
  typeof fetchTotalItemsStatsSuccess
>
export type FetchTotalItemsStatsFailureAction = ReturnType<
  typeof fetchTotalItemsStatsFailure
>

/// Fetch Connectors Stats Request

export const FETCH_CONNECTORS_STATS_REQUEST = '[Request] Fetch Connectors Stats'
export const FETCH_CONNECTORS_STATS_SUCCESS = '[Success] Fetch Connectors Stats'
export const FETCH_CONNECTORS_STATS_FAILURE = '[Failure] Fetch Connectors Stats'

export const fetchConnectorsStatsRequest = (request?: ConnectorsStatsRequest) =>
  action(FETCH_CONNECTORS_STATS_REQUEST, { request })
export const fetchConnectorsStatsSuccess = (response: ConnectorsStats) =>
  action(FETCH_CONNECTORS_STATS_SUCCESS, { response })
export const fetchConnectorsStatsFailure = (error: string) =>
  action(FETCH_CONNECTORS_STATS_FAILURE, { error })

export type FetchConnectorsStatsRequestAction = ReturnType<
  typeof fetchConnectorsStatsRequest
>
export type FetchConnectorsStatsSuccessAction = ReturnType<
  typeof fetchConnectorsStatsSuccess
>
export type FetchConnectorsStatsFailureAction = ReturnType<
  typeof fetchConnectorsStatsFailure
>

/// Change Item Stats Filters

export const CHANGE_ITEM_STATS_FILTERS = 'Change Item Stats Filters'

export const changeItemStatsFilters = (
  request: ItemStatsFiltersChangeRequest,
) => action(CHANGE_ITEM_STATS_FILTERS, { request })

export type ChangeItemStatsFiltersAction = ReturnType<
  typeof changeItemStatsFilters
>
