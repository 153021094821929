import React, { useCallback, useEffect, useState } from 'react'
import { Trans, useTranslation } from 'react-i18next'

import { Alert, Button, Radio } from '@pluggyai/ui'
import { Modal } from 'semantic-ui-react'

import { TrackEventName } from '../../modules/analytics/events'
import { track } from '../../modules/analytics/utils'
import { CloseIcon } from '../Icon'
import { Props } from './TermsAndConditionsModal.types'

import './TermsAndConditionsModal.css'

const TermsAndConditionsModal = ({
  isLoading,
  error,
  withActions,
  onClose,
  onAcceptTermsRequest,
}: Props) => {
  const { t } = useTranslation()

  const [submit, setSubmit] = useState(false)

  const [termsAccepted, setTermsAccepted] = useState(false)

  const trackModalCloseClick = useCallback(() => {
    track(TrackEventName.CTA_VERIFICATION_TERMS_MODAL_CLOSE)
  }, [])

  const trackCancelClick = useCallback(() => {
    track(TrackEventName.CTA_VERIFICATION_TERMS_MODAL_CANCEL)
  }, [])

  const trackOnAcceptClick = useCallback(() => {
    track(TrackEventName.CTA_VERIFICATION_TERMS_MODAL_ACCEPT)
  }, [])

  const close = useCallback(() => {
    setTermsAccepted(false)
    onClose()
  }, [onClose])

  const handleClose = useCallback(() => {
    close()
    trackModalCloseClick()
  }, [close, trackModalCloseClick])

  const handleCancel = useCallback(() => {
    close()
    trackCancelClick()
  }, [close, trackCancelClick])

  const handleSubmit = useCallback(
    (event: React.FormEvent) => {
      if (isLoading) {
        // is already loading/submitting, wait
        return
      }

      event.preventDefault()

      setSubmit(true)
      onAcceptTermsRequest()
      trackOnAcceptClick()
    },
    [onAcceptTermsRequest, isLoading, trackOnAcceptClick],
  )

  useEffect(() => {
    // check if we are done, and close modal if no errors
    const isDone = submit && error === null && !isLoading
    if (isDone) {
      handleClose()
    }
  }, [error, isLoading, submit, handleClose])

  const handleToggleTermsAccepted = useCallback(() => {
    setTermsAccepted((value) => !value)
  }, [])

  return (
    <Modal
      className={'TermsAndConditionsModal'}
      onClose={handleClose}
      open={true}
      closeIcon={<CloseIcon />}
    >
      <Modal.Header>
        {t('stepsToSuccess.item.acceptTerms.form.title')}
        {error !== null && <Alert type={'error'} message={error} />}
      </Modal.Header>
      <Modal.Content>
        <div className={'terms-and-conditions-container'}>
          <div className={'terms-and-conditions-content'}>
            <Trans
              i18nKey={'stepsToSuccess.item.acceptTerms.form.terms-content'}
            />
          </div>
          <div className={'terms-and-conditions-content-backdrop'} />
        </div>
        {withActions && (
          <Radio
            type="checkbox"
            className={'terms-and-conditions-accept'}
            onClick={handleToggleTermsAccepted}
            checked={termsAccepted}
            label={t(
              'stepsToSuccess.item.acceptTerms.form.field.acceptTerms.label',
            )}
          />
        )}
      </Modal.Content>
      {withActions && (
        <Modal.Actions>
          <Button secondary onClick={handleCancel}>
            {t('stepsToSuccess.item.acceptTerms.form.action.cancel')}
          </Button>
          <Button
            primary
            onClick={handleSubmit}
            loading={isLoading}
            disabled={!termsAccepted}
          >
            {t('stepsToSuccess.item.acceptTerms.form.action.confirm')}
          </Button>
        </Modal.Actions>
      )}
    </Modal>
  )
}

export default React.memo(TermsAndConditionsModal)
