import { AnyAction } from 'redux'

import { LoadingState } from '../loading/types'
import { RootState } from '../reducer'
import { getUser } from '../user/selectors'
import { TeamState } from './reducer'
import { FeatureType, Team, TeamRole } from './types'
import {
  getTeamFreeTrialDaysLeft,
  getUserTeamRole,
  isTeamFreeTrialSubscription,
  isTeamRole,
} from './utils'

export const getState = (state: RootState): TeamState => state.team as TeamState
export const getData = (state: RootState) => getState(state).data
export const getLoading = (state: RootState) => getState(state).loading
export const getError = (state: RootState) => getState(state).error

export const isLoadingType = (state: LoadingState, type: string): boolean =>
  state.some((action: AnyAction) => action.type === type)

export const getTeams = (state: RootState): Team[] | null =>
  getData(state).teams

export const getCurrentTeam = (state: RootState): Team | null =>
  getData(state).current

export const getAsTeam = (state: RootState): Team | null =>
  getData(state).asTeam

export const isUserTeamRole = (
  state: RootState,
  roles: TeamRole[],
): boolean => {
  const user = getUser(state)
  const currentTeam = getCurrentTeam(state)

  if (!user) {
    return false
  }

  if (!currentTeam) {
    // no currentTeam, doesn't have any team role
    return false
  }

  const asTeam = getAsTeam(state)
  if (asTeam?.id === currentTeam.id) {
    // is in impersonated team, consider current role as 'OWNER'
    return roles.includes('OWNER')
  }

  return isTeamRole(currentTeam, user, roles)
}

export const getCurrentUserTeamRole = (state: RootState): TeamRole | null => {
  const user = getUser(state)
  const currentTeam = getCurrentTeam(state)

  if (!user) {
    // no user loaded yet
    return null
  }

  if (!currentTeam) {
    // no currentTeam, user profile selected, doesn't have any team role
    return null
  }

  const asTeam = getAsTeam(state)
  if (asTeam?.id === currentTeam.id) {
    // is in impersonated team, return 'OWNER' to allow any action
    return 'OWNER'
  }

  return getUserTeamRole(currentTeam, user)
}

export const currentTeamCanGoToProduction = (
  state: RootState,
): boolean | null => {
  const currentTeam = getCurrentTeam(state)
  if (!currentTeam) {
    return null
  }
  return currentTeam.subscription.production
}

export const isUserOnlyTeamOwner = (state: RootState, team: Team): boolean => {
  const user = getUser(state)

  if (!user) {
    return false
  }

  // check if no OWNER members left
  const currentOwnerMembers = team.members.filter(
    (member_) => member_.teamUser.role === 'OWNER',
  )

  return (
    currentOwnerMembers.length === 1 && currentOwnerMembers[0].id === user.id
  )
}
export const getCurrentTeamFeatures = (
  state: RootState,
): FeatureType[] | null => {
  const currentTeam = getCurrentTeam(state)

  if (!currentTeam) {
    return null
  }
  return currentTeam.subscription.features
}

export const isFreeTrial = (state: RootState): boolean => {
  const currentTeam = getCurrentTeam(state)
  if (!currentTeam) {
    return false
  }
  return isTeamFreeTrialSubscription(currentTeam)
}

export const teamFreeTrialDaysLeft = (state: RootState): number | null => {
  const currentTeam = getCurrentTeam(state)

  if (!currentTeam) {
    return null
  }

  return getTeamFreeTrialDaysLeft(currentTeam)
}
