import React, { useCallback, useEffect, useMemo, useRef, useState } from 'react'
import { useTranslation } from 'react-i18next'

import { Connector, ConnectorFilters } from 'pluggy-js'
import { Header } from 'semantic-ui-react'

import { Props } from './ConnectorsStatusList.types'
import { ConnectorStatusRow } from './ConnectorStatusRow'
import {
  groupConnectorsExecutionsByConnectorId,
  sortConnectorsByUsage,
} from './utils'

import './ConnectorsStatusList.css'

// we are using the component in the same file, no need to
// create a new file for it or export it
const Line = () => <div className={'line'} />

const CONNECTOR_TYPES: ConnectorFilters['types'] = [
  'PERSONAL_BANK',
  'BUSINESS_BANK',
  'INVESTMENT',
]

const ConnectorsStatusList = ({ connectors, connectorsStats }: Props) => {
  const { t } = useTranslation()
  const [connectorsList, setConnectorsList] = useState<Connector[] | null>([])
  const [scrollPosition, setScrollPosition] = useState(0)

  const handleScroll = useCallback(() => {
    if (!connectorStatusListRef.current?.scrollTop) {
      return
    }

    setScrollPosition(connectorStatusListRef.current.scrollTop)
  }, [])

  const groupedConnectorsStats = useMemo(
    () => groupConnectorsExecutionsByConnectorId(connectorsStats),
    [connectorsStats],
  )

  const connectorStatusListRef = useRef<HTMLDivElement | null>(null)
  const hasOverflow =
    connectorStatusListRef.current &&
    connectorStatusListRef.current.scrollHeight >
      connectorStatusListRef.current.clientHeight

  const hasScrolledToBottom =
    connectorStatusListRef.current &&
    connectorStatusListRef.current.scrollHeight -
      connectorStatusListRef.current.clientHeight ===
      scrollPosition

  // fetch data and use the connectors based on the customization
  useEffect(() => {
    if (!connectors) {
      // for a reason we can't get the data -> just return
      return
    }

    const recentConnectorIds = [...groupedConnectorsStats.keys()]

    const recentConnectors = connectors.filter((connector) =>
      [...recentConnectorIds].includes(connector.id),
    )

    const connectorsListSortedByName = recentConnectors.sort((a, b) =>
      a.name > b.name ? 1 : -1,
    )

    setConnectorsList(connectorsListSortedByName)
  }, [connectors, groupedConnectorsStats])

  return (
    <>
      <div
        className={'ConnectorsStatusList'}
        ref={connectorStatusListRef}
        onScroll={handleScroll}
      >
        {CONNECTOR_TYPES.map((connectorType) => {
          const connectorTypeConnectorsList: Connector[] =
            connectorsList?.filter(
              (connector) => connector.type === connectorType,
            ) || []

          const connectorsListSorteredByUsage = sortConnectorsByUsage(
            connectorTypeConnectorsList,
            connectorType,
          )

          return (
            <div key={connectorType}>
              {connectorsListSorteredByUsage.length >= 1 && (
                <Header as={'h4'}>
                  {t(
                    `connectorsStatus.connectors-status-list.header.${connectorType}`,
                  )}
                </Header>
              )}
              {connectorsListSorteredByUsage.map((connector, i) => (
                <div
                  className={`status-row ${connector.name}`}
                  key={`${connector.name}_${connector.id}`}
                >
                  <ConnectorStatusRow
                    connector={connector}
                    connectorStats={groupedConnectorsStats.get(connector.id)}
                  />
                  {i !== connectorTypeConnectorsList.length - 1 && <Line />}
                </div>
              ))}
            </div>
          )
        })}
      </div>
      {hasOverflow && !hasScrolledToBottom && (
        <div className="scroll-shadow"></div>
      )}
    </>
  )
}

export default React.memo(ConnectorsStatusList)
