import { connect } from 'react-redux'

import { RootState } from '../../modules/reducer'
import { updateUserRequest } from '../../modules/user/actions'
import {
  getUser,
  isCurrentUserCreateDateAfterDate,
} from '../../modules/user/selectors'
import WelcomeWidget from './WelcomeWidget'
import {
  MapDispatch,
  MapDispatchProps,
  MapStateProps,
} from './WelcomeWidget.types'

const WELCOME_PAGE_FEATURE_RELEASE_DATE = new Date('August 25, 2022')

const mapState = (state: RootState): MapStateProps => ({
  userHasClosedWidget: getUser(state)?.uiState?.userHasClosedWelcomeWidget,
  userCreatedAfterFeatureRelease: isCurrentUserCreateDateAfterDate(
    state,
    WELCOME_PAGE_FEATURE_RELEASE_DATE,
  ),
})

const mapDispatch = (dispatch: MapDispatch): MapDispatchProps => ({
  onClose: () =>
    dispatch(
      updateUserRequest({
        uiState: { userHasClosedWelcomeWidget: true },
      }),
    ),
})

export default connect(mapState, mapDispatch)(WelcomeWidget)
