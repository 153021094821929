import { memo } from 'react'

import { TeamBillingSection } from './TeamBillingSection'
import { TeamSubscriptionDetailSection } from './TeamSubscriptionDetailSection'
import { Props } from './TeamSubscriptionSection.types'

import './TeamSubscriptionSection.css'

const TeamSubscriptionSection = ({ team, userTeamMemberRole }: Props) => {
  const plan = team?.subscription.plan

  const isOwnerOrAdminRole = ['OWNER', 'ADMIN'].includes(
    String(userTeamMemberRole),
  )

  return (
    <div className={'TeamSubscriptionSection'}>
      {plan === 'FULL' && isOwnerOrAdminRole && <TeamBillingSection />}
      <TeamSubscriptionDetailSection />
    </div>
  )
}
export default memo(TeamSubscriptionSection)
