import { memo, useCallback } from 'react'
import { useTranslation } from 'react-i18next'

import { CreateApplicationButton } from '../CreateApplicationButton'
import { GoToProductionModal } from '../GoToProductionModal'
import { Props } from './GoToProductionButton.types'

import './GoToProductionButton.css'

const GoToProductionButton = ({
  currentTeamSignedContract,
  onModalOpen,
}: Props) => {
  const { t } = useTranslation()

  const goToProductionI18nKey = 'applications.action.go-to-production'
  const goToProductionText = t(goToProductionI18nKey)

  const handleGoToProductionModalOpen = useCallback(() => {
    onModalOpen(goToProductionText, goToProductionI18nKey)
  }, [goToProductionText, onModalOpen])

  return (
    <div className={'GoToProductionButton'}>
      {currentTeamSignedContract ? (
        <CreateApplicationButton
          isProductionApplication
          onModalOpen={handleGoToProductionModalOpen}
          primary
        >
          {goToProductionText}
        </CreateApplicationButton>
      ) : (
        <GoToProductionModal onModalOpen={handleGoToProductionModalOpen}>
          {goToProductionText}
        </GoToProductionModal>
      )}
    </div>
  )
}

export default memo(GoToProductionButton)
