import { Connector, ConnectorType } from 'pluggy-js'

import {
  BUSINESS_BANK_TYPE_IDS_ORDER,
  INVESTMENT_BANK_ORDER_TYPE_IDS_ORDER,
  PERSONAL_BANK_TYPE_IDS_ORDER,
} from '../../../lib/constants/connectorsIdsOrder'
import { ConnectorStatsReport } from '../../../modules/stats/types'

/**
 * takes the current list of Connectors Stats and return a map
 * with the connectorId as key of the hole list
 * @returns - a Map with the connectors differenciate by id
 */
export function groupConnectorsExecutionsByConnectorId(
  connectorsStatsList: ConnectorStatsReport[] | undefined,
): Map<ConnectorStatsReport['connectorId'], ConnectorStatsReport[]> {
  if (!connectorsStatsList) {
    return new Map()
  }
  const map = new Map()

  connectorsStatsList.forEach((connectorStatsList: ConnectorStatsReport) => {
    const key = connectorStatsList['connectorId']
    const collection = map.get(key)
    if (!collection) {
      // we don't have the connector id as key, so create a new list with
      // that id
      map.set(key, [connectorStatsList])
    } else {
      // we already have the connector id as a key, so we push
      // the execution stat in the collection
      collection.push(connectorStatsList)
    }
  })
  return map
}

/**
 * match a list of ids with the actual connectors list
 * to get a custom sort
 *
 * @param connectors - connectors list to sort
 * @param connectorType - the current connectorType selected
 *
 * @returns - connectors list sorted by usage
 */
export function sortConnectorsByUsage(
  connectors: Connector[],
  connectorType: ConnectorType,
): Connector[] {
  const connectorIdsByUsageOrder: number[] = []

  if (connectorType === 'PERSONAL_BANK') {
    connectorIdsByUsageOrder.push(...PERSONAL_BANK_TYPE_IDS_ORDER)
  } else if (connectorType === 'BUSINESS_BANK') {
    connectorIdsByUsageOrder.push(...BUSINESS_BANK_TYPE_IDS_ORDER)
  } else if (connectorType === 'INVESTMENT') {
    connectorIdsByUsageOrder.push(...INVESTMENT_BANK_ORDER_TYPE_IDS_ORDER)
  }

  // sort the connectors array matching with the ids array
  const connectorsSorted = [...connectors].sort(
    (a: Connector, b: Connector) =>
      connectorIdsByUsageOrder.indexOf(a.id) -
      connectorIdsByUsageOrder.indexOf(b.id),
  )

  // sort non-matching by id connectors at the end, alphabetical order
  if (connectorsSorted.length > connectorIdsByUsageOrder.length) {
    // in this case, there may connectors that there are not in the list,
    // so, we splice them and add them to the bottom of the connectorsList (in alphabetical order)
    const connectorsDifference: number =
      connectorsSorted.length - connectorIdsByUsageOrder.length
    connectorsSorted.push(
      ...connectorsSorted
        // note: using splice in order to remove the data from the actual array
        .splice(0, connectorsDifference)
        .sort((a, b) => (a.name < b.name ? -1 : 1)),
    )
  }
  return connectorsSorted
}
