import { connect } from 'react-redux'

import { RootState } from '../../../modules/reducer'
import { updateUserRequest } from '../../../modules/user/actions'
import { getTheme } from '../../../modules/user/selectors'
import { Theme } from '../../../modules/user/types'
import ThemeSwitchItem from './ThemeSwitchItem'
import {
  MapDispatch,
  MapDispatchProps,
  MapStateProps,
} from './ThemeSwitchItem.types'

const mapState = (state: RootState): MapStateProps => ({
  theme: getTheme(state),
})

const mapDispatch = (dispatch: MapDispatch): MapDispatchProps => ({
  onThemeChange: (theme: Theme) =>
    dispatch(
      updateUserRequest({
        uiState: { theme },
      }),
    ),
})

export default connect(mapState, mapDispatch)(ThemeSwitchItem)
