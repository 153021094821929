import { AxiosResponse } from 'axios'

import {
  CreateTeamMemberRequest,
  CreateTeamRequest,
  Team,
  UpdateTeamInvitationRoleRequest,
  UpdateTeamMemberRoleRequest,
  UpdateTeamRequest,
} from '../../modules/team/types'
import { BaseService } from './BaseService'

export class TeamService extends BaseService {
  async createTeam(
    createTeamFields: CreateTeamRequest,
  ): Promise<AxiosResponse<Team>> {
    return await this.getServiceInstance().post(
      this.urls.POST_TEAM,
      createTeamFields,
    )
  }

  async createTeamMember(
    id: string,
    createTeamMemberFields: CreateTeamMemberRequest,
  ): Promise<AxiosResponse<Team>> {
    const createTeamMemberUrl = this.replaceUrlParams(
      this.urls.POST_TEAM_MEMBER,
      { id },
    )
    return await this.getServiceInstance().post(
      createTeamMemberUrl,
      createTeamMemberFields,
    )
  }

  async getTeams(): Promise<AxiosResponse<Team[]>> {
    return await this.getServiceInstance().get(this.urls.GET_TEAMS)
  }

  async getTeam(id: string): Promise<AxiosResponse<Team>> {
    const getTeamUrl = this.replaceUrlParams(this.urls.GET_TEAM, { id })

    return await this.getServiceInstance().get(getTeamUrl)
  }

  async updateTeam(
    id: string,
    updateTeamFields: Partial<UpdateTeamRequest>,
  ): Promise<AxiosResponse<Team>> {
    const updateTeamUrl = this.replaceUrlParams(this.urls.PATCH_TEAM, { id })

    return await this.getServiceInstance().patch(
      updateTeamUrl,
      updateTeamFields,
    )
  }

  async deleteTeam(id: string): Promise<AxiosResponse<void>> {
    const deleteTeamUrl = this.replaceUrlParams(this.urls.DELETE_TEAM, { id })

    return await this.getServiceInstance().delete(deleteTeamUrl)
  }

  async updateTeamMember(
    id: string,
    memberId: string,
    updateTeamMemberFields: UpdateTeamMemberRoleRequest,
  ): Promise<AxiosResponse<Team>> {
    const updateTeamMemberUrl = this.replaceUrlParams(
      this.urls.PATCH_TEAM_MEMBER,
      { id, memberId },
    )
    return await this.getServiceInstance().patch(
      updateTeamMemberUrl,
      updateTeamMemberFields,
    )
  }

  async deleteTeamMember(
    id: string,
    memberId: string,
  ): Promise<AxiosResponse<Team>> {
    const deleteTeamMemberUrl = this.replaceUrlParams(
      this.urls.DELETE_TEAM_MEMBER,
      { id, memberId },
    )
    return await this.getServiceInstance().delete(deleteTeamMemberUrl)
  }

  async updateTeamInvitation(
    id: string,
    invitationId: string,
    updateTeamInvitationFields: UpdateTeamInvitationRoleRequest,
  ): Promise<AxiosResponse<Team>> {
    const updateTeamInvitationUrl = this.replaceUrlParams(
      this.urls.PATCH_TEAM_INVITATION,
      { id, invitationId },
    )
    return await this.getServiceInstance().patch(
      updateTeamInvitationUrl,
      updateTeamInvitationFields,
    )
  }

  async deleteTeamInvitation(
    id: string,
    invitationId: string,
  ): Promise<AxiosResponse<Team>> {
    const deleteTeamInvitationUrl = this.replaceUrlParams(
      this.urls.DELETE_TEAM_INVITATION,
      { id, invitationId },
    )
    return await this.getServiceInstance().delete(deleteTeamInvitationUrl)
  }

  async resendTeamInvitationEmail(
    id: string,
    invitationId: string,
  ): Promise<AxiosResponse<void>> {
    const resendTeamInvitationEmailUrl = this.replaceUrlParams(
      this.urls.RESEND_TEAM_INVITATION_EMAIL,
      { id, invitationId },
    )
    return await this.getServiceInstance().post(resendTeamInvitationEmailUrl)
  }
}
