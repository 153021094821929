import { memo } from 'react'

const InsightsIcon = () => (
  <svg
    width="28"
    height="25"
    viewBox="0 0 28 25"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <rect width="28" height="25" rx="2" fill="#EBE8F1" />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M4.25 3.125C4.25 2.50368 3.74632 2 3.125 2C2.50368 2 2 2.50368 2 3.125V21.875C2 22.4963 2.50368 23 3.125 23H24.875C25.4963 23 26 22.4963 26 21.875C26 21.2537 25.4963 20.75 24.875 20.75H4.25V3.125ZM25.6705 6.9205C26.1098 6.48116 26.1098 5.76884 25.6705 5.3295C25.2311 4.89017 24.5189 4.89017 24.0795 5.3295L17 12.409L13.2955 8.7045C12.8562 8.26517 12.1438 8.26517 11.7045 8.7045L6.8295 13.5795C6.39017 14.0188 6.39017 14.7312 6.8295 15.1705C7.26884 15.6098 7.98116 15.6098 8.4205 15.1705L12.5 11.091L16.2045 14.7955C16.4155 15.0065 16.7016 15.125 17 15.125C17.2984 15.125 17.5845 15.0065 17.7955 14.7955L25.6705 6.9205Z"
      fill="#271158"
    />
  </svg>
)

export default memo(InsightsIcon)
