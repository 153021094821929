import i18next from 'i18next'
import type { Webhook } from 'pluggy-sdk'

import {
  WEBHOOK_EVENTS,
  WebhookEventOption,
} from '../../../../../modules/webhook/types'
import { isValidSecureUrl } from '../../../../../utils/validation'

export function validateWebhookParameters(
  url: string,
  event: Webhook['event'] | undefined,
  webhooks: Webhook[] | null,
):
  | {
      url?: string
      event?: string
    }
  | undefined {
  if (!url) {
    return {
      url: i18next.t('application.page.webhooks.input.url.error.required'),
    }
  }

  if (!isValidSecureUrl(url)) {
    return {
      url: i18next.t('application.page.webhooks.input.url.error.https'),
    }
  }

  if (
    webhooks?.some((webhook) => webhook.url === url && webhook.event === event)
  ) {
    return {
      url: i18next.t('application.page.webhooks.input.url.error.exists'),
      event: ' ',
    }
  }

  return undefined
}

export function buildAllWebhookEventsOption(): WebhookEventOption {
  return {
    id: 'all',
    name: i18next.t('application.page.webhooks.input.event.all'),
  }
}

export function buildWebhookEventOptions(): WebhookEventOption[] {
  return WEBHOOK_EVENTS.map((event) => {
    if (event === 'all') {
      return buildAllWebhookEventsOption()
    }
    return {
      id: event,
      name: event,
    }
  })
}
