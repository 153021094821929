import { action } from 'typesafe-actions'

// Upload logo image file

export const UPLOAD_LOGO_IMAGE_REQUEST = '[Request] Upload logo image'
export const UPLOAD_LOGO_IMAGE_SUCCESS = '[Success] Upload logo image'
export const UPLOAD_LOGO_IMAGE_FAILURE = '[Failure] Upload logo image'

export const uploadLogoImageRequest = (logoImageFile: File) =>
  action(UPLOAD_LOGO_IMAGE_REQUEST, { logoImageFile })

export const uploadLogoImageSuccess = (logoImageUrl: string | null) =>
  action(UPLOAD_LOGO_IMAGE_SUCCESS, { logoImageUrl })

export const uploadLogoImageFailure = (error: string) =>
  action(UPLOAD_LOGO_IMAGE_FAILURE, { error })

export type UploadLogoImageRequestAction = ReturnType<
  typeof uploadLogoImageRequest
>
export type UploadLogoImageSuccessAction = ReturnType<
  typeof uploadLogoImageSuccess
>
export type UploadLogoImageFailureAction = ReturnType<
  typeof uploadLogoImageFailure
>
