/**
 * Converts a string from snake_case to text
 * i.e. 'hello_world' -> 'Hello World'
 *
 * @param value - string in snake_case
 * @returns string in text
 */
export function snakeCaseToText(value: string): string {
  return value
    .split('_')
    .map((word) => word[0].toUpperCase() + word.slice(1).toLowerCase())
    .join(' ')
}
