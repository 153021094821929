import React from 'react'

import { ConnectorStatsReport } from '../../../../../modules/stats/types'
import { StatusBar } from './StatusBar'
import { Props } from './StatusBarContainer.types'
import {
  getConnectorExpectedRateAverage,
  mapStatusFromConnectorStat,
} from './utils'

import './StatusBarContainer.css'

const StatusBarContainer = ({ connectorStats }: Props) => {
  const average = getConnectorExpectedRateAverage(connectorStats)

  const statusAverageClassNames =
    parseInt(average) >= 95
      ? 'operational'
      : parseInt(average) >= 90
      ? 'degradedPerformance'
      : 'outage'

  return (
    <div className={'StatusBarContainer'}>
      {connectorStats?.map((connectorStat: ConnectorStatsReport, i: number) => (
        <StatusBar
          status={mapStatusFromConnectorStat(connectorStat)}
          key={`${connectorStat.connectorId}_${connectorStat.timespan}_${i}`}
        />
      ))}

      <div
        className={`status-average ${
          average !== '-' ? statusAverageClassNames : 'non-executions'
        }`}
      >
        {average !== '-' ? `${average}%` : '-'}
      </div>
    </div>
  )
}

export default React.memo(StatusBarContainer)
