import { connect } from 'react-redux'

import {
  FETCH_CONNECTORS_REQUEST,
  FETCH_CUSTOMIZATION_REQUEST,
} from '../../../modules/customization/actions'
import {
  getConnectors,
  getConnectorsError,
  getCustomization,
  getLoading,
  isCurrentUserFreeSubscription,
  isLoadingType,
} from '../../../modules/customization/selectors'
import { RootState } from '../../../modules/reducer'
import { getCurrentTeam, isUserTeamRole } from '../../../modules/team/selectors'
import ConnectorsCustomizationForm from './ConnectorsCustomizationForm'
import {
  MapDispatch,
  MapDispatchProps,
  MapStateProps,
} from './ConnectorsCustomizationForm.types'

const mapState = (state: RootState): MapStateProps => {
  return {
    connectors: getConnectors(state),
    customization: getCustomization(state),
    error: getConnectorsError(state),
    isLoading:
      isLoadingType(getLoading(state), FETCH_CUSTOMIZATION_REQUEST) ||
      isLoadingType(getLoading(state), FETCH_CONNECTORS_REQUEST),
    isFreeSubscription: isCurrentUserFreeSubscription(state),
    isTeamOwnerOrAdminRole:
      !getCurrentTeam(state) || isUserTeamRole(state, ['OWNER', 'ADMIN']),
  }
}

const mapDispatch = (_: MapDispatch): MapDispatchProps => ({})

export default connect(mapState, mapDispatch)(ConnectorsCustomizationForm)
