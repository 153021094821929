import React, { useCallback, useEffect, useMemo, useState } from 'react'
import { Trans, useTranslation } from 'react-i18next'
import { Prompt, Redirect } from 'react-router-dom'

import { Alert, Button, Header, Tag } from '@pluggyai/ui'

import { PLUGGY_CUSTOMIZATION_DOCS_URL } from '../../lib/constants/urls'
import { openHubspotChat } from '../../lib/hubspot'
import { TrackEventName } from '../../modules/analytics/events'
import { track } from '../../modules/analytics/utils'
import {
  ConnectorIdsCustomizationFields,
  Customization,
  GeneralCustomizationFields,
  GeneralCustomizationFieldsErrors,
} from '../../modules/customization/types'
import { isArrayEqual } from '../../utils/array'
import { isStringsArray } from '../../utils/validation'
import { Accordion } from '../Accordion'
import { FreeTrialAlert } from '../FreeTrialAlert'
import { Page } from '../Page'
import { WelcomeWidget } from '../WelcomeWidget'
import { ConfirmSaveChangesModal } from './ConfirmSaveChangesModal'
import { ConnectorsCustomizationForm } from './ConnectorsCustomizationForm'
import { ConnectorItem } from './ConnectorsCustomizationForm/ConnectorsCustomizationForm'
import { isNewConnector } from './ConnectorsCustomizationForm/utils'
import { ConsentCustomization } from './ConsentCustomization'
import { Props } from './CustomizationPage.types'
import { GeneralCustomizationForm } from './GeneralCustomizationForm'
import { validateField } from './GeneralCustomizationForm/utils'
import { PreviewButton } from './PreviewButton'
import { UnsavedChangesModal } from './UnsavedChangesModal'

import './CustomizationPage.css'

const CustomizationPage = ({
  isFreeSubscription,
  onFetchCustomization,
  onFetchConnectors,
  currentTeam,
  isTeamOwnerOrAdminRole,
  customization,
  onUpdate,
  connectTokenError,
  connectors,
  isLoadingLogoImageUpload,
  temporaryLogoImageUrl,
}: Props) => {
  useEffect(() => {
    onFetchCustomization()
    onFetchConnectors()
  }, [onFetchConnectors, onFetchCustomization])

  const { t } = useTranslation()

  const handleSubscriptionUpgradeContactLinkClick = useCallback(
    (event: React.MouseEvent<HTMLButtonElement>) => {
      openHubspotChat('customization.free-features-limited-alert')

      track(TrackEventName.LINK_CLICKED, {
        location: 'CustomizationPage Alert',
        text: (event.target as HTMLButtonElement).textContent,
      })
    },
    [],
  )

  useEffect(() => {
    if (temporaryLogoImageUrl === null) {
      return
    }
    setGeneralCustomizationValues((previous) => ({
      ...previous,
      logoImageUrl: temporaryLogoImageUrl,
    }))
  }, [temporaryLogoImageUrl])

  const [generalCustomizationValues, setGeneralCustomizationValues] =
    useState<GeneralCustomizationFields>()

  const trackGeneralCustomizationUpdateSubmit = useCallback(() => {
    if (!generalCustomizationValues) {
      return
    }

    const fieldsChanged: (keyof Customization)[] = []
    for (const [key, value] of Object.entries(generalCustomizationValues)) {
      const keyOfCustomization = key as keyof Customization
      if (customization?.[keyOfCustomization] !== value) {
        // changed field
        fieldsChanged.push(keyOfCustomization)
      }
    }
    track(TrackEventName.CUSTOMIZATION_DETAILS_UPDATE_SUBMIT, {
      fields: fieldsChanged,
    })
  }, [generalCustomizationValues, customization])

  const trackGeneralCustomizationFormValidationError = useCallback(
    (errors: GeneralCustomizationFieldsErrors) => {
      track(TrackEventName.FORM_VALIDATION_ERRORS, {
        fields: Object.keys(errors),
        location: 'GeneralCustomizationForm',
      })
    },
    [],
  )

  const [generalCustomizationFormErrors, setGeneralCustomizationFormErrors] =
    useState<GeneralCustomizationFieldsErrors>({})

  const handleGeneralCustomizationFormErrorsChange = useCallback(
    (errors: GeneralCustomizationFieldsErrors) => {
      setGeneralCustomizationFormErrors(errors)
    },
    [],
  )

  const handleGeneralCustomizationFieldChange = useCallback(
    (fieldName: keyof GeneralCustomizationFields, value: string | string[]) => {
      setGeneralCustomizationValues((previous) => ({
        ...previous,
        [fieldName]: value,
      }))
    },
    [],
  )

  const [selectedConnectorsIds, setSelectedConnectorsIds] =
    useState<ConnectorItem['id'][]>()

  useEffect(() => {
    if (!customization?.connectorIds) {
      return
    }

    setSelectedConnectorsIds(customization.connectorIds)
  }, [customization?.connectorIds])

  const handleSelectedConnectorsIdsChange = useCallback(
    (connectorsIds_: ConnectorItem['id'][]) => {
      setSelectedConnectorsIds(connectorsIds_)
    },
    [],
  )

  const trackConnectorsUpdateSubmit = useCallback(() => {
    const fieldsChanged: (keyof Customization)[] = ['connectorIds']

    track(TrackEventName.CUSTOMIZATION_DETAILS_UPDATE_SUBMIT, {
      fields: fieldsChanged,
    })
  }, [])

  const handleSaveCustomizationChanges = useCallback(() => {
    // close the save changes modal
    setShowConfirmSaveChangesModal(false)
    let updatedCustomization: GeneralCustomizationFields &
      ConnectorIdsCustomizationFields = {}

    if (!customization) {
      return
    }

    if (generalCustomizationValues) {
      trackGeneralCustomizationUpdateSubmit()

      // validate fields values
      const currentFormErrors: GeneralCustomizationFieldsErrors = {}
      let errorsCount = 0
      for (const [field, value] of Object.entries(generalCustomizationValues)) {
        let fieldError: string | string[] | undefined
        if (isStringsArray(value)) {
          const valueJoined = value.join(',')
          const errorsJoined = validateField(
            field as keyof GeneralCustomizationFieldsErrors,
            valueJoined,
          )
          const fieldErrors = errorsJoined?.split(',')
          if (fieldErrors?.some((fieldError_) => fieldError_.length > 0)) {
            errorsCount++
          }

          fieldError = fieldErrors
        } else {
          fieldError = validateField(
            field as keyof Omit<GeneralCustomizationFields, '_type'>,
            String(value),
          )

          if (fieldError) {
            errorsCount++
          }
        }

        if (fieldError) {
          // using 'as any' here as a hack to support both 'string' or 'string[]'
          // fieldError values.
          // TODO rework/improve this, maybe using separated validations per field
          currentFormErrors[field as keyof GeneralCustomizationFieldsErrors] =
            fieldError as any
        }
      }

      setGeneralCustomizationFormErrors(currentFormErrors)
      if (errorsCount > 0) {
        // got errors, can't proceed
        trackGeneralCustomizationFormValidationError(currentFormErrors)
        return
      }
      updatedCustomization = generalCustomizationValues
      setGeneralCustomizationValues(undefined)
    }
    const prevConnectorsIds = customization.connectorIds || []
    const connectorsIdsEquals =
      selectedConnectorsIds &&
      prevConnectorsIds.length === selectedConnectorsIds.length &&
      prevConnectorsIds.every((id) => selectedConnectorsIds.includes(id))

    if (!connectorsIdsEquals) {
      // connectorIds are not equal, submit changes
      trackConnectorsUpdateSubmit()
      updatedCustomization.connectorIds = selectedConnectorsIds
    }

    onUpdate(updatedCustomization)
  }, [
    customization,
    generalCustomizationValues,
    selectedConnectorsIds,
    onUpdate,
    trackGeneralCustomizationUpdateSubmit,
    trackGeneralCustomizationFormValidationError,
    trackConnectorsUpdateSubmit,
  ])

  const saveButtonI18n = 'customization.form.general.save-button'
  const saveButtonText = t(saveButtonI18n)

  const handleSaveButtonClick = useCallback(() => {
    track(TrackEventName.BUTTON_CLICKED, {
      location: 'CustomizationPage',
      text: saveButtonText,
      i18nKey: saveButtonI18n,
    })
    setShowConfirmSaveChangesModal(true)
  }, [saveButtonText])

  const [showUnsavedChangesModal, setShowUnsavedChangesModal] = useState(false)

  const [showConfirmSaveChangesModal, setShowConfirmSaveChangesModal] =
    useState(false)

  const [locationToContinue, setLocationToContinue] = useState<
    string | undefined
  >()

  const [hasDiscardedChanges, setHasDiscardedChanges] = useState(false)

  const handleLeaveWithUnsavedChanges = useCallback(
    ({ pathname }) => {
      setLocationToContinue(pathname)
      setShowUnsavedChangesModal(true)
      // return false to prevent navigation
      return false
    },
    [setShowUnsavedChangesModal],
  )

  const handleDiscardChanges = useCallback(() => {
    setHasDiscardedChanges(true)
  }, [])

  const handleCancelSaveChanges = useCallback(() => {
    setShowConfirmSaveChangesModal(false)
    setShowUnsavedChangesModal(false)
  }, [])

  const hasChangesInConnectorsCustomization =
    !!customization?.connectorIds &&
    !!selectedConnectorsIds &&
    !isArrayEqual(selectedConnectorsIds, customization.connectorIds)

  const hasChangesInGeneralCustomization =
    generalCustomizationValues !== undefined &&
    customization !== null &&
    Object.entries(generalCustomizationValues).some(
      ([key, value]) =>
        customization[key as keyof GeneralCustomizationFields] !== value,
    )

  const hasUnsavedChanges =
    hasChangesInGeneralCustomization || hasChangesInConnectorsCustomization

  const hasCustomCompanyDeviceName = Boolean(
    currentTeam?.subscription.features.includes('CUSTOM_COMPANY_DEVICE_NAME'),
  )

  const docsLinkI18nKey = 'customization.form.general.need-help'
  const docsLinkText = t(docsLinkI18nKey)

  const handleDocsLinkClick = useCallback(() => {
    track(TrackEventName.LINK_CLICKED, {
      location: 'CustomizationPage',
      text: docsLinkText,
      i18nKey: docsLinkI18nKey,
      linkTo: PLUGGY_CUSTOMIZATION_DOCS_URL,
    })
  }, [docsLinkText])

  const areNewConnectorsAvailable = useMemo(
    () => connectors?.some((connector) => isNewConnector(connector)),
    [connectors],
  )

  return (
    <>
      {hasDiscardedChanges && locationToContinue && (
        <Redirect to={locationToContinue} />
      )}
      <Prompt
        when={hasUnsavedChanges && !hasDiscardedChanges}
        message={handleLeaveWithUnsavedChanges}
      />
      {locationToContinue && (
        <UnsavedChangesModal
          isOpen={showUnsavedChangesModal}
          onContinue={handleDiscardChanges}
          onCancel={handleCancelSaveChanges}
        />
      )}
      <ConfirmSaveChangesModal
        isOpen={showConfirmSaveChangesModal}
        onSaveChanges={handleSaveCustomizationChanges}
        onCancel={handleCancelSaveChanges}
      />
      <Page className={'CustomizationPage'}>
        <WelcomeWidget />
        <FreeTrialAlert />
        <div className={'customization-column'}>
          {connectTokenError !== null && (
            <Alert
              type={'error'}
              size={'medium'}
              message={t('customization.preview.error.connect-token-fetch')}
            />
          )}
          {isFreeSubscription && (
            <Alert
              className={'customization-alert'}
              type={'info'}
              size={'medium'}
              message={
                <Trans
                  i18nKey={'customization.free-features-limited-alert.message'}
                  components={{
                    a: (
                      <Button
                        link
                        onClick={handleSubscriptionUpgradeContactLinkClick}
                      />
                    ),
                  }}
                />
              }
            />
          )}
          <div className="customization-top-section">
            <div className="customization-header">
              <Header as={'h4'}>{t('customization.title')}</Header>
            </div>

            <div className="customization-actions">
              <PreviewButton
                customizationHasUnsavedChanges={hasUnsavedChanges}
              />

              <Button
                onClick={handleSaveButtonClick}
                disabled={
                  !hasUnsavedChanges ||
                  !isTeamOwnerOrAdminRole ||
                  isLoadingLogoImageUpload
                }
                primary
              >
                {t('customization.form.general.save-button')}
              </Button>
            </div>
          </div>
          <p className={'description'}>
            <Trans
              i18nKey={'customization.description'}
              components={{
                a: (
                  // we don't need content inside the anchor because we are
                  // interpolating the element
                  // eslint-disable-next-line jsx-a11y/anchor-has-content
                  <a
                    href={PLUGGY_CUSTOMIZATION_DOCS_URL}
                    target="_blank"
                    rel="noreferrer"
                    onClick={handleDocsLinkClick}
                  />
                ),
              }}
            />
          </p>
          <Accordion
            elements={[
              {
                title: t('customization.form.general.title'),
                children: (
                  <GeneralCustomizationForm
                    handleGeneralCustomizationFieldChange={
                      handleGeneralCustomizationFieldChange
                    }
                    formErrors={generalCustomizationFormErrors}
                    handleFormErrorsChange={
                      handleGeneralCustomizationFormErrorsChange
                    }
                    hasCustomCompanyDeviceName={hasCustomCompanyDeviceName}
                  />
                ),
              },
              {
                title: (
                  <div className="connectors-list-title">
                    {t('customization.form.connectors-list.title')}
                    {areNewConnectorsAvailable && (
                      <Tag
                        text={t(
                          'customization.form.connectors-list.new-connector-available',
                        )}
                        size="small"
                      />
                    )}
                    {selectedConnectorsIds?.length === 0 && (
                      <div className={'empty-connectors-warning-container'}>
                        <Alert
                          className={'empty-connectors-warning'}
                          size={'medium'}
                          type={'warning'}
                          message={t(
                            'customization.form.connectors-list.empty-warning',
                          )}
                        />
                      </div>
                    )}
                  </div>
                ),
                children: (
                  <ConnectorsCustomizationForm
                    handleSelectedConnectorsIdsChange={
                      handleSelectedConnectorsIdsChange
                    }
                    connectorIds={selectedConnectorsIds}
                  />
                ),
              },
              {
                title: t('customization.form.consent.title'),
                children: (
                  <ConsentCustomization
                    isFreeSubscription={isFreeSubscription}
                    isTeamOwnerOrAdminRole={isTeamOwnerOrAdminRole}
                  />
                ),
              },
            ]}
          />
        </div>
      </Page>
    </>
  )
}

export default React.memo(CustomizationPage)
