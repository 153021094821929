import React, { useCallback, useEffect, useState } from 'react'
import { useTranslation } from 'react-i18next'

import { Header, Loader, Popup } from 'semantic-ui-react'

import { InfoIcon } from '../Icon'
import { EmptyChartMessage } from '../Stats/EmptyChartMessage'
import { Props } from './ConnectorsStatus.types'
import { ConnectorsStatusList } from './ConnectorsStatusList'
import { ConnectorsStatusTimeFilter } from './ConnectorsStatusTimeFilter'
import {
  TimeFilterValue,
  timeValueDaysMap,
} from './ConnectorsStatusTimeFilter/ConnectorsStatusTimeFilter.types'

import './ConnectorsStatus.css'

// if we have less than this amount of connectors we shlould add space in the container
// note: this amount was decided with agus
const FEW_CONNECTORS_THRESHOLD = 4

const ConnectorsStatus = ({
  onFetchConnectors,
  onFetchCustomization,
  onFetchConnectorsStats,
  isLoading,
  connectors,
  customization,
  connectorsStats,
  user,
  team,
}: Props) => {
  const { t } = useTranslation()

  const [currentTimeFilterValue, setCurrentTimeFilterValue] =
    useState<TimeFilterValue>('24d')

  // fetch customization, connectors and connectorsStatus
  useEffect(() => {
    if (!user || !team) {
      // mounted but 'user' or 'team' still not retrieved -> don't request stats, yet
      return
    }

    onFetchCustomization()
    onFetchConnectors()
    onFetchConnectorsStats()
  }, [
    onFetchConnectors,
    onFetchConnectorsStats,
    onFetchCustomization,
    team,
    user,
  ])

  const handleTimeFilterChange = useCallback(
    (value: TimeFilterValue) => {
      if (value === currentTimeFilterValue) {
        // same time filter -> just return
        return
      }

      setCurrentTimeFilterValue(value)
    },
    [currentTimeFilterValue],
  )

  useEffect(() => {
    if (!user) {
      // mounted but 'user' still not retrieved -> don't request stats, yet
      return
    }
    onFetchConnectorsStats({
      fromDay: timeValueDaysMap[currentTimeFilterValue],
    })
  }, [currentTimeFilterValue, onFetchConnectorsStats, user])

  const isFirstTimeLoading =
    isLoading && (!connectors || !customization || !connectorsStats)

  return (
    <div
      className={`ConnectorsStatus ${
        customization?.connectorIds &&
        customization.connectorIds.length < FEW_CONNECTORS_THRESHOLD
          ? 'extra-space'
          : ''
      }`}
    >
      <div className={'header-container'}>
        <div className={'header-title'}>
          <Header as={'h3'}>{t('connectorsStatus.header.title')}</Header>
          <Popup
            content={t('connectorsStatus.header.tooltip.message')}
            on="hover"
            position={'top left'}
            mouseLeaveDelay={200}
            trigger={
              <div className={'tooltip'}>
                <InfoIcon />
              </div>
            }
          />
        </div>
        <div className={'time-filter-container'}>
          <ConnectorsStatusTimeFilter
            onTimeFilterChange={handleTimeFilterChange}
            value={currentTimeFilterValue}
            isDisabled={isLoading}
          />
        </div>
      </div>
      {isFirstTimeLoading ? (
        <div className={'loader-container'}>
          <Loader size={'medium'} active />
        </div>
      ) : (
        <div className={'connectors-status-list-container'}>
          {connectorsStats && connectorsStats.length > 0 ? (
            <ConnectorsStatusList
              connectorsStats={connectorsStats}
              connectors={connectors}
            />
          ) : (
            <EmptyChartMessage teamOrUserHasItemsConnected={false} />
          )}
        </div>
      )}
    </div>
  )
}

export default React.memo(ConnectorsStatus)
