import type { Connector } from 'pluggy-js'
import { action } from 'typesafe-actions'

import {
  ConnectorIdsCustomizationFields,
  Customization,
  GeneralCustomizationFields,
} from './types'

/// Fetch Customization request

export const FETCH_CUSTOMIZATION_REQUEST = '[Request] Fetch Customization'
export const FETCH_CUSTOMIZATION_SUCCESS = '[Success] Fetch Customization'
export const FETCH_CUSTOMIZATION_FAILURE = '[Failure] Fetch Customization'

export const fetchCustomizationRequest = () =>
  action(FETCH_CUSTOMIZATION_REQUEST)
export const fetchCustomizationSuccess = (customization: Customization) =>
  action(FETCH_CUSTOMIZATION_SUCCESS, { customization })
export const fetchCustomizationFailure = (error: string) =>
  action(FETCH_CUSTOMIZATION_FAILURE, { error })

export type FetchCustomizationRequestAction = ReturnType<
  typeof fetchCustomizationRequest
>
export type FetchCustomizationSuccessAction = ReturnType<
  typeof fetchCustomizationSuccess
>
export type FetchCustomizationFailureAction = ReturnType<
  typeof fetchCustomizationFailure
>

/// Update Customization request

export const UPDATE_CUSTOMIZATION_REQUEST = '[Request] Update Customization'
export const UPDATE_CUSTOMIZATION_SUCCESS = '[Success] Update Customization'
export const UPDATE_CUSTOMIZATION_FAILURE = '[Failure] Update Customization'

export const updateCustomizationRequest = (
  updateCustomizationFields: GeneralCustomizationFields &
    ConnectorIdsCustomizationFields,
) =>
  action(UPDATE_CUSTOMIZATION_REQUEST, {
    updateCustomizationFields,
  })
export const updateCustomizationSuccess = (customization: Customization) =>
  action(UPDATE_CUSTOMIZATION_SUCCESS, { customization })
export const updateCustomizationFailure = (error: string) =>
  action(UPDATE_CUSTOMIZATION_FAILURE, { error })

export type UpdateCustomizationRequestAction = ReturnType<
  typeof updateCustomizationRequest
>
export type UpdateCustomizationSuccessAction = ReturnType<
  typeof updateCustomizationSuccess
>
export type UpdateCustomizationFailureAction = ReturnType<
  typeof updateCustomizationFailure
>

/// Fetch Connectors Request

export const FETCH_CONNECTORS_REQUEST = '[Request] Fetch connectors'
export const FETCH_CONNECTORS_SUCCESS = '[Success] Fetch connectors'
export const FETCH_CONNECTORS_FAILURE = '[Failure] Fetch connectors'

export const fetchConnectorsRequest = () => action(FETCH_CONNECTORS_REQUEST, {})

export const fetchConnectorsSuccess = (connectors: Connector[]) =>
  action(FETCH_CONNECTORS_SUCCESS, { connectors })

export const fetchConnectorsFailure = (error: string) =>
  action(FETCH_CONNECTORS_FAILURE, { error })

export type FetchConnectorsRequestAction = ReturnType<
  typeof fetchConnectorsRequest
>
export type FetchConnectorsSuccessAction = ReturnType<
  typeof fetchConnectorsSuccess
>
export type FetchConnectorsFailureAction = ReturnType<
  typeof fetchConnectorsFailure
>
