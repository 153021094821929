/**
 * Helper to compare numbers or strings
 * used to sort arrays
 *
 * @param a
 * @param b
 */
function compareValues<T extends string | number>(a: T, b: T): number {
  if (a < b) {
    return -1
  }
  if (a > b) {
    return 1
  }
  return 0
}

/**
 * Takes two arrays and returns
 * true if they are equal
 *
 * @param array1 - array of unknown values
 * @param array2 - array of unknown values
 * @returns
 */
export function isArrayEqual<T extends string | number>(
  array1: T[],
  array2: T[],
): boolean {
  if (!Array.isArray(array1) || !Array.isArray(array2)) {
    return false
  }

  const sortedArray1 = [...array1].sort(compareValues)
  const sortedArray2 = [...array2].sort(compareValues)

  return JSON.stringify(sortedArray1) === JSON.stringify(sortedArray2)
}

/**
 * Takes an array of numbers and returns
 * the sum of all numbers
 * @param numbers
 * @returns sum
 */
export function sumArray(numbers: number[]): number {
  let sum = 0

  for (const number of numbers) {
    sum += number
  }

  return sum
}

/**
 * return the first letter capitalized found
 * @param value
 * @returns - letter
 */
export function getFirstCapitalizedLetter(value: string): string {
  for (let i = 0; i < value.length; i++) {
    const letter = value.charAt(i)
    if (letter !== ' ' && letter === letter.toUpperCase()) {
      return letter
    }
  }

  return value[0]
}

/**
 * Groups an array of objects by a given key.
 * The return value is an array of objects that have a name property
 * and an items property. The name property is the value of the key
 * for each group, and the items property is an array of objects
 * that share that value for the key.
 *
 * @param array - an array of objects
 * @param key - key of the object to group by
 * @returns an array of groups with name and elements of the group
 */
export function groupBy<T>(
  array: T[],
  key: keyof T,
): {
  name: T[keyof T]
  values: T[]
}[] {
  const uniqueNames = [...new Set(array.map((item) => item[key]))]
  const groupedItems = uniqueNames.map((name) => ({
    name,
    values: array.filter((item) => item[key] === name),
  }))
  return groupedItems
}
