import { SupportedLanguage } from '.'

const TERMS_OF_USE_FILENAME = 'terms-of-use'

export async function fetchTermsOfUseText(
  language: SupportedLanguage,
): Promise<string> {
  const termsFilePath = `/${TERMS_OF_USE_FILENAME}_${language}.txt`

  let termsFileTextContent: string
  try {
    const response = await fetch(termsFilePath)
    termsFileTextContent = await response.text()
  } catch (error) {
    error.message = `Failed to fetch terms of use file '${termsFilePath}'. Reason: ${error.message}`
    throw error
  }

  // convert '\n' line breaks to <br/> for i18n rendering
  return termsFileTextContent.replace(/\n/g, '<br/>')
}
