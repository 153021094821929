import { AnyAction } from 'redux'

export function removeLast<T>(
  actions: T[],
  comparator: (action: T) => boolean,
): T[] {
  const last = actions.filter(comparator).pop()
  return actions.filter((action) => action !== last)
}

export const getType = (action: AnyAction): string => action.type.slice(10)
export const getStatus = (action: AnyAction): string =>
  action.type.slice(1, 8).toUpperCase()
