import { connect } from 'react-redux'

import {
  FETCH_APPLICATION_REQUEST,
  fetchApplicationRequest,
  updateApplicationRequest,
} from '../../../modules/application/actions'
import {
  getApplication,
  getError as getApplicationsError,
  isLoadingApplicationSelector,
} from '../../../modules/application/selectors'
import { ApplicationFields } from '../../../modules/application/types'
import { RootState } from '../../../modules/reducer'
import { fetchItemsStatsRequest } from '../../../modules/stats/actions'
import { getCurrentTeam, isUserTeamRole } from '../../../modules/team/selectors'
import { getIsUserActionsRequired } from '../../../modules/user/selectors'
import ApplicationDetails from './ApplicationDetails'
import {
  MapDispatch,
  MapDispatchProps,
  MapStateProps,
} from './ApplicationDetails.types'

const mapState = (state: RootState): MapStateProps => ({
  isFeaturesLimited:
    (getCurrentTeam(state) && !isUserTeamRole(state, ['OWNER', 'ADMIN'])) ||
    getIsUserActionsRequired(state),
  error: getApplicationsError(state),
  isLoading: isLoadingApplicationSelector(
    state,
    FETCH_APPLICATION_REQUEST,
  )(getApplication(state)),
})

const mapDispatch = (dispatch: MapDispatch): MapDispatchProps => ({
  onFetchApplication: (id: string) => dispatch(fetchApplicationRequest(id)),
  onUpdateApplication: (
    id: string,
    updateApplicationFields: ApplicationFields,
  ) => dispatch(updateApplicationRequest(id, updateApplicationFields)),
  onFetchApplicationsStats: () => dispatch(fetchItemsStatsRequest()),
})

export default connect(mapState, mapDispatch)(ApplicationDetails)
