import { memo, ReactNode, useEffect } from 'react'
import { useTranslation } from 'react-i18next'

import { Alert } from '@pluggyai/ui'
import { format } from 'date-fns'
import { Popup } from 'semantic-ui-react'

import { InfoIcon } from '../Icon'
import { Page } from '../Page'
import { NoWebhooksCreatedSection } from './NoWebhooksCreatedSection'
import { PageControls } from './PageControls'
import { Table } from './Table'
import { buildWebhookEventsColumnHeaders } from './utils'
import { WebhookEventFilters } from './WebhookEventsFilters'
import { Props } from './WebhookEventsPage.types'

import './WebhookEventsPage.css'

const WebhookEventsPage = ({
  webhookEvents,
  loading,
  teamHasWebhooksCreated,
  webhookEventsFilters,
  error,
  team: _,
  onFetchWebhookEvents,
}: Props) => {
  useEffect(() => {
    onFetchWebhookEvents(webhookEventsFilters)

    const interval = setInterval(() => {
      onFetchWebhookEvents(webhookEventsFilters)
    }, 3000)

    return () => {
      clearInterval(interval)
    }
  }, [onFetchWebhookEvents, webhookEventsFilters])

  const { t } = useTranslation()

  const tableRows = webhookEvents?.map(
    ({ completedAt, createdAt, event, subjectId, retries, url }) => (
      <tr
        className={`table-row ${retries >= 3 ? 'error' : ''}`}
        key={`${createdAt}-${url}-${subjectId}`}
      >
        {[
          format(createdAt, 'dd/MM/yyyy HH:mm:ss'),
          event,
          url,
          subjectId,
          completedAt ? format(completedAt, 'dd/MM/yyyy HH:mm:ss') : '-',
          retries || '-',
        ].map((cell, cellIndex) => (
          <Popup
            key={cellIndex}
            content={t('webhook-events.page.table.row.error-tooltip')}
            disabled={retries < 3}
            position={'top left'}
            trigger={<td className={'table-row-data'}>{cell}</td>}
          />
        ))}
      </tr>
    ),
  )

  const headers: ReactNode[] = buildWebhookEventsColumnHeaders(
    webhookEventsFilters.event,
  ).map((column) => (
    <div className={'column-header'} key={column}>
      {t(`webhook-events.page.table.headers.${column}`)}
      {column === 'subjectId' && (
        <Popup
          trigger={
            /* note: wrapping children into a div to properly hide/show the Popup
            related issue: https://github.com/Semantic-Org/Semantic-UI-React/issues/1413 */
            <div className={'tooltip'}>
              <InfoIcon />
            </div>
          }
          content={t('webhook-events.page.table.headers.subjectId-tooltip')}
        ></Popup>
      )}
    </div>
  ))

  const isFirstTimeLoading = loading && webhookEvents === null

  return (
    <Page className={'WebhookEventsPage'}>
      <div className={'events-container'}>
        <h3 className={'title'}>{t('webhook-events.page.title')}</h3>
        <p className={'description'}>{t('webhook-events.page.description')}</p>
        <WebhookEventFilters disabled={!teamHasWebhooksCreated} />
        {/* TODO: review why the request fails, but if we have data and we have an error
         re-validating don't show an error message since it blinks the UI */}
        {error && !webhookEvents && <Alert type={'error'} message={error} />}
        {teamHasWebhooksCreated ? (
          (tableRows !== undefined && tableRows.length > 0) ||
          isFirstTimeLoading ? (
            <>
              <div className={'table-container'}>
                <Table
                  rows={tableRows || []}
                  headers={headers}
                  loading={isFirstTimeLoading}
                />
              </div>
              <PageControls />
            </>
          ) : (
            <div className={'no-data'}>{t('webhook-events.page.no-data')}</div>
          )
        ) : (
          <NoWebhooksCreatedSection />
        )}
      </div>
    </Page>
  )
}

export default memo(WebhookEventsPage)
