const CURRENT_TEAM_ID_KEY = 'currentTeamId'

export function saveCurrentTeamId(teamId: string | null): void {
  // store selected teamId, or 'null' if none (user doesn't belong to any)
  if (!teamId) {
    // no teamId selected, remove it from storage
    localStorage.removeItem(CURRENT_TEAM_ID_KEY)
    return
  }
  localStorage.setItem(CURRENT_TEAM_ID_KEY, teamId)
}

export function retrieveCurrentTeamId(): string | null {
  return localStorage.getItem(CURRENT_TEAM_ID_KEY)
}
