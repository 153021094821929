import { Subscription } from '../team/types'

export type User = {
  id: string
  token: string
  auth0Id: string
  name: string
  email: string
  emailVerified: boolean
  imageUrl: string | null
  termsAccepted: boolean
  createdAt: string
  updatedAt: string
  subscription: Subscription
  companyRole: CompanyRole | null
  uiState: UiState | null
  platforms: string[] | null
  // hubspot Visitor ID token, only available on user /login request
  hubspotVisitorIdToken?: string
}

export type UpdateUserTermsRequest = {
  termsAccepted: true
}

export type UpdateUserFields = Partial<
  Pick<User, 'imageUrl' | 'companyRole' | 'termsAccepted' | 'platforms'>
> & { uiState?: Partial<UiState> }

export type UserFieldErrors = Partial<UpdateUserFields>

export const COMPANY_ROLES = [
  'DEVELOPER',
  'PRODUCT_MANAGER',
  'CUSTOMER_SUPPORT',
  'SALES',
  'OTHER',
]

export type CompanyRole = typeof COMPANY_ROLES[number]

type UiState = {
  userHasClosedWelcomeWidget?: boolean
  userHasClosedThemeModal?: boolean
  userHasClosedFreeTrialExpiredModal?: boolean
  theme?: Theme
}

export type Theme = 'light' | 'dark'
