import { connect } from 'react-redux'

import {
  DELETE_APPLICATION_REQUEST,
  deleteApplicationRequest,
} from '../../../../modules/application/actions'
import {
  getApplication,
  getError,
  isLoadingApplicationSelector,
} from '../../../../modules/application/selectors'
import { RootState } from '../../../../modules/reducer'
import ApplicationDeleteConfirmModal from './ApplicationDeleteConfirmModal'
import {
  MapDispatch,
  MapDispatchProps,
  MapStateProps,
} from './ApplicationDeleteConfirmModal.types'

const mapState = (state: RootState): MapStateProps => ({
  error: getError(state),
  isLoading: isLoadingApplicationSelector(
    state,
    DELETE_APPLICATION_REQUEST,
  )(getApplication(state)),
})

const mapDispatch = (dispatch: MapDispatch): MapDispatchProps => ({
  onDeleteApplication: (id: string) => dispatch(deleteApplicationRequest(id)),
})

export default connect(mapState, mapDispatch)(ApplicationDeleteConfirmModal)
