import { PLUGGY_DEMO_ORIGIN_URL } from '../../../lib/constants/urls'

/**
 * Determines whether an origin is the default Pluggy Demo Url
 * @param url - the origin url
 * @param index - the index of the url in the origins array
 * @returns {boolean}
 */
export function isDefaultPluggyDemoOrigin(url: string, index: number): boolean {
  return url === PLUGGY_DEMO_ORIGIN_URL && index === 0
}

/**
 * Determines whether an allowedOrigins array only includes the default pluggy demo url
 * @param origins - the array of allowed origins
 * @returns {boolean}
 */
export function demoIsOnlyOrigin(origins: string[]): boolean {
  return origins.length === 1 && origins[0] === PLUGGY_DEMO_ORIGIN_URL
}
