import React from 'react'

import { Page } from '../Page'
import { TeamsList } from './TeamsList'
import { UserDangerZone } from './UserDangerZone'
import { UserDetailsSection } from './UserDetailsSection'

import './UserSettingsPage.css'

const UserSettingsPage = () => {
  return (
    <Page className={'UserSettingsPage'}>
      <UserDetailsSection />
      <TeamsList />
      <UserDangerZone />
    </Page>
  )
}

export default React.memo(UserSettingsPage)
