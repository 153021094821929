import React, { useCallback, useState } from 'react'

import { Menu } from 'semantic-ui-react'

import { MenuIcon } from '../../Icon'
import { DocumentationLinks } from '../DocumentationLinks'
import { LogoutItem } from '../LogoutItem'
import { StatusPageItem } from '../StatusPageItem'
import { ThemeSwitchItem } from '../ThemeSwitchItem'
import { UserSettingsItem } from '../UserSettingsItem'
import { Props } from './FloatingMenu.types'

import './FloatingMenu.css'

const FloatingMenu = ({ onLogout, onNavigate }: Props): JSX.Element => {
  const [isFloatingMenuOpen, setIsFloatingMenuOpen] = useState(false)

  const toggleFloatingMenuVisibility = useCallback(() => {
    setIsFloatingMenuOpen((previIsFloatingMenuOpen) => !previIsFloatingMenuOpen)
  }, [])

  const handleCloseFloatingMenu = useCallback(
    () => setIsFloatingMenuOpen(false),
    [],
  )
  const handleEscapePress = useCallback(
    (event) => {
      if (event.key !== 'Escape') {
        return
      }
      handleCloseFloatingMenu()
    },
    [handleCloseFloatingMenu],
  )

  return (
    <div className={'FloatingMenu'}>
      <MenuIcon onClick={toggleFloatingMenuVisibility} />
      {isFloatingMenuOpen && (
        <>
          <div
            className="floating-menu-backdrop"
            onClick={handleCloseFloatingMenu}
            onKeyDown={handleEscapePress}
            role="button"
            tabIndex={0}
          />
          <Menu className="MobileMenu" vertical>
            <DocumentationLinks />
            <StatusPageItem as={Menu.Item} />
            <UserSettingsItem as={Menu.Item} onNavigate={onNavigate} />
            <ThemeSwitchItem as={Menu.Item} />
            <LogoutItem as={Menu.Item} onLogout={onLogout} />
          </Menu>
        </>
      )}
    </div>
  )
}

export default React.memo(FloatingMenu)
