import { loadingReducer } from '../loading/reducer'
import { LoadingState } from '../loading/types'
import {
  FETCH_BILLING_DASHBOARD_FAILURE,
  FETCH_BILLING_DASHBOARD_REQUEST,
  FETCH_BILLING_DASHBOARD_SUCCESS,
  FetchBillingDashboardFailureAction,
  FetchBillingDashboardRequestAction,
  FetchBillingDashboardSuccessAction,
} from './actions'

export type BillingState = {
  data: string | null
  loading: LoadingState
  error: Error | null
}

const INITIAL_STATE: BillingState = {
  data: null,
  loading: [],
  error: null,
}

type NotificationReducerAction =
  | FetchBillingDashboardRequestAction
  | FetchBillingDashboardSuccessAction
  | FetchBillingDashboardFailureAction

export function billingReducer(
  state: BillingState = INITIAL_STATE,
  action: NotificationReducerAction,
): BillingState {
  switch (action.type) {
    case FETCH_BILLING_DASHBOARD_REQUEST:
      return {
        ...state,
        error: null,
        loading: loadingReducer(state.loading, action),
      }

    case FETCH_BILLING_DASHBOARD_SUCCESS:
      const { billingDashboard } = action.payload
      return {
        ...state,
        data: billingDashboard,
        loading: loadingReducer(state.loading, action),
      }

    case FETCH_BILLING_DASHBOARD_FAILURE: {
      const { error } = action.payload
      return {
        ...state,
        error,
        loading: loadingReducer(state.loading, action),
      }
    }
    default:
      return state
  }
}
