import React from 'react'

import { Alert } from '@pluggyai/ui'

import { ConnectorsStatus } from '../ConnectorsStatus'
import { FreeTrialAlert } from '../FreeTrialAlert'
import { Page } from '../Page'
import { Stats } from '../Stats'
import { ThemeModal } from '../ThemeModal'
import { WelcomeWidget } from '../WelcomeWidget'
import { Props } from './OverviewPage.types'
import { RecentActivity } from './RecentActivity'

import './OverviewPage.css'

const OverviewPage = ({ statsError }: Props) => (
  <Page className={'OverviewPage'}>
    <WelcomeWidget />
    {statsError && (
      <Alert type={'error'} message={statsError} size={'medium'} />
    )}
    <FreeTrialAlert />
    <ThemeModal />
    <Stats />
    <ConnectorsStatus />

    <RecentActivity />
  </Page>
)

export default React.memo(OverviewPage)
