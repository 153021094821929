import React, { useCallback, useMemo, useState } from 'react'
import { useTranslation } from 'react-i18next'

import { Button, DropdownOption, Input } from '@pluggyai/ui'
import { Header, Table } from 'semantic-ui-react'

import { TrackEventName } from '../../../../modules/analytics/events'
import { track } from '../../../../modules/analytics/utils'
import {
  isTeamMember,
  TEAM_ROLES,
  TeamInvitation,
  TeamMember,
  TeamRole,
} from '../../../../modules/team/types'
import { Avatar } from '../../../Avatar'
import { DeleteIcon } from '../../../Icon/DeleteIcon'
import { Section } from '../../../Section'
import { TeamAddMembersModal } from '../../../TeamAddMembersModal'
import { teamRoleToString } from '../../../TeamForm/TeamMembersEmailsInput/utils'
import { NoOwnersLeftErrorModal } from './NoOwnersLeftErrorModal'
import { Props, RoleOption } from './TeamMembersSection.types'
import { sortByEmail } from './utils'

import './TeamMembersSection.css'

const TeamMembersSection = ({
  team,
  userTeamMemberRole,
  isLoading,
  isFeaturesLimited,
  onDeleteTeamMember,
  onTeamMemberRoleUpdate,
  onResendTeamInvitation,
}: Props) => {
  const { t } = useTranslation()

  const [addMemberModalOpen, setAddMemberModalOpen] = useState(false)

  const handleAddMembersModalOpen = useCallback(() => {
    setAddMemberModalOpen(true)
    track(TrackEventName.LINK_TEAM_INVITE_MEMBERS, {
      location: 'SettingsPage',
    })
  }, [])

  const handleAddMembersModalClose = useCallback(() => {
    setAddMemberModalOpen(false)
  }, [])

  const teamMembersList: (TeamMember | TeamInvitation)[] | null = useMemo(
    () =>
      team && [...sortByEmail(team.members), ...sortByEmail(team.invitations)],
    [team],
  )

  const [noOwnersLeftErrorModalVisible, setNoOwnersLeftErrorModalVisible] =
    useState(false)

  const handleNoOwnersLeftErrorModalClose = useCallback(() => {
    track(TrackEventName.ICON_CLICKED, {
      location: 'SettingsPage',
      icon: 'CloseIcon',
    })
    setNoOwnersLeftErrorModalVisible(false)
  }, [])

  const handleNoOwnersLeftErrorModalGoBack = useCallback(
    ({ text, i18nKey }: { text: string; i18nKey: string }) => {
      track(TrackEventName.BUTTON_CLICKED, {
        location: 'UserSettingsPage TeamsList',
        text,
        i18nKey,
      })

      setNoOwnersLeftErrorModalVisible(false)
    },
    [],
  )

  const onTeamMemberRoleChange = useCallback(
    (roleValue: TeamRole, index: number) => {
      if (!team || !teamMembersList) {
        return
      }
      const member = teamMembersList[index]

      if (isTeamMember(member)) {
        const current = member.teamUser.role
        if (current === roleValue) {
          // no change
          return
        }
        track(TrackEventName.TEAM_MEMBER_ROLE_CHANGE, {
          previous: current,
          value: roleValue,
          member,
          location: 'SettingsPage',
        })

        // check if no OWNER members left, in that case show error modal
        const currentOwnerMembers = team.members.filter(
          (member_) => member_.teamUser.role === 'OWNER',
        )

        if (
          currentOwnerMembers.length === 1 &&
          roleValue !== 'OWNER' &&
          currentOwnerMembers[0].id === member.id
        ) {
          // last OWNER member role is being changed to other
          setNoOwnersLeftErrorModalVisible(true)
          return
        }
      } else {
        const current = member.role
        if (current === roleValue) {
          // no change
          return
        }
        track(TrackEventName.TEAM_INVITATION_ROLE_CHANGE, {
          previous: current,
          value: roleValue,
          invitation: member,
          location: 'SettingsPage',
        })
      }

      onTeamMemberRoleUpdate(team, member, roleValue)
    },
    [team, teamMembersList, onTeamMemberRoleUpdate],
  )

  const handleDeleteTeamMemberClick = useCallback(
    (teamMember: TeamMember | TeamInvitation) => {
      if (!team) {
        return
      }
      if (isTeamMember(teamMember)) {
        track(TrackEventName.CTA_TEAM_MEMBER_DELETE, {
          team,
          member: teamMember,
        })

        const lastOwnerMemberIsBeingDeleted = team.members
          .filter(({ teamUser: { role } }) => role === 'OWNER')
          .every(({ id }) => id === teamMember.id)

        if (lastOwnerMemberIsBeingDeleted) {
          setNoOwnersLeftErrorModalVisible(true)
          return
        }
      } else {
        track(TrackEventName.CTA_TEAM_MEMBER_DELETE, {
          team,
          invitation: teamMember,
        })
      }

      onDeleteTeamMember(team, teamMember)
    },
    [team, onDeleteTeamMember],
  )

  const handleResendInvitationClick = useCallback(
    (invitation: TeamInvitation) => {
      if (!team) {
        return
      }
      onResendTeamInvitation(team, invitation)
    },
    [team, onResendTeamInvitation],
  )

  const isOwnerOrAdminRole = ['OWNER', 'ADMIN'].includes(
    String(userTeamMemberRole),
  )
  const isAdminRole = userTeamMemberRole === 'ADMIN'

  const rolesOptions: RoleOption[] = TEAM_ROLES.map((roleValue) => ({
    isDisabled: isAdminRole && roleValue === 'OWNER',
    id: roleValue,
    name: teamRoleToString(roleValue),
  }))

  const handleTeamRoleChange = useCallback(
    (value: DropdownOption | DropdownOption[], index: number): void => {
      // cast to to RoleOption to handle value as array of DropdownOption
      const { id: selectedRole } = value as RoleOption

      onTeamMemberRoleChange(selectedRole, index)
    },
    [onTeamMemberRoleChange],
  )

  return (
    <Section className={'TeamMembersSection'}>
      <Header as={'h2'}>
        {t('settings.team.members.header')}
        {isOwnerOrAdminRole && (
          <Button
            link
            onClick={handleAddMembersModalOpen}
            disabled={isFeaturesLimited}
          >
            {t('overview.profile.team.action.add-members')}
          </Button>
        )}
        {addMemberModalOpen && (
          <TeamAddMembersModal onClose={handleAddMembersModalClose} />
        )}
      </Header>

      <Table>
        <Table.Header>
          <Table.Row>
            <Table.HeaderCell>
              {t('settings.team.members.data-table.headers.member')}
            </Table.HeaderCell>
            <Table.HeaderCell>
              {t('settings.team.members.data-table.headers.role')}
            </Table.HeaderCell>
            {/* Member actions ('Resend invitation' & 'Remove') buttons column header (empty) */}
            <Table.HeaderCell />
          </Table.Row>
        </Table.Header>
        {teamMembersList ? (
          <Table.Body>
            {teamMembersList.map((teamMemberItem, i) => {
              let picture: string | undefined | null
              let role: TeamRole
              if (isTeamMember(teamMemberItem)) {
                const {
                  imageUrl: memberPicture,
                  teamUser: { role: memberRole },
                } = teamMemberItem
                picture = memberPicture
                role = memberRole
              } else {
                const { role: invitationRole } = teamMemberItem
                picture = null
                role = invitationRole
              }

              const currentRoleOption = rolesOptions.find(
                ({ id }) => id === role,
              )
              return (
                <Table.Row key={teamMemberItem.id}>
                  <Table.Cell>
                    <div className={'member-profile'}>
                      <Avatar
                        pictureUrl={picture}
                        className={picture === null ? 'blank' : ''}
                      />
                      <span>{teamMemberItem.email}</span>
                    </div>
                  </Table.Cell>
                  <Table.Cell className={'TeamMembersEmailsInput'}>
                    <Input
                      type="select"
                      label={t('team.form.field.members.field.role.label')}
                      disabled={
                        isFeaturesLimited ||
                        isLoading ||
                        (isAdminRole && role === 'OWNER') // ADMIN can't edit an OWNER's role
                      }
                      options={rolesOptions}
                      value={currentRoleOption}
                      onChange={({
                        value,
                      }: {
                        value: DropdownOption | DropdownOption[]
                      }) => handleTeamRoleChange(value, i)}
                    />
                  </Table.Cell>
                  <Table.Cell textAlign="right" className={'member-actions'}>
                    {isOwnerOrAdminRole && !isTeamMember(teamMemberItem) && (
                      <Button
                        text
                        disabled={isFeaturesLimited || isLoading}
                        onClick={() =>
                          handleResendInvitationClick(teamMemberItem)
                        }
                      >
                        {t(
                          'settings.team.members.data-table.action.resend-invitation',
                        )}
                      </Button>
                    )}

                    {isOwnerOrAdminRole && (
                      <Button
                        text
                        disabled={
                          isFeaturesLimited ||
                          isLoading ||
                          (isAdminRole && role === 'OWNER') // ADMIN can't delete OWNER
                        }
                        onClick={() =>
                          handleDeleteTeamMemberClick(teamMemberItem)
                        }
                      >
                        <DeleteIcon />
                        {t('settings.team.members.data-table.action.remove')}
                      </Button>
                    )}
                  </Table.Cell>
                </Table.Row>
              )
            })}
          </Table.Body>
        ) : (
          <div>{t('settings.team.members.data-table.empty')}</div>
        )}
      </Table>
      {noOwnersLeftErrorModalVisible && (
        <NoOwnersLeftErrorModal
          onClose={handleNoOwnersLeftErrorModalClose}
          onGoBack={handleNoOwnersLeftErrorModalGoBack}
        />
      )}
    </Section>
  )
}

export default React.memo(TeamMembersSection)
