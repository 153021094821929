import React from 'react'
import { useTranslation } from 'react-i18next'

import { Popup } from 'semantic-ui-react'

import { Props } from './StatusBar.types'

import './StatusBar.css'

const StatusBar = ({ status }: Props) => {
  const { t } = useTranslation()

  const barClassNames = `StatusBar ${status}`
  return (
    <Popup
      className={'StatusBar-popup'}
      position={'top center'}
      content={t(
        `connectorsStatus.connectors-status-list.connector-row.status-bar.popup.${status}`,
      )}
      trigger={<div className={barClassNames} />}
    />
  )
}

export default React.memo(StatusBar)
