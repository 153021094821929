import i18n from 'i18next'

import { UpdateUserFields } from '../../../modules/user/types'
import { isValidUrl } from '../../../utils/validation'

const validations: Record<
  keyof Pick<UpdateUserFields, 'imageUrl'>,
  (fieldName: string, value: string) => string | undefined
> = {
  imageUrl: (fieldName: string, value: string): string | undefined => {
    if (typeof value !== 'string') {
      throw new Error('Not a string value')
    }
    if (value.length > 0 && !isValidUrl(value)) {
      return i18n.t('user.form.error.url', {
        fieldName,
      })
    }
    return undefined
  },
}

export function validateUserField(
  fieldName: keyof Pick<UpdateUserFields, 'imageUrl'>,
  value: string,
): string | undefined {
  const fieldLabel = i18n.t(`user.form.field.${fieldName}.label`)
  return validations[fieldName](fieldLabel, value)
}
