import { captureException } from '@sentry/react'
import axios, { AxiosResponse } from 'axios'
import i18n from 'i18next'
import { all, put, select, takeEvery } from 'redux-saga/effects'

import { ItemService } from '../../lib/api/ItemService'
import { getAuth0AccessToken } from '../auth0/selectors'
import { addNotificationAction } from '../notification/actions'
import { NotificationOptions } from '../notification/types'
import { getCurrentTeam } from '../team/selectors'
import { Team } from '../team/types'
import {
  fetchItemFailure,
  FetchItemFailureAction,
  FetchItemRequestAction,
  fetchItemSuccess,
  FETCH_ITEM_FAILURE,
  FETCH_ITEM_REQUEST,
} from './actions'
import { ItemWithExecutions } from './types'

function* handleFetchItemRequest({ payload: itemId }: FetchItemRequestAction) {
  try {
    const accessToken: string = yield select(getAuth0AccessToken)
    if (!accessToken) {
      throw new Error('No access token in state, can not get item')
    }
    const itemService: ItemService = new ItemService(accessToken)

    const currentTeam: Team | null = yield select(getCurrentTeam)

    if (!currentTeam) {
      throw new Error('No current team in state, can not get item')
    }

    const { data: item }: AxiosResponse<ItemWithExecutions> =
      yield itemService.getItem(itemId, currentTeam.id)

    yield put(fetchItemSuccess(item))
  } catch (error) {
    yield put(fetchItemFailure(error as Error, itemId))
  }
}

function* handleFetchItemFailure(action: FetchItemFailureAction) {
  const { error, itemId } = action.payload

  const isErrorResponseNotFound =
    axios.isAxiosError(error) && error.response?.status === 404
  const errorResponseI18nKey = isErrorResponseNotFound
    ? 'not-found' // item not found
    : 'unexpected' // unexpected error or network error

  const notificationOptions: NotificationOptions = {
    level: 'error',
    title: i18n.t(
      `item-executions-page.alert-error.${errorResponseI18nKey}.title`,
    ),
    message: i18n.t(
      `item-executions-page.alert-error.${errorResponseI18nKey}.message`,
      {
        itemId,
      },
    ),
  }

  captureException(error, {
    contexts: {
      error: {
        action,
        object: error,
      },
    },
  })

  yield put(addNotificationAction(notificationOptions))
}

export function* itemSaga() {
  yield all([
    takeEvery(FETCH_ITEM_REQUEST, handleFetchItemRequest),
    takeEvery(FETCH_ITEM_FAILURE, handleFetchItemFailure),
  ])
}
