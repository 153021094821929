import React from 'react'
import { useTranslation } from 'react-i18next'

import { Popup } from 'semantic-ui-react'

import { Props } from './WebhooksDisabledPopup.types'

import './WebhooksDisabledPopup.css'

const WebhooksDisabledPopup = ({
  isApplicationEnabled,
  isTeamMemberRole,
  children,
  className,
  messagePosition,
  webhookDisabled,
}: Props) => {
  const { t } = useTranslation()

  let popupMessage

  if (isTeamMemberRole) {
    popupMessage = t(
      'application.page.details.webhooks.disabled-popup.features-limited',
    )
  } else if (!isApplicationEnabled) {
    popupMessage = t(
      'application.page.details.webhooks.disabled-popup.application-disabled',
    )
  } else if (webhookDisabled) {
    popupMessage = t(
      'application.page.details.webhooks.disabled-popup.webhook-disabled',
    )
  }

  const userHasAllPermissions =
    isApplicationEnabled && !isTeamMemberRole && !webhookDisabled

  return (
    <Popup
      className={'IsDisabledPopup-Popup'}
      position={messagePosition || 'top right'}
      content={popupMessage}
      disabled={userHasAllPermissions}
      /* note: wrapping children into a div to properly hide/show the Popup even if 'disabled'
            related issue: https://github.com/Semantic-Org/Semantic-UI-React/issues/1413 */
      trigger={
        <div className={`IsDisabledPopup ${className || ''}`}>{children}</div>
      }
    />
  )
}

export default React.memo(WebhooksDisabledPopup)
