import { action } from 'typesafe-actions'

import { Auth0Result } from '../auth0/types'
import { UpdateUserFields, User } from './types'

// Fetch user from auth-api

export const FETCH_USER_REQUEST = '[Request] Fetch User'
export const FETCH_USER_SUCCESS = '[Success] Fetch User'
export const FETCH_USER_FAILURE = '[Failure] Fetch User'

export const fetchUserRequest = (auth0Result: Auth0Result) =>
  action(FETCH_USER_REQUEST, { auth0Result })
export const fetchUserSuccess = (user: User) =>
  action(FETCH_USER_SUCCESS, { user })
export const fetchUserFailure = (error: string) =>
  action(FETCH_USER_FAILURE, { error })

export type FetchUserRequestAction = ReturnType<typeof fetchUserRequest>
export type FetchUserSuccessAction = ReturnType<typeof fetchUserSuccess>
export type FetchUserFailureAction = ReturnType<typeof fetchUserFailure>

/// Impersonate User Request

export const IMPERSONATE_USER_REQUEST = '[Request] Impersonate User'
export const IMPERSONATE_USER_SUCCESS = '[Success] Impersonate User'
export const IMPERSONATE_USER_FAILURE = '[Failure] Impersonate User'

export const impersonateUserRequest = (email: string | null) =>
  action(IMPERSONATE_USER_REQUEST, { email })
export const impersonateUserSuccess = (user: User | null) =>
  action(IMPERSONATE_USER_SUCCESS, { user })
export const impersonateUserFailure = (error: string) =>
  action(IMPERSONATE_USER_FAILURE, { error })

export type ImpersonateUserRequestAction = ReturnType<
  typeof impersonateUserRequest
>
export type ImpersonateUserSuccessAction = ReturnType<
  typeof impersonateUserSuccess
>
export type ImpersonateUserFailureAction = ReturnType<
  typeof impersonateUserFailure
>

// Dismiss user actions required Action

export const SET_SHOW_USER_ACTIONS_REQUIRED = 'Set Show User Actions Required'
export const setShowUserActionsRequired = (showUserActionsRequired: boolean) =>
  action(SET_SHOW_USER_ACTIONS_REQUIRED, { showUserActionsRequired })

export type SetShowUserActionsRequired = ReturnType<
  typeof setShowUserActionsRequired
>

/// Update User Terms Accepted request

export const UPDATE_USER_TERMS_ACCEPTED_REQUEST =
  '[Request] Update User Terms Accepted'
export const UPDATE_USER_TERMS_ACCEPTED_SUCCESS =
  '[Success] Update User Terms Accepted'
export const UPDATE_USER_TERMS_ACCEPTED_FAILURE =
  '[Failure] Update User Terms Accepted'

export const updateUserTermsAcceptedRequest = () =>
  action(UPDATE_USER_TERMS_ACCEPTED_REQUEST)
export const updateUserTermsAcceptedSuccess = (user: User) =>
  action(UPDATE_USER_TERMS_ACCEPTED_SUCCESS, { user })
export const updateUserTermsAcceptedFailure = (error: string) =>
  action(UPDATE_USER_TERMS_ACCEPTED_FAILURE, { error })

export type UpdateUserTermsAcceptedRequestAction = ReturnType<
  typeof updateUserTermsAcceptedRequest
>
export type UpdateUserTermsAcceptedSuccessAction = ReturnType<
  typeof updateUserTermsAcceptedSuccess
>
export type UpdateUserTermsAcceptedFailureAction = ReturnType<
  typeof updateUserTermsAcceptedFailure
>

/**
 *  Resend Verification Email Request
 */

export const RESEND_VERIFICATION_EMAIL_REQUEST =
  '[Request] Resend Verification Email'
export const RESEND_VERIFICATION_EMAIL_SUCCESS =
  '[Success] Resend Verification Email'
export const RESEND_VERIFICATION_EMAIL_FAILURE =
  '[Failure] Resend Verification Email'

export const resendVerificationEmailRequest = () =>
  action(RESEND_VERIFICATION_EMAIL_REQUEST)
export const resendVerificationEmailSuccess = () =>
  action(RESEND_VERIFICATION_EMAIL_SUCCESS)
export const resendVerificationEmailFailure = (error: string) =>
  action(RESEND_VERIFICATION_EMAIL_FAILURE, { error })

export type ResendVerificationEmailRequestAction = ReturnType<
  typeof resendVerificationEmailRequest
>
export type ResendVerificationEmailSuccessAction = ReturnType<
  typeof resendVerificationEmailSuccess
>
export type ResendVerificationEmailFailureAction = ReturnType<
  typeof resendVerificationEmailFailure
>

/**
 *  Update User Request
 */

export const UPDATE_USER_REQUEST = '[Request] Update User'
export const UPDATE_USER_SUCCESS = '[Success] Update User'
export const UPDATE_USER_FAILURE = '[Failure] Update User'

export const updateUserRequest = (fields: UpdateUserFields) =>
  action(UPDATE_USER_REQUEST, { fields })
export const updateUserSuccess = (user: User) =>
  action(UPDATE_USER_SUCCESS, { user })
export const updateUserFailure = (error: string) =>
  action(UPDATE_USER_FAILURE, { error })

export type UpdateUserRequestAction = ReturnType<typeof updateUserRequest>
export type UpdateUserSuccessAction = ReturnType<typeof updateUserSuccess>
export type UpdateUserFailureAction = ReturnType<typeof updateUserFailure>

/**
 *  Delete User Request
 */

export const DELETE_USER_REQUEST = '[Request] Delete User'
export const DELETE_USER_SUCCESS = '[Success] Delete User'
export const DELETE_USER_FAILURE = '[Failure] Delete User'

export const deleteUserRequest = () => action(DELETE_USER_REQUEST)
export const deleteUserSuccess = () => action(DELETE_USER_SUCCESS)
export const deleteUserFailure = (error: string) =>
  action(DELETE_USER_FAILURE, { error })

export type DeleteUserRequestAction = ReturnType<typeof deleteUserRequest>
export type DeleteUserSuccessAction = ReturnType<typeof deleteUserSuccess>
export type DeleteUserFailureAction = ReturnType<typeof deleteUserFailure>
