import { connect } from 'react-redux'

import {
  fetchConnectorsRequest,
  fetchCustomizationRequest,
  FETCH_CONNECTORS_REQUEST,
  FETCH_CUSTOMIZATION_REQUEST,
} from '../../modules/customization/actions'
import {
  getConnectors,
  isLoadingType as isCustomizationLoadingType,
  getLoading as getCustomizationLoading,
  getCustomization,
} from '../../modules/customization/selectors'
import { RootState } from '../../modules/reducer'
import {
  fetchConnectorsStatsRequest,
  FETCH_CONNECTORS_STATS_REQUEST,
} from '../../modules/stats/actions'
import {
  getData as getConnectorsStatsData,
  getLoading as getStatsLoading,
  isLoadingType as isStatsLoadingType,
} from '../../modules/stats/selectors'
import { ConnectorsStatsRequest } from '../../modules/stats/types'
import { getCurrentTeam } from '../../modules/team/selectors'
import { getUser } from '../../modules/user/selectors'
import ConnectorsStatus from './ConnectorsStatus'
import {
  MapDispatchProps,
  MapDispatch,
  MapStateProps,
} from './ConnectorsStatus.types'

const mapState = (state: RootState): MapStateProps => ({
  isLoading:
    isCustomizationLoadingType(
      getCustomizationLoading(state),
      FETCH_CUSTOMIZATION_REQUEST,
    ) ||
    isCustomizationLoadingType(
      getCustomizationLoading(state),
      FETCH_CONNECTORS_REQUEST,
    ) ||
    isStatsLoadingType(getStatsLoading(state), FETCH_CONNECTORS_STATS_REQUEST),
  connectors: getConnectors(state),
  connectorsStats:
    getConnectorsStatsData(state).connectorsStats?.connectorsStats,
  customization: getCustomization(state),
  user: getUser(state),
  team: getCurrentTeam(state),
})

const mapDispatch = (dispatch: MapDispatch): MapDispatchProps => ({
  onFetchConnectors: () => dispatch(fetchConnectorsRequest()),
  onFetchCustomization: () => dispatch(fetchCustomizationRequest()),
  onFetchConnectorsStats: (filterableStats?: ConnectorsStatsRequest) =>
    dispatch(fetchConnectorsStatsRequest(filterableStats)),
})

export default connect(mapState, mapDispatch)(ConnectorsStatus)
