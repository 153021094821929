import { connect } from 'react-redux'

import { RootState } from '../../../modules/reducer'
import { FETCH_ITEMS_STATS_REQUEST } from '../../../modules/stats/actions'
import {
  getItemsStatsReportItemStats,
  getItemStatsFilters,
  getLoading as isLoadingStats,
  isLoadingType,
} from '../../../modules/stats/selectors'
import ConnectionStats from './ConnectionStats'
import {
  MapDispatch,
  MapDispatchProps,
  MapStateProps,
} from './ConnectionStats.types'

const mapState = (state: RootState): MapStateProps => ({
  isLoading: isLoadingType(isLoadingStats(state), FETCH_ITEMS_STATS_REQUEST),
  itemsStatsReport: getItemsStatsReportItemStats(state),
  startDate: getItemStatsFilters(state).startDate,
  endDate: getItemStatsFilters(state).endDate,
})

const mapDispatch = (_dispatch: MapDispatch): MapDispatchProps => ({
  // nothing yet
})

export default connect(mapState, mapDispatch)(ConnectionStats)
