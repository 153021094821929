import { connectRouter } from 'connected-react-router'
import { History } from 'history'
import { combineReducers, Reducer } from 'redux'

import { activityReducer as activity } from './activity/reducer'
import { applicationReducer as application } from './application/reducer'
import { auth0Reducer as auth0 } from './auth0/reducer'
import { billingReducer as billing } from './billing/reducer'
import { connectTokenReducer as connectToken } from './connectToken/reducer'
import { customizationReducer as customization } from './customization/reducer'
import { goToProductionReducer as goToProduction } from './goToProduction/reducer'
import { itemReducer as item } from './item/reducer'
import { logoImageReducer as logoImage } from './logoImage/reducer'
import { noCodeReducer as noCode } from './noCode/reducer'
import { notificationReducer as notification } from './notification/reducer'
import { statsReducer as stats } from './stats/reducer'
import { teamReducer as team } from './team/reducer'
import { userReducer as user } from './user/reducer'
import { webhookReducer as webhook } from './webhook/reducer'
import { webhookEventsReducer as webhookEvents } from './webhookEvents/reducer'

export const createRootReducer = (history: History): Reducer =>
  combineReducers({
    activity,
    auth0,
    billing,
    application,
    customization,
    router: connectRouter(history),
    notification,
    stats,
    team,
    user,
    connectToken,
    goToProduction,
    noCode,
    webhook,
    webhookEvents,
    logoImage,
    item,
  })

export type RootState = ReturnType<ReturnType<typeof createRootReducer>>
