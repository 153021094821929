import React from 'react'

import Icon from '../Icon'

import './PlusIcon.css'

type Props = {
  className?: string
  onClick?: () => void
}

const PlusIcon = ({ className, onClick }: Props) => {
  return (
    <Icon
      width="20"
      height="20"
      viewBox="0 0 20 20"
      fill="none"
      onClick={onClick}
      className={`PlusIcon ${className || ''}`}
    >
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M17 10C17 10.5523 16.5523 11 16 11H11V16C11 16.5523 10.5523 17 10 17C9.44772 17 9 16.5523 9 16V11H4C3.44772 11 3 10.5523 3 10C3 9.44772 3.44772 9 4 9H9V4C9 3.44772 9.44772 3 10 3C10.5523 3 11 3.44772 11 4V9H16C16.5523 9 17 9.44772 17 10Z"
        fill="#F5F5F5"
      />
      <mask
        id="mask0_0_832"
        style={{ maskType: 'alpha' }}
        maskUnits="userSpaceOnUse"
        x="3"
        y="3"
        width="14"
        height="14"
      >
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M17 10C17 10.5523 16.5523 11 16 11H11V16C11 16.5523 10.5523 17 10 17C9.44772 17 9 16.5523 9 16V11H4C3.44772 11 3 10.5523 3 10C3 9.44772 3.44772 9 4 9H9V4C9 3.44772 9.44772 3 10 3C10.5523 3 11 3.44772 11 4V9H16C16.5523 9 17 9.44772 17 10Z"
          fill="white"
        />
      </mask>
      <g mask="url(#mask0_0_832)"></g>
    </Icon>
  )
}

export default React.memo(PlusIcon)
