import React, {
  MouseEvent,
  useCallback,
  useEffect,
  useMemo,
  useState,
} from 'react'
import { Trans, useTranslation } from 'react-i18next'

import { FilePickerArea, Input, Header } from '@pluggyai/ui'
import { Option } from '@pluggyai/ui/dist/components/Dropdown/Dropdown.types'

import { PLUGGY_CUSTOMIZATION_DOCS_URL } from '../../../lib/constants/urls'
import { TrackEventName } from '../../../modules/analytics/events'
import { track } from '../../../modules/analytics/utils'
import {
  GeneralCustomizationFields,
  GeneralCustomizationFieldsErrors,
} from '../../../modules/customization/types'
import { IsDisabledPopup } from '../IsDisabledPopup'
import { Props } from './GeneralCustomizationForm.types'
import {
  buildConnectFormStepButtonOptions,
  buildWelcomeStepButtonOptions,
} from './utils'

import './GeneralCustomizationForm.css'

const PLUGGY_LOGO_URL = 'https://pluggy.ai/logo.png'

const INITIAL_GENERAL_CUSTOMIZATION_FIELDS: GeneralCustomizationFields = {
  welcomeText: '',
  primaryColor: '',
  logoImageUrl: '',
  borderRadius: '10',
}

const LOGO_IMAGE_ACCEPTED_FILE_FORMATS = ['.png', '.jpeg', '.jpg']

const GeneralCustomizationForm = ({
  isLoading,
  isFreeSubscription,
  isTeamOwnerOrAdminRole,
  handleGeneralCustomizationFieldChange,
  customization,
  formErrors,
  hasCustomCompanyDeviceName,
  handleFormErrorsChange,
  onUploadLogoImageFile,
  isLoadingLogoImageUpload,
}: Props) => {
  const { t } = useTranslation()

  const [logoImageFile, setLogoImageFile] = useState<File | null>(null)

  const [generalCustomizationValues, setGeneralCustomizationValues] =
    useState<GeneralCustomizationFields>(INITIAL_GENERAL_CUSTOMIZATION_FIELDS)

  useEffect(() => {
    // set the customization values every time it changes
    setGeneralCustomizationValues({
      welcomeText: customization?.welcomeText || '',
      primaryColor: customization?.primaryColor || '',
      logoImageUrl: customization?.logoImageUrl || '',
      borderRadius:
        customization?.borderRadius !== undefined &&
        customization.borderRadius !== null
          ? customization.borderRadius
          : '10',
      connectFormStepButtonKey:
        customization?.connectFormStepButtonKey || 'CONNECT',
      welcomeStepButtonKey: customization?.welcomeStepButtonKey || 'CONTINUE',
    })
  }, [customization])

  const handleFieldChange = useCallback(
    (
      fieldName: keyof GeneralCustomizationFieldsErrors,
      value: string | string[],
    ) => {
      if (formErrors[fieldName]) {
        delete formErrors[fieldName]
        handleFormErrorsChange({ ...formErrors })
      }
      const newGeneralCustomizationValues = {
        ...generalCustomizationValues,
        [fieldName]: value,
      }
      setGeneralCustomizationValues(newGeneralCustomizationValues)
      handleGeneralCustomizationFieldChange(fieldName, value)
    },
    [
      formErrors,
      generalCustomizationValues,
      handleGeneralCustomizationFieldChange,
      handleFormErrorsChange,
    ],
  )

  const handlePrimaryColorChange = useCallback(
    ({ value }: { value: string }) => {
      handleFieldChange('primaryColor', value)
    },
    [handleFieldChange],
  )

  const handleBorderRadiusChange = useCallback(
    ({ value }: { value: string }) => {
      handleFieldChange('borderRadius', value)
    },
    [handleFieldChange],
  )

  const handleLogoImageFileChange = useCallback(
    (file: File | null) => {
      setLogoImageFile(file)

      if (file === null) {
        // user removed the file
        // we set the logoImageUrl to default value
        handleFieldChange('logoImageUrl', PLUGGY_LOGO_URL)
        return
      }

      setGeneralCustomizationValues((prevGeneralCustomizationValues) => ({
        ...prevGeneralCustomizationValues,
        logoImageUrl: '',
      }))

      onUploadLogoImageFile(file)
    },
    [handleFieldChange, onUploadLogoImageFile],
  )

  const handleDocsLinkClick = useCallback(
    ({ target }: MouseEvent<HTMLAnchorElement>) => {
      track(TrackEventName.LINK_CLICKED, {
        location: 'CustomizationPage GeneralCustomizationForm',
        text: (target as HTMLAnchorElement).textContent,
        linkTo: PLUGGY_CUSTOMIZATION_DOCS_URL,
      })
    },
    [],
  )

  const handleLogoImageUrlChange = useCallback(
    ({ value }: { name?: string | undefined; value: string }): void => {
      setLogoImageFile(null)
      handleFieldChange('logoImageUrl', value)
    },
    [handleFieldChange],
  )

  const isDisabled = isLoading || isFreeSubscription || !isTeamOwnerOrAdminRole

  const welcomeStepButtonOptions = buildWelcomeStepButtonOptions()
  const connectFormStepButtonOptions = buildConnectFormStepButtonOptions()

  const welcomeStepButtonOption = useMemo(
    () =>
      welcomeStepButtonOptions.find(
        ({ id }) => id === generalCustomizationValues.welcomeStepButtonKey,
      ),
    [generalCustomizationValues.welcomeStepButtonKey, welcomeStepButtonOptions],
  )

  const connectFormStepButtonOption = useMemo(
    () =>
      connectFormStepButtonOptions.find(
        ({ id }) => id === generalCustomizationValues.connectFormStepButtonKey,
      ),
    [
      connectFormStepButtonOptions,
      generalCustomizationValues.connectFormStepButtonKey,
    ],
  )

  const handleConnectFormStepButtonOptionChange = useCallback(
    ({ value }: { value: Option | Option[] }) => {
      handleFieldChange(
        'connectFormStepButtonKey',
        (value as Option).id as string,
      )
    },
    [handleFieldChange],
  )
  const handleWelcomeStepButtonOptionChange = useCallback(
    ({ value }: { value: Option | Option[] }) => {
      handleFieldChange('welcomeStepButtonKey', (value as Option).id as string)
    },
    [handleFieldChange],
  )

  const handleWelcomeTextChange = useCallback(
    ({ value }: { value: string }) => {
      handleFieldChange('welcomeText', value)
    },
    [handleFieldChange],
  )

  const isLogoImageHostedByPluggy =
    generalCustomizationValues.logoImageUrl?.includes(
      'pluggy-sa-east-1-dashboard-uploads.s3.sa-east-1.amazonaws.com',
    )

  const logoImageFileExtension = logoImageFile
    ? logoImageFile.name.split('.').pop()
    : null

  const logoImageFileName = customization?.welcomeText
    ?.replace(' ', '-')
    .toLowerCase()

  useEffect(() => {
    if (isLogoImageHostedByPluggy) {
      // if the logo image is hosted by Pluggy, we set the logoImageUrl to previous value

      setLogoImageFile({
        name: `${logoImageFileName}.${customization?.logoImageUrl
          ?.split('/')
          .pop()}`,
      } as File)

      setGeneralCustomizationValues((prevGeneralCustomizationValues) => ({
        ...prevGeneralCustomizationValues,
        logoImageUrl: '',
      }))
    }
  }, [
    customization?.logoImageUrl,
    isLogoImageHostedByPluggy,
    logoImageFileExtension,
    logoImageFileName,
  ])

  const displayLogoImageFile =
    logoImageFile === null
      ? null
      : {
          ...logoImageFile,
          name: `${logoImageFileName}.${logoImageFileExtension}`,
        }

  return (
    <div className={'GeneralCustomizationForm'}>
      <Header>{t('customization.form.general.companyName.title')}</Header>
      <IsDisabledPopup
        isFreeSubscription={isFreeSubscription}
        isTeamMemberRole={!isTeamOwnerOrAdminRole}
        className={'field'}
      >
        <Input
          label={t('customization.form.general.field.welcomeText.label')}
          type={'text'}
          onChange={handleWelcomeTextChange}
          value={generalCustomizationValues.welcomeText}
          error={formErrors.welcomeText}
          disabled={isDisabled}
          assistiveText={t(
            `customization.form.general.field.welcomeText.assistiveText.${
              hasCustomCompanyDeviceName
                ? 'deviceNameFeature'
                : 'noDeviceNameFeature'
            }`,
          )}
        />
      </IsDisabledPopup>
      <Header>{t('customization.form.general.logoImage.title')}</Header>
      <p>
        <Trans
          i18nKey={'customization.form.general.logoImage.subtitle'}
          components={{
            a: (
              // disabled because the anchor is filled with translatable content
              // eslint-disable-next-line jsx-a11y/anchor-has-content
              <a
                href={PLUGGY_CUSTOMIZATION_DOCS_URL}
                target="_blank"
                rel="noopener noreferrer"
                onClick={handleDocsLinkClick}
              />
            ),
          }}
        />
      </p>
      <div className={'logoImage'}>
        <IsDisabledPopup
          isFreeSubscription={isFreeSubscription}
          isTeamMemberRole={!isTeamOwnerOrAdminRole}
          className={'field'}
        >
          <FilePickerArea
            value={displayLogoImageFile}
            onChange={handleLogoImageFileChange}
            acceptedFileFormats={LOGO_IMAGE_ACCEPTED_FILE_FORMATS}
            error={formErrors.logoImageUrl}
            loading={isLoadingLogoImageUpload}
            instructions={t(
              'customization.form.general.upload-logo-image.instructions',
            )}
            replaceLabel={t(
              'customization.form.general.upload-logo-image.action.replace.label',
            )}
            invalidFileFormatErrorLabel={t(
              'customization.form.general.upload-logo-image.error.invalid-format',
            )}
            uploadButtonLabel={t(
              'customization.form.general.upload-logo-image.action.upload',
            )}
            hoverText={t(
              'customization.form.general.upload-logo-image.action.drop-file',
            )}
            disabled={isDisabled}
          >
            <div className={'imageUrl'}>
              <p className={'title'}>
                {t('customization.form.general.field.logoImageUrl.title')}
              </p>
              <Input
                label={t('customization.form.general.field.logoImageUrl.label')}
                type="text"
                onChange={handleLogoImageUrlChange}
                value={generalCustomizationValues.logoImageUrl}
                error={formErrors.logoImageUrl}
                disabled={isDisabled}
              />
            </div>
            <p>
              {t('customization.form.general.upload-logo-image.specifications')}
            </p>
          </FilePickerArea>
        </IsDisabledPopup>
      </div>

      <div className={'primaryColor'}>
        <Header>{t('customization.form.general.primaryColor.title')}</Header>
        <p>{t('customization.form.general.primaryColor.subtitle')}</p>

        <IsDisabledPopup
          isFreeSubscription={isFreeSubscription}
          isTeamMemberRole={!isTeamOwnerOrAdminRole}
          className={'field'}
        >
          <Input
            label={t('customization.form.general.field.primaryColor.label')}
            type="color"
            onChange={handlePrimaryColorChange}
            value={generalCustomizationValues.primaryColor || '#'}
            error={formErrors.primaryColor}
            disabled={isDisabled}
          />
        </IsDisabledPopup>
      </div>

      <div className={'buttons'}>
        <Header>{t('customization.form.general.buttons.title')}</Header>
        <p>{t('customization.form.general.buttons.subtitle')}</p>
        <IsDisabledPopup
          isFreeSubscription={isFreeSubscription}
          isTeamMemberRole={!isTeamOwnerOrAdminRole}
          className={'field'}
        >
          <Input
            label={t('customization.form.general.field.border-radius.label')}
            type={'number'}
            value={generalCustomizationValues.borderRadius?.toString() || ''}
            onChange={handleBorderRadiusChange}
            minimum={0}
            maximum={50}
            disabled={isDisabled}
            assistiveText={t(
              'customization.form.general.field.border-radius.assistiveText',
            )}
          />
        </IsDisabledPopup>
        <div className={'button-texts'}>
          <div className={'welcome-step'}>
            <p>
              {t('customization.form.general.field.welcomeStepButtonKey.title')}
            </p>
            <IsDisabledPopup
              isFreeSubscription={isFreeSubscription}
              isTeamMemberRole={!isTeamOwnerOrAdminRole}
              className={'field'}
            >
              <Input
                name={'welcomeStepButtonKey'}
                type={'select'}
                disabled={isDisabled}
                label={t(
                  'customization.form.general.field.welcomeStepButtonKey.label',
                )}
                options={welcomeStepButtonOptions}
                value={welcomeStepButtonOption}
                onChange={handleWelcomeStepButtonOptionChange}
              />
            </IsDisabledPopup>
          </div>

          <div className={'credentials-step'}>
            <p>
              {t(
                'customization.form.general.field.connectFormStepButtonKey.title',
              )}
            </p>
            <IsDisabledPopup
              isFreeSubscription={isFreeSubscription}
              isTeamMemberRole={!isTeamOwnerOrAdminRole}
              className={'field'}
            >
              <Input
                name={'connectFormStepButtonKey'}
                type={'select'}
                disabled={isDisabled}
                label={t(
                  'customization.form.general.field.connectFormStepButtonKey.label',
                )}
                options={connectFormStepButtonOptions}
                value={connectFormStepButtonOption}
                onChange={handleConnectFormStepButtonOptionChange}
              />
            </IsDisabledPopup>
          </div>
        </div>
      </div>
    </div>
  )
}

export default React.memo(GeneralCustomizationForm)
