import { captureException } from '@sentry/react'
import axios, { AxiosResponse } from 'axios'
import { push } from 'connected-react-router'
import i18next from 'i18next'
import { all, call, put, select, take, takeEvery } from 'redux-saga/effects'

import { teamRoleToString } from '../../components/TeamForm/TeamMembersEmailsInput/utils'
import { TeamService } from '../../lib/api/TeamService'
import {
  trackUserCurrentTeamInIntercom,
  trackUserTeamsInIntercom,
} from '../../lib/intercom'
import { trackTeamInSegment } from '../analytics/utils'
import { fetchApplicationsRequest } from '../application/actions'
import { getAuth0AccessToken } from '../auth0/selectors'
import { fetchConnectTokenSuccess } from '../connectToken/actions'
import { addNotificationAction } from '../notification/actions'
import { NotificationOptions } from '../notification/types'
import { locations } from '../routing/locations'
import { fetchCurrentProfileData } from '../user/sagas'
import { getAsUser, getUser } from '../user/selectors'
import { User } from '../user/types'
import { changeWebhookEventsFilters } from '../webhookEvents/actions'
import { DEFAULT_WEBHOOK_EVENTS_FILTERS } from '../webhookEvents/reducer'
import {
  CREATE_TEAM_MEMBER_REQUEST,
  CREATE_TEAM_REQUEST,
  CREATE_TEAM_SUCCESS,
  createTeamFailure,
  createTeamMemberFailure,
  CreateTeamMemberRequestAction,
  createTeamMemberSuccess,
  CreateTeamRequestAction,
  createTeamSuccess,
  CreateTeamSuccessAction,
  DELETE_TEAM_MEMBER_REQUEST,
  DELETE_TEAM_REQUEST,
  DELETE_TEAM_SUCCESS,
  deleteTeamFailure,
  deleteTeamMemberFailure,
  DeleteTeamMemberRequestAction,
  deleteTeamMemberSuccess,
  DeleteTeamRequestAction,
  deleteTeamSuccess,
  DeleteTeamSuccessAction,
  FETCH_TEAMS_REQUEST,
  FETCH_TEAMS_SUCCESS,
  fetchTeamsFailure,
  fetchTeamsRequest,
  FetchTeamsRequestAction,
  fetchTeamsSuccess,
  FetchTeamsSuccessAction,
  IMPERSONATE_TEAM_FAILURE,
  IMPERSONATE_TEAM_REQUEST,
  IMPERSONATE_TEAM_SUCCESS,
  impersonateTeamFailure,
  ImpersonateTeamFailureAction,
  impersonateTeamRequest,
  ImpersonateTeamRequestAction,
  impersonateTeamSuccess,
  ImpersonateTeamSuccessAction,
  RESEND_TEAM_INVITATION_REQUEST,
  resendTeamInvitationFailure,
  ResendTeamInvitationRequestAction,
  resendTeamInvitationSuccess,
  SELECT_CURRENT_TEAM_REQUEST,
  SELECT_CURRENT_TEAM_SUCCESS,
  selectCurrentTeamFailure,
  SelectCurrentTeamRequestAction,
  selectCurrentTeamSuccess,
  SelectCurrentTeamSuccessAction,
  UPDATE_TEAM_MEMBER_ROLE_REQUEST,
  UPDATE_TEAM_REQUEST,
  updateTeamFailure,
  updateTeamMemberRoleFailure,
  UpdateTeamMemberRoleRequestAction,
  updateTeamMemberRoleSuccess,
  UpdateTeamRequestAction,
  updateTeamSuccess,
} from './actions'
import { getAsTeam, getCurrentTeam, getTeams } from './selectors'
import { saveCurrentTeamId } from './storage'
import {
  CreateTeamMemberRequest,
  CreateTeamRequest,
  isTeamMember,
  MemberInvitation,
  Team,
  UpdateTeamRequest,
} from './types'
import { setTeamInSentryContext } from './utils'

function* handleFetchTeamsRequest(action: FetchTeamsRequestAction) {
  try {
    const accessToken: string = yield select(getAuth0AccessToken)
    const teamService = new TeamService(accessToken)
    const { data: teams }: AxiosResponse<Team[]> = yield call(() =>
      teamService.getTeams(),
    )

    yield put(fetchTeamsSuccess(teams))
  } catch (error) {
    error.message = `Failed fetch of Teams data: ${error.message}`
    captureException(error, {
      contexts: {
        error: {
          action,
          object: error,
        },
      },
    })
    const errorMessage = i18next.t('teams.error.fetch')
    yield put(fetchTeamsFailure(errorMessage))
  }
}

function* handleFetchTeamsSuccess(action: FetchTeamsSuccessAction) {
  const {
    payload: { teams },
  } = action
  // user teams retrieved
  // track user teams in Intercom
  trackUserTeamsInIntercom(teams)

  // check if we are impersonating Team
  const queryParams = new URLSearchParams(window.location.search)
  const asTeamIdValue = queryParams.get('asTeamId')
  const asTeamId = !asTeamIdValue
    ? undefined
    : asTeamIdValue === 'null'
    ? null
    : asTeamIdValue

  let asTeam: Team | null = null
  if (asTeamId !== undefined) {
    // perform impersonate request & wait for result
    yield put(impersonateTeamRequest(asTeamId))
    const result: ImpersonateTeamSuccessAction | ImpersonateTeamFailureAction =
      yield take([IMPERSONATE_TEAM_SUCCESS, IMPERSONATE_TEAM_FAILURE])
    if (result.type === IMPERSONATE_TEAM_SUCCESS) {
      // success
      asTeam = result.payload.team
    }
  }

  // -> select the team profile
  const teamToSelect: Team | null = asTeam || (yield select(getCurrentTeam))

  // first time team profile selection, avoid unneeded redirect to root
  // (if no teamToSelect, will redirect to /onboarding instead)
  yield handleTeamProfileChange(teamToSelect, false)
}

function* handleImpersonateTeamRequest(action: ImpersonateTeamRequestAction) {
  const {
    payload: { teamId: asTeamId },
  } = action

  // validate I don't already belong to asTeamId
  const currentTeams: Team[] | null = yield select(getTeams)

  if (currentTeams?.find(({ id }) => id === asTeamId)) {
    // asTeamId already exists
    const errorMessage = "Already member of this Team, can't impersonate"
    yield put(impersonateTeamFailure(errorMessage))
    const notificationOptions: NotificationOptions = {
      title: i18next.t('team.error.impersonate.title'),
      message: i18next.t('team.error.impersonate.message', {
        id: asTeamId,
        message: errorMessage,
        status: 0,
      }),
      level: 'error',
    }
    yield put(addNotificationAction(notificationOptions))
    return
  }

  if (asTeamId === null) {
    // unset impersonate
    yield put(impersonateTeamSuccess(null))
    yield put(
      addNotificationAction({
        title: i18next.t('team.success.impersonateStop.title'),
        message: i18next.t('team.success.impersonateStop.message'),
        level: 'info',
      }),
    )
    return
  }

  // try to fetch the specified team, then select it
  const accessToken: string = yield select(getAuth0AccessToken)
  const teamService = new TeamService(accessToken)
  try {
    const { data: team }: AxiosResponse<Team> = yield call(() =>
      teamService.getTeam(asTeamId),
    )

    // success
    yield put(impersonateTeamSuccess(team))
    yield put(
      addNotificationAction({
        title: i18next.t('team.success.impersonate.title'),
        message: i18next.t('team.success.impersonate.message', {
          id: asTeamId,
          name: team.name,
        }),
        level: 'succeed',
      }),
    )
  } catch (error) {
    // parse & display error response
    let errorMessage: string = error.message
    let errorStatus: number | undefined = undefined
    if (axios.isAxiosError(error) && error.response) {
      ;({
        data: { message: errorMessage },
        status: errorStatus,
      } = error.response as {
        data: { message: string }
        status: number
      })
    }

    // failure
    yield put(impersonateTeamFailure(errorMessage))
    const notificationOptions: NotificationOptions = {
      title: i18next.t('team.error.impersonate.title'),
      message: i18next.t('team.error.impersonate.message', {
        id: asTeamId,
        message: errorMessage,
        status: errorStatus,
      }),
      level: 'error',
    }
    yield put(addNotificationAction(notificationOptions))
  }
}
function* handleCreateTeamRequest(action: CreateTeamRequestAction) {
  const { createTeamFields } = action.payload

  const user: User | null = yield select(getUser)
  if (!user) {
    const errorMessage = i18next.t('team.error.create')
    yield put(createTeamFailure(errorMessage))
    return
  }

  // map team form fields to create request body
  const { imageUrl, name, members } = createTeamFields

  const membersWithOwnerUser: MemberInvitation[] = [
    {
      // include current User as 'OWNER' team member
      email: user.email,
      role: 'OWNER',
    },
    ...members.filter(
      // exclude empty emails
      (invite) => invite.email.length > 0,
    ),
  ]

  const teamFields: CreateTeamRequest = {
    name,
    imageUrl: imageUrl || undefined,
    members: membersWithOwnerUser,
  }

  try {
    const accessToken: string = yield select(getAuth0AccessToken)
    const teamService = new TeamService(accessToken)
    // submit create request
    const { data: team }: AxiosResponse<Team> = yield call(() =>
      teamService.createTeam(teamFields),
    )
    yield put(createTeamSuccess(team))
  } catch (error) {
    const errorMessage = i18next.t('team.error.create')
    yield put(createTeamFailure(errorMessage))
  }
}

function* handleUpdateTeamRequest(action: UpdateTeamRequestAction) {
  const { id, updateTeamFields } = action.payload

  // map team form fields to create request body
  const teamFields: UpdateTeamRequest = {
    imageUrl: updateTeamFields.imageUrl || null,
    name: updateTeamFields.name,
  }

  try {
    const accessToken: string = yield select(getAuth0AccessToken)
    const teamService = new TeamService(accessToken)
    // submit update request
    const { data: team }: AxiosResponse<Team> = yield call(() =>
      teamService.updateTeam(id, teamFields),
    )
    yield put(updateTeamSuccess(team))
    yield put(
      addNotificationAction({
        title: i18next.t('team.success.update.title'),
        message: i18next.t('team.success.update.message'),
        duration: 4000,
        level: 'succeed',
      }),
    )
  } catch (error) {
    const errorMessage = i18next.t('team.error.update')
    yield put(updateTeamFailure(errorMessage))
  }
}

function* handleDeleteTeamRequest(action: DeleteTeamRequestAction) {
  const { team } = action.payload
  const { id } = team

  try {
    const accessToken: string = yield select(getAuth0AccessToken)
    const teamService = new TeamService(accessToken)
    // submit delete request
    yield call(() => teamService.deleteTeam(id))
    yield put(deleteTeamSuccess(team))
  } catch (error) {
    const errorMessage = i18next.t('team.error.delete')
    yield put(deleteTeamFailure(errorMessage))
  }
}

function* handleCreateTeamMemberRequest(action: CreateTeamMemberRequestAction) {
  const { id, createTeamMembersFields } = action.payload
  const count = createTeamMembersFields.length

  try {
    const accessToken: string = yield select(getAuth0AccessToken)
    const teamService = new TeamService(accessToken)
    // submit create member requests
    let updatedTeam: Team | undefined = undefined
    for (const createTeamMemberFields of createTeamMembersFields) {
      // map member form fields to create request body
      const teamFields: CreateTeamMemberRequest = {
        email: createTeamMemberFields.email,
        role: createTeamMemberFields.role,
      }
      const { data: team }: AxiosResponse<Team> = yield call(() =>
        teamService.createTeamMember(id, teamFields),
      )
      updatedTeam = team
    }
    if (!updatedTeam) {
      throw new Error('Unexpectedly updatedTeam is not defined')
    }
    yield put(createTeamMemberSuccess(updatedTeam))
    yield put(
      addNotificationAction({
        title: i18next.t('team.success.add-member.title'),
        message: i18next.t('team.success.add-member.message', { count }),
        level: 'succeed',
      }),
    )
  } catch (error) {
    const errorMessage = i18next.t('team.error.add-member')
    yield put(createTeamMemberFailure(errorMessage))
  }
}

function* handleUpdateTeamMemberRoleRequest(
  action: UpdateTeamMemberRoleRequestAction,
) {
  const { team, teamMember, role } = action.payload
  const { email } = teamMember
  const accessToken: string = yield select(getAuth0AccessToken)
  const teamService = new TeamService(accessToken)

  if (isTeamMember(teamMember)) {
    // submit update team member request
    try {
      const { data: updatedTeam }: AxiosResponse<Team> = yield call(() =>
        teamService.updateTeamMember(team.id, teamMember.id, { role }),
      )
      yield put(
        addNotificationAction({
          title: i18next.t('team.success.update-member-role.title'),
          message: i18next.t('team.success.update-member-role.message', {
            value: teamRoleToString(role),
            email,
          }),
          level: 'succeed',
        }),
      )
      yield put(updateTeamMemberRoleSuccess(updatedTeam))
    } catch (error) {
      const errorMessage = i18next.t('team.error.update-member-role', {
        value: role,
        email,
      })
      yield put(updateTeamMemberRoleFailure(errorMessage))
    }
    return
  }

  // submit update team invitation request
  try {
    const { data: updatedTeam }: AxiosResponse<Team> = yield call(() =>
      teamService.updateTeamInvitation(team.id, teamMember.id, { role }),
    )
    yield put(
      addNotificationAction({
        title: i18next.t('team.success.update-invitation-role.title'),
        message: i18next.t('team.success.update-invitation-role.message', {
          value: teamRoleToString(role),
          email,
        }),
        level: 'succeed',
      }),
    )
    yield put(updateTeamMemberRoleSuccess(updatedTeam))
  } catch (error) {
    const errorMessage = i18next.t('team.error.update-invitation-role', {
      value: role,
      email,
    })
    yield put(updateTeamMemberRoleFailure(errorMessage))
  }
}

function* handleDeleteTeamMemberRequest(action: DeleteTeamMemberRequestAction) {
  const { team, teamMember } = action.payload
  const { email } = teamMember

  const user: User | null = yield select(getUser)

  const memberIsUser = teamMember.id === user?.id

  const accessToken: string = yield select(getAuth0AccessToken)
  const teamService = new TeamService(accessToken)

  if (isTeamMember(teamMember)) {
    // submit delete team member request
    try {
      const { data: updatedTeam }: AxiosResponse<Team> = yield call(() =>
        teamService.deleteTeamMember(team.id, teamMember.id),
      )
      yield put(deleteTeamMemberSuccess(updatedTeam))
      if (memberIsUser) {
        yield put(fetchTeamsRequest())
        yield put(
          addNotificationAction({
            title: i18next.t('team.success.exit.title'),
            message: i18next.t('team.success.exit.message'),
            level: 'succeed',
          }),
        )
      } else {
        yield put(
          addNotificationAction({
            title: i18next.t('team.success.delete-member.title'),
            message: i18next.t('team.success.delete-member.message', {
              email,
            }),
            level: 'succeed',
          }),
        )
      }
    } catch (error) {
      let errorMessage: string

      if (memberIsUser) {
        error.message = `Could not exit team: ${error.message}`
        errorMessage = i18next.t('team.error.exit.message')
        // display toast notification
        yield put(
          addNotificationAction({
            title: i18next.t('team.error.exit.title'),
            message: errorMessage,
            level: 'error',
          }),
        )
      } else {
        error.message = `Could not remove member from team: ${error.message}`
        errorMessage = i18next.t('team.error.delete-member', {
          email,
        })
      }

      captureException(error, {
        contexts: {
          error: {
            action,
            object: error,
            notificationOptions: {
              message: errorMessage,
              memberIsUser,
            },
          },
        },
      })

      yield put(deleteTeamMemberFailure(errorMessage))
    }
    return
  }

  // submit delete team invitation request
  try {
    const { data: updatedTeam }: AxiosResponse<Team> = yield call(() =>
      teamService.deleteTeamInvitation(team.id, teamMember.id),
    )

    yield put(deleteTeamMemberSuccess(updatedTeam))
    yield put(
      addNotificationAction({
        title: i18next.t('team.success.delete-invitation.title'),
        message: i18next.t('team.success.delete-invitation.message', {
          email,
        }),
        level: 'succeed',
      }),
    )
  } catch (error) {
    const errorMessage = i18next.t('team.error.delete-invitation', {
      email,
    })
    yield put(deleteTeamMemberFailure(errorMessage))
  }
}

function* handleResendTeamInvitationRequest(
  action: ResendTeamInvitationRequestAction,
) {
  const {
    payload: { team, teamInvitation },
  } = action
  const { email, id: invitationId } = teamInvitation

  const accessToken: string = yield select(getAuth0AccessToken)
  const teamService = new TeamService(accessToken)

  try {
    yield call(() =>
      teamService.resendTeamInvitationEmail(team.id, invitationId),
    )
    yield put(resendTeamInvitationSuccess(team, teamInvitation))
    yield put(
      addNotificationAction({
        title: i18next.t('team.success.resend-invitation.title'),
        message: i18next.t('team.success.resend-invitation.message', {
          email,
        }),
        level: 'succeed',
      }),
    )
  } catch (error) {
    const errorMessage = i18next.t('team.error.resend-invitation', {
      email,
    })
    yield put(resendTeamInvitationFailure(errorMessage))
  }
}

/**
 * Helper to actually change the Team Profile in the UI
 *
 * If there is no more Teams left (team === null) -> redirect to /onboarding
 * Otherwise:
 * - fetch related profile data
 * - redirect to locations.root(), if needed
 *
 * @param team { Team | null } - the Team to fetch profile data for, and update UI.
 *                               if null, means there is no more valid teams, so we redirect to /onboarding.
 * @param shouldRedirectToRoot - if set to false, no redirect to root will happen.
 *                               (useful to set false, for the first time loading the app)
 *                               default: true.
 */
function* handleTeamProfileChange(
  team: Team | null,
  shouldRedirectToRoot = true,
) {
  const {
    location: { pathname: currentLocation },
  } = window

  const isInRoot = currentLocation === locations.root()
  const isInOnboarding = currentLocation === locations.onboarding()

  const teamIdOrNull = team?.id || null

  const asTeam: Team | null = yield select(getAsTeam)
  const isImpersonatingTeam = asTeam?.id === teamIdOrNull

  // set Team data in Sentry context
  setTeamInSentryContext(team)
  // set Team data in Segment context
  trackTeamInSegment(team)

  if (!isImpersonatingTeam) {
    // update saved currentTeam.id
    saveCurrentTeamId(teamIdOrNull)
  }

  if (team === null) {
    // No more teams left, ie. User doesn't belong to any more teams -> go to /onboarding
    if (isInOnboarding) {
      // is already in /onboarding
      return
    }
    yield put(push(locations.onboarding()))
    return
  }

  const user: User = yield select(getUser)
  const asUser: User | null = yield select(getAsUser)

  if (!isImpersonatingTeam && !asUser) {
    // set current Team data in Intercom context
    trackUserCurrentTeamInIntercom(team, user)
  }

  // check if user required data has been specified, if not redirect to request it
  const { companyRole, platforms } = user

  if (
    (companyRole === null ||
      companyRole === 'OTHER' ||
      (companyRole === 'DEVELOPER' && platforms === null)) &&
    !asUser &&
    !isImpersonatingTeam
  ) {
    // User belong to a team, but has no role assigned -> go to /company-role
    //  or user is developer but doesn't have any platform selected -> go to /company-role to choose it
    yield put(push(locations.companyRole()))
    return
  }

  yield put(fetchConnectTokenSuccess({ connectToken: undefined }))
  yield fetchCurrentProfileData()

  if (isInRoot) {
    // it's already in root
    return
  }

  if (!shouldRedirectToRoot && !isInOnboarding) {
    // redirect is not needed, it's first time team select on app load
    // except if is first time team select & we're in /onboarding, in that case we must do the redirect
    return
  }

  // fetch applications data
  yield put(fetchApplicationsRequest())

  // reset webhook events filter
  yield put(changeWebhookEventsFilters(DEFAULT_WEBHOOK_EVENTS_FILTERS))

  // redirect to locations.root()
  yield put(push(locations.root()))
}

/**
 * User manually selected a new Team, in the profile selector UI.
 *
 * @param action
 */
function* handleSelectCurrentTeamRequest(
  action: SelectCurrentTeamRequestAction,
) {
  const {
    payload: { team },
  } = action

  try {
    // re-fetch data for the newly selected Team
    const accessToken: string = yield select(getAuth0AccessToken)
    const teamService = new TeamService(accessToken)

    // fetch team request
    const { data: teamResponse }: AxiosResponse<Team> = yield call(() =>
      teamService.getTeam(team.id),
    )

    yield put(selectCurrentTeamSuccess(teamResponse))
  } catch (error) {
    // failed to fetch up-to-date team data, update error state & prompt to try again
    const errorMessage = i18next.t('team.error.select')
    yield put(selectCurrentTeamFailure(errorMessage, team))
  }
}

function* handleSelectCurrentTeamSuccess(
  action: SelectCurrentTeamSuccessAction,
) {
  // switched team (or user) profile -> re-fetch app data
  const { team } = action.payload

  // update profile UI (if needed), fetch related data
  yield handleTeamProfileChange(team)
}

function* handleCreateTeamSuccess(action: CreateTeamSuccessAction) {
  const { team } = action.payload
  const { name } = team
  yield put(
    addNotificationAction({
      title: i18next.t('team.success.create.title'),
      message: i18next.t('team.success.create.message', { name }),
      level: 'succeed',
    }),
  )
  // created team -> select it & re-fetch profile app data
  yield handleTeamProfileChange(team)
}

function* handleDeleteTeamSuccess(action: DeleteTeamSuccessAction) {
  const { team } = action.payload
  const { name } = team
  yield put(
    addNotificationAction({
      title: i18next.t('team.success.delete.title'),
      message: i18next.t('team.success.delete.message', { name }),
      level: 'succeed',
    }),
  )

  // deleted team -> select another one from the teams, or if none, go to /onboarding
  const nextCurrentTeam: Team | null = yield select(getCurrentTeam)

  // there is at least one more team left -> select it
  // (if no nextCurrentTeam, will redirect to /onboarding instead)
  yield handleTeamProfileChange(nextCurrentTeam)
}

export function* teamSaga() {
  yield all([
    takeEvery(FETCH_TEAMS_REQUEST, handleFetchTeamsRequest),
    takeEvery(FETCH_TEAMS_SUCCESS, handleFetchTeamsSuccess),
    takeEvery(IMPERSONATE_TEAM_REQUEST, handleImpersonateTeamRequest),
    takeEvery(CREATE_TEAM_REQUEST, handleCreateTeamRequest),
    takeEvery(UPDATE_TEAM_REQUEST, handleUpdateTeamRequest),
    takeEvery(DELETE_TEAM_REQUEST, handleDeleteTeamRequest),
    takeEvery(CREATE_TEAM_SUCCESS, handleCreateTeamSuccess),
    takeEvery(DELETE_TEAM_SUCCESS, handleDeleteTeamSuccess),
    takeEvery(CREATE_TEAM_MEMBER_REQUEST, handleCreateTeamMemberRequest),
    takeEvery(DELETE_TEAM_MEMBER_REQUEST, handleDeleteTeamMemberRequest),
    takeEvery(
      UPDATE_TEAM_MEMBER_ROLE_REQUEST,
      handleUpdateTeamMemberRoleRequest,
    ),
    takeEvery(
      RESEND_TEAM_INVITATION_REQUEST,
      handleResendTeamInvitationRequest,
    ),
    takeEvery(SELECT_CURRENT_TEAM_REQUEST, handleSelectCurrentTeamRequest),
    takeEvery(SELECT_CURRENT_TEAM_SUCCESS, handleSelectCurrentTeamSuccess),
  ])
}
