import { connect } from 'react-redux'

import { RootState } from '../../../modules/reducer'
import { getApplicationsItemsStatsByApplicationId } from '../../../modules/stats/selectors'
import ApplicationItem from './ApplicationItem'
import {
  MapDispatch,
  MapDispatchProps,
  MapStateProps,
} from './ApplicationItem.types'

const mapState = (state: RootState): MapStateProps => ({
  itemStatsByApplicationId: getApplicationsItemsStatsByApplicationId(state),
})

const mapDispatch = (_dispatch: MapDispatch): MapDispatchProps => ({
  // nothing yet
})

export default connect(mapState, mapDispatch)(ApplicationItem)
