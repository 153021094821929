import { AnyAction } from 'redux'

import { Application } from '../application/types'
import { LoadingState } from '../loading/types'
import { RootState } from '../reducer'
import { StatsState } from './reducer'
import { ItemsStats, ItemStatsReport, ItemStatsFilters } from './types'

export const getState = (state: RootState): StatsState =>
  state.stats as StatsState
export const getData = (state: RootState) => getState(state).data
export const getLoading = (state: RootState) => getState(state).loading
export const getError = (state: RootState) => getState(state).error

export const isLoadingType: (state: LoadingState, type: string) => boolean = (
  state,
  type,
) => state.some((action: AnyAction) => action.type === type)

export const getTotalItemStats = (state: RootState): ItemsStats | undefined => {
  const stats = getData(state)

  const { totalItemStats } = stats

  // all applications items stats
  return totalItemStats
}

export const getApplicationsItemsStatsByApplicationId = (
  state: RootState,
): Record<Application['id'], ItemsStats | undefined> | undefined => {
  const stats = getData(state)

  const { itemsStatsByApplicationId } = stats

  return itemsStatsByApplicationId
}

export const teamOrUserHasItemsConnected = (state: RootState): boolean => {
  const totalItemStats = getTotalItemStats(state)

  if (totalItemStats?.itemStats === undefined) {
    // no total item stats loaded
    return false
  }

  return totalItemStats.itemStats.totalItems > 0
}

export const getItemStatsFilters = (state: RootState): ItemStatsFilters => {
  const {
    itemStatsReport: { filters },
  } = getData(state)

  return filters
}

export const getItemsStatsReportItemStats = (
  state: RootState,
): ItemStatsReport | undefined => {
  const {
    itemStatsReport: { data },
  } = getData(state)

  if (!data) {
    return undefined
  }

  return data.itemStats
}
