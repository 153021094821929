import { memo } from 'react'

import { Props } from './Table.types'

import './Table.css'

const ROWS_PER_PAGE = 7

const Table = ({ headers, rows, className, loading }: Props) => (
  <table className={`Table ${className || ''}`}>
    <thead className={'headers-container'}>
      <tr>
        {headers.map((header) => (
          <th className={'table-header'} key={header?.toString()}>
            {header}
          </th>
        ))}
      </tr>
    </thead>
    <tbody>
      {loading
        ? Array.from(Array(ROWS_PER_PAGE).keys()).map((row) => (
            <tr className={'table-row'} key={row}>
              {headers.map((_, index) => (
                <td key={index}>
                  <div className={'skeleton'} />
                </td>
              ))}
            </tr>
          ))
        : rows}
    </tbody>
  </table>
)

export default memo(Table)
