import React from 'react'
import { useTranslation } from 'react-i18next'

import { EnvironmentTag } from '../../Navbar/EnvironmentTag'
import { ApplicationDemoPreviewLink } from '../ApplicationDemoPreviewLink'
import { Props } from './ApplicationHeader.types'
import { StateIndicator } from './StateIndicator'

import './ApplicationHeader.css'

const ApplicationHeader = ({
  application,
  actions,
  hasDemoPreviewLink,
  onGoToDemoApplicationClick,
}: Props) => {
  const { name, enabled, environment } = application
  const { t } = useTranslation()

  const isDemoApplication = environment === 'DEMO'
  return (
    <div
      className={`ApplicationHeader environment-${environment.toLowerCase()}`}
    >
      <div className={'header'}>
        {name}
        {isDemoApplication && (
          <div className={'demo-description'}>
            {t('application.item.demo-description')}
          </div>
        )}
      </div>

      <StateIndicator enabled={enabled} />
      {environment !== 'DEMO' && <EnvironmentTag environment={environment} />}
      <div className={'actions'}>
        {hasDemoPreviewLink && (
          <ApplicationDemoPreviewLink
            application={application}
            onClick={onGoToDemoApplicationClick}
          />
        )}

        {actions}
      </div>
    </div>
  )
}

export default React.memo(ApplicationHeader)
