import React, { useCallback } from 'react'
import { useTranslation } from 'react-i18next'

import { Input } from '@pluggyai/ui'
import { Option } from '@pluggyai/ui/dist/components/Dropdown/Dropdown.types'

import { itemsStatsTypesGroupsNames, Props } from './ItemsTypeFilters.types'
import { mapItemsTypesToOptions } from './utils'

const ItemsTypeFilters = ({ onChange, disabled, selectedTypes }: Props) => {
  const { t } = useTranslation()

  const handleDropdownChange = useCallback(
    ({ value }: { value: Option[] | Option }) => {
      // casting to Option[] because the component is not working properly with multiple
      const types = value as Option[]

      if (value.length === 0) {
        return
      }
      const typeIds = types.map(({ id }) => id as string)

      onChange(typeIds)
    },
    [onChange],
  )

  return (
    <Input
      type={'select'}
      value={mapItemsTypesToOptions(selectedTypes)}
      multiple
      withSelectAllOption
      selectAllLabel={t('dailyItems.items-type-filter.all-status')}
      options={mapItemsTypesToOptions(itemsStatsTypesGroupsNames)}
      label={t('dailyItems.items-type-filter.label')}
      disabled={disabled}
      onChange={handleDropdownChange}
      className={'ItemsTypeFilters'}
      allOptionsSelectedLabel={t('dailyItems.items-type-filter.all-status')}
      withOverflowCount
    />
  )
}

export default React.memo(ItemsTypeFilters)
