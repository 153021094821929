import { connect } from 'react-redux'

import {
  DELETE_APPLICATION_REQUEST,
  deleteApplicationRequest,
  FETCH_APPLICATION_REQUEST,
} from '../../../../modules/application/actions'
import {
  getApplication,
  getError,
  isLoadingApplicationSelector,
} from '../../../../modules/application/selectors'
import { RootState } from '../../../../modules/reducer'
import ApplicationDeleteWidget from './ApplicationDeleteWidget'
import {
  MapDispatch,
  MapDispatchProps,
  MapStateProps,
} from './ApplicationDeleteWidget.types'

const mapState = (state: RootState): MapStateProps => ({
  error: getError(state),
  isLoading: isLoadingApplicationSelector(state, [
    FETCH_APPLICATION_REQUEST,
    DELETE_APPLICATION_REQUEST,
  ])(getApplication(state)),
})

const mapDispatch = (dispatch: MapDispatch): MapDispatchProps => ({
  onDeleteApplication: (id: string) => dispatch(deleteApplicationRequest(id)),
})

export default connect(mapState, mapDispatch)(ApplicationDeleteWidget)
