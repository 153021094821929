import React, { memo, useCallback, useMemo, useRef, useState } from 'react'
import { useTranslation } from 'react-i18next'

import { Button, Input, Radio } from '@pluggyai/ui'
import { Option } from '@pluggyai/ui/dist/components/Dropdown/Dropdown.types'
import { format } from 'date-fns'
import type { Webhook, WebhookEvent } from 'pluggy-sdk'

import { TrackEventName } from '../../../../../modules/analytics/events'
import { track } from '../../../../../modules/analytics/utils'
import { DeleteIcon } from '../../../../Icon/DeleteIcon'
import { WebhooksDisabledPopup } from '../WebhooksDisabledPopup'
import { buildAllWebhookEventsOption, buildWebhookEventOptions } from './utils'
import { WebhookDeleteConfirmModal } from './WebhookDeleteConfirmModal'
import { Props } from './WebhookInput.types'

import './WebhookInput.css'

const FORM_AUTO_SUBMIT_TIMEOUT_MS = 2000 // 2 seconds

const CREATE_WEBHOOK_DEFAULT_VALUES: Pick<
  Webhook,
  'url' | 'event' | 'disabledAt'
> = {
  url: '',
  event: 'all',
  disabledAt: null,
}

const WebhookInput = (props: Props) => {
  const { userCanEdit, webhook, validateWebhookParameters } = props

  const webhookEventOptions = buildWebhookEventOptions()

  const { t } = useTranslation()

  const { url: initialUrl, disabledAt } =
    webhook || CREATE_WEBHOOK_DEFAULT_VALUES
  const enabled = disabledAt === null

  const [event, setEvent] = useState<Webhook['event']>('all')
  const [error, setError] = useState<
    | {
        url?: string
        event?: string
      }
    | undefined
  >()
  const [url, setUrl] = useState(initialUrl)

  const [isSubmitting, setIsSubmitting] = useState(false)
  const [deleteModalOpen, setDeleteModalOpen] = useState(false)

  const deleteButtonI18nKey = 'application.page.webhooks.input.delete.label'
  const deleteButtonLabel = t(deleteButtonI18nKey)

  const createdAtLabel = webhook
    ? t('application.page.webhooks.input.url.assistiveText.createdAt', {
        value: format(new Date(webhook.createdAt), 'dd/MM/yyyy'),
      })
    : ''

  const disabledAtLabel = webhook?.disabledAt
    ? ` - ${t('application.page.webhooks.input.url.assistiveText.disabledAt', {
        value: format(new Date(webhook.disabledAt), 'dd/MM/yyyy'),
      })}`
    : ''

  const handleEnabledToggleClick = useCallback(() => {
    track(TrackEventName.TOGGLE_CLICKED, {
      location: 'ApplicationWebhooks',
    })

    if (props.webhook === null || !userCanEdit) {
      return
    }

    const { onUpdate } = props

    onUpdate(props.webhook.id, {
      enabled: !enabled,
    })
  }, [userCanEdit, props, enabled])

  const handleDeleteButtonClick = useCallback(() => {
    track(TrackEventName.BUTTON_CLICKED, {
      location: 'ApplicationWebhooks',
      text: deleteButtonLabel,
      i18nKey: deleteButtonI18nKey,
    })

    if (props.webhook === null) {
      const { onCancel } = props
      onCancel()
      return
    }
    setDeleteModalOpen(true)
  }, [deleteButtonLabel, props])

  const submitTimeout = useRef<number>()

  const handleSubmit = useCallback(
    (url_: string) => {
      if (submitTimeout.current) {
        clearTimeout(submitTimeout.current)
      }

      if (!url_ || url_ === webhook?.url) {
        return
      }

      const webhookError = validateWebhookParameters({
        url: url_,
        event: webhook?.event || 'all',
      })

      if (webhookError) {
        setError(webhookError)
        return
      }
      setError(undefined)

      if (props.webhook !== null) {
        const { onUpdate } = props

        onUpdate(props.webhook.id, {
          url: url_,
        })
      } else if (!isSubmitting) {
        const { onCreate } = props

        setIsSubmitting(true)

        onCreate({
          url: url_,
          event,
        })
      }
    },
    [
      event,
      isSubmitting,
      props,
      validateWebhookParameters,
      webhook?.event,
      webhook?.url,
    ],
  )

  const handleFormSubmit = useCallback(
    (formEvent: React.FormEvent) => {
      formEvent.preventDefault()
      handleSubmit(url)
    },
    [handleSubmit, url],
  )

  const handleEventChange = useCallback(
    ({ value }: { value: Option | Option[] }) => {
      const event_ = (value as Option).id as WebhookEvent

      if (submitTimeout.current) {
        clearTimeout(submitTimeout.current)
      }

      if (props.webhook === null) {
        setEvent(event_)

        const { onCreate } = props

        const webhookError = validateWebhookParameters({
          url,
          event: event_,
        })

        if (webhookError) {
          setError(webhookError)
          return
        }

        onCreate({
          url,
          event: event_,
        })

        setError(undefined)

        return
      }

      const webhookError = validateWebhookParameters({
        url: webhook?.url || '',
        event: event_,
      })

      if (webhookError) {
        setError(webhookError)
        return
      }
      setError(undefined)

      const { onUpdate } = props
      onUpdate(props.webhook.id, {
        event: event_,
      })
    },
    [props, url, validateWebhookParameters, webhook?.url],
  )

  const handleUrlChange = useCallback(
    ({ value }: { value: string }) => {
      setUrl(value)
      if (submitTimeout.current) {
        clearTimeout(submitTimeout.current)
      }

      // set timer to auto-submit
      // if it's submitting -> clear timer

      submitTimeout.current = window.setTimeout(
        () => handleSubmit(value),
        FORM_AUTO_SUBMIT_TIMEOUT_MS,
      )
    },
    [handleSubmit],
  )

  const handleDeleteWebhook = useCallback(() => {
    if (props.webhook === null) {
      return
    }
    props.onDelete(props.webhook.id)
  }, [props])

  const currentEventOption = useMemo(
    () =>
      webhookEventOptions.find(
        (option) => option.name === webhook?.event || option.name === event,
      ) || buildAllWebhookEventsOption(),
    [event, webhook?.event, webhookEventOptions],
  )

  return (
    <form className={'WebhookInput'} onSubmit={handleFormSubmit}>
      {props.webhook !== null && deleteModalOpen && (
        <WebhookDeleteConfirmModal
          onClose={() => setDeleteModalOpen(false)}
          onDeleteWebhook={handleDeleteWebhook}
        />
      )}
      <WebhooksDisabledPopup webhookDisabled={!enabled}>
        <Input
          className={'url-input'}
          type={'text'}
          label={t('application.page.webhooks.input.url.label')}
          value={url}
          onChange={handleUrlChange}
          assistiveText={`${createdAtLabel}${disabledAtLabel}`}
          error={error?.url}
          disabled={!userCanEdit || !enabled}
        />
      </WebhooksDisabledPopup>
      <WebhooksDisabledPopup webhookDisabled={!enabled}>
        <Input
          className={'events-input'}
          type={'select'}
          label={t('application.page.webhooks.input.events.label')}
          options={webhookEventOptions}
          value={currentEventOption}
          onChange={handleEventChange}
          disabled={!userCanEdit || !enabled}
          error={error?.event}
          maxMenuHeight={200}
        />
      </WebhooksDisabledPopup>
      <WebhooksDisabledPopup>
        <Radio
          toggle
          label={t('application.page.webhooks.input.active.label')}
          className={'enable-webhook'}
          checked={enabled}
          onClick={handleEnabledToggleClick}
          disabled={!userCanEdit}
        />
      </WebhooksDisabledPopup>
      <WebhooksDisabledPopup>
        <Button
          text
          className={'delete-webhook'}
          onClick={handleDeleteButtonClick}
          type={'button'}
          disabled={!userCanEdit}
          size={'small'}
        >
          <DeleteIcon className={!userCanEdit ? 'disabled' : ''} />
          {deleteButtonLabel}
        </Button>
      </WebhooksDisabledPopup>
      <button type={'submit'} className={'hidden-submit-button'} />
    </form>
  )
}

export default memo(WebhookInput)
