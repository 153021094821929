import { memo } from 'react'

import Icon from '../Icon'

import './TrendArrowDownIcon.css'

type Props = {
  onClick?: () => void
  className?: string
}

const TrendArrowDownIcon = ({ className }: Props) => (
  <Icon
    className={`TrendArrowDownIcon ${className || ''}`}
    width="15"
    height="11"
    viewBox="0 0 15 11"
    fill="none"
  >
    <path
      d="M5.56072 5.1095C4.76773 4.35262 4.00923 3.62754 3.24999 2.90211C2.6001 2.28269 1.95021 1.66423 1.30032 1.04671C1.08773 0.845523 0.831269 0.783344 0.542193 0.864464C0.253116 0.945584 0.0810205 1.14106 0.0172812 1.41729C-0.0442085 1.68603 0.0633984 1.90651 0.260615 2.09627C1.16196 2.95488 2.06256 3.81372 2.96241 4.67281C3.62955 5.30843 4.29719 5.94369 4.96532 6.57859C5.33164 6.92523 5.75569 6.92701 6.1205 6.58395C6.62742 6.10748 7.13096 5.62743 7.63112 5.1438C7.68774 5.08949 7.72336 5.01551 7.76348 4.95834L11.9763 8.97716C11.9163 8.98074 11.8585 8.98681 11.8004 8.98717C11.313 8.98717 10.8256 8.98253 10.3382 8.99003C10.0161 8.99468 9.77351 9.14369 9.64116 9.42279C9.51481 9.68938 9.5568 9.9481 9.75364 10.1754C9.82425 10.2596 9.91422 10.3273 10.0166 10.3731C10.119 10.419 10.2311 10.4418 10.3442 10.4398C11.511 10.4423 12.6808 10.4445 13.8491 10.4388C14.2904 10.4366 14.6169 10.1171 14.6184 9.69724C14.6222 8.58943 14.6222 7.48163 14.6184 6.37382C14.6184 5.95286 14.2724 5.62338 13.8532 5.62695C13.434 5.63052 13.1108 5.945 13.1033 6.36703C13.0962 6.81945 13.1015 7.27222 13.1015 7.72499V7.9126L13.0332 7.95477C12.9908 7.89109 12.9434 7.83052 12.8915 7.77359C11.3997 6.34917 9.90687 4.92582 8.41287 3.50354C8.02406 3.13368 7.60375 3.13296 7.21644 3.50032C6.70778 3.98299 6.19999 4.46721 5.69308 4.95298C5.64958 4.99479 5.61471 5.04625 5.56072 5.1095Z"
      fill="#D91616"
    />
  </Icon>
)

export default memo(TrendArrowDownIcon)
