import { connect } from 'react-redux'

import { RootState } from '../../../../../modules/reducer'
import { getCurrentTeam } from '../../../../../modules/team/selectors'
import TeamSubscriptionDetailSection from './TeamSubscriptionDetailSection'
import {
  MapDispatch,
  MapDispatchProps,
  MapStateProps,
} from './TeamSubscriptionDetailSection.types'

const mapState = (state: RootState): MapStateProps => ({
  team: getCurrentTeam(state),
})

const mapDispatch = (_dispatch: MapDispatch): MapDispatchProps => ({})

export default connect(mapState, mapDispatch)(TeamSubscriptionDetailSection)
