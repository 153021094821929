import { loadingReducer } from '../loading/reducer'
import { LoadingState } from '../loading/types'
import {
  UPLOAD_LOGO_IMAGE_FAILURE,
  UPLOAD_LOGO_IMAGE_REQUEST,
  UPLOAD_LOGO_IMAGE_SUCCESS,
  UploadLogoImageFailureAction,
  UploadLogoImageRequestAction,
  UploadLogoImageSuccessAction,
} from './actions'

export type LogoImageState = {
  data: {
    temporaryImageLogoUrl: string | null
  }
  loading: LoadingState
  error: {
    logoImageFile: string | null
  }
}

const INITIAL_STATE: LogoImageState = {
  data: {
    temporaryImageLogoUrl: null,
  },
  loading: [],
  error: {
    logoImageFile: null,
  },
}

type CustomizationReducerAction =
  | UploadLogoImageRequestAction
  | UploadLogoImageSuccessAction
  | UploadLogoImageFailureAction

export function logoImageReducer(
  state: LogoImageState = INITIAL_STATE,
  action: CustomizationReducerAction,
): LogoImageState {
  switch (action.type) {
    case UPLOAD_LOGO_IMAGE_REQUEST: {
      return {
        ...state,
        loading: loadingReducer(state.loading, action),
        error: {
          ...state.error,
        },
      }
    }

    case UPLOAD_LOGO_IMAGE_SUCCESS: {
      return {
        ...state,
        loading: loadingReducer(state.loading, action),
        error: {
          ...state.error,
          logoImageFile: null,
        },
        data: {
          ...state.data,
          temporaryImageLogoUrl: action.payload.logoImageUrl,
        },
      }
    }
    case UPLOAD_LOGO_IMAGE_FAILURE: {
      return {
        ...state,
        loading: loadingReducer(state.loading, action),
        error: {
          ...state.error,
          logoImageFile: action.payload.error,
        },

        data: {
          ...state.data,
          temporaryImageLogoUrl: null,
        },
      }
    }

    default:
      return state
  }
}
