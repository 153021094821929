import { PageResponse } from 'pluggy-js'
import { action } from 'typesafe-actions'

import {
  WebhookEvent,
  WebhookEventsFilters,
  WebhookEventsFiltersChangeRequest,
} from './types'

/// Fetch Webhook Events Request

export const FETCH_WEBHOOK_EVENTS_REQUEST = '[Request] Fetch Webhook Events'
export const FETCH_WEBHOOK_EVENTS_SUCCESS = '[Success] Fetch Webhook Events'
export const FETCH_WEBHOOK_EVENTS_FAILURE = '[Failure] Fetch Webhook Events'

export const fetchWebhookEventsRequest = (request: WebhookEventsFilters) =>
  action(FETCH_WEBHOOK_EVENTS_REQUEST, { request })
export const fetchWebhookEventsSuccess = (
  webhookEventsResponse: PageResponse<WebhookEvent>,
) => action(FETCH_WEBHOOK_EVENTS_SUCCESS, webhookEventsResponse)
export const fetchWebhookEventsFailure = (error: string) =>
  action(FETCH_WEBHOOK_EVENTS_FAILURE, { error })

export type FetchWebhookEventsRequestAction = ReturnType<
  typeof fetchWebhookEventsRequest
>
export type FetchWebhookEventsSuccessAction = ReturnType<
  typeof fetchWebhookEventsSuccess
>
export type FetchWebhookEventsFailureAction = ReturnType<
  typeof fetchWebhookEventsFailure
>

// Change Webhook Events Filters

export const CHANGE_WEBHOOK_EVENTS_FILTERS = 'Change Webhook events filters'

export const changeWebhookEventsFilters = (
  request: WebhookEventsFiltersChangeRequest,
) => action(CHANGE_WEBHOOK_EVENTS_FILTERS, { request })

export type ChangeWebhookEventsFiltersAction = ReturnType<
  typeof changeWebhookEventsFilters
>
