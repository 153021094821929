import React, { useCallback } from 'react'
import { Trans, useTranslation } from 'react-i18next'

import { Alert, Button } from '@pluggyai/ui'

import { openHubspotChat } from '../../lib/hubspot'
import { TrackEventName } from '../../modules/analytics/events'
import { track } from '../../modules/analytics/utils'
import { isTeamProSubscription } from '../../modules/team/types'
import { isTeamTrialSubscriptionExpired } from '../../modules/team/utils'
import { Props } from './FreeTrialAlert.types'

const DAYS_LEFT_FREE_TRIAL_WARNING = 5

const FreeTrialAlert = ({
  isFreeTrial,
  teamFreeTrialDaysLeft,
  team,
}: Props) => {
  const { t } = useTranslation()

  const lessThanFiveDaysLeftInFreeTrial =
    teamFreeTrialDaysLeft !== null &&
    teamFreeTrialDaysLeft <= DAYS_LEFT_FREE_TRIAL_WARNING

  const freeTrialExpired = team && isTeamTrialSubscriptionExpired(team)
  const lastDayOfFreeTrial =
    teamFreeTrialDaysLeft !== null && teamFreeTrialDaysLeft === 0

  const contactSalesI18nKey = freeTrialExpired
    ? 'team.trial.expired.action.contact-sales'
    : lessThanFiveDaysLeftInFreeTrial
    ? 'team.trial.5-days-left.action.contact-sales'
    : 'team.trial.action.contact-sales'

  const handleFreeTrialContactButtonClick = useCallback(
    (event: React.MouseEvent<HTMLButtonElement>) => {
      openHubspotChat('team.trial')

      track(TrackEventName.LINK_CLICKED, {
        location: 'FreeTrialAlert',
        i18nKey: contactSalesI18nKey,
        text: (event.target as HTMLDivElement).textContent,
      })
    },
    [contactSalesI18nKey],
  )

  // show alert only if: team is in TRIAL, or if had trial (expired) and is not paid/pro subscription
  const shouldShowFreeTrialAlert =
    isFreeTrial || (freeTrialExpired && !(team && isTeamProSubscription(team)))

  if (!shouldShowFreeTrialAlert) {
    return null
  }

  return (
    <Alert
      type={
        lessThanFiveDaysLeftInFreeTrial && !freeTrialExpired
          ? 'warning'
          : 'info'
      }
      message={
        <>
          {freeTrialExpired
            ? t('team.trial.expired.message')
            : lastDayOfFreeTrial
            ? t('team.trial.last-day.message')
            : lessThanFiveDaysLeftInFreeTrial
            ? t('team.trial.5-days-left.message', { teamFreeTrialDaysLeft })
            : t('team.trial.message', { teamFreeTrialDaysLeft })}{' '}
          <Trans
            i18nKey={contactSalesI18nKey}
            components={{
              a: <Button link onClick={handleFreeTrialContactButtonClick} />,
            }}
          />
        </>
      }
      size={'medium'}
    />
  )
}

export default React.memo(FreeTrialAlert)
