import { connect } from 'react-redux'

import { auth0LogoutRequest } from '../../modules/auth0/actions'
import { RootState } from '../../modules/reducer'
import LogoutPage from './LogoutPage'
import {
  MapDispatch,
  MapDispatchProps,
  MapStateProps,
} from './LogoutPage.types'

const mapState = (_state: RootState): MapStateProps => ({})

const mapDispatch = (dispatch: MapDispatch): MapDispatchProps => ({
  onLogout: () => dispatch(auth0LogoutRequest()),
})

export default connect(mapState, mapDispatch)(LogoutPage)
