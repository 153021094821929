import React, { useCallback } from 'react'
import { useTranslation } from 'react-i18next'

import { Input } from '@pluggyai/ui'

import { UnhideIcon } from '../../Icon/UnhideIcon'
import { Props } from './ApplicationClientKeys.types'
import CopyActionButton from './CopyActionButton'

import './ApplicationClientKeys.css'

const CREDENTIALS_PLACEHOLDER = '********-****-****-****-************'

const ApplicationClientKeys = ({
  application,
  canReadClientSecret,
  isLoading,
  onShowClientSecretClick,
  onCopyClientIdClick,
  onCopyClientSecretClick,
}: Props) => {
  const { t } = useTranslation()

  const isDemoApplication = application?.environment === 'DEMO'

  const handleShowClientSecretClick = useCallback(() => {
    if (!application || !canReadClientSecret) {
      return
    }
    const { clientSecret } = application
    if (clientSecret) {
      // client secret is already defined, skip new request
      return
    }
    onShowClientSecretClick(application)
  }, [onShowClientSecretClick, application, canReadClientSecret])

  const handleClientSecretFocus = useCallback(
    (event: React.FocusEvent<HTMLInputElement>) => {
      if (!application) {
        return
      }
      const { clientSecret } = application

      if (!clientSecret) {
        // no client secret visible
        return
      }
      event.target.select()
    },
    [application],
  )

  const handleClientIdFocus = useCallback(
    (event: React.FocusEvent<HTMLInputElement>) => event.target.select(),
    [],
  )

  const handleCopyClientSecretClick = useCallback(() => {
    if (!application) {
      return
    }
    onCopyClientSecretClick(application)
  }, [onCopyClientSecretClick, application])

  const handleCopyClientIdClick = useCallback(() => {
    if (!application) {
      return
    }
    onCopyClientIdClick(application)
  }, [onCopyClientIdClick, application])

  if (!application) {
    return null
  }

  const {
    clientId = CREDENTIALS_PLACEHOLDER,
    clientSecret = CREDENTIALS_PLACEHOLDER,
  } = application

  return (
    <div className={'ApplicationClientKeys'}>
      <Input
        type={'text'}
        label={t('application.item.field.clientId.label')}
        value={!isDemoApplication ? clientId : CREDENTIALS_PLACEHOLDER}
        disabled
        onFocus={handleClientIdFocus}
        icon={
          !isDemoApplication ? (
            <CopyActionButton
              text={clientId}
              onClick={handleCopyClientIdClick}
            />
          ) : undefined
        }
      />
      <Input
        type={'text'}
        label={t('application.item.field.clientSecret.label')}
        placeholder={CREDENTIALS_PLACEHOLDER}
        value={clientSecret}
        disabled
        loading={isLoading}
        onFocus={handleClientSecretFocus}
        icon={
          !isDemoApplication
            ? canReadClientSecret &&
              (clientSecret !== CREDENTIALS_PLACEHOLDER ? (
                <CopyActionButton
                  text={clientSecret}
                  onClick={handleCopyClientSecretClick}
                />
              ) : (
                <UnhideIcon onClick={handleShowClientSecretClick} />
              ))
            : undefined
        }
      />
    </div>
  )
}

export default React.memo(ApplicationClientKeys)
