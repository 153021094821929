import { ITEMS_STATS_EXECUTION_STATUS_GROUPS } from '../../../modules/stats/constants'

export const itemsStatsTypesGroupsNames =
  ITEMS_STATS_EXECUTION_STATUS_GROUPS.map((itemType) => itemType.name)

export type ItemsTypeValue = typeof itemsStatsTypesGroupsNames[number]

export type Props = {
  currentValue: ItemsTypeValue[] | null
  onChange: (value: ItemsTypeValue[]) => void
  selectedTypes: ItemsTypeValue[]
  disabled?: boolean
}

export type ItemFilterOption = { name: string; id: ItemsTypeValue }
