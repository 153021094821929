import { connect } from 'react-redux'

import { RootState } from '../../../modules/reducer'
import ApplicationDemoPreviewLink from './ApplicationNoCodeDashboardLink'
import {
  MapDispatch,
  MapDispatchProps,
  MapStateProps,
} from './ApplicationNoCodeDashboardLink.types'

const mapState = (_state: RootState): MapStateProps => ({
  // nothing here, yet
})

const mapDispatch = (_dispatch: MapDispatch): MapDispatchProps => ({
  // nothing here, yet
})

export default connect(mapState, mapDispatch)(ApplicationDemoPreviewLink)
