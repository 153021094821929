import { connect } from 'react-redux'

import {
  FETCH_BILLING_DASHBOARD_REQUEST,
  fetchBillingDashboardRequest,
} from '../../modules/billing/actions'
import {
  getData,
  getLoading,
  getError,
  isLoadingType,
} from '../../modules/billing/selectors'
import { RootState } from '../../modules/reducer'
import BillingPage from './BillingPage'
import {
  MapDispatch,
  MapDispatchProps,
  MapStateProps,
} from './BillingPage.types'

const mapState = (state: RootState): MapStateProps => ({
  billingDashboardUrl: getData(state),
  isLoading: isLoadingType(getLoading(state), FETCH_BILLING_DASHBOARD_REQUEST),
  error: !!getError(state),
})

const mapDispatch = (dispatch: MapDispatch): MapDispatchProps => ({
  onFetchBillingDashboard: () => dispatch(fetchBillingDashboardRequest()),
})

export default connect(mapState, mapDispatch)(BillingPage)
