import { memo, useCallback, useEffect, useState } from 'react'
import { Trans, useTranslation } from 'react-i18next'

import { Button, Confirm } from '@pluggyai/ui'
import { isAfter } from 'date-fns'

import { openHubspotChat } from '../../lib/hubspot'
import { TrackEventName } from '../../modules/analytics/events'
import { track } from '../../modules/analytics/utils'
import { getFreeTrialExpirationDate } from '../../modules/team/utils'
import { CloseIcon } from '../Icon'
import { Props } from './FreeTrialExpiredModal.types'

import './FreeTrialExpiredModal.css'

const FREE_TRIAL_EXPIRED_MODAL_FEATURE_DATE = '2023-04-13'

const FreeTrialExpiredModal = ({
  userHasClosedFreeTrialModal,
  currentTeam,
  onFreeTierExpiredModalClose,
}: Props) => {
  const { t } = useTranslation()
  const [modalOpen, setModalOpen] = useState(false)

  useEffect(() => {
    if (!currentTeam) {
      // team not loaded yet
      return
    }

    if (userHasClosedFreeTrialModal === undefined) {
      // user's ui state value userHasClosedFreeTrialModal not loaded yet
      return
    }

    const trialExpirationDate = getFreeTrialExpirationDate(currentTeam)
    const isFreeTrialExpiredDateAfterModalDate =
      trialExpirationDate &&
      isAfter(
        new Date(trialExpirationDate),
        new Date(FREE_TRIAL_EXPIRED_MODAL_FEATURE_DATE),
      )

    if (
      userHasClosedFreeTrialModal ||
      !isFreeTrialExpiredDateAfterModalDate ||
      currentTeam.subscription.plan === 'FULL'
    ) {
      // in this case the user has already closed the modal or the trial is not expired yet
      // or the trial is expired but the expiration date is before this feature's modal date
      // or Team is already in a paid subscription
      // -> don't open the modal
      return
    }

    setModalOpen(true)
  }, [currentTeam, userHasClosedFreeTrialModal])

  const handleClose = useCallback(() => {
    setModalOpen(false)
    onFreeTierExpiredModalClose()
  }, [onFreeTierExpiredModalClose])

  const handleModalCancel = useCallback(() => {
    handleClose()
  }, [handleClose])

  const contactUsButtonI18nKey = 'free-trial-expired-modal.actions.contact-us'
  const contactUsButtonText = t(contactUsButtonI18nKey)

  const handleModalConfirm = useCallback(() => {
    openHubspotChat('team.trial')
    handleClose()
    track(TrackEventName.BUTTON_CLICKED, {
      i18nKey: contactUsButtonI18nKey,
      text: contactUsButtonText,
      location: 'FreeTrialExpiredModal',
    })
  }, [contactUsButtonText, handleClose])

  return (
    <Confirm
      className={'FreeTrialExpiredModal'}
      size="small"
      content={
        <div className="content">
          <h2 className={'title'}>
            <Trans i18nKey={'free-trial-expired-modal.title'} />
          </h2>

          <p className={'description'}>
            <Trans i18nKey={'free-trial-expired-modal.description'} />
          </p>
        </div>
      }
      open={modalOpen}
      onCancel={handleModalCancel}
      cancelButton={
        <Button secondary>{t('free-trial-expired-modal.actions.close')}</Button>
      }
      onConfirm={handleModalConfirm}
      confirmButton={<Button primary>{contactUsButtonText}</Button>}
      onClose={handleClose}
      closeIcon={<CloseIcon />}
    />
  )
}

export default memo(FreeTrialExpiredModal)
