import axios, { AxiosError, AxiosInstance, AxiosRequestConfig } from 'axios'

import { urls, UrlsMap } from '../constants/urls'

type ErrorResponse = {
  // http status code
  status: number
  // server error response code
  code: number
  // server error message
  message: string
}

export abstract class BaseService {
  protected urls: UrlsMap
  private service: AxiosInstance | undefined

  private static asEmailHeader: string | undefined

  constructor(protected accessToken: string) {
    this.urls = urls
  }

  static setAsEmailHeader(asEmailHeader: string | undefined): void {
    this.asEmailHeader = asEmailHeader
  }

  /**
   * Get or initialize the Axios client instance
   */
  protected getServiceInstance(): AxiosInstance {
    if (this.service === undefined) {
      const axiosInstance: AxiosInstance = axios.create({
        headers: {
          Authorization: `Bearer ${this.accessToken}`,
        },
      })

      // setup a request interceptor to add header for impersonated user, if any
      this.setupAsEmailHeaderRequestInterceptor(axiosInstance)
      this.service = axiosInstance
    }
    return this.service
  }

  /**
   * Helper to setup a request interceptor, to add the 'asEmail' value header to
   * the request, whenever it's defined
   *
   * @param axiosInstance
   * @private
   */
  private setupAsEmailHeaderRequestInterceptor(
    axiosInstance: AxiosInstance,
  ): void {
    axiosInstance.interceptors.request.use((config: AxiosRequestConfig) => {
      const { asEmailHeader } = BaseService
      if (!asEmailHeader) {
        // value not set, just proceed with the original request
        return config
      }
      // add asEmail value to request header
      config.headers['x-pluggy-as-user-email'] = asEmailHeader
      return config
    })
  }

  /**
   * Replace url params (ie. /:id/) with the specified values
   */
  protected replaceUrlParams(
    url: string,
    params: Record<string, string | number>,
  ): string {
    let replacedUrl = url
    for (const [param, value] of Object.entries(params)) {
      replacedUrl = replacedUrl.replace(`:${param}`, String(value))
    }
    return replacedUrl
  }

  public static isServerError(
    error: Error,
  ): error is AxiosError<ErrorResponse> {
    return (error as AxiosError).isAxiosError
  }

  public static parseError(error: AxiosError<ErrorResponse>): ErrorResponse {
    const { request, response, message } = error

    if (request === undefined && response === undefined) {
      // Something happened in setting up the request that triggered an Error
      return {
        code: 0,
        status: 0,
        message: `Malformed request error: '${message}'`,
      }
    }

    if (response === undefined) {
      // The request was made but no response was received
      // `error.request` is an instance of XMLHttpRequest in the browser and an instance of
      // http.ClientRequest in node.js
      console.log(request)
      return {
        code: 0,
        status: 0,
        message: 'No response from server',
      }
    }

    // The request was made and the server responded with a status code
    // that falls out of the range of 2xx
    const {
      data: { code, message: serverMessage },
      status,
    } = response

    return {
      code,
      status,
      message: serverMessage,
    }
  }
}
