import { memo, useCallback, useState } from 'react'

import { Props } from './Accordion.types'
import { AccordionItem } from './AccordionItem'

import './Accordion.css'

const Accordion = ({ elements }: Props) => {
  const [activeElement, setActiveElement] = useState<number | null>(null)

  const handleAccordionItemSelect = useCallback(
    (index: number) => {
      if (activeElement === index) {
        // if active element is clicked, unselect it
        setActiveElement(null)
        return
      }
      setActiveElement(index)
    },
    [activeElement],
  )

  return (
    <div className={'Accordion'}>
      {elements.map(({ title, children }, index) => (
        <AccordionItem
          title={title}
          key={index}
          active={index === activeElement}
          onSelect={() => handleAccordionItemSelect(index)}
        >
          {children}
        </AccordionItem>
      ))}
    </div>
  )
}

export default memo(Accordion)
