import { connect } from 'react-redux'

import { isLoadingType } from '../../modules/application/selectors'
import { RootState } from '../../modules/reducer'
import {
  CREATE_TEAM_REQUEST,
  createTeamRequest,
} from '../../modules/team/actions'
import {
  getCurrentUserTeamRole,
  getError,
  getLoading,
} from '../../modules/team/selectors'
import { TeamFields } from '../../modules/team/types'
import TeamCreateModal from './TeamCreateModal'
import {
  MapDispatch,
  MapDispatchProps,
  MapStateProps,
} from './TeamCreateModal.types'

const mapState = (state: RootState): MapStateProps => ({
  userTeamMemberRole: getCurrentUserTeamRole(state),
  error: getError(state),
  isLoading: isLoadingType(getLoading(state), CREATE_TEAM_REQUEST),
})

const mapDispatch = (dispatch: MapDispatch): MapDispatchProps => ({
  onCreateTeam: (createTeamFields: TeamFields) =>
    dispatch(createTeamRequest(createTeamFields)),
})

export default connect(mapState, mapDispatch)(TeamCreateModal)
