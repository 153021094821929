import { format } from 'date-fns'
import { enUS, pt } from 'date-fns/locale'

import { getCurrentLanguageOrDefault } from '../utils/i18n/'

/**
 * Returns a formatted date string like 'MMMM dd, yyyy, hh:hh'
 * @param date - date to format
 * @param locale - locale to use
 * @returns - formatted date string
 */
export function formatDateTime(date: Date, dateFormat: string): string {
  return format(new Date(date), dateFormat, {
    locale: getCurrentLanguageOrDefault() === 'pt' ? pt : enUS,
  })
}
