import { TimeFilterValue } from './ConnectorsStatusTimeFilter.types'

const timeValuesI18nKeyMap: Record<TimeFilterValue, string> = {
  '24h': 'connectorsStatus.connectors-status-time-filter.24h',
  '24d': 'connectorsStatus.connectors-status-time-filter.24d',
}

export function mapTimeValueToI18nKey(timeValue: TimeFilterValue): string {
  const i18nKey = timeValuesI18nKeyMap[timeValue]
  return i18nKey
}
