import { connect } from 'react-redux'

import { getLocation, push } from 'connected-react-router'

import { auth0LogoutRequest } from '../../modules/auth0/actions'
import { getError as getAuth0Error } from '../../modules/auth0/selectors'
import { RootState } from '../../modules/reducer'
import { selectCurrentTeamRequest } from '../../modules/team/actions'
import { getCurrentTeam, getTeams } from '../../modules/team/selectors'
import { Team } from '../../modules/team/types'
import { getUser, getUserError } from '../../modules/user/selectors'
import Navbar from './Navbar'
import { MapDispatch, MapDispatchProps, MapStateProps } from './Navbar.types'

const mapState = (state: RootState): MapStateProps => ({
  location: getLocation(state),
  authenticationError: getAuth0Error(state) || getUserError(state),
  user: getUser(state),
  teams: getTeams(state),
  currentTeam: getCurrentTeam(state),
})

const mapDispatch = (dispatch: MapDispatch): MapDispatchProps => ({
  onLogout: () => dispatch(auth0LogoutRequest()),
  onNavigate: (path: string) => dispatch(push(path)),
  onSelectCurrentProfile: (team: Team) =>
    dispatch(selectCurrentTeamRequest(team)),
})

export default connect(mapState, mapDispatch)(Navbar)
