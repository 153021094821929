import { AnyAction } from 'redux'

import { LoadingState } from '../loading/types'
import { RootState } from '../reducer'
import {
  FETCH_USER_REQUEST,
  RESEND_VERIFICATION_EMAIL_REQUEST,
} from './actions'
import { UserState } from './reducer'
import { Theme, User } from './types'
import { isUserActionsRequired } from './utils'

export const getState = (state: RootState): UserState => state.user as UserState
export const getData = (state: RootState) => getState(state).data
export const getLoading = (state: RootState) => getState(state).loading
export const getError = (state: RootState) => getState(state).error

export const getUserError = (state: RootState) => getState(state).error.user
export const getResendVerificationEmailError = (state: RootState) =>
  getState(state).error.resendVerificationEmail

export const isLoadingType: (state: LoadingState, type: string) => boolean = (
  state,
  type,
) => state.some((action: AnyAction) => action.type === type)

export const isUserLoading = (state: RootState) =>
  isLoadingType(getLoading(state), FETCH_USER_REQUEST)

export const getUser = (state: RootState): User | null => {
  const { user, asUser } = getData(state)
  if (asUser) {
    // is impersonating
    return asUser
  }
  return user
}

export const getAsUser = (state: RootState): User | null =>
  getData(state).asUser

/**
 * Util to check if current user contains 'test' in it's email
 * after a '+' symbol and before the '@'.
 */
export const isCurrentUserTest = (state: RootState): boolean => {
  const user = getUser(state)
  if (!user) {
    return false
  }
  const { email } = user
  if (!email) {
    return false
  }
  return /\+.*?(test).*?@/.test(email)
}

export const isCurrentUserPluggueiro = (state: RootState): boolean => {
  const user = getUser(state)
  if (!user) {
    return false
  }
  const { email } = user
  if (!email) {
    return false
  }
  return /.*@pluggy\.ai$/.test(email)
}

export const getIsUserActionsRequired = (state: RootState): boolean => {
  const user = getUser(state)
  if (!user) {
    return false
  }
  return isUserActionsRequired(user)
}

export const getUserAcceptedTerms = (state: RootState): boolean => {
  const user = getUser(state)
  if (!user) {
    return false
  }

  return user.termsAccepted
}

export const getShowUserActionsRequired = (state: RootState): boolean =>
  getData(state).showUserActionsRequired

export const isResendVerificatonEmailLoading = (state: RootState): boolean =>
  isLoadingType(getLoading(state), RESEND_VERIFICATION_EMAIL_REQUEST)

export const isCurrentUserCreateDateAfterDate = (
  state: RootState,
  date: Date,
): boolean => {
  const user = getUser(state)
  if (!user) {
    return false
  }
  const { createdAt } = user

  return new Date(createdAt) > date
}

export const getUserHasClosedFreeTrialExpiredModal = (
  state: RootState,
): boolean | undefined => {
  const { uiState } = getUser(state) || { uiState: null }

  return uiState?.userHasClosedFreeTrialExpiredModal
}

export const getTheme = (state: RootState): Theme => {
  const { uiState } = getUser(state) || { uiState: null }

  if (!uiState) {
    return 'dark'
  }

  const { theme } = uiState

  return theme || 'dark'
}
