import { ConnectorStatsReport } from '../../../../../modules/stats/types'
import { Status } from './StatusBarContainer.types'

/**
 * get the connector stats and return an average
 * @param connectorStats - array of executions
 * @returns {number} - average of the expected rate of all the execution
 */
export function getConnectorExpectedRateAverage(
  connectorStats: ConnectorStatsReport[] | undefined,
): string {
  if (!connectorStats) {
    return '-'
  }
  const connectorStatsSum = connectorStats.reduce(
    (average: number, executionStat: ConnectorStatsReport) =>
      average + executionStat.expectedRate,
    0,
  )
  const average = Math.round((connectorStatsSum / connectorStats.length) * 100)

  return average.toString()
}

/**
 * get a single execution and depending on the expected rate
 * an status is setted
 * @param connectorStats
 * @returns {Status} - the current status of the execution
 */
export function mapStatusFromConnectorStat(
  connectorStats: ConnectorStatsReport | undefined,
): Status {
  const defaultStatus = 'operational'
  if (!connectorStats) {
    // there is no connection, expected rate is 100%
    return defaultStatus
  }

  const { expectedRate } = connectorStats
  if (expectedRate >= 0.95) {
    return 'operational'
  }
  if (expectedRate >= 0.9) {
    return 'degradedPerformance'
  }
  if (expectedRate < 0.9) {
    return 'outage'
  }

  return defaultStatus
}
