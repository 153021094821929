import React, { useCallback } from 'react'
import { useTranslation } from 'react-i18next'

import { Button } from '@pluggyai/ui'

import { openHubspotChat } from '../../../lib/hubspot'
import { TrackEventName } from '../../../modules/analytics/events'
import { track } from '../../../modules/analytics/utils'
import { IsDisabledPopup } from '../IsDisabledPopup'
import { Props } from './ConsentCustomization.types'

import './ConsentCustomization.css'

const ConsentCustomization = ({
  isFreeSubscription,
  isTeamOwnerOrAdminRole,
}: Props) => {
  const { t } = useTranslation()

  const contactLinkI18nKey = 'customization.form.consent.action.talk-with-us'
  const contactLinkI18nText = t(contactLinkI18nKey)

  const trackConsentUpdateRequest = useCallback(() => {
    openHubspotChat('customization.form.consent')

    track(TrackEventName.LINK_CLICKED, {
      location: 'CustomizationPage ConsentCustomization',
      text: contactLinkI18nText,
      i18nKey: contactLinkI18nKey,
    })
  }, [contactLinkI18nText])

  return (
    <div className={'ConsentCustomization'}>
      <div className={'header-container'}>
        <IsDisabledPopup
          isFreeSubscription={isFreeSubscription}
          isTeamMemberRole={!isTeamOwnerOrAdminRole}
        >
          <Button
            link
            disabled={isFreeSubscription || !isTeamOwnerOrAdminRole}
            onClick={trackConsentUpdateRequest}
          >
            {contactLinkI18nText}
          </Button>
        </IsDisabledPopup>
      </div>
      <p>{t('customization.form.consent.text')}</p>
    </div>
  )
}

export default React.memo(ConsentCustomization)
