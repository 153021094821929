import { connect } from 'react-redux'

import { auth0LoginRequest } from '../../modules/auth0/actions'
import { getError, isAuth0LoggingIn } from '../../modules/auth0/selectors'
import { RootState } from '../../modules/reducer'
import { getCurrentTeam, getTeams } from '../../modules/team/selectors'
import CallbackPage from './CallbackPage'
import {
  MapDispatch,
  MapDispatchProps,
  MapStateProps,
  OwnProps,
} from './CallbackPage.types'

const mapState = (state: RootState, ownProps: OwnProps): MapStateProps => {
  let loadingSelectorsResult = false
  for (const loadingSelector of ownProps.isLoadingSelectors) {
    loadingSelectorsResult = loadingSelectorsResult || loadingSelector(state)
  }

  return {
    isLoading: isAuth0LoggingIn(state) || loadingSelectorsResult,
    error: getError(state),
    teams: getTeams(state),
    currentTeam: getCurrentTeam(state),
  }
}

const mapDispatch = (dispatch: MapDispatch): MapDispatchProps => ({
  onAuth0Login: (getIdTokenClaims, getAccessTokenSilently) =>
    dispatch(auth0LoginRequest(getIdTokenClaims, getAccessTokenSilently)),
})

export default connect(mapState, mapDispatch)(CallbackPage)
