import { connect } from 'react-redux'

import { replace } from 'connected-react-router'

import { FETCH_APPLICATION_REQUEST } from '../../modules/application/actions'
import {
  getApplication,
  getApplicationItemsCount,
  getError,
  isDemoApplication,
  isLoadingApplication,
} from '../../modules/application/selectors'
import { RootState } from '../../modules/reducer'
import { locations } from '../../modules/routing/locations'
import { fetchItemsStatsRequest } from '../../modules/stats/actions'
import { getError as getStatsError } from '../../modules/stats/selectors'
import { ItemsStatsRequest } from '../../modules/stats/types'
import {
  currentTeamCanGoToProduction,
  getCurrentTeam,
  isUserTeamRole,
} from '../../modules/team/selectors'
import ApplicationPage from './ApplicationPage'
import {
  MapDispatch,
  MapDispatchProps,
  MapStateProps,
} from './ApplicationPage.types'

const mapState = (state: RootState): MapStateProps => {
  const application = getApplication(state)

  return {
    application,
    canAccessDangerZone:
      !isDemoApplication(state) &&
      (!getCurrentTeam(state) || isUserTeamRole(state, ['OWNER'])),
    isLoading: isLoadingApplication(state, FETCH_APPLICATION_REQUEST),
    error: getError(state),
    statsError: getStatsError(state),
    applicationItemsCount:
      application && getApplicationItemsCount(state, application),
    teamCanGoToProduction: currentTeamCanGoToProduction(state),
  }
}

const mapDispatch = (dispatch: MapDispatch): MapDispatchProps => ({
  onFetchApplicationStats: (filterableStats?: ItemsStatsRequest) =>
    dispatch(fetchItemsStatsRequest(filterableStats)),
  onGoToApplications: () => dispatch(replace(locations.applications())),
})

export default connect(mapState, mapDispatch)(ApplicationPage)
