import React, { useCallback, useState } from 'react'
import { useTranslation } from 'react-i18next'

import { Alert, Button, Radio } from '@pluggyai/ui'
import { Divider, Header, Loader } from 'semantic-ui-react'

import { TrackEventName } from '../../../../modules/analytics/events'
import { track } from '../../../../modules/analytics/utils'
import { UserDeleteConfirmModal } from '../UserDeleteConfirmModal'
import { Props } from './UserDeleteWidget.types'

import './UserDeleteWidget.css'

const UserDeleteWidget = ({ user, isLoading, error }: Props) => {
  const { t } = useTranslation()

  const [checked, setChecked] = useState(false)

  const deleteButtonI18n = 'settings.user.danger.delete.action'
  const deleteButtonText = t(deleteButtonI18n)

  const confirmCheckI18nKey = 'settings.user.danger.delete.confirm'
  const confirmCheckText = t(confirmCheckI18nKey)

  const handleCheckboxClick = useCallback(() => {
    track(TrackEventName.CHECKBOX_CLICKED, {
      location: 'UserSettings UserDeleteWidget',
      value: checked,
      text: confirmCheckText,
      i18nKey: confirmCheckI18nKey,
    })
    setChecked((previous) => !previous)
  }, [confirmCheckText, checked])

  const [confirmModalVisible, setConfirmModalVisible] = useState(false)
  const handleDeleteActionClick = useCallback(() => {
    track(TrackEventName.BUTTON_CLICKED, {
      location: 'UserSettings UserDeleteWidget',
      text: deleteButtonText,
      i18nKey: deleteButtonI18n,
    })
    setConfirmModalVisible(true)
    setChecked(false)
  }, [deleteButtonText])

  const handleConfirmModalClose = useCallback(() => {
    setConfirmModalVisible(false)
  }, [])

  if (!user) {
    return null
  }

  return (
    <div className={'UserDeleteWidget'}>
      {isLoading ? (
        <div className={'widget-section-loader'}>
          <Loader size="big" active />
        </div>
      ) : (
        <>
          <div className={'pluggy card user-delete'}>
            <Header as={'h5'} className={'user-delete-header'}>
              {t('settings.user.danger.delete.header')}
            </Header>
            {error && <Alert size={'medium'} type={'error'} message={error} />}
            <p>{t('settings.user.danger.delete.content')}</p>
            <Radio
              checked={checked}
              onClick={handleCheckboxClick}
              label={confirmCheckText}
            />
            <Divider />
            <div className={'action-buttons'}>
              <Button
                primary
                onClick={handleDeleteActionClick}
                disabled={!checked}
              >
                {deleteButtonText}
              </Button>
            </div>
          </div>
          {confirmModalVisible && (
            <UserDeleteConfirmModal
              user={user}
              onClose={handleConfirmModalClose}
            />
          )}
        </>
      )}
    </div>
  )
}

export default React.memo(UserDeleteWidget)
