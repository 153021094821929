import React from 'react'

import Icon from '../Icon'

import './ArrowIcon.css'

type Props = {
  onClick?: () => void
  className?: string
}

const ArrowIcon = ({ onClick, className }: Props) => (
  <Icon
    className={`ArrowIcon ${className || ''}`}
    onClick={onClick}
    width="30"
    height="30"
    viewBox="0 0 20 20"
    fill="none"
  >
    <path
      d="M5.83362 14.1665L14.167 5.83314"
      stroke="#F5F5F5"
      strokeWidth="1.25"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M5.83362 5.83314L14.167 5.83314L14.167 14.1665"
      stroke="#F5F5F5"
      strokeWidth="1.25"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
  </Icon>
)

export default React.memo(ArrowIcon)
