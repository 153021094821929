import { connect } from 'react-redux'

import { getConnectors } from '../../../modules/customization/selectors'
import { RootState } from '../../../modules/reducer'
import { changeItemStatsFilters } from '../../../modules/stats/actions'
import { getItemStatsFilters } from '../../../modules/stats/selectors'
import { ItemStatsFiltersChangeRequest } from '../../../modules/stats/types'
import ConnectorsFilter from './ConnectorsFilter'
import {
  MapDispatch,
  MapDispatchProps,
  MapStateProps,
} from './ConnectorsFilter.types'

const mapState = (state: RootState): MapStateProps => ({
  connectors: getConnectors(state),
  selectedConnector: getItemStatsFilters(state).selectedConnector,
  showSandbox: getItemStatsFilters(state).showSandbox,
})

const mapDispatch = (dispatch: MapDispatch): MapDispatchProps => ({
  onChange: (itemStatsFilters: ItemStatsFiltersChangeRequest) =>
    dispatch(changeItemStatsFilters(itemStatsFilters)),
})

export default connect(mapState, mapDispatch)(ConnectorsFilter)
