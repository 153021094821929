import { connect } from 'react-redux'

import { isLoadingType } from '../../modules/application/selectors'
import { RootState } from '../../modules/reducer'
import {
  CREATE_TEAM_MEMBER_REQUEST,
  createTeamMemberRequest,
} from '../../modules/team/actions'
import {
  getCurrentTeam,
  getCurrentUserTeamRole,
  getError,
  getLoading,
} from '../../modules/team/selectors'
import { MemberInvitation } from '../../modules/team/types'
import TeamAddMembersModal from './TeamAddMembersModal'
import {
  MapDispatch,
  MapDispatchProps,
  MapStateProps,
} from './TeamAddMembersModal.types'

const mapState = (state: RootState): MapStateProps => ({
  userTeamMemberRole: getCurrentUserTeamRole(state),
  error: getError(state),
  isLoading: isLoadingType(getLoading(state), CREATE_TEAM_MEMBER_REQUEST),
  currentTeam: getCurrentTeam(state),
})

const mapDispatch = (dispatch: MapDispatch): MapDispatchProps => ({
  onCreateTeamMember: (
    teamId: string,
    createTeamMemberFields: MemberInvitation[],
  ) => dispatch(createTeamMemberRequest(teamId, createTeamMemberFields)),
})

export default connect(mapState, mapDispatch)(TeamAddMembersModal)
