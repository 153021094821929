import { memo } from 'react'
import { useTranslation } from 'react-i18next'

import {
  Bar,
  BarChart,
  CartesianGrid,
  Legend,
  ResponsiveContainer,
  Tooltip,
  XAxis,
  YAxis,
} from 'recharts'

import {
  DailyChartDarkThemeColors,
  DailyChartLightThemeColors,
} from '../../../lib/constants/colors'
import { ITEMS_STATS_EXECUTION_STATUS_GROUPS } from '../../../modules/stats/constants'
import { EmptyChartMessage } from '../EmptyChartMessage'
import { Props } from './DailyChart.types'

import './DailyChart.css'

const DailyChart = ({
  dailyItemStatsData,
  theme,
  teamOrUserHasItemsConnected,
}: Props) => {
  const { t } = useTranslation()

  const DailyChartColors =
    theme === 'light' ? DailyChartLightThemeColors : DailyChartDarkThemeColors

  const hasItemsForSelectedRange = dailyItemStatsData.some(
    ({ total }) => total !== 0,
  )

  return (
    <div className={'DailyChart'}>
      <h2 className={'title'}>{t('dailyItems.title')}</h2>
      {hasItemsForSelectedRange ? (
        <div className={'daily-chart-wrapper'}>
          {/* Using width:'99%' and 'aspect: 3' suggested by https://stackoverflow.com/a/53205850/6279385 
        To help responsive chart work with flexbox */}
          {/* heigth={246} to match the design */}
          <ResponsiveContainer width="99%" height={246}>
            <BarChart
              barSize={300}
              data={dailyItemStatsData}
              margin={{
                top: 20,
                right: 1,
                bottom: 0,
                left: -10,
              }}
            >
              <CartesianGrid
                strokeDasharray="5 5"
                stroke={DailyChartColors.LIGHT_GREY}
                vertical={false}
              />
              <XAxis dataKey="day" />
              <YAxis tick={true} />
              <Tooltip
                cursor={{ fill: DailyChartColors.HOVER }} // grey 700
                content={(props) => (
                  <div className="tooltip-legend">
                    {props.payload
                      ?.filter(({ value }) => value !== 0)
                      ?.map(({ color, value, dataKey }, index) => (
                        <p key={index} style={{ color }}>
                          {t(`items-stats.legend.${dataKey}`)}: {value}
                        </p>
                      ))}
                  </div>
                )}
              />

              <Legend
                iconSize={16}
                iconType={'circle'}
                // note: overriding default styles to center the legend
                wrapperStyle={{
                  left: '50%',
                  transform: 'translateX(-50%)',
                }}
              />
              {ITEMS_STATS_EXECUTION_STATUS_GROUPS.map(
                ({ colorName, name }) => (
                  <Bar
                    key={name}
                    dataKey={name}
                    stackId="a"
                    fill={DailyChartColors[colorName]}
                    name={t(`items-stats.legend.${name}`)}
                  />
                ),
              )}
            </BarChart>
          </ResponsiveContainer>
        </div>
      ) : (
        <EmptyChartMessage
          teamOrUserHasItemsConnected={teamOrUserHasItemsConnected}
        />
      )}
    </div>
  )
}

export default memo(DailyChart)
