import { connect } from 'react-redux'

import { fetchConnectTokenRequest } from '../../../modules/connectToken/actions'
import { getData as getConnectToken } from '../../../modules/connectToken/selectors'
import { RootState } from '../../../modules/reducer'
import { getData } from '../../../modules/team/selectors'
import { PreviewButton } from './PreviewButton'
import {
  MapDispatch,
  MapDispatchProps,
  MapStateProps,
} from './PreviewButton.types'

const mapState = (state: RootState): MapStateProps => ({
  connectToken: getConnectToken(state)?.connectToken,
  team: getData(state).current,
})

const mapDispatch = (dispatch: MapDispatch): MapDispatchProps => ({
  onFetchConnectToken: () => dispatch(fetchConnectTokenRequest()),
})

export default connect(mapState, mapDispatch)(PreviewButton)
