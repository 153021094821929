import { connect } from 'react-redux'

import { RootState } from '../../modules/reducer'
import {
  CREATE_TEAM_REQUEST,
  createTeamRequest,
} from '../../modules/team/actions'
import {
  getCurrentUserTeamRole,
  getError,
  getLoading as getTeamLoading,
  getTeams,
  isLoadingType,
} from '../../modules/team/selectors'
import { TeamFields } from '../../modules/team/types'
import {
  UPDATE_USER_TERMS_ACCEPTED_REQUEST,
  updateUserTermsAcceptedRequest,
  updateUserRequest,
} from '../../modules/user/actions'
import {
  getLoading as getUserLoading,
  getUserAcceptedTerms,
} from '../../modules/user/selectors'
import OnboardingPage from './OnboardingPage'
import {
  MapDispatch,
  MapDispatchProps,
  MapStateProps,
} from './OnboardingPage.types'

const mapState = (state: RootState): MapStateProps => ({
  teams: getTeams(state),
  userTeamMemberRole: getCurrentUserTeamRole(state),
  error: getError(state),
  isLoading:
    isLoadingType(getTeamLoading(state), CREATE_TEAM_REQUEST) ||
    isLoadingType(getUserLoading(state), UPDATE_USER_TERMS_ACCEPTED_REQUEST),
  acceptedTermsAndConditions: getUserAcceptedTerms(state),
})

const mapDispatch = (dispatch: MapDispatch): MapDispatchProps => ({
  onCreateTeam: (createTeamFields: TeamFields) =>
    dispatch(createTeamRequest(createTeamFields)),
  onAcceptTermsRequest: () => dispatch(updateUserTermsAcceptedRequest()),
  onUpdateUser: ({ companyRole, platforms }) =>
    dispatch(updateUserRequest({ companyRole, platforms })),
})

export default connect(mapState, mapDispatch)(OnboardingPage)
