import { memo, useCallback, useState } from 'react'
import { useTranslation } from 'react-i18next'

import { Button } from '@pluggyai/ui'
import { Cell, Pie, PieChart } from 'recharts'

import {
  DailyChartDarkThemeColors,
  DailyChartLightThemeColors,
} from '../../../lib/constants/colors'
import { TrackEventName } from '../../../modules/analytics/events'
import { track } from '../../../modules/analytics/utils'
import { getItemStatsTypesGroupsReport } from '../../../modules/stats/utils'
import { ChevronDownIcon } from '../../Icon/ChevronDownIcon'
import { ChevronUpIcon } from '../../Icon/ChevronUpIcon'
import { EmptyChartMessage } from '../EmptyChartMessage'
import { LegendItem } from './LegendItem'
import { Props } from './Report.types'

import './Report.css'

const Report = ({
  itemsStatsReport,
  theme,
  currentItemsType,
  teamOrUserHasItemsConnected,
}: Props) => {
  const { t } = useTranslation()

  const [detailsOpen, setDetailsOpen] = useState(false)

  const handleDetailsVisibilityToggle = useCallback(
    (event: React.MouseEvent<HTMLButtonElement>) => {
      track(TrackEventName.LINK_CLICKED, {
        location: 'Stats Report',
        text: event.currentTarget.textContent,
        i18nKey: `items-report.${detailsOpen ? 'hide' : 'show'}-details`,
      })

      setDetailsOpen((previous) => !previous)
    },
    [detailsOpen],
  )

  const total = itemsStatsReport?.totalItems || 0

  const colors =
    theme === 'dark' ? DailyChartDarkThemeColors : DailyChartLightThemeColors

  const itemStatsTypesGroupsReport = getItemStatsTypesGroupsReport(
    itemsStatsReport,
    colors,
  ).filter(({ name, value }) => currentItemsType.includes(name) && value !== 0)

  return (
    <div className="Report">
      <h2 className={'title'}>{t('items-report.title')}</h2>

      {total === 0 ? (
        <EmptyChartMessage
          teamOrUserHasItemsConnected={teamOrUserHasItemsConnected}
        />
      ) : (
        <div className={'report-body'}>
          <PieChart width={209} height={209}>
            <Pie
              data={itemStatsTypesGroupsReport}
              innerRadius={50}
              outerRadius={100}
              dataKey="value"
            >
              {itemStatsTypesGroupsReport.map((entry, index) => (
                <Cell
                  key={`cell-${index}`}
                  fill={entry.color}
                  stroke={entry.color}
                />
              ))}
            </Pie>
          </PieChart>
          <div className={'data'}>
            <div className={'total-items'}>
              <span className={'total-items-value'}>{total}</span>{' '}
              <span className={'total-items-label'}>
                {t('items-stats.total-items.title')}
              </span>
            </div>
            <div className={'legend'}>
              {itemStatsTypesGroupsReport.map((dataItem, index) => (
                <LegendItem {...{ ...dataItem, total }} key={index} />
              ))}
            </div>
          </div>
        </div>
      )}

      {detailsOpen && (
        <div className={'details'}>
          {itemStatsTypesGroupsReport.map((dataItem, index) => (
            <div className={'detail'} key={index}>
              <LegendItem {...{ ...dataItem, total }} />
              {Object.entries(dataItem.detail).map(([key, value]) => (
                <div key={key} className={'detail-entry'}>
                  {t(`items-stats.details-legend.${key}`)}
                  <span className={'value'}>{value || '-'}</span>
                </div>
              ))}
            </div>
          ))}
        </div>
      )}

      <div className={'footer'}>
        <Button
          link
          onClick={handleDetailsVisibilityToggle}
          className={'show-details'}
          disabled={total === 0}
        >
          {detailsOpen ? (
            <>
              {t('items-report.hide-details')}
              <ChevronUpIcon />
            </>
          ) : (
            <>
              {t('items-report.show-details')}
              <ChevronDownIcon className={total === 0 ? 'disabled' : ''} />
            </>
          )}
        </Button>
      </div>
    </div>
  )
}

export default memo(Report)
