import { action } from 'typesafe-actions'

import { ConnectToken } from './types'

export const FETCH_CONNECT_TOKEN_REQUEST = '[Request] Fetch Connect Token'
export const FETCH_CONNECT_TOKEN_SUCCESS = '[Success] Fetch Connect Token'
export const FETCH_CONNECT_TOKEN_FAILURE = '[Failure] Fetch Connect Token'

export const fetchConnectTokenRequest = () =>
  action(FETCH_CONNECT_TOKEN_REQUEST, {})
export const fetchConnectTokenSuccess = (connectToken: ConnectToken) =>
  action(FETCH_CONNECT_TOKEN_SUCCESS, connectToken)
export const fetchConnectTokenFailure = (error: string) =>
  action(FETCH_CONNECT_TOKEN_FAILURE, { error })

export type FetchConnectTokenRequestAction = ReturnType<
  typeof fetchConnectTokenRequest
>
export type FetchConnectTokenSuccessAction = ReturnType<
  typeof fetchConnectTokenSuccess
>
export type FetchConnectTokenFailureAction = ReturnType<
  typeof fetchConnectTokenFailure
>
