import React, { useCallback, useEffect, useRef, useState } from 'react'
import { useTranslation } from 'react-i18next'

import { Form, Header, Input } from '@pluggyai/ui'

import { TrackEventName } from '../../../modules/analytics/events'
import { track } from '../../../modules/analytics/utils'
import { UserFieldErrors } from '../../../modules/user/types'
import { Avatar } from '../../Avatar'
import { Props, UserValues } from './UserDetailsSection.types'
import { validateUserField } from './utils'

import './UserDetailsSection.css'

const FORM_AUTO_SUBMIT_TIMEOUT_MS = 1000 // 1 second

const UserDetailsSection = ({ user, isLoading, onUpdateUser }: Props) => {
  const { t } = useTranslation()

  const [userValues, setUserValues] = useState<UserValues>()
  const [formErrors, setFormErrors] = useState<UserFieldErrors>({})

  const submitTimeout = useRef<number>()

  const handleUserValuesChange = useCallback((newValues: UserValues) => {
    setUserValues((oldValues) => ({ ...oldValues, ...newValues }))
  }, [])

  const trackFormValidationError = useCallback((errors: UserFieldErrors) => {
    track(TrackEventName.FORM_VALIDATION_ERRORS, {
      fields: Object.keys(errors),
      location: 'UserDetailsSection',
    })
  }, [])

  const handleSubmit = useCallback(
    (value?: string) => {
      setFormErrors({})

      if (value === user?.imageUrl || value === undefined) {
        // value hasn't changed, don't do anything
        return
      }

      const imageUrlFieldErrors = validateUserField('imageUrl', value)

      if (imageUrlFieldErrors) {
        setFormErrors({ imageUrl: imageUrlFieldErrors })
        trackFormValidationError(formErrors)
        return
      }

      if (submitTimeout.current) {
        clearTimeout(submitTimeout.current)
      }

      const updatedValue = value !== '' ? value : null

      onUpdateUser({ imageUrl: updatedValue })
    },
    [formErrors, onUpdateUser, trackFormValidationError, user],
  )

  const handleFormSubmit = useCallback(
    (event: React.FormEvent) => {
      event.preventDefault()

      if (!userValues) {
        return
      }
      handleSubmit(userValues.imageUrl)
    },
    [handleSubmit, userValues],
  )

  const handleChangeImageUrl = useCallback(
    ({ value }: { value: string }) => {
      handleUserValuesChange({ imageUrl: value })

      // set timer to auto-submit
      if (submitTimeout.current) {
        clearTimeout(submitTimeout.current)
      }
      submitTimeout.current = window.setTimeout(() => {
        handleSubmit(value)
      }, FORM_AUTO_SUBMIT_TIMEOUT_MS)
    },

    [handleUserValuesChange, handleSubmit],
  )

  useEffect(() => {
    if (!user) {
      return
    }

    handleUserValuesChange({
      imageUrl: user.imageUrl !== null ? user.imageUrl : undefined,
      name: user.name,
      email: user.email,
    })
  }, [handleUserValuesChange, user])

  return (
    <div className={'UserDetailsSection'}>
      {user && (
        <>
          <div className={'user'}>
            <Avatar pictureUrl={user.imageUrl} className={'large'} />
            <div className={'user-data'}>
              <Header as={'h4'} className={'ellipsis'}>
                {t('navbar.profile-options.action.user-settings')}
              </Header>
              <p className={'ellipsis'}>{user.name}</p>
            </div>
          </div>
          <Form className="fields" onSubmit={handleFormSubmit}>
            <Input
              label={t('user.form.field.name.label')}
              type={'text'}
              value={userValues?.name}
              disabled
            />
            <Input
              label={t('user.form.field.email.label')}
              type={'text'}
              value={userValues?.email}
              disabled
            />
            <Input
              label={t('user.form.field.imageUrl.label')}
              type={'text'}
              onChange={handleChangeImageUrl}
              value={userValues?.imageUrl}
              error={
                formErrors.imageUrl !== null ? formErrors.imageUrl : undefined
              }
              disabled={isLoading}
              optionalMessage={t('general.form.optional-message')}
            />
            {/* Hidden input to allow submitting with "enter" key */}
            <input type="submit" style={{ display: 'none' }} />
          </Form>
        </>
      )}
    </div>
  )
}

export default React.memo(UserDetailsSection)
