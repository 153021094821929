import { connect } from 'react-redux'

import { FETCH_ACTIVITY_REQUEST } from '../../../modules/activity/actions'
import {
  getData,
  getLoading,
  isLoadingType,
} from '../../../modules/activity/selectors'
import { RootState } from '../../../modules/reducer'
import { isUserLoading } from '../../../modules/user/selectors'
import RecentActivity from './RecentActivity'
import {
  MapDispatch,
  MapDispatchProps,
  MapStateProps,
} from './RecentActivity.types'

const mapState = (state: RootState): MapStateProps => ({
  isLoading:
    isUserLoading(state) ||
    isLoadingType(getLoading(state), FETCH_ACTIVITY_REQUEST),
  activityItems: getData(state),
})

const mapDispatch = (_dispatch: MapDispatch): MapDispatchProps => ({
  // nothing here, yet
})

export default connect(mapState, mapDispatch)(RecentActivity)
