import { isValidUrl } from '../../utils/validation'
import { ItemsStats } from '../stats/types'
import { User } from '../user/types'
import { Application } from './types'

const isLocaldev = process.env.NODE_ENV === 'development'
const pluggyDemoUrl = process.env.REACT_APP_PLUGGY_DEMO_URL
const pluggyNoCodeDashboardUrl =
  process.env.REACT_APP_PLUGGY_NO_CODE_DASHBOARD_URL

if (!pluggyDemoUrl) {
  throw new Error('Missing env variable REACT_APP_PLUGGY_DEMO_URL')
}

// validate PLUGGY_DEMO_URL url value
// accepting 'localhost' URL for local dev only.
if (
  !(
    (isLocaldev && pluggyDemoUrl.includes('localhost')) ||
    isValidUrl(pluggyDemoUrl)
  )
) {
  throw new Error(
    `Env variable REACT_APP_PLUGGY_DEMO_URL is not a valid URL: ${pluggyDemoUrl}, for current NODE_ENV: ${process.env.NODE_ENV}`,
  )
}

export function buildPluggyDemoApplicationUrl(
  application: Application,
  user: User | null,
): string {
  return `${pluggyDemoUrl}/?application_id=${application.id}${
    user ? `&e=${encodeURIComponent(user.email)}` : ''
  }`
}

export function buildPluggyNoCodeDashboardApplicationUrl(
  application: Application,
): string {
  return `${pluggyNoCodeDashboardUrl}/?application_id=${application.id}`
}

export function getApplicationItemsCount(
  application: Application,
  itemsStatsByApplicationId: Record<string, ItemsStats | undefined>,
): number | null {
  const itemsCount =
    itemsStatsByApplicationId[application.id]?.itemStats.totalItems
  if (typeof itemsCount !== 'number') {
    return null
  }

  return itemsCount
}
