import React, { useCallback } from 'react'
import { useTranslation } from 'react-i18next'

import { CreateApplicationButton } from '../../CreateApplicationButton'

import './ApplicationEmptyPlaceholder.css'

const ApplicationEmptyPlaceholder = ({
  messageText,
  actionText,
  actionI18nKey,
  onNewApplicationModalOpen,
}: {
  messageText?: string
  actionText?: string
  actionI18nKey?: string
  onNewApplicationModalOpen: (text: string, i18nKey: string) => void
}) => {
  const { t } = useTranslation()

  const firstApplicationButtonI18nKey = 'applications.empty.action.create'
  const firstApplicationButtonText = t(firstApplicationButtonI18nKey)

  const handleNewApplicationModalOpen = useCallback(() => {
    onNewApplicationModalOpen(
      actionText || firstApplicationButtonText,
      actionI18nKey || firstApplicationButtonI18nKey,
    )
  }, [
    actionI18nKey,
    actionText,
    firstApplicationButtonText,
    onNewApplicationModalOpen,
  ])

  return (
    <div className={'ApplicationEmptyPlaceholder'}>
      <p>{messageText || t('applications.empty.message')}</p>
      <CreateApplicationButton
        className={'link'}
        onModalOpen={handleNewApplicationModalOpen}
      >
        {actionText || firstApplicationButtonText}
      </CreateApplicationButton>
    </div>
  )
}

export default React.memo(ApplicationEmptyPlaceholder)
