import { memo, useCallback } from 'react'

import { Pagination } from '@pluggyai/ui'

import { TrackEventName } from '../../../modules/analytics/events'
import { track } from '../../../modules/analytics/utils'
import { Props } from './PageControls.types'

import './PageControls.css'

const PageControls = ({
  page,
  onWebhookEventsPageChange,
  totalPages,
}: Props) => {
  const handlePaginationChange = useCallback(
    (activePage) => {
      track(TrackEventName.WEBHOOK_EVENTS_PAGE_CHANGE, {
        location: 'WebhookEventsPage',
        previousValue: page,
        value: activePage,
      })

      onWebhookEventsPageChange(activePage)
    },
    [onWebhookEventsPageChange, page],
  )

  return (
    <div className={'PageControls'}>
      <Pagination
        activePage={page}
        onPageChange={handlePaginationChange}
        totalPages={totalPages}
        firstItem={null}
        lastItem={null}
        ellipsisItem={null}
        siblingRange={2}
        boundaryRange={0}
      />
    </div>
  )
}

export default memo(PageControls)
