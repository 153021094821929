import React from 'react'

import { mapSubscriptionToPlanName, Team } from '../../../modules/team/types'
import { Tag } from '../../Tag'

import './SubscriptionTag.css'

const SubscriptionTag = ({ team }: { team: Team | null }) => {
  const subscriptionText = team
    ? mapSubscriptionToPlanName(team.subscription)
    : 'Free'

  return (
    <Tag
      text={subscriptionText}
      className={`SubscriptionTag ${subscriptionText.toLocaleLowerCase()}`}
    />
  )
}
export default React.memo(SubscriptionTag)
