import { useCallback, useEffect, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { PluggyConnect } from 'react-pluggy-connect'

import { Button, Popup } from '@pluggyai/ui'

import { TrackEventName } from '../../../modules/analytics/events'
import { track } from '../../../modules/analytics/utils'
import { Props } from './PreviewButton.types'

const { REACT_APP_CONNECT_PLUGGY_URL: pluggyConnectUrl } = process.env

export const PreviewButton = ({
  connectToken,
  customizationHasUnsavedChanges,
  team,
  onFetchConnectToken,
}: Props): JSX.Element => {
  const [isConnectWidgetOpen, setIsConnectWidgetOpen] = useState(false)

  const { t } = useTranslation()

  const previewButtonI18nKey = 'customization.preview.action.open-preview'
  const previewButtonText = t(previewButtonI18nKey)

  const handlePreviewButtonClick = useCallback(() => {
    track(TrackEventName.BUTTON_CLICKED, {
      location: 'CustomizationPage',
      text: previewButtonText,
      i18nKey: previewButtonI18nKey,
    })

    setIsConnectWidgetOpen(true)
  }, [previewButtonText])

  const handleConnectWidgetClose = useCallback(() => {
    track(TrackEventName.PLUGGY_CONNECT_WIDGET_EVENT, {
      eventName: 'onClose',
    })
    setIsConnectWidgetOpen(false)
  }, [])

  useEffect(() => {
    if (!team) {
      return
    }

    onFetchConnectToken()
  }, [onFetchConnectToken, team])

  if (!pluggyConnectUrl) {
    throw new Error('REACT_APP_CONNECT_PLUGGY_URL is not defined')
  }

  const handleConnectWidgetError = useCallback(() => {
    track(TrackEventName.PLUGGY_CONNECT_WIDGET_EVENT, {
      eventName: 'onError',
    })
  }, [])

  const handleConnectWidgetSuccess = useCallback(() => {
    track(TrackEventName.PLUGGY_CONNECT_WIDGET_EVENT, {
      eventName: 'onSuccess',
    })
  }, [])

  const handleConnectWidgetOpen = useCallback(() => {
    track(TrackEventName.PLUGGY_CONNECT_WIDGET_EVENT, {
      eventName: 'onOpen',
    })
  }, [])

  return (
    <>
      {isConnectWidgetOpen && connectToken && pluggyConnectUrl && (
        <PluggyConnect
          url={pluggyConnectUrl}
          connectToken={connectToken}
          onOpen={handleConnectWidgetOpen}
          onClose={handleConnectWidgetClose}
          onSuccess={handleConnectWidgetSuccess}
          onError={handleConnectWidgetError}
        />
      )}

      {customizationHasUnsavedChanges ? (
        <Popup
          content={t('customization.preview.unsaved-changes-message')}
          on="hover"
          position="bottom center"
          mouseLeaveDelay={500}
          trigger={
            <Button secondary className="disabled">
              {previewButtonText}
            </Button>
          }
        />
      ) : (
        <Button
          onClick={handlePreviewButtonClick}
          secondary
          disabled={!connectToken}
        >
          {previewButtonText}
        </Button>
      )}
    </>
  )
}
