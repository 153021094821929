import i18next from 'i18next'

import {
  isMembersInvitationsArray,
  TeamFields,
  TeamFieldsErrors,
} from '../../modules/team/types'
import { isStringsArray } from '../../utils/validation'
import { validateTeamField } from '../TeamForm'

const COMPANY_ROLE_MAX_CHARACTERS = 200

export function validateCreateTeamFormErrors(teamValues: TeamFields): {
  formErrors: TeamFieldsErrors
  errorsCount: number
} {
  // validate fields values
  // TODO: each field should "register" and define it's own validator? to support custom types per field
  const formErrors: TeamFieldsErrors = {}
  let errorsCount = 0
  for (const [field, value] of Object.entries(teamValues)) {
    let fieldError: string | string[] | undefined
    if (isStringsArray(value)) {
      const valueJoined = value.join(',')
      const errorsJoined = validateTeamField(
        field as keyof TeamFields,
        valueJoined,
      )
      const fieldErrors = errorsJoined?.split(',')
      if (fieldErrors?.some((fieldError_) => fieldError_.length > 0)) {
        errorsCount++
      }

      fieldError = fieldErrors
    } else if (isMembersInvitationsArray(value)) {
      const errorsJoined = validateTeamField(field as keyof TeamFields, value)
      const fieldErrors = errorsJoined?.split(',')
      if (fieldErrors?.some((fieldError_) => fieldError_.length > 0)) {
        errorsCount++
      }

      fieldError = fieldErrors
    } else {
      fieldError = validateTeamField(field as keyof TeamFields, value)

      if (fieldError) {
        errorsCount++
      }
    }

    if (fieldError) {
      // using 'as any' here as a hack to support both 'string' or 'string[]'
      // fieldError values.
      // TODO rework/improve this, maybe using separated validations per field
      formErrors[field as keyof TeamFields] = fieldError as any
    }
  }

  return { formErrors, errorsCount }
}

export function validateCompanyRoleErrors(
  companyRole?: string,
): string | undefined {
  if (companyRole === undefined || companyRole === '') {
    return i18next.t('team.onboarding.form.error.empty')
  }

  if (companyRole.length > COMPANY_ROLE_MAX_CHARACTERS) {
    return i18next.t('team.onboarding.form.error.max-length', {
      fieldName: 'companyRole',
      length: COMPANY_ROLE_MAX_CHARACTERS,
    })
  }

  return undefined
}

export function validatePlatformsErrors(
  platforms?: string[],
): string | undefined {
  if (platforms === undefined || platforms.length === 0) {
    return i18next.t('team.onboarding.form.error.no-platforms')
  }

  return undefined
}
