import { connect } from 'react-redux'

import { RootState } from '../../modules/reducer'
import { getTheme } from '../../modules/user/selectors'
import Theme from './Theme'
import { MapStateProps } from './Theme.types'

const mapState = (state: RootState): MapStateProps => ({
  theme: getTheme(state),
})

export default connect(mapState)(Theme)
