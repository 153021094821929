import { AxiosResponse } from 'axios'

import {
  UpdateUserFields,
  UpdateUserTermsRequest,
  User,
} from '../../modules/user/types'
import { BaseService } from './BaseService'

export class UserService extends BaseService {
  async postUserLogin(): Promise<AxiosResponse<User>> {
    return await this.getServiceInstance().post(this.urls.POST_USER_LOGIN)
  }

  async updateUser(
    updateUserFields: UpdateUserFields | UpdateUserTermsRequest,
  ): Promise<AxiosResponse<User>> {
    return await this.getServiceInstance().patch(
      this.urls.PATCH_USER,
      updateUserFields,
    )
  }

  async deleteUser(): Promise<AxiosResponse<User>> {
    return await this.getServiceInstance().delete(this.urls.DELETE_USER)
  }

  async resendVerificationEmail(): Promise<AxiosResponse<void>> {
    return await this.getServiceInstance().post(
      this.urls.POST_VERIFICATION_EMAIL,
    )
  }
}
