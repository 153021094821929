import i18next from 'i18next'

import { Application } from '../../../modules/application/types'
import { ApplicationFilterOption } from './ApplicationFilters.types'

const APPLICATION_ENVIRONMENTS_ORDER: Application['environment'][] = [
  'PRODUCTION',
  'DEVELOPMENT',
  'DEMO',
]

/**
 * Build initial list of applications filter options, including an 'all applications' option.
 *
 * @param applications
 */
export function buildApplicationsFilterOptions(
  applications: Application[] | null,
): ApplicationFilterOption[] {
  if (!applications) {
    return []
  }

  const applicationSortedByEnvironment = applications.sort(
    (application1, application2) => {
      return (
        APPLICATION_ENVIRONMENTS_ORDER.indexOf(application1.environment) -
        APPLICATION_ENVIRONMENTS_ORDER.indexOf(application2.environment)
      )
    },
  )

  const applicationsFilterOptions = [
    getAllApplicationsFilterOption(),
    ...applicationSortedByEnvironment.map(({ id, name, environment }) => ({
      id,
      name,
      environment,
    })),
  ]

  return applicationsFilterOptions
}

/**
 * Retrieve 'all applications' filter option data
 */
export function getAllApplicationsFilterOption(): ApplicationFilterOption {
  return {
    id: 'all',
    name:
      i18next.t('dailyItems.application-filters.all-applications') ||
      'Todas as aplicações', // TODO: fix this so we don't have to hardcode the fallback
  }
}
