import { useCallback } from 'react'
import { Trans, useTranslation } from 'react-i18next'

import { Image } from '@pluggyai/ui'
import { Modal } from 'semantic-ui-react'

import { TrackEventName } from '../../modules/analytics/events'
import { track } from '../../modules/analytics/utils'
import { CloseIcon } from '../Icon'
import { Props } from './ThemeModal.types'

import './ThemeModal.css'

const ThemeModal = ({ open, onClose }: Props): JSX.Element => {
  const { t } = useTranslation()

  const handleClose = useCallback(() => {
    track(TrackEventName.MODAL_CLOSED, {
      location: 'ThemeModal',
    })
    onClose()
  }, [onClose])

  return (
    <Modal
      className="ThemeModal"
      open={open}
      closeIcon={<CloseIcon />}
      onClose={handleClose}
    >
      <Modal.Header>{t('theme-modal.title')}</Modal.Header>
      <Modal.Content>
        <div className={'feature-info-container'}>
          <div className={'subtitle'}>{t('theme-modal.subtitle')}</div>
          <div className={'image-container'}>
            <Image src={'/theme-news.gif'} alt={'change theme in navbar'} />
          </div>
          <p className={'feature-title'}>{t('theme-modal.new-theme.title')}</p>

          <p className={'feature-description'}>
            <Trans i18nKey={'theme-modal.new-theme.description'} />
          </p>
        </div>
      </Modal.Content>
    </Modal>
  )
}

export default ThemeModal
