import { memo, useCallback, useMemo } from 'react'
import { useTranslation } from 'react-i18next'

import { Input, SearchBox } from '@pluggyai/ui'
import { Option } from '@pluggyai/ui/dist/components/Dropdown/Dropdown.types'
import { endOfDay } from 'date-fns'
import type { WebhookEvent } from 'pluggy-sdk'

import { TrackEventName } from '../../../modules/analytics/events'
import { track } from '../../../modules/analytics/utils'
import {
  buildAllWebhookEventsOption,
  buildWebhookEventOptions,
} from '../../ApplicationPage/ApplicationDetails/ApplicationWebhooks/WebhookInput/utils'
import { Props } from './WebhookEventsFilters.types'

import './WebhookEventsFilters.css'

const WebhookEventsFilters = ({
  eventsFilters,
  disabled,
  team,
  onChange,
}: Props) => {
  const { event, createdAtStartDate, createdAtEndDate, search } = eventsFilters

  const { t } = useTranslation()

  const webhookEventOptions = buildWebhookEventOptions()

  const currentEventOption = useMemo(
    () =>
      webhookEventOptions.find((option) => option.name === event) ||
      buildAllWebhookEventsOption(),
    [event, webhookEventOptions],
  )

  const handleEventChange = useCallback(
    ({ value }: { value: Option | Option[] }) => {
      track(TrackEventName.WEBHOOK_EVENTS_EVENT_FILTER_CHANGE, {
        location: 'Webhooks Events Page',
        previousValue: event,
        value,
      })

      onChange({
        event: (value as Option).id as WebhookEvent,
      })
    },
    [event, onChange],
  )

  const handleTrackTimeFiltersChange = useCallback(
    ({ startDate, endDate }: { startDate: Date; endDate: Date }) => {
      track(TrackEventName.WEBHOOK_EVENTS_DATE_FILTER_CHANGE, {
        location: 'Webhooks Events Page',
        previousValue: {
          createdAtStartDate,
          createdAtEndDate,
        },
        value: {
          createdAtStartDate: startDate,
          createdAtEndDate: endDate,
        },
      })
    },
    [createdAtEndDate, createdAtStartDate],
  )

  const handleTimeFiltersChange = useCallback(
    ({ startDate, endDate }: { startDate: Date; endDate: Date }) => {
      if (
        createdAtStartDate.getDate() === startDate.getDate() &&
        endDate.getDate() === createdAtEndDate?.getDate()
      ) {
        return
      }
      const endOfEndDate = endOfDay(endDate)

      handleTrackTimeFiltersChange({ startDate, endDate: endOfEndDate })

      onChange({
        createdAtStartDate: startDate,
        createdAtEndDate: endOfEndDate,
      })
    },
    [
      createdAtEndDate,
      createdAtStartDate,
      handleTrackTimeFiltersChange,
      onChange,
    ],
  )

  const handleSearchBoxChange = useCallback(
    ({ target: { value } }: React.ChangeEvent<HTMLInputElement>) => {
      track(TrackEventName.WEBHOOK_EVENTS_SEARCH_CHANGE, {
        location: 'Webhooks Events Page',
        previousValue: search,
        value,
      })

      onChange({
        search: value,
      })
    },
    [onChange, search],
  )

  const teamCreationDate = team?.createdAt
    ? new Date(team.createdAt)
    : undefined

  return (
    <div className={'WebhookEventsFilters'}>
      <Input
        className={'date-input'}
        type={'date-picker'}
        startDate={createdAtStartDate}
        endDate={createdAtEndDate || new Date()}
        onChange={handleTimeFiltersChange}
        disabled={disabled}
        minDate={teamCreationDate}
        maxDate={new Date()}
      />
      <Input
        className={'events-input'}
        type={'select'}
        label={t('application.page.webhooks.input.events.label')}
        options={webhookEventOptions}
        value={disabled ? undefined : currentEventOption}
        onChange={handleEventChange}
        disabled={disabled}
      />
      <SearchBox
        className={'search-input'}
        value={search}
        onChange={handleSearchBoxChange}
        size={'medium'}
        placeholder={t('webhook-events.page.filters.search.placeholder')}
        disabled={disabled}
      />
    </div>
  )
}

export default memo(WebhookEventsFilters)
