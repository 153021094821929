import React, { useCallback, useState } from 'react'
import { Trans, useTranslation } from 'react-i18next'

import { Alert, Button, Input } from '@pluggyai/ui'
import { Form, Modal } from 'semantic-ui-react'

import { TrackEventName } from '../../../../modules/analytics/events'
import { track } from '../../../../modules/analytics/utils'
import { CloseIcon } from '../../../Icon'
import { Props } from './ApplicationDeleteConfirmModal.types'

import './ApplicationDeleteConfirmModal.css'

const ApplicationDeleteConfirmModal = ({
  application,
  isLoading,
  error,
  onClose,
  onDeleteApplication,
}: Props) => {
  const { t } = useTranslation()

  const { name, id } = application
  const [confirmApplicationName, setConfirmApplicationName] = useState('')

  // TODO: this is a workaround to make it easier to delete an application,
  // should we don't allow users to have an application name with
  // spaces? https://pluggy.atlassian.net/browse/DVP-163
  const isApplicationNameConfirmed = confirmApplicationName === name.trim()

  const trackDeleteSubmit = useCallback(() => {
    track(TrackEventName.CTA_APPLICATION_DELETE_MODAL_CONFIRM, {
      applicationId: id,
    })
  }, [id])

  const handleSubmit = useCallback(() => {
    if (!isApplicationNameConfirmed) {
      return
    }
    onDeleteApplication(id)
    trackDeleteSubmit()
    // close the modal
    onClose()
  }, [
    id,
    isApplicationNameConfirmed,
    onDeleteApplication,
    onClose,
    trackDeleteSubmit,
  ])

  const handleOnApplicationNameChange = useCallback(({ value }) => {
    setConfirmApplicationName(value)
  }, [])

  return (
    <Modal
      className={'ApplicationDeleteConfirmModal'}
      onClose={onClose}
      open={true}
      closeIcon={<CloseIcon />}
    >
      <Modal.Header>
        {t('application.page.danger.delete.modal.header')}
        {error !== null ? (
          <Alert size={'medium'} type={'error'} message={error} />
        ) : (
          <Alert
            type={'warning'}
            size={'medium'}
            message={t('application.page.danger.delete.modal.warning')}
          />
        )}
      </Modal.Header>
      <Modal.Content>
        <Trans
          i18nKey="application.page.danger.delete.modal.content"
          values={{ name }}
        />
        <Form onSubmit={handleSubmit}>
          <Input
            type={'text'}
            label={t('application.page.danger.delete.modal.confirm.label')}
            value={confirmApplicationName}
            onChange={handleOnApplicationNameChange}
            disabled={isLoading}
          />
        </Form>
      </Modal.Content>
      <Modal.Actions>
        <Button
          primary
          fluid
          onClick={handleSubmit}
          disabled={!isApplicationNameConfirmed}
          loading={isLoading}
        >
          {t('application.page.danger.delete.modal.action')}
        </Button>
      </Modal.Actions>
    </Modal>
  )
}

export default React.memo(ApplicationDeleteConfirmModal)
