import { useCallback } from 'react'
import { useTranslation } from 'react-i18next'

import { Button } from '@pluggyai/ui'
import { Modal } from 'semantic-ui-react'

import { TrackEventName } from '../../../modules/analytics/events'
import { track } from '../../../modules/analytics/utils'
import { CloseIcon } from '../../Icon'
import { Props } from './UnsavedChangesModal.types'

import './UnsavedChangesModal.css'

export const UnsavedChangesModal = ({
  onContinue,
  onCancel,
  isOpen,
}: Props): JSX.Element => {
  const { t } = useTranslation()

  const continueButtonI18nKey =
    'customization.unsaved-changes-modal.action.continue'
  const continueButtonText = t(continueButtonI18nKey)

  const cancelButtonI18nKey =
    'customization.unsaved-changes-modal.action.cancel'

  const cancelButtonText = t(cancelButtonI18nKey)

  const handleContinueButtonClick = useCallback(() => {
    track(TrackEventName.BUTTON_CLICKED, {
      location: 'CustomizationPage UnsavedChangesModal',
      text: continueButtonText,
      i18nKey: continueButtonI18nKey,
    })
    onContinue()
  }, [continueButtonText, onContinue])

  const handleCancelButtonClick = useCallback(() => {
    track(TrackEventName.BUTTON_CLICKED, {
      location: 'CustomizationPage UnsavedChangesModal',
      text: cancelButtonText,
      i18nKey: cancelButtonI18nKey,
    })
    onCancel()
  }, [cancelButtonText, onCancel])

  return (
    <Modal
      open={isOpen}
      closeIcon={<CloseIcon />}
      className={'UnsavedChangesModal'}
      onClose={onCancel}
    >
      <Modal.Header>
        {t('customization.unsaved-changes-modal.title')}
      </Modal.Header>
      <Modal.Actions>
        <p>{t('customization.unsaved-changes-modal.message')}</p>
        <Button secondary onClick={handleCancelButtonClick}>
          {cancelButtonText}
        </Button>
        <Button primary onClick={handleContinueButtonClick}>
          {continueButtonText}
        </Button>
      </Modal.Actions>
    </Modal>
  )
}
