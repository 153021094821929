import { connect } from 'react-redux'

import { RootState } from '../../modules/reducer'
import { updateUserRequest } from '../../modules/user/actions'
import { getAsUser, getUser } from '../../modules/user/selectors'
import ThemeModal from './ThemeModal'
import {
  MapDispatch,
  MapDispatchProps,
  MapStateProps,
} from './ThemeModal.types'

const mapState = (state: RootState): MapStateProps => {
  const user = getUser(state)
  const isImpersonating = getAsUser(state) !== null

  return {
    open:
      !isImpersonating &&
      user !== null &&
      (user.uiState === null || user.uiState.userHasClosedThemeModal === false),
  }
}

const mapDispatch = (dispatch: MapDispatch): MapDispatchProps => ({
  onClose: () =>
    dispatch(updateUserRequest({ uiState: { userHasClosedThemeModal: true } })),
  // nothing here, yet
})

export default connect(mapState, mapDispatch)(ThemeModal)
