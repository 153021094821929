import { AxiosResponse } from 'axios'

import {
  ConnectToken,
  ConnectTokenRequest,
} from '../../modules/connectToken/types'
import { BaseService } from './BaseService'

export class ConnectTokenService extends BaseService {
  async createConnectToken(
    params: ConnectTokenRequest,
  ): Promise<AxiosResponse<ConnectToken>> {
    return await this.getServiceInstance().post(
      this.urls.POST_CONNECT_TOKEN,
      {},
      { params },
    )
  }
}
