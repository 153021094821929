import React, { useCallback, useEffect, useState } from 'react'
import { useTranslation } from 'react-i18next'

import { Alert, Button } from '@pluggyai/ui'
import { Modal, Form } from 'semantic-ui-react'

import { TrackEventName } from '../../modules/analytics/events'
import { track } from '../../modules/analytics/utils'
import {
  isMembersInvitationsArray,
  TeamFields,
  TeamFieldsErrors,
} from '../../modules/team/types'
import { isStringsArray } from '../../utils/validation'
import { CloseIcon } from '../Icon'
import { validateTeamField } from '../TeamForm'
import { TeamMembersEmailsInput } from '../TeamForm/TeamMembersEmailsInput'
import { Props } from './TeamAddMembersModal.types'

import './TeamAddMembersModal.css'

const initialMembersInvitationsFields = (): Pick<TeamFields, 'members'> => ({
  members: [
    {
      email: '',
      role: 'MEMBER',
    },
  ],
})

const TeamAddMembersModal = ({
  userTeamMemberRole,
  currentTeam,
  isLoading,
  error,
  onClose,
  onCreateTeamMember,
}: Props) => {
  const { t } = useTranslation()

  const [submit, setSubmit] = useState(false)
  const [formErrors, setFormErrors] = useState<TeamFieldsErrors>({})
  const [formValues, setFormValues] = useState(
    initialMembersInvitationsFields(),
  )

  const trackAddMembersSubmit = useCallback(() => {
    track(TrackEventName.CTA_TEAM_INVITE_MEMBERS_MODAL_CONFIRM, {
      teamId: currentTeam?.id,
    })
  }, [currentTeam])

  const trackFormValidationError = useCallback((errors: TeamFieldsErrors) => {
    track(TrackEventName.FORM_VALIDATION_ERRORS, {
      fields: Object.keys(errors),
      location: 'TeamAddMembersModal',
    })
  }, [])

  const handleClose = useCallback(() => {
    setFormErrors({})
    setFormValues(initialMembersInvitationsFields())
    onClose()
  }, [onClose])

  const handleValuesChange = useCallback(
    (fieldName: keyof TeamFields, value: unknown) => {
      // clear field error, if any
      if (formErrors[fieldName]) {
        delete formErrors[fieldName]
        setFormErrors({ ...formErrors })
      }
      // set field value
      setFormValues({ ...formValues, [fieldName]: value })
    },
    [formValues, formErrors],
  )

  // TODO remove uneeded logic
  const handleSubmit = useCallback(
    (event: React.FormEvent) => {
      if (!currentTeam) {
        throw new Error('Not defined current team')
      }

      if (isLoading) {
        // is already loading/submitting, wait
        return
      }
      trackAddMembersSubmit()
      event.preventDefault()

      // validate fields values
      // TODO: each field should "register" and define it's own validator? to support custom types per field
      const currentFormErrors: TeamFieldsErrors = {}
      let errorsCount = 0
      for (const [field, value] of Object.entries(formValues)) {
        let fieldError: string | string[] | undefined
        if (isStringsArray(value)) {
          const valueJoined = value.join(',')
          const errorsJoined = validateTeamField(
            field as keyof TeamFields,
            valueJoined,
          )
          const fieldErrors = errorsJoined?.split(',')
          if (fieldErrors?.some((fieldError_) => fieldError_.length > 0)) {
            errorsCount++
          }

          fieldError = fieldErrors
        } else if (isMembersInvitationsArray(value)) {
          const errorsJoined = validateTeamField(
            field as keyof TeamFields,
            value,
          )
          const fieldErrors = errorsJoined?.split(',')
          if (fieldErrors?.some((fieldError_) => fieldError_.length > 0)) {
            errorsCount++
          }

          fieldError = fieldErrors
        } else {
          fieldError = validateTeamField(field as keyof TeamFields, value)

          if (fieldError) {
            errorsCount++
          }
        }

        if (fieldError) {
          // using 'as any' here as a hack to support both 'string' or 'string[]'
          // fieldError values.
          // TODO rework/improve this, maybe using separated validations per field
          currentFormErrors[field as keyof TeamFields] = fieldError as any
        }
      }

      setFormErrors(currentFormErrors)
      if (errorsCount > 0) {
        // got errors, can't proceed
        trackFormValidationError(currentFormErrors)
        return
      }
      const definedMembersInvitations = formValues.members.filter(
        (memberInvite) => memberInvite.email.length > 0,
      )
      if (definedMembersInvitations.length === 0) {
        return
      }
      setSubmit(true)
      onCreateTeamMember(currentTeam.id, definedMembersInvitations)
    },
    [
      currentTeam,
      formValues,
      onCreateTeamMember,
      isLoading,
      trackFormValidationError,
      trackAddMembersSubmit,
    ],
  )

  useEffect(() => {
    // check if we are done, and close modal if no errors
    const isDone = submit && error === null && !isLoading
    if (isDone) {
      handleClose()
    }
  }, [error, isLoading, submit, handleClose])

  return (
    <Modal
      className={'TeamAddMembersModal'}
      onClose={handleClose}
      open={true}
      closeIcon={<CloseIcon />}
    >
      <Modal.Header>
        {t('team.add-members-form.title')}
        {/* TODO uncomment when TeamMembersEmailsInput is properly implemented for roles too */}
        {/*<p>{t('team.add-members-form.subtitle')}</p>*/}
        {error !== null && (
          <Alert size={'medium'} type={'error'} message={error} />
        )}
      </Modal.Header>
      <Modal.Content>
        <Form onSubmit={handleSubmit}>
          <TeamMembersEmailsInput
            value={formValues.members}
            errors={formErrors.members}
            isSubmitting={isLoading}
            onFieldChange={handleValuesChange}
            userTeamMemberRole={userTeamMemberRole}
            isEdit
          />
        </Form>
      </Modal.Content>
      <Modal.Actions>
        <Button secondary onClick={handleClose}>
          {t('team.add-members-form.action.cancel')}
        </Button>
        <Button primary onClick={handleSubmit} loading={isLoading}>
          {t('team.add-members-form.action.create')}
        </Button>
      </Modal.Actions>
    </Modal>
  )
}

export default React.memo(TeamAddMembersModal)
