import { createMatchSelector } from 'connected-react-router'
import { AnyAction } from 'redux'
import { createSelector } from 'reselect'

import { LoadingState } from '../loading/types'
import { RootState } from '../reducer'
import { locations } from '../routing/locations'
import { getApplicationsItemsStatsByApplicationId } from '../stats/selectors'
import { ApplicationState } from './reducer'
import { Application } from './types'
import { getApplicationItemsCount as getApplicationItemsCount_ } from './utils'

export const getState = (state: RootState): ApplicationState =>
  state.application as ApplicationState
export const getData = (state: RootState) => getState(state).data
export const getLoading = (state: RootState) => getState(state).loading
export const getError = (state: RootState) => getState(state).error

export const isLoadingType = (state: LoadingState, type: string): boolean =>
  state.some((action: AnyAction) => action.type === type)

const applicationMatchSelector = createMatchSelector<
  RootState,
  { applicationId: string }
>(locations.application(':applicationId'))

export const getApplicationId = createSelector<
  RootState,
  ReturnType<typeof applicationMatchSelector>,
  string | undefined
>(applicationMatchSelector, (match) => match?.params.applicationId)

/**
 * Builds a selector that checks if an Application param is in the loading state.
 */
export const isLoadingApplicationSelector = (
  state: RootState,
  loadingActionTypeOrTypes: string | string[],
): ((application: Application | null) => boolean) => {
  const types = Array.isArray(loadingActionTypeOrTypes)
    ? loadingActionTypeOrTypes
    : [loadingActionTypeOrTypes]
  return (application: Application | null): boolean =>
    application !== null &&
    getLoading(state)
      .filter((action: AnyAction) => types.includes(action.type))
      .some(({ payload }) => payload.id === application.id)
}

export const getApplication = (state: RootState): Application | null => {
  const id = getApplicationId(state)
  return getData(state)?.find((application) => application.id === id) || null
}

export const isLoadingApplication = (
  state: RootState,
  loadingActionTypeOrTypes: string | string[],
): boolean => {
  const id = getApplicationId(state)
  if (!id) {
    return false
  }
  const application = { id } as Application

  return isLoadingApplicationSelector(
    state,
    loadingActionTypeOrTypes,
  )(application)
}

export const isDemoApplication = (state: RootState): boolean => {
  const application = getApplication(state)

  if (!application) {
    return false
  }

  const { environment } = application

  return environment === 'DEMO'
}

export const getApplicationItemsCount = (
  state: RootState,
  application: Application,
): number | null => {
  const itemsStatsByApplicationId =
    getApplicationsItemsStatsByApplicationId(state)

  if (!itemsStatsByApplicationId) {
    return null
  }

  return getApplicationItemsCount_(application, itemsStatsByApplicationId)
}
