import { memo } from 'react'
import { useTranslation } from 'react-i18next'

import { Table } from '@pluggyai/ui'
import { format } from 'date-fns'

import { DataPlaceholder } from '../DataPlaceholder'
import { Props } from './ExecutionsDataContainer.types'

import './ExecutionsDataContainer.css'

const ExecutionsDataContainer = ({ executions }: Props) => {
  const { t } = useTranslation('translation', {
    keyPrefix: 'item-executions-page.executions-data-container',
  })

  const rows = executions.map((execution) => {
    return [
      execution.status,
      execution.triggeredBy,
      format(new Date(execution.createdAt), 'dd/MM/yyyy HH:mm:ss'),
      execution.endedAt
        ? format(new Date(execution.endedAt), 'dd/MM/yyyy HH:mm:ss')
        : '-',
    ]
  })

  return (
    <div className={'ExecutionsDataContainer'}>
      <h2 className={'executions-title'}>{t('title')}</h2>
      <p className={'executions-subtitle'}>{t('subtitle')}</p>
      {executions.length > 0 ? (
        <Table
          headers={[
            t('headers.status'),
            t('headers.triggered-by'),
            t('headers.started-at'),
            t('headers.ended-at'),
          ]}
          rows={rows}
        />
      ) : (
        <DataPlaceholder message={t('empty-data-message')} />
      )}
    </div>
  )
}

export default memo(ExecutionsDataContainer)
