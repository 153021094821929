import { connect } from 'react-redux'

import { getData as getApplications } from '../../modules/application/selectors'
import { RootState } from '../../modules/reducer'
import {
  changeItemStatsFilters,
  FETCH_ITEMS_STATS_REQUEST,
} from '../../modules/stats/actions'
import {
  getItemStatsFilters,
  getData as getStats,
  getLoading as isLoadingStats,
  isLoadingType,
  teamOrUserHasItemsConnected,
} from '../../modules/stats/selectors'
import { ItemStatsFiltersChangeRequest } from '../../modules/stats/types'
import { getCurrentTeam } from '../../modules/team/selectors'
import { getUser } from '../../modules/user/selectors'
import Stats from './Stats'
import { MapDispatch, MapDispatchProps, MapStateProps } from './Stats.types'

const mapState = (state: RootState): MapStateProps => ({
  isLoading: isLoadingType(isLoadingStats(state), FETCH_ITEMS_STATS_REQUEST),
  isApplicationsEmpty: getApplications(state)?.length === 0,
  applications: getApplications(state),
  user: getUser(state),
  teamOrUserHasItemsConnected: teamOrUserHasItemsConnected(state),
  team: getCurrentTeam(state),
  itemsStatsFilters: getItemStatsFilters(state),
  itemStats: getStats(state).itemStatsReport.data,
})

const mapDispatch = (dispatch: MapDispatch): MapDispatchProps => ({
  onItemStatsFiltersChange: (statsFilers: ItemStatsFiltersChangeRequest) =>
    dispatch(changeItemStatsFilters(statsFilers)),
})

export default connect(mapState, mapDispatch)(Stats)
