import React, { useCallback } from 'react'

import { TrackEventName } from '../../modules/analytics/events'
import { track } from '../../modules/analytics/utils'
import { Application } from '../../modules/application/types'
import { ApplicationsList } from '../ApplicationsList'
import { FreeTrialAlert } from '../FreeTrialAlert'
import { Page } from '../Page'
import { WelcomeWidget } from '../WelcomeWidget'
import { Props } from './ApplicationsPage.types'

import './ApplicationsPage.css'

const ApplicationsPage = ({ onGoToApplicationPage }: Props) => {
  const handleOnGoToApplicationPage = useCallback(
    (applicationId: string) => {
      onGoToApplicationPage(applicationId)
      track(TrackEventName.LINK_APPLICATION_MORE, {
        location: 'ApplicationsPage ApplicationsList',
        applicationId,
      })
    },
    [onGoToApplicationPage],
  )

  const trackNewApplicationModalOpen = useCallback(
    (text: string, i18nKey: string) => {
      track(TrackEventName.BUTTON_CLICKED, {
        location: 'ApplicationsPage ApplicationsList',
        text,
        i18nKey,
      })
    },
    [],
  )

  const handleGoToDemoApplicationClick = useCallback(
    (application: Application) => {
      track(TrackEventName.CTA_APPLICATION_GO_TO_DEMO, {
        applicationId: application.id,
        application,
        location: 'ApplicationsPage ApplicationsList',
      })
    },
    [],
  )

  const handleOnGoToNoCodeDashboardApplicationClick = useCallback(
    (application: Application) => {
      track(TrackEventName.CTA_APPLICATION_GO_TO_NO_CODE_DASHBOARD, {
        applicationId: application.id,
        application,
        location: 'ApplicationsPage ApplicationDetails',
      })
    },
    [],
  )

  const handleOnCopyClientIdClick = useCallback(
    ({ id: applicationId }: Application) => {
      track(TrackEventName.ICON_APPLICATION_CLIENT_ID_COPY, {
        applicationId,
        location: 'ApplicationsPage ApplicationsList',
      })
    },
    [],
  )

  const handleOnCopyClientSecretClick = useCallback(
    ({ id: applicationId }: Application) => {
      track(TrackEventName.ICON_APPLICATION_CLIENT_SECRET_COPY, {
        applicationId,
        location: 'ApplicationsPage ApplicationsList',
      })
    },
    [],
  )

  const handleOnShowClientSecretClick = useCallback(
    ({ id: applicationId }: Application) => {
      track(TrackEventName.ICON_APPLICATION_CLIENT_SECRET_SHOW, {
        applicationId,
        location: 'ApplicationsPage ApplicationsList',
      })
    },
    [],
  )

  return (
    <Page className={'ApplicationsPage'}>
      <WelcomeWidget />
      <FreeTrialAlert />
      <ApplicationsList
        onGoToApplicationPage={handleOnGoToApplicationPage}
        onNewApplicationModalOpen={trackNewApplicationModalOpen}
        onGoToDemoApplicationClick={handleGoToDemoApplicationClick}
        onGoToNoCodeDashboardApplicationClick={
          handleOnGoToNoCodeDashboardApplicationClick
        }
        onCopyClientIdClick={handleOnCopyClientIdClick}
        onCopyClientSecretClick={handleOnCopyClientSecretClick}
        onShowClientSecretClick={handleOnShowClientSecretClick}
      />
    </Page>
  )
}

export default React.memo(ApplicationsPage)
