import {
  AUTH0_LOGOUT_SUCCESS,
  Auth0LogoutSuccessAction,
} from '../auth0/actions'
import { loadingReducer } from '../loading/reducer'
import { LoadingState } from '../loading/types'
import {
  DeleteUserFailureAction,
  DeleteUserRequestAction,
  DeleteUserSuccessAction,
  FETCH_USER_FAILURE,
  FETCH_USER_REQUEST,
  FETCH_USER_SUCCESS,
  FetchUserFailureAction,
  FetchUserRequestAction,
  FetchUserSuccessAction,
  IMPERSONATE_USER_FAILURE,
  IMPERSONATE_USER_REQUEST,
  IMPERSONATE_USER_SUCCESS,
  ImpersonateUserFailureAction,
  ImpersonateUserRequestAction,
  ImpersonateUserSuccessAction,
  RESEND_VERIFICATION_EMAIL_FAILURE,
  RESEND_VERIFICATION_EMAIL_REQUEST,
  RESEND_VERIFICATION_EMAIL_SUCCESS,
  ResendVerificationEmailFailureAction,
  ResendVerificationEmailRequestAction,
  ResendVerificationEmailSuccessAction,
  SET_SHOW_USER_ACTIONS_REQUIRED,
  SetShowUserActionsRequired,
  UPDATE_USER_FAILURE,
  UPDATE_USER_REQUEST,
  UPDATE_USER_SUCCESS,
  UPDATE_USER_TERMS_ACCEPTED_FAILURE,
  UPDATE_USER_TERMS_ACCEPTED_REQUEST,
  UPDATE_USER_TERMS_ACCEPTED_SUCCESS,
  UpdateUserFailureAction,
  UpdateUserRequestAction,
  UpdateUserSuccessAction,
  UpdateUserTermsAcceptedFailureAction,
  UpdateUserTermsAcceptedRequestAction,
  UpdateUserTermsAcceptedSuccessAction,
} from './actions'
import { User } from './types'

export type UserState = {
  data: {
    user: User | null
    showUserActionsRequired: boolean
    asUser: User | null
  }
  loading: LoadingState
  error: {
    user: string | null
    resendVerificationEmail: string | null
  }
}

const INITIAL_STATE: UserState = {
  data: {
    user: null,
    showUserActionsRequired: false,
    asUser: null,
  },
  loading: [],
  error: {
    user: null,
    resendVerificationEmail: null,
  },
}

type UserReducerAction =
  | FetchUserRequestAction
  | FetchUserSuccessAction
  | FetchUserFailureAction
  | ImpersonateUserRequestAction
  | ImpersonateUserSuccessAction
  | ImpersonateUserFailureAction
  | Auth0LogoutSuccessAction
  | UpdateUserTermsAcceptedRequestAction
  | UpdateUserTermsAcceptedSuccessAction
  | UpdateUserTermsAcceptedFailureAction
  | SetShowUserActionsRequired
  | ResendVerificationEmailRequestAction
  | ResendVerificationEmailSuccessAction
  | ResendVerificationEmailFailureAction
  | UpdateUserRequestAction
  | UpdateUserSuccessAction
  | UpdateUserFailureAction
  | DeleteUserRequestAction
  | DeleteUserSuccessAction
  | DeleteUserFailureAction

export function userReducer(
  state: UserState = INITIAL_STATE,
  action: UserReducerAction,
): UserState {
  switch (action.type) {
    case RESEND_VERIFICATION_EMAIL_REQUEST:
    case UPDATE_USER_TERMS_ACCEPTED_REQUEST:
    case FETCH_USER_REQUEST:
    case UPDATE_USER_REQUEST:
      return {
        ...state,
        loading: loadingReducer(state.loading, action),
        error: {
          user: null,
          resendVerificationEmail: null,
        },
      }
    case IMPERSONATE_USER_REQUEST:
    case IMPERSONATE_USER_FAILURE:
      return {
        ...state,
        loading: loadingReducer(state.loading, action),
      }
    case IMPERSONATE_USER_SUCCESS:
      return {
        ...state,
        loading: loadingReducer(state.loading, action),
        data: {
          ...state.data,
          asUser: action.payload.user,
        },
      }
    case UPDATE_USER_TERMS_ACCEPTED_SUCCESS:
      return {
        ...state,
        loading: loadingReducer(state.loading, action),
        error: {
          ...state.error,
          user: null,
        },
        data: {
          ...state.data,
          user: action.payload.user,
        },
      }
    case UPDATE_USER_SUCCESS:
      return {
        ...state,
        loading: loadingReducer(state.loading, action),
        data: {
          ...state.data,
          user: action.payload.user,
        },
      }
    case RESEND_VERIFICATION_EMAIL_SUCCESS:
      return {
        ...state,
        loading: loadingReducer(state.loading, action),
        error: {
          ...state.error,
          resendVerificationEmail: null,
        },
      }
    case FETCH_USER_SUCCESS:
      const { user } = action.payload
      return {
        ...state,
        loading: loadingReducer(state.loading, action),
        error: {
          ...state.error,
          user: null,
        },
        data: {
          ...state.data,
          user,
        },
      }
    case SET_SHOW_USER_ACTIONS_REQUIRED:
      const { showUserActionsRequired: showUserActionsRequired_ } =
        action.payload
      return {
        ...state,
        data: {
          ...state.data,
          showUserActionsRequired: showUserActionsRequired_,
        },
      }
    case RESEND_VERIFICATION_EMAIL_FAILURE:
      return {
        ...state,
        loading: loadingReducer(state.loading, action),
        error: {
          ...state.error,
          resendVerificationEmail: action.payload.error,
        },
      }
    case UPDATE_USER_TERMS_ACCEPTED_FAILURE:
    case FETCH_USER_FAILURE:
    case UPDATE_USER_FAILURE:
      return {
        ...state,
        loading: loadingReducer(state.loading, action),
        error: {
          ...state.error,
          user: action.payload.error,
        },
      }
    case AUTH0_LOGOUT_SUCCESS:
      return INITIAL_STATE
    default:
      return state
  }
}
