import i18n from 'i18next'

import {
  ConnectFormStepButtonKey,
  GeneralCustomizationFields,
  WelcomeStepButtonKey,
} from '../../../modules/customization/types'
import { isValidHexColor, isValidUrl } from '../../../utils/validation'

const validations: Record<
  keyof Omit<GeneralCustomizationFields, '_type'>,
  (fieldName: string, value: string) => string | undefined
> = {
  logoImageUrl: (fieldName: string, value: string): string | undefined => {
    if (value.length > 0 && !isValidUrl(value)) {
      return i18n.t('customization.form.general.error.url', {
        fieldName,
      })
    }
    return undefined
  },
  welcomeText: (fieldName: string, value: string): string | undefined => {
    if (value.length === 0) {
      return i18n.t('customization.form.general.error.empty', {
        fieldName,
      })
    }
    const maxLength = 40
    if (value.length > maxLength) {
      return i18n.t('customization.form.general.error.max-length', {
        fieldName,
        length: maxLength,
      })
    }
    return undefined
  },
  primaryColor: (fieldName: string, value: string): string | undefined => {
    if (value && !isValidHexColor(value)) {
      return i18n.t('customization.form.general.error.hex-color', { fieldName })
    }
    return undefined
  },
  borderRadius: (fieldName: string, value: string): string | undefined => {
    if (Number(value) < 0) {
      return i18n.t('customization.form.general.error.negative', { fieldName })
    }
    if (Number(value) > 50) {
      return i18n.t('customization.form.general.error.max-value', {
        fieldName,
        value: 50,
      })
    }
    return undefined
  },
  connectFormStepButtonKey: (
    fieldName: string,
    value: string,
  ): string | undefined => {
    if (value.length === 0) {
      return i18n.t('customization.form.general.error.empty', {
        fieldName,
      })
    }
    return undefined
  },
  welcomeStepButtonKey: (
    fieldName: string,
    value: string,
  ): string | undefined => {
    if (value.length === 0) {
      return i18n.t('customization.form.general.error.empty', {
        fieldName,
      })
    }
    return undefined
  },
}

export function validateField(
  fieldName: keyof Omit<GeneralCustomizationFields, '_type'>,
  value: string,
): string | undefined {
  const fieldLabel = i18n.t(
    `customization.form.general.field.${fieldName}.label`,
  )
  return validations[fieldName](fieldLabel, value)
}

export function buildWelcomeStepButtonOptions(): {
  id: WelcomeStepButtonKey
  name: string
}[] {
  return [
    {
      id: 'CONTINUE',
      name: i18n.t(
        'customization.form.general.field.connectFormStepButtonKey.option.continue',
      ),
    },
    {
      id: 'GET_STARTED',
      name: i18n.t(
        'customization.form.general.field.connectFormStepButtonKey.option.get-started',
      ),
    },
    {
      id: 'AGREE',
      name: i18n.t(
        'customization.form.general.field.connectFormStepButtonKey.option.agree',
      ),
    },
  ]
}

export function buildConnectFormStepButtonOptions(): {
  id: ConnectFormStepButtonKey
  name: string
}[] {
  return [
    {
      id: 'CONNECT',
      name: i18n.t(
        'customization.form.general.field.credentialsStepButtonKey.option.continue',
      ),
    },
    {
      id: 'CONNECT_MY_ACCOUNT',
      name: i18n.t(
        'customization.form.general.field.credentialsStepButtonKey.option.connect-my-account',
      ),
    },
    {
      id: 'SUBMIT',
      name: i18n.t(
        'customization.form.general.field.credentialsStepButtonKey.option.submit',
      ),
    },
  ]
}
