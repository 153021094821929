import React, { useCallback, useEffect, useState } from 'react'
import { useTranslation } from 'react-i18next'

import { Popup } from 'semantic-ui-react'

import { CopyIcon } from '../../Icon/CopyIcon'

function copyToClipboard(text: string) {
  navigator.clipboard
    .writeText(text)
    .catch((error) => console.error('Failed copy to clipboard', error))
}

const DEFAULT_COPIED_MESSAGE_TIMEOUT_MS = 3000 // 3 seconds

type Props = {
  text: string
  timeout?: number
  onClick: () => void
}

const CopyActionButton = ({
  text,
  timeout = DEFAULT_COPIED_MESSAGE_TIMEOUT_MS,
  onClick,
}: Props) => {
  const { t } = useTranslation()
  const [showCopiedMessageTimeout, setShowCopiedMessageTimeout] =
    useState<NodeJS.Timeout | null>(null)

  const handleOnCopyClick = useCallback(() => {
    copyToClipboard(text)

    if (showCopiedMessageTimeout) {
      clearTimeout(showCopiedMessageTimeout)
    }
    const timeout_ = setTimeout(() => {
      setShowCopiedMessageTimeout(null)
    }, timeout)
    setShowCopiedMessageTimeout(timeout_)
    onClick()
    return () => clearTimeout(timeout_)
  }, [text, showCopiedMessageTimeout, timeout, onClick])

  useEffect(() => {
    // timeout cleanup
    return () => {
      if (showCopiedMessageTimeout !== null) {
        clearTimeout(showCopiedMessageTimeout)
      }
    }
  }, [showCopiedMessageTimeout])

  return (
    <Popup
      content={t('application.item.copied-message')}
      open={showCopiedMessageTimeout !== null}
      on="click"
      position={'top center'}
      trigger={<CopyIcon onClick={handleOnCopyClick} />}
    />
  )
}

export default React.memo(CopyActionButton)
