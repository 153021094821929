import { connect } from 'react-redux'

import { RootState } from '../../../modules/reducer'
import { getData as getTeam } from '../../../modules/team/selectors'
import { changeWebhookEventsFilters } from '../../../modules/webhookEvents/actions'
import { getWebhookEventsFilters } from '../../../modules/webhookEvents/selectors'
import WebhookEventsFilters from './WebhookEventsFilters'
import {
  MapDispatch,
  MapDispatchProps,
  MapStateProps,
} from './WebhookEventsFilters.types'

const mapState = (state: RootState): MapStateProps => ({
  eventsFilters: getWebhookEventsFilters(state),
  team: getTeam(state).current,
})

const mapDispatch = (dispatch: MapDispatch): MapDispatchProps => ({
  onChange: (webhookEventFilters) =>
    dispatch(changeWebhookEventsFilters(webhookEventFilters)),
})

export default connect(mapState, mapDispatch)(WebhookEventsFilters)
