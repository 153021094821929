import { AxiosResponse } from 'axios'

import {
  ConnectorsStats,
  ConnectorsStatsRequest,
  ItemsStats,
  ItemsStatsRequest,
} from '../../modules/stats/types'
import { BaseService } from './BaseService'

export class StatsService extends BaseService {
  async getItemsStats(
    params?: ItemsStatsRequest,
  ): Promise<AxiosResponse<ItemsStats>> {
    return await this.getServiceInstance().get(this.urls.GET_STATS_ITEMS, {
      params,
    })
  }
  async getConnectorsStats(
    params?: ConnectorsStatsRequest,
  ): Promise<AxiosResponse<ConnectorsStats>> {
    return await this.getServiceInstance().get(this.urls.GET_STATS_CONNECTORS, {
      params,
    })
  }
}
