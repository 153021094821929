import React, { useCallback, useMemo } from 'react'
import { useTranslation } from 'react-i18next'

import { Input } from '@pluggyai/ui'
import { Option } from '@pluggyai/ui/dist/components/Dropdown/Dropdown.types'

import { EnvironmentTag } from '../../Navbar/EnvironmentTag'
import { ApplicationFilterOption, Props } from './ApplicationFilters.types'
import { buildApplicationsFilterOptions } from './utils'

import './ApplicationFilters.css'

const ApplicationFilters = ({
  onChange,
  disabled,
  applications,
  selectedApplication,
}: Props) => {
  const { t } = useTranslation()

  const applicationFilterOptions: Option[] = useMemo(
    () =>
      buildApplicationsFilterOptions(applications).map(
        ({ environment, ...rest }) => ({
          ...rest,
          children: environment ? (
            <EnvironmentTag environment={environment} size={'small'} />
          ) : undefined,
        }),
      ),
    [applications],
  )

  const handleDropdownChange = useCallback(
    ({ value }: { value: Option | Option[] }) => {
      // Note: fn type 'Option | Option[]' is neccesary to match the type of
      //  pluggy-ui Input.type='select' signature,
      //  We type-cast it below as a workaround (TODO this needs improvement in pluggy-ui component API)
      const valueAsApplicationOption = value as ApplicationFilterOption
      onChange(valueAsApplicationOption)
    },
    [onChange],
  )

  return (
    <Input
      className={'ApplicationFilters'}
      type={'select'}
      options={applicationFilterOptions}
      label={t('dailyItems.application-filters.label')}
      disabled={disabled}
      onChange={handleDropdownChange}
      value={selectedApplication}
    />
  )
}

export default React.memo(ApplicationFilters)
