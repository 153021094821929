import type { Webhook } from 'pluggy-sdk'

import { Application } from '../application/types'
import { loadingReducer } from '../loading/reducer'
import { LoadingState } from '../loading/types'
import {
  FetchWebhooksRequestAction,
  FetchWebhooksSuccessAction,
  FetchWebhooksFailureAction,
  CreateWebhookRequestAction,
  CreateWebhookSuccessAction,
  CreateWebhookFailureAction,
  UpdateWebhookRequestAction,
  UpdateWebhookSuccessAction,
  UpdateWebhookFailureAction,
  DeleteWebhookRequestAction,
  DeleteWebhookSuccessAction,
  DeleteWebhookFailureAction,
  FETCH_WEBHOOKS_REQUEST,
  FETCH_WEBHOOKS_SUCCESS,
  UPDATE_WEBHOOK_FAILURE,
  UPDATE_WEBHOOK_SUCCESS,
  UPDATE_WEBHOOK_REQUEST,
  CREATE_WEBHOOK_FAILURE,
  CREATE_WEBHOOK_SUCCESS,
  CREATE_WEBHOOK_REQUEST,
  FETCH_WEBHOOKS_FAILURE,
  DELETE_WEBHOOK_REQUEST,
  DELETE_WEBHOOK_SUCCESS,
  DELETE_WEBHOOK_FAILURE,
} from './actions'

export type WebhooksState = {
  data: Record<Application['id'], Webhook[]> | null
  loading: LoadingState
  error: string | null
}

const INITIAL_STATE: WebhooksState = {
  data: null,
  loading: [],
  error: null,
}

type WebhooksReducerAction =
  | FetchWebhooksRequestAction
  | FetchWebhooksSuccessAction
  | FetchWebhooksFailureAction
  | CreateWebhookRequestAction
  | CreateWebhookSuccessAction
  | CreateWebhookFailureAction
  | UpdateWebhookRequestAction
  | UpdateWebhookSuccessAction
  | UpdateWebhookFailureAction
  | DeleteWebhookRequestAction
  | DeleteWebhookSuccessAction
  | DeleteWebhookFailureAction

export function webhookReducer(
  state: WebhooksState = INITIAL_STATE,
  action: WebhooksReducerAction,
): WebhooksState {
  switch (action.type) {
    case FETCH_WEBHOOKS_REQUEST:
    case UPDATE_WEBHOOK_REQUEST:
    case DELETE_WEBHOOK_REQUEST:
    case CREATE_WEBHOOK_REQUEST:
      return {
        ...state,
        error: null,
        loading: loadingReducer(state.loading, action),
      }

    case FETCH_WEBHOOKS_SUCCESS:
      const { webhooks, applicationId } = action.payload
      return {
        ...state,
        loading: loadingReducer(state.loading, action),
        error: null,
        data: {
          ...state.data,
          [applicationId]: webhooks,
        },
      }
    case UPDATE_WEBHOOK_SUCCESS:
      const { webhook: updatedWebhook } = action.payload

      const previousWebhooks = state.data?.[action.payload.applicationId] || []

      return {
        ...state,
        loading: loadingReducer(state.loading, action),
        error: null,
        data: {
          ...state.data,
          [action.payload.applicationId]: previousWebhooks.map((webhook) =>
            webhook.id === updatedWebhook.id ? updatedWebhook : webhook,
          ),
        },
      }

    case DELETE_WEBHOOK_SUCCESS: {
      const { webhookId } = action.payload

      const previousWebhooks2 = state.data?.[action.payload.applicationId] || []

      return {
        ...state,
        loading: loadingReducer(state.loading, action),
        error: null,
        data: {
          ...state.data,
          [action.payload.applicationId]: previousWebhooks2.filter(
            (webhook) => webhook.id !== webhookId,
          ),
        },
      }
    }

    case CREATE_WEBHOOK_SUCCESS:
      const { webhook } = action.payload

      return {
        ...state,
        loading: loadingReducer(state.loading, action),
        error: null,
        data: {
          ...state.data,
          [action.payload.applicationId]: [
            ...(state.data?.[action.payload.applicationId] || []),
            webhook,
          ],
        },
      }

    case FETCH_WEBHOOKS_FAILURE:
    case UPDATE_WEBHOOK_FAILURE:
    case CREATE_WEBHOOK_FAILURE:
    case DELETE_WEBHOOK_FAILURE:
      return {
        ...state,
        loading: loadingReducer(state.loading, action),
        error: action.payload.error,
      }

    default:
      return state
  }
}
