import { connect } from 'react-redux'

import { RootState } from '../../../../modules/reducer'
import { DELETE_TEAM_REQUEST } from '../../../../modules/team/actions'
import {
  getCurrentTeam,
  isUserTeamRole,
} from '../../../../modules/team/selectors'
import { getLoading, isLoadingType } from '../../../../modules/user/selectors'
import TeamDangerZone from './TeamDangerZone'
import {
  MapDispatch,
  MapDispatchProps,
  MapStateProps,
} from './TeamDangerZone.types'

const mapState = (state: RootState): MapStateProps => ({
  team: getCurrentTeam(state),
  isOwnerRole: isUserTeamRole(state, ['OWNER']),
  isLoading: isLoadingType(getLoading(state), DELETE_TEAM_REQUEST),
})

const mapDispatch = (_dispatch: MapDispatch): MapDispatchProps => ({
  // nothing here yet
})

export default connect(mapState, mapDispatch)(TeamDangerZone)
