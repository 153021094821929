import React, { useCallback } from 'react'
import { useTranslation } from 'react-i18next'

import { Button, Header } from '@pluggyai/ui'

import { openHubspotChat } from '../../../../../lib/hubspot'
import { TrackEventName } from '../../../../../modules/analytics/events'
import { track } from '../../../../../modules/analytics/utils'
import { isTeamProSubscription } from '../../../../../modules/team/types'
import { SubscriptionTag } from '../../../../Navbar/SubscriptionTag'
import { Props } from './TeamSubscriptionDetailSection.types'

import './TeamSubscriptionDetailSection.css'

const EVENT_LOCATION =
  'TeamSettingsPage TeamSubscriptionSection TeamSubscriptionDetailSection'

const TeamSubscriptionDetailSection = ({ team }: Props) => {
  const { t } = useTranslation()

  const isPro = team && isTeamProSubscription(team)

  const proSubscriptionContactI18nKey =
    'settings.team.subscription.pro.contact.submit'
  const proSubscriptionContactText = t(proSubscriptionContactI18nKey)

  const handleProSubscriptionContactButtonClick = useCallback(() => {
    openHubspotChat('settings.team.subscription.pro')

    track(TrackEventName.LINK_CLICKED, {
      i18nKey: proSubscriptionContactI18nKey,
      text: proSubscriptionContactText,
      location: EVENT_LOCATION,
    })
  }, [proSubscriptionContactText])

  const freeSubscriptionButtonI18nKey =
    'settings.team.subscription.free.contact.submit'
  const freeSubscriptionButtonText = t(freeSubscriptionButtonI18nKey)

  const handleFreeSubscriptionContactButtonClick = useCallback(() => {
    openHubspotChat('settings.team.subscription.free')

    track(TrackEventName.BUTTON_CLICKED, {
      i18nKey: freeSubscriptionButtonI18nKey,
      text: freeSubscriptionButtonText,
      location: EVENT_LOCATION,
    })
  }, [freeSubscriptionButtonText])

  return (
    <div className="TeamSubscriptionDetailSection">
      <div className={'heading'}>
        <div>
          <Header as={'h3'}>{t('settings.team.subscription.header')}</Header>
        </div>
        <SubscriptionTag team={team} />
      </div>

      <div className={'description'}>
        {isPro ? (
          <p className="pro subscription-text">
            {t('settings.team.subscription.pro.paragraph')}
            <p>
              {t('settings.team.subscription.pro.help')}{' '}
              <Button link onClick={handleProSubscriptionContactButtonClick}>
                {proSubscriptionContactText}
              </Button>
            </p>
          </p>
        ) : (
          <div className="free">
            <p className={'subscription-text'}>
              <span className={'callToUpgrade'}>
                {t('settings.team.subscription.free.callToUpgrade')}{' '}
              </span>
              {t('settings.team.subscription.free.paragraph')}
            </p>

            <Button
              primary
              className={'sales-button'}
              size="small"
              link
              onClick={handleFreeSubscriptionContactButtonClick}
            >
              {freeSubscriptionButtonText}
            </Button>
          </div>
        )}
      </div>
    </div>
  )
}

export default React.memo(TeamSubscriptionDetailSection)
