import { captureException } from '@sentry/react'
import i18next from 'i18next'
import { all, call, put, select, takeEvery } from 'redux-saga/effects'

import { TrackEventName } from '../analytics/events'
import { track } from '../analytics/utils'
import { addNotificationAction } from '../notification/actions'
import { NotificationOptions } from '../notification/types'
import { getCurrentTeam } from '../team/selectors'
import { Team } from '../team/types'
import { getUser } from '../user/selectors'
import { User } from '../user/types'
import {
  SUBMIT_NO_CODE_REQUEST,
  SUBMIT_NO_CODE_SUCCESS,
  submitNoCodeFailure,
  SubmitNoCodeRequestAction,
  submitNoCodeSuccess,
} from './actions'

const THREE_SECONDS = 3000

function* handleSubmitNoCodeRequest(action: SubmitNoCodeRequestAction) {
  const { companyName, insightsRequested } = action.payload

  try {
    const user: User | null = yield select(getUser)

    const team: Team | null = yield select(getCurrentTeam)

    yield call(
      () =>
        // we create a promise to make the function async to properly manage the loading state
        new Promise<void>((resolve, reject) => {
          setTimeout(() => {
            // if the call does not resolve in at in 3 seconds, we reject the promise

            reject(
              new Error(
                'Segment request timed out and the form was not submitted',
              ),
            )
          }, THREE_SECONDS)

          track(
            TrackEventName.NO_CODE_LANDING_REQUEST_SUBMITTED,
            {
              companyName,
              insightsRequested,
              user,
              team,
            },
            undefined,
            resolve,
          )
        }),
    )

    yield put(submitNoCodeSuccess())
  } catch (error) {
    const notificationOptions: NotificationOptions = {
      level: 'error',
      title: i18next.t('team.no-code.form.toast.error.title'),
      message: i18next.t('team.no-code.form.toast.error.message'),
      duration: 4000,
    }
    yield put(addNotificationAction(notificationOptions))

    error.message = `Could not submit No Code: ${error.message}`
    captureException(error, {
      contexts: {
        error: {
          action,
          object: error,
          notificationOptions,
        },
      },
    })

    yield put(submitNoCodeFailure(error.message))
  }
}

function* handleSubmitNoCodeSuccess() {
  yield put(
    addNotificationAction({
      title: i18next.t('team.no-code.form.toast.success.title'),
      message: i18next.t('team.no-code.form.toast.success.message'),
      duration: 4000,
    }),
  )
}

export function* noCodeSaga() {
  yield all([
    takeEvery(SUBMIT_NO_CODE_REQUEST, handleSubmitNoCodeRequest),
    takeEvery(SUBMIT_NO_CODE_SUCCESS, handleSubmitNoCodeSuccess),
  ])
}
