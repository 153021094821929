import { Auth0Result } from './types'

const AUTH0_KEY = 'auth0'

export function retrieveAuth0AuthenticationResult(): Auth0Result | null {
  const auth0Json = localStorage.getItem(AUTH0_KEY)
  if (!auth0Json) {
    return null
  }
  return JSON.parse(auth0Json)
}

export function storeAuth0AuthenticationResult(auth0Result: Auth0Result): void {
  localStorage.setItem(AUTH0_KEY, JSON.stringify(auth0Result))
}

export function clearAuth0Authentication(): void {
  localStorage.removeItem(AUTH0_KEY)
}
