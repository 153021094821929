import React, { useCallback, useEffect, useState } from 'react'
import { useTranslation } from 'react-i18next'

import { TrackEventName } from '../../modules/analytics/events'
import { track } from '../../modules/analytics/utils'
import { TeamFields, TeamFieldsErrors } from '../../modules/team/types'
import { ActionNeededContainer } from '../ActionNeededContainer'
import { CompanyRoleInput } from '../CompanyRoleInput'
import {
  CompanyRoleFormErrors,
  PlatformsFormErrors,
} from '../CompanyRolePage/CompanyRolePage.types'
import { LoadingPage } from '../LoadingPage'
import { TeamForm } from '../TeamForm'
import { Props } from './OnboardingPage.types'
import {
  validateCompanyRoleErrors,
  validateCreateTeamFormErrors,
  validatePlatformsErrors,
} from './utils'

import './OnboardingPage.css'

const INITIAL_TEAM_FIELDS: TeamFields = {
  name: '',
  imageUrl: '',
  members: [
    {
      email: '',
      role: 'MEMBER',
    },
  ],
}

const OnboardingPage = ({
  teams,
  isLoading,
  error,
  userTeamMemberRole,
  acceptedTermsAndConditions,
  onCreateTeam,
  onAcceptTermsRequest,
  onUpdateUser,
}: Props) => {
  const { t } = useTranslation()

  const [isSubmitting, setIsSubmitting] = useState(false)
  const [formErrors, setFormErrors] = useState<
    TeamFieldsErrors & CompanyRoleFormErrors & PlatformsFormErrors
  >({})
  const [teamValues, setTeamValues] = useState(INITIAL_TEAM_FIELDS)

  const [userCompanyRole, setUserCompanyRole] = useState<string | undefined>()

  const [developerUserPlatforms, setDeveloperUserPlatforms] = useState<
    string[] | undefined
  >()

  const createTeamButtonI18nKey = 'team.form.action.create'
  const createTeamButtonText = t(createTeamButtonI18nKey)

  const trackFormValidationError = useCallback((errors: TeamFieldsErrors) => {
    track(TrackEventName.FORM_VALIDATION_ERRORS, {
      fields: Object.keys(errors),
      location: 'OnboardingPage',
    })
  }, [])

  const trackCreateSubmit = useCallback(() => {
    track(TrackEventName.BUTTON_CLICKED, {
      location: 'OnboardingPage',
      text: createTeamButtonText,
      i18nKey: createTeamButtonI18nKey,
    })
  }, [createTeamButtonText])

  const handleValuesChange = useCallback(
    (fieldName: keyof TeamFields, value: unknown) => {
      // clear field error, if any
      if (formErrors[fieldName]) {
        delete formErrors[fieldName]
        setFormErrors({ ...formErrors })
      }
      // set field value
      setTeamValues({ ...teamValues, [fieldName]: value })
    },
    [teamValues, formErrors],
  )

  const handleCreateTeamButtonClick = useCallback(
    (event: React.FormEvent) => {
      event.preventDefault()

      if (isSubmitting || isLoading) {
        // is already loading/submitting, wait
        return
      }

      trackCreateSubmit()

      const { formErrors: teamFormErrors, errorsCount: teamErrorsCount } =
        validateCreateTeamFormErrors(teamValues)

      const companyRoleErrors = validateCompanyRoleErrors(userCompanyRole)

      const platformsErrors =
        userCompanyRole === 'DEVELOPER'
          ? validatePlatformsErrors(developerUserPlatforms)
          : undefined

      const currentFormErrors = {
        ...teamFormErrors,
        companyRole: companyRoleErrors,
        platforms: platformsErrors,
      }

      const errorsCount =
        teamErrorsCount +
        (companyRoleErrors ? 1 : 0) +
        (platformsErrors ? 1 : 0)

      setFormErrors(currentFormErrors)

      if (errorsCount > 0) {
        // got errors, can't proceed
        trackFormValidationError(currentFormErrors)
        return
      }

      // first, trigger request to accept user terms
      // later, when this completes, we trigger the request to create the team
      setIsSubmitting(true)
      onAcceptTermsRequest()
    },
    [
      isSubmitting,
      isLoading,
      trackCreateSubmit,
      teamValues,
      userCompanyRole,
      developerUserPlatforms,
      onAcceptTermsRequest,
      trackFormValidationError,
    ],
  )

  useEffect(() => {
    if (!isSubmitting || !acceptedTermsAndConditions || !userCompanyRole) {
      return
    }

    // had just termsAccepted updated, proceed to create the team
    setIsSubmitting(false)
    onCreateTeam(teamValues)
    onUpdateUser({
      companyRole: userCompanyRole,
      platforms:
        userCompanyRole === 'DEVELOPER' ? developerUserPlatforms : undefined,
    })
  }, [
    acceptedTermsAndConditions,
    isSubmitting,
    onCreateTeam,
    onUpdateUser,
    teamValues,
    userCompanyRole,
    developerUserPlatforms,
  ])

  const hasFormErrors =
    Object.values(formErrors).filter((value) => value).length > 0

  const handleCompanyRoleChange = useCallback(
    (role?: string) => {
      // clear field error, if any
      if (formErrors['companyRole']) {
        delete formErrors['companyRole']
      }
      setUserCompanyRole(role)
    },
    [formErrors],
  )

  const handlePlatformsChange = useCallback(
    (platforms?: string[]) => {
      // clear field error, if any
      if (formErrors['platforms']) {
        delete formErrors['platforms']
      }

      setDeveloperUserPlatforms(platforms)
    },
    [formErrors],
  )

  if (!teams) {
    // avoid displaying page until initial teams data is loaded
    // if there are any teams, we'll directly redirect (from redux saga)
    return <LoadingPage />
  }

  return (
    <ActionNeededContainer
      page={'OnboardingPage'}
      error={error}
      disabled={hasFormErrors}
      loading={isLoading || isSubmitting}
      onSubmitButtonClick={handleCreateTeamButtonClick}
      title={t('team.onboarding.title')}
      subtitle={t('team.onboarding.subtitle')}
      buttonText={createTeamButtonText}
      showConsentSection
    >
      <TeamForm
        onSubmit={handleCreateTeamButtonClick}
        onFieldChange={handleValuesChange}
        values={teamValues}
        errors={formErrors}
        isSubmitting={isLoading}
        userTeamMemberRole={userTeamMemberRole}
        fields={['name', 'imageUrl', 'members']}
        userCompanyRoleInput={
          <CompanyRoleInput
            onRoleChange={handleCompanyRoleChange}
            onPlatformsChange={handlePlatformsChange}
            companyRoleError={formErrors.companyRole}
            platformsError={formErrors.platforms}
          />
        }
      />
    </ActionNeededContainer>
  )
}

export default React.memo(OnboardingPage)
