import { useCallback } from 'react'
import { useTranslation } from 'react-i18next'

import { Dropdown, Menu } from 'semantic-ui-react'

type Props = {
  as: typeof Dropdown.Item | typeof Menu.Item
  onLogout: () => void
}

const LogoutItem = ({ as: Wrapper, onLogout }: Props): JSX.Element => {
  const { t } = useTranslation()

  const handleLogoutClick = useCallback(() => {
    onLogout()
  }, [onLogout])

  return (
    <Wrapper onClick={handleLogoutClick}>
      {t('navbar.profile-options.action.logout')}
    </Wrapper>
  )
}

export { LogoutItem }
