import { connect } from 'react-redux'

import { submitNoCodeRequest } from '../../modules/noCode/actions'
import { RootState } from '../../modules/reducer'
import { getTheme } from '../../modules/user/selectors'
import NoCodePage from './NoCodePage'
import {
  MapDispatch,
  MapDispatchProps,
  MapStateProps,
} from './NoCodePage.types'

const mapState = (state: RootState): MapStateProps => ({
  theme: getTheme(state),
})

const mapDispatch = (dispatch: MapDispatch): MapDispatchProps => ({
  onSubmit: ({ companyName, insightsRequested }) =>
    dispatch(submitNoCodeRequest({ companyName, insightsRequested })),
})

export default connect(mapState, mapDispatch)(NoCodePage)
