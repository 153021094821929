import { Application } from '../../modules/application/types'
import { getApplicationItemsCount } from '../../modules/application/utils'
import { ItemsStats } from '../../modules/stats/types'

type ApplicationOrderFunction = (a: Application, b: Application) => number

export function applicationsByCreatedAtDescendingOrder(): ApplicationOrderFunction {
  return (a: Application, b: Application) =>
    // most recently created first
    a.createdAt > b.createdAt ? -1 : 1
}

/**
 *  sort applications by environment (first production, then the rest)
 * @returns
 */
export function applicationsByEnvironment(): ApplicationOrderFunction {
  return (a: Application, b: Application) => {
    return a.environment === 'PRODUCTION' && b.environment !== 'PRODUCTION'
      ? -1
      : 1
  }
}

export function applicationsByItemsCountDescendingOrder(
  itemsStatsByApplicationId: Record<string, ItemsStats | undefined>,
): ApplicationOrderFunction {
  return (a: Application, b: Application) => {
    const applicationAItemsCount = getApplicationItemsCount(
      a,
      itemsStatsByApplicationId,
    )
    const applicationBItemsCount = getApplicationItemsCount(
      b,
      itemsStatsByApplicationId,
    )

    if (applicationAItemsCount === null || applicationBItemsCount === null) {
      // some or both itemsCount not yet available, don't affect order
      return 0
    }

    // most itemsCount first
    return applicationAItemsCount > applicationBItemsCount ? -1 : 1
  }
}
