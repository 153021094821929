import { useCallback } from 'react'
import { useTranslation } from 'react-i18next'

import { Button } from '@pluggyai/ui'
import { Modal } from 'semantic-ui-react'

import { TrackEventName } from '../../../modules/analytics/events'
import { track } from '../../../modules/analytics/utils'
import { CloseIcon } from '../../Icon'
import { Props } from './ConfirmSaveChangesModal.types'

import './ConfirmSaveChangesModal.css'

export const ConfirmSaveChangesModal = ({
  onSaveChanges,
  isOpen,
  onCancel,
}: Props): JSX.Element => {
  const { t } = useTranslation()

  const cancelButtonI18nKey = 'customization.save-changes-modal.action.cancel'
  const cancelButtonText = t(cancelButtonI18nKey)

  const saveButtonI18nKey = 'customization.save-changes-modal.action.save'
  const saveButtonText = t(saveButtonI18nKey)

  const handleCancelButtonClick = useCallback(() => {
    track(TrackEventName.BUTTON_CLICKED, {
      location: 'CustomizationPage ConfirmSaveChangesModal',
      text: cancelButtonText,
      i18nKey: cancelButtonI18nKey,
    })
    onCancel()
  }, [cancelButtonText, onCancel])

  const handleSaveButtonClick = useCallback(() => {
    track(TrackEventName.BUTTON_CLICKED, {
      location: 'CustomizationPage ConfirmSaveChangesModal',
      text: saveButtonText,
      i18nKey: saveButtonI18nKey,
    })
    onSaveChanges()
  }, [onSaveChanges, saveButtonText])

  return (
    <Modal
      open={isOpen}
      closeIcon={<CloseIcon />}
      className={'ConfirmSaveChangesModal'}
      onClose={onCancel}
    >
      <Modal.Header>{t('customization.save-changes-modal.title')}</Modal.Header>
      <Modal.Actions>
        <p>{t('customization.save-changes-modal.message')}</p>
        <Button secondary onClick={handleCancelButtonClick}>
          {cancelButtonText}
        </Button>
        <Button primary onClick={handleSaveButtonClick}>
          {saveButtonText}
        </Button>
      </Modal.Actions>
    </Modal>
  )
}
