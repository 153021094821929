import { memo, useCallback, useEffect, useMemo, useState } from 'react'
import { Trans, useTranslation } from 'react-i18next'

import { Alert, Button } from '@pluggyai/ui'
import type { CreateWebhook, UpdateWebhook, Webhook } from 'pluggy-sdk'
import { Header } from 'semantic-ui-react'

import { PLUGGY_WEBHOOKS_DOCS_URL } from '../../../../lib/constants/urls'
import { TrackEventName } from '../../../../modules/analytics/events'
import { track } from '../../../../modules/analytics/utils'
import { PlusIcon } from '../../../Icon'
import { Props } from './ApplicationWebhooks.types'
import { sortByCreatedAtAscending } from './utils'
import { WebhookInput } from './WebhookInput'
import { validateWebhookParameters } from './WebhookInput/utils'
import { WebhooksDisabledPopup } from './WebhooksDisabledPopup'

import './ApplicationWebhooks.css'

const ApplicationWebhooks = ({
  application,
  webhooks,
  userCanCreateAndEditWebhooks,
  error,
  onCreate,
  onUpdate,
  onDelete,
  loading,
}: Props) => {
  const [isCreatingWebhook, setIsCreatingWebhook] = useState(false)
  const [isSubmitting, setIsSubmitting] = useState(false)

  const { t } = useTranslation()

  const addWebhookButtonI18nKey =
    'application.page.webhooks.actions.add-webhook'
  const addWebhookButtonText = t(addWebhookButtonI18nKey)

  const handleCreate = useCallback(
    (newWebhook: CreateWebhook) => {
      setIsSubmitting(true)
      onCreate(application.id, newWebhook)
    },
    [application.id, onCreate],
  )

  useEffect(() => {
    if (loading || !isSubmitting || error) {
      return
    }
    setIsCreatingWebhook(false)
    setIsSubmitting(false)
  }, [error, isSubmitting, loading])

  const handleAddWebhookButtonClick = useCallback(() => {
    track(TrackEventName.BUTTON_CLICKED, {
      location: 'ApplicationWebhooks',
      text: addWebhookButtonText,
      i18nKey: addWebhookButtonI18nKey,
    })

    setIsCreatingWebhook(true)
  }, [addWebhookButtonText])

  const handleUpdateWebhook = useCallback(
    (id: string, webhookUpdateValues: UpdateWebhook) =>
      onUpdate(id, application.id, webhookUpdateValues),
    [application.id, onUpdate],
  )

  const handleDeleteWebhook = useCallback(
    (webhookId: string): void => onDelete(webhookId, application.id),
    [application.id, onDelete],
  )

  const handleCancelCreatingWebhook = useCallback(
    () => setIsCreatingWebhook(false),
    [],
  )

  const sortedWebhooksByCreationDateAscending = useMemo(
    () => sortByCreatedAtAscending(webhooks),
    [webhooks],
  )

  const handleValidateWebhookParameters = useCallback(
    ({ url, event }: Pick<Webhook, 'url' | 'event'>) =>
      validateWebhookParameters(url, event, webhooks),
    [webhooks],
  )

  const trackWebhooksDocsClick = useCallback(
    (event: React.MouseEvent<HTMLAnchorElement>) => {
      track(TrackEventName.LINK_CLICKED, {
        location: 'ApplicationWebhooks',
        linkTo: PLUGGY_WEBHOOKS_DOCS_URL,
        text: (event.target as HTMLDivElement).textContent,
      })
    },
    [],
  )

  return (
    <div className={'ApplicationWebhooks'}>
      <div className={'header-container'}>
        <div className={'header'}>
          <Header as={'h3'}>{t('application.page.webhooks.title')}</Header>

          <p className={'description'}>
            <Trans
              i18nKey={'application.page.webhooks.description'}
              components={{
                a: (
                  // we don't need content inside the anchor because we are
                  // interpolating the element
                  // eslint-disable-next-line jsx-a11y/anchor-has-content
                  <a
                    href={PLUGGY_WEBHOOKS_DOCS_URL}
                    target={'_blank'}
                    rel={'noreferrer'}
                    onClick={trackWebhooksDocsClick}
                  />
                ),
              }}
            />
          </p>
        </div>
        <WebhooksDisabledPopup>
          <Button
            className={'add-webhook'}
            disabled={!userCanCreateAndEditWebhooks}
            onClick={handleAddWebhookButtonClick}
            text
            size={'small'}
          >
            <PlusIcon
              className={userCanCreateAndEditWebhooks ? '' : 'disabled'}
            />
            {addWebhookButtonText}
          </Button>
        </WebhooksDisabledPopup>
      </div>
      {error && <Alert type={'error'} message={error} />}
      {application.enabled ? (
        <>
          {sortedWebhooksByCreationDateAscending?.map((webhook) => (
            <WebhookInput
              userCanEdit={userCanCreateAndEditWebhooks}
              key={webhook.id}
              webhook={webhook}
              onUpdate={handleUpdateWebhook}
              onDelete={handleDeleteWebhook}
              validateWebhookParameters={handleValidateWebhookParameters}
            />
          ))}
          {isCreatingWebhook && (
            <WebhookInput
              userCanEdit={userCanCreateAndEditWebhooks}
              webhook={null}
              onCreate={handleCreate}
              onCancel={handleCancelCreatingWebhook}
              validateWebhookParameters={handleValidateWebhookParameters}
            />
          )}
        </>
      ) : (
        <div className="disabled-application-placeholder">
          {t('application.page.webhooks.actions.disabled-application.message')}
        </div>
      )}
    </div>
  )
}

export default memo(ApplicationWebhooks)
