import React from 'react'

import './Tag.css'

type TagProps = {
  text: string
  type?: 'info' | 'success'
  className?: string
}

const Tag = ({ type, text, className }: TagProps) => (
  <div className={`Tag ${type} ${className || ''}`}>{text}</div>
)

export default React.memo(Tag)
