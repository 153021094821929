import { AxiosResponse } from 'axios'

import {
  Application,
  CreateApplicationRequest,
  UpdateApplicationRequest,
} from '../../modules/application/types'
import { BaseService } from './BaseService'

export class ApplicationService extends BaseService {
  async getApplications(
    teamId?: string,
  ): Promise<AxiosResponse<Application[]>> {
    return await this.getServiceInstance().get(this.urls.GET_APPLICATIONS, {
      params: { teamId },
    })
  }

  async getApplication(id: string): Promise<AxiosResponse<Application>> {
    const url = this.replaceUrlParams(this.urls.GET_APPLICATION, { id })
    return await this.getServiceInstance().get(url)
  }

  async createApplication(
    createApplicationFields: CreateApplicationRequest,
  ): Promise<AxiosResponse<Application>> {
    return await this.getServiceInstance().post(
      this.urls.POST_APPLICATION,
      createApplicationFields,
    )
  }

  async updateApplication(
    id: string,
    updateApplicationFields: UpdateApplicationRequest,
  ): Promise<AxiosResponse<Application>> {
    const updateApplicationUrl = this.replaceUrlParams(
      this.urls.PATCH_APPLICATION,
      { id },
    )
    return await this.getServiceInstance().patch(
      updateApplicationUrl,
      updateApplicationFields,
    )
  }

  async deleteApplication(id: string): Promise<void> {
    const deleteApplicationUrl = this.replaceUrlParams(
      this.urls.DELETE_APPLICATION,
      { id },
    )
    return await this.getServiceInstance().delete(deleteApplicationUrl)
  }
}
