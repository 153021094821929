import React from 'react'
import { useTranslation } from 'react-i18next'

import { formatDistance } from 'date-fns'

import { Avatar } from '../../Avatar'
import { ListSection } from '../../ListSection'
import { Props } from './RecentActivity.types'

import './RecentActivity.css'

const RecentActivity = ({ activityItems, isLoading }: Props) => {
  const { t } = useTranslation()

  return (
    <div className={'RecentActivity'}>
      <ListSection
        title={t('recentActivity.title')}
        data={activityItems || []}
        isLoading={isLoading}
        renderElement={(activityItem, i): JSX.Element => {
          const { date, message, pictureUrl } = activityItem
          const timeSince = formatDistance(date, new Date())
          return (
            <div key={`activity-${i}`} className={'activity-item-row'}>
              <div className={'activity-item'}>
                <Avatar pictureUrl={pictureUrl} />
                <span className={'message'}>{message}</span>
              </div>
              <span className={'time'}>{timeSince}</span>
            </div>
          )
        }}
        EmptyPlaceholder={
          <div className={'activity-empty'}>{t('recentActivity.empty')}</div>
        }
      />
    </div>
  )
}

export default React.memo(RecentActivity)
