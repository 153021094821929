import { captureException } from '@sentry/react'
import { AxiosResponse } from 'axios'
import i18next from 'i18next'
import type { Connector } from 'pluggy-js'
import { all, call, put, select, takeEvery } from 'redux-saga/effects'

import { ConnectorsService } from '../../lib/api/ConnectorsService'
import { CustomizationService } from '../../lib/api/CustomizationService'
import { getAuth0AccessToken } from '../auth0/selectors'
import { uploadLogoImageSuccess } from '../logoImage/actions'
import { addNotificationAction } from '../notification/actions'
import { NotificationOptions } from '../notification/types'
import { getCurrentTeam } from '../team/selectors'
import { Team } from '../team/types'
import {
  FETCH_CONNECTORS_REQUEST,
  FETCH_CUSTOMIZATION_REQUEST,
  fetchConnectorsFailure,
  FetchConnectorsRequestAction,
  fetchConnectorsSuccess,
  fetchCustomizationFailure,
  FetchCustomizationRequestAction,
  fetchCustomizationSuccess,
  UPDATE_CUSTOMIZATION_REQUEST,
  updateCustomizationFailure,
  UpdateCustomizationRequestAction,
  updateCustomizationSuccess,
} from './actions'
import { getCustomization } from './selectors'
import { Customization, UpdateCustomizationValues } from './types'

function* handleFetchCustomizationRequest(
  _action: FetchCustomizationRequestAction,
) {
  try {
    const accessToken: string = yield select(getAuth0AccessToken)
    if (!accessToken) {
      throw new Error('No access token in state, can not fetch customization')
    }
    const customizationService = new CustomizationService(accessToken)

    const currentTeam: Team | null = yield select(getCurrentTeam)

    const { data: customization }: AxiosResponse<Customization> = yield call(
      () => customizationService.getCustomization(currentTeam?.id),
    )

    yield put(fetchCustomizationSuccess(customization))
  } catch (error) {
    const errorMessage = i18next.t('customization.error.fetch')
    yield put(fetchCustomizationFailure(errorMessage))
  }
}

function* handleUpdateCustomizationRequest(
  action: UpdateCustomizationRequestAction,
) {
  const { updateCustomizationFields } = action.payload
  try {
    const accessToken: string = yield select(getAuth0AccessToken)
    const customizationService = new CustomizationService(accessToken)
    const currentCustomization: Customization | null = yield select(
      getCustomization,
    )
    if (!currentCustomization) {
      throw new Error("No customization in state, can't update")
    }
    const currentTeam: Team | null = yield select(getCurrentTeam)

    const updateCustomizationValues: UpdateCustomizationValues = {
      connectorIds: updateCustomizationFields.connectorIds,
      welcomeText: updateCustomizationFields.welcomeText,
      logoImageUrl: !updateCustomizationFields.logoImageUrl
        ? undefined
        : updateCustomizationFields.logoImageUrl,
      primaryColor: !updateCustomizationFields.primaryColor
        ? undefined
        : updateCustomizationFields.primaryColor,
      borderRadius:
        updateCustomizationFields.borderRadius !== undefined
          ? Number(updateCustomizationFields.borderRadius)
          : undefined,
      welcomeStepButtonKey: updateCustomizationFields.welcomeStepButtonKey
        ? updateCustomizationFields.welcomeStepButtonKey
        : undefined,
      connectFormStepButtonKey:
        updateCustomizationFields.connectFormStepButtonKey
          ? updateCustomizationFields.connectFormStepButtonKey
          : undefined,
    }

    // submit update request
    const { data: updatedCustomization }: AxiosResponse<Customization> =
      yield call(() =>
        customizationService.updateCustomization(
          currentTeam?.id,
          updateCustomizationValues,
        ),
      )

    yield put(
      addNotificationAction({
        title: i18next.t('customization.form.success.update.title'),
        message: i18next.t('customization.form.success.update.message'),
        duration: 4000,
        level: 'succeed',
      }),
    )

    yield put(updateCustomizationSuccess(updatedCustomization))
    yield put(uploadLogoImageSuccess(null))
  } catch (error) {
    const notificationOptions: NotificationOptions = {
      title: i18next.t('customization.form.error.update.title'),
      message: i18next.t('customization.form.error.update.message'),
      level: 'error',
    }
    yield put(addNotificationAction(notificationOptions))
    error.message = `Could not update customization: ${error.message}`
    captureException(error, {
      contexts: {
        error: {
          action,
          object: error,
          notificationOptions,
        },
      },
    })

    yield put(updateCustomizationFailure(error.message))
  }
}

function* handleFetchConnectorsRequest(_: FetchConnectorsRequestAction) {
  try {
    const accessToken: string = yield select(getAuth0AccessToken)

    const connectorsService = new ConnectorsService(accessToken)

    const currentTeam: Team | null = yield select(getCurrentTeam)

    if (!currentTeam) {
      throw new Error('No current team in state, can not get Connectors')
    }

    const { data: connectors }: AxiosResponse<Connector[]> = yield call(() =>
      connectorsService.get(currentTeam.id),
    )
    yield put(fetchConnectorsSuccess(connectors))
  } catch (error) {
    yield put(fetchConnectorsFailure(error.message))
  }
}

export function* customizationSaga() {
  yield all([
    takeEvery(FETCH_CUSTOMIZATION_REQUEST, handleFetchCustomizationRequest),
    takeEvery(UPDATE_CUSTOMIZATION_REQUEST, handleUpdateCustomizationRequest),
    takeEvery(FETCH_CONNECTORS_REQUEST, handleFetchConnectorsRequest),
  ])
}
