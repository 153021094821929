import React, { useCallback } from 'react'
import { useTranslation } from 'react-i18next'

import { Form, Input } from '@pluggyai/ui'

import { TeamFields } from '../../modules/team/types'
import { Props } from './TeamForm.types'
import { TeamMembersEmailsInput } from './TeamMembersEmailsInput'

import './TeamForm.css'

const TeamForm = ({
  userTeamMemberRole,
  onSubmit,
  values,
  onFieldChange,
  errors,
  isSubmitting,
  isEdit = false,
  isEditAllowed = true,
  fields,
  className,
  userCompanyRoleInput,
}: Props) => {
  const { t } = useTranslation()

  const isFieldIncluded = useCallback(
    (field: keyof TeamFields) => !fields || fields.includes(field),
    [fields],
  )

  return (
    <Form className={className || 'TeamForm'} onSubmit={onSubmit}>
      {isFieldIncluded('name') && (
        <Input
          label={t('team.form.field.name.label')}
          type="text"
          onChange={({ value }) => onFieldChange('name', value)}
          value={values.name}
          error={errors.name}
          disabled={isSubmitting || !isEditAllowed}
        />
      )}
      {isFieldIncluded('imageUrl') && (
        <Input
          label={t('team.form.field.imageUrl.label')}
          type="text"
          onChange={({ value }) => onFieldChange('imageUrl', value)}
          value={values.imageUrl}
          error={errors.imageUrl}
          disabled={isSubmitting || !isEditAllowed}
          optionalMessage={t('general.form.optional-message')}
        />
      )}
      {userCompanyRoleInput}
      {isFieldIncluded('members') && (
        <TeamMembersEmailsInput
          value={values.members}
          errors={errors.members}
          isSubmitting={isSubmitting}
          onFieldChange={onFieldChange}
          userTeamMemberRole={userTeamMemberRole}
          isEdit={isEdit}
        />
      )}
      {/* Hidden input to allow submitting with "enter" key */}
      <input type="submit" style={{ display: 'none' }} />
    </Form>
  )
}

export default React.memo(TeamForm)
