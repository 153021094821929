import React, { useCallback, useEffect, useRef, useState } from 'react'
import { useTranslation } from 'react-i18next'

import { Header, Loader } from 'semantic-ui-react'

import { TrackEventName } from '../../../../modules/analytics/events'
import { track } from '../../../../modules/analytics/utils'
import { ApplicationFieldsErrors } from '../../../../modules/application/types'
import {
  Team,
  TeamFields,
  TeamFieldsErrors,
} from '../../../../modules/team/types'
import { isStringsArray } from '../../../../utils/validation'
import { Avatar } from '../../../Avatar'
import { Section } from '../../../Section'
import { TeamForm, validateTeamField } from '../../../TeamForm'
import { Props } from './TeamDetailsSection.types'

import './TeamDetailsSection.css'

const initialTeamFields = (team: Team | null): TeamFields | null =>
  team && {
    name: team.name,
    imageUrl: team.imageUrl || '',
    members: [],
  }

const FORM_AUTO_SUBMIT_TIMEOUT_MS = 1000 // 1 second

const TeamDetailsSection = ({
  userTeamMemberRole,
  team,
  isLoading,
  isSubmitting,
  isFeaturesLimited,
  onUpdateTeam,
}: Props) => {
  const { t } = useTranslation()

  const [formErrors, setFormErrors] = useState<TeamFieldsErrors>({})
  const [teamValues, setTeamValues] = useState<TeamFields | null>()

  useEffect(() => {
    // set new team values every time
    // the team changes
    setTeamValues(initialTeamFields(team))
  }, [team])

  const trackUpdateSubmit = useCallback(() => {
    track(TrackEventName.TEAM_DETAILS_UPDATE_SUBMIT)
  }, [])

  const trackFormValidationError = useCallback(
    (errors: ApplicationFieldsErrors) => {
      track(TrackEventName.FORM_VALIDATION_ERRORS, {
        fields: Object.keys(errors),
        location: 'TeamDetailsSection',
      })
    },
    [],
  )

  const formSubmit = useCallback(
    (teamValues_: TeamFields) => {
      if (!team) {
        // no team present
        return
      }
      if (isLoading) {
        // is already loading/submitting, wait
        return
      }

      trackUpdateSubmit()

      // validate fields values
      // TODO: each field should "register" and define it's own validator? to support custom types per field
      const currentFormErrors: TeamFieldsErrors = {}
      let errorsCount = 0
      for (const [field, value] of Object.entries(teamValues_)) {
        let fieldError: string | string[] | undefined
        if (isStringsArray(value)) {
          const valueJoined = value.join(',')
          const errorsJoined = validateTeamField(
            field as keyof TeamFields,
            valueJoined,
          )
          const fieldErrors = errorsJoined?.split(',')
          if (fieldErrors?.some((fieldError_) => fieldError_.length > 0)) {
            errorsCount++
          }

          fieldError = fieldErrors
        } else {
          fieldError = validateTeamField(field as keyof TeamFields, value)

          if (fieldError) {
            errorsCount++
          }
        }

        if (fieldError) {
          // using 'as any' here as a hack to support both 'string' or 'string[]'
          // fieldError values.
          // TODO rework/improve this, maybe using separated validations per field
          currentFormErrors[field as keyof TeamFields] = fieldError as any
        }
      }

      setFormErrors(currentFormErrors)
      if (errorsCount > 0) {
        // got errors, can't proceed
        trackFormValidationError(currentFormErrors)
        return
      }

      onUpdateTeam(team.id, teamValues_)
    },
    [
      team,
      onUpdateTeam,
      isLoading,
      trackUpdateSubmit,
      trackFormValidationError,
    ],
  )

  const submitTimeout = useRef<number>()

  const handleSubmit = useCallback(
    (event: React.FormEvent) => {
      event.preventDefault()
      if (!teamValues) {
        return
      }
      if (submitTimeout.current) {
        clearTimeout(submitTimeout.current)
      }
      formSubmit({ ...teamValues })
    },
    [formSubmit, teamValues],
  )

  const handleValuesChange = useCallback(
    (fieldName: keyof TeamFields, value: unknown) => {
      if (!teamValues) {
        // no team present
        return
      }
      // clear field error, if any
      if (formErrors[fieldName]) {
        delete formErrors[fieldName]
        setFormErrors({ ...formErrors })
      }
      // set field value
      const newTeamValues = { ...teamValues, [fieldName]: value }
      setTeamValues(newTeamValues)

      // set timer to auto-submit
      if (submitTimeout.current) {
        clearTimeout(submitTimeout.current)
      }
      submitTimeout.current = window.setTimeout(() => {
        formSubmit(newTeamValues)
      }, FORM_AUTO_SUBMIT_TIMEOUT_MS)
    },
    [teamValues, formErrors, formSubmit],
  )

  return (
    <Section className={'TeamDetailsSection'}>
      {isLoading ? (
        <div className={'widget-section-loader'}>
          <Loader size="big" active />
        </div>
      ) : (
        team &&
        teamValues && (
          <>
            <div className={'team-profile'}>
              <Avatar pictureUrl={team.imageUrl} className={'large '} />
              <div className={'profile'}>
                <Header as={'h4'} className={'ellipsis'}>
                  {team.name}
                </Header>
                <span>
                  {t('settings.team.details.members', {
                    count: team.members.length,
                  })}
                </span>
              </div>
            </div>
            <TeamForm
              className={'TeamUpdateForm'}
              onSubmit={handleSubmit}
              onFieldChange={handleValuesChange}
              values={teamValues}
              errors={formErrors}
              isSubmitting={isSubmitting}
              isEdit
              isEditAllowed={!isFeaturesLimited}
              userTeamMemberRole={userTeamMemberRole}
              fields={['name', 'imageUrl']}
            />
          </>
        )
      )}
    </Section>
  )
}

export default React.memo(TeamDetailsSection)
