import { AnyAction } from 'redux'

import { LoadingState } from '../loading/types'
import { RootState } from '../reducer'
import { ItemState } from './reducer'
import { ItemWithExecutions } from './types'

export const getState = (state: RootState): ItemState => state.item as ItemState
export const getData = (state: RootState) => getState(state).data
export const getLoading = (state: RootState) => getState(state).loading
export const getError = (state: RootState) => getState(state).error

export const isLoadingType = (state: LoadingState, type: string): boolean =>
  state.some((action: AnyAction) => action.type === type)

export const getItem = (state: RootState): ItemWithExecutions | null => {
  return getState(state).data
}
