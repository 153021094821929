import { mapSubscriptionToPlanName, Team, TeamRole } from '../team/types'
import { User } from '../user/types'
import { TrackEventName } from './events'

const { REACT_APP_SEGMENT_API_KEY: analyticsApiKey = '' } = process.env

let currentTeam: Team | null = null
let currentUserId: string | null = null
let currentAsUser: User | null = null

function getAnalytics(): SegmentAnalytics.AnalyticsJS {
  const { analytics } = window
  if (typeof analytics === 'undefined') {
    throw new Error(
      'Analytics not enabled because `window.analytics` is not present',
    )
  }
  if (!analyticsApiKey) {
    throw new Error('Analytics not enabled due to missing API key.')
  }
  return analytics
}

/**
 * Combine event-specified properties with the current context data
 *
 * @param properties
 */
function combineEventPropertiesWithContext(
  properties?: Record<string, unknown>,
): Record<string, unknown> | undefined {
  const combinedProperties = { ...(properties || {}) }

  if (currentAsUser) {
    combinedProperties.asUserId = currentAsUser.id
  }

  if (!currentTeam) {
    // nothing to combine with
    return combinedProperties
  }

  // set currentTeam
  if (!combinedProperties.team) {
    combinedProperties.team = currentTeam
  }

  // set currentTeamSubscriptionPlanName
  if (!combinedProperties.teamSubscriptionPlanName) {
    combinedProperties.teamSubscriptionPlanName = mapSubscriptionToPlanName(
      currentTeam.subscription,
    )
  }

  // set currentTeamUserRole
  const currentTeamUserRole: TeamRole | undefined = currentTeam.members.find(
    (member) => member.id === currentUserId,
  )?.teamUser.role

  if (currentTeamUserRole && !combinedProperties.teamUser) {
    combinedProperties.teamUser = {
      role: currentTeamUserRole,
    }
  }

  return combinedProperties
}

/**
 * Send 'track' call to analytics.
 *
 * @param name - the name of the event to track
 * @param properties - extra pieces of information of the events to track (optional)
 * @param options - options to pass to Segment (optional)
 * @param callback - function to call when is done, only if we want to use it async (optional)
 */
export function track(
  name: TrackEventName,
  properties?: Record<string, unknown>,
  options?: Record<string, unknown>,
  callback?: () => void,
): void {
  try {
    const analytics = getAnalytics()
    if (typeof analytics.track !== 'function') {
      callback?.()
      return
    }
    analytics.track(
      name,
      combineEventPropertiesWithContext(properties),
      options,
      callback,
    )
  } catch (error) {
    // probably, analytics not enabled, or other issue sending the report
    // ignore this error - if not enabled it was already logged once
    callback?.()
  }
}

/**
 * Send 'identify' call to analytics.
 * Used to tie an user and their actions to a recognizable userId and traits
 *
 * @param userId - the id for the user to identify
 * @param traits - user-related data, such as name, email etc.
 * @param options - options to pass to Segment
 *
 */
export function identify(
  userId?: string,
  traits?: Record<string, unknown>,
  options?: Record<string, unknown>,
): void {
  let analytics: SegmentAnalytics.AnalyticsJS
  try {
    analytics = getAnalytics()
  } catch (error) {
    // probably, analytics not enabled, or other issue sending the report
    // ignore this error - if not enabled it was already logged once
    return
  }

  if (!userId) {
    // only add traits
    analytics.identify(traits, options)
    return
  }

  currentUserId = userId
  analytics.identify(userId, traits, options)
}

/**
 * Helper to identify the logged in user in Segment.
 *
 * @param user
 * @param asUser
 */
export function identifyUserInSegment(user: User, asUser?: User | null): void {
  const { id, email, name, emailVerified, companyRole } = user
  currentAsUser = asUser || null

  identify(id, {
    email,
    name,
    emailVerified,
    companyRole,
    asEmail: asUser?.email,
    asUserId: asUser?.id,
  })
}

/**
 * Send 'group' call to analytics.
 * Used to tie an user and their actions to a recognizable team/organization
 * @see https://segment.com/docs/connections/spec/group/
 *
 * @param groupId - the id for the group/team to identify
 * @param traits - user-related data, such as name, email etc.
 * @param options - options to pass to Segment
 *
 */
function group(
  groupId: string,
  traits?: Record<string, unknown>,
  options?: Record<string, unknown>,
): void {
  try {
    getAnalytics().group(groupId, traits, options)
  } catch (error) {
    // probably, analytics not enabled, or other issue sending the report
    // ignore this error - if not enabled it was already logged once
  }
}

/**
 * Send 'page' call to analytics.
 *
 * @param name - the name of the event to track
 * @param properties - parameters to pass as part of the track / page call
 * @param options - options to pass to Segment
 *
 */
export function page(
  name?: string,
  properties?: Record<string, unknown>,
  options?: Record<string, unknown>,
): void {
  try {
    getAnalytics().page(
      name,
      combineEventPropertiesWithContext(properties),
      options,
    )
  } catch (error) {
    // probably, analytics not enabled, or other issue sending the report
    // ignore this error - if not enabled it was already logged once
  }
}

/**
 * Initial analytics load() call - should be called as early as possible.
 */
export function initAnalytics(): void {
  if (!analyticsApiKey) {
    console.warn('Analytics not enabled due to missing API key')
    return
  }
  let analytics: SegmentAnalytics.AnalyticsJS
  try {
    analytics = getAnalytics()
  } catch (error) {
    console.warn(error.message)
    return
  }
  analytics.load(analyticsApiKey)
}

/**
 * Helper to track Team in Segment as a group the user belongs to
 * @see https://segment.com/docs/connections/spec/group/
 *
 * @param team
 */
export function trackTeamInSegment(team: Team | null): void {
  currentTeam = team

  if (!team) {
    group('')
    return
  }

  const { id, subscription, name, imageUrl, createdAt, members } = team

  group(id, {
    name,
    avatar: imageUrl,
    createdAt,
    employees: members.length,
    plan: mapSubscriptionToPlanName(subscription),
  })
}
