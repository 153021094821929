import { AxiosResponse } from 'axios'

import {
  Customization,
  UpdateCustomizationValues,
} from '../../modules/customization/types'
import { BaseService } from './BaseService'

export class CustomizationService extends BaseService {
  async getCustomization(
    teamId?: string,
  ): Promise<AxiosResponse<Customization>> {
    return await this.getServiceInstance().get(this.urls.GET_CUSTOMIZATION, {
      params: { teamId },
    })
  }

  async updateCustomization(
    teamId: string | undefined,
    updateCustomizationValues: UpdateCustomizationValues,
  ): Promise<AxiosResponse<Customization>> {
    return await this.getServiceInstance().patch(
      this.urls.PATCH_CUSTOMIZATION,
      updateCustomizationValues,
      { params: { teamId } },
    )
  }

  async uploadLogoImage(
    teamId: string,
    logoImage: File,
  ): Promise<AxiosResponse<string>> {
    const formData = new FormData()

    formData.append('logoImage', logoImage)

    return await this.getServiceInstance().put(
      this.urls.PUT_LOGO_IMAGE,
      formData,
      { params: { teamId } },
    )
  }
}
