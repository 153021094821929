import React from 'react'

import { Container } from 'semantic-ui-react'

import { retrieveCurrentTeamId } from '../../modules/team/storage'
import { LoadingPage } from '../LoadingPage'
import { Navbar } from '../Navbar'
import { Props } from './Page.types'

import './Page.css'

const Page = ({ className, children, teams }: Props) => {
  const classNames = ['Page']
  if (className !== undefined) {
    classNames.push(className)
  }

  if (!teams && !retrieveCurrentTeamId()) {
    // teams not yet loaded, and still hadn't any teamId profile selected
    // display loading page until any is defined
    return <LoadingPage />
  }

  return (
    <>
      <Navbar />
      <Container className={classNames.join(' ')}>{children}</Container>
    </>
  )
}

export default React.memo(Page)
