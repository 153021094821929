import React, { useCallback, useState } from 'react'
import { useTranslation } from 'react-i18next'

import { Popup } from '@pluggyai/ui'

import { WarningIcon } from '../../../Icon/WarningIcon'
import { Props } from './ConnectorStatusRow.types'
import { StatusBarContainer } from './StatusBarContainer'
import { getConnectorLetter } from './utils'

import './ConnectorStatusRow.css'

const ConnectorStatusRow = ({ connector, connectorStats }: Props) => {
  const { t } = useTranslation()

  // handle the image loading
  const [imageLoadError, setImageLoadError] = useState<boolean>(false)
  const handleImageLoadError = useCallback(() => {
    setImageLoadError(true)
  }, [])

  const isConnectorOffline = connector?.health.status === 'OFFLINE'

  return (
    <div
      className={`ConnectorStatusRow ${isConnectorOffline ? 'disabled' : ''}`}
    >
      <div className={'connector-data'}>
        <div className={'connector-logo'}>
          {!imageLoadError ? (
            <img
              alt={`${connector?.name} imagen `}
              src={`https://cdn.pluggy.ai/assets/connector-icons/${connector?.id}.svg`}
              onError={handleImageLoadError}
            />
          ) : (
            getConnectorLetter(connector)
          )}
        </div>
        <div className={'connector-name'}>{connector?.name}</div>
        {isConnectorOffline && (
          <Popup
            content={t(
              'connectorsStatus.connectors-status-list.connector-row.offline',
            )}
            on="hover"
            position={'bottom center'}
            mouseLeaveDelay={200}
            trigger={
              /* note: wrapping children into a div to properly hide/show the Popup
              related issue: https://github.com/Semantic-Org/Semantic-UI-React/issues/1413 */
              <div>
                <WarningIcon className={'tooltip'} />
              </div>
            }
          />
        )}
      </div>
      <StatusBarContainer connectorStats={connectorStats} />
    </div>
  )
}

export default React.memo(ConnectorStatusRow)
