export const WEB_PLATFORMS: string[] = [
  'React',
  'Next.js',
  'JQuery',
  'Angular',
  'Vue',
  'Svelte',
  'Javascript',
  'ASP.NET',
  'Django',
  'Rails',
]

export const MOBILE_PLATFORMS: string[] = [
  'React Native',
  'Flutter',
  'Ionic',
  'Kotlin (Android)',
  'Java (Android)',
  'Swift (iOS)',
  'Objective-C (iOS)',
]

export const BACKEND_PLATFORMS: string[] = [
  'Node.js',
  'Java',
  'Python',
  'Flask',
  'PHP',
  '.NET',
  'Go',
  'Ruby',
  'Kotlin',
  'Laravel',
]
