import { AxiosResponse } from 'axios'
import { Connector } from 'pluggy-js'

import { BaseService } from './BaseService'

export class ConnectorsService extends BaseService {
  async get(teamId: string): Promise<AxiosResponse<Connector[]>> {
    const connectorsUrl = this.replaceUrlParams(this.urls.GET_CONNECTORS, {
      teamId,
    })

    return await this.getServiceInstance().get(connectorsUrl)
  }
}
