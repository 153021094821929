import { connect } from 'react-redux'

import { RootState } from '../../modules/reducer'
import {
  getCurrentTeam,
  isFreeTrial,
  teamFreeTrialDaysLeft,
} from '../../modules/team/selectors'
import FreeTrialAlert from './FreeTrialAlert'
import {
  MapDispatch,
  MapDispatchProps,
  MapStateProps,
} from './FreeTrialAlert.types'

const mapState = (state: RootState): MapStateProps => ({
  isFreeTrial: isFreeTrial(state),
  teamFreeTrialDaysLeft: teamFreeTrialDaysLeft(state),
  team: getCurrentTeam(state),
})

const mapDispatch = (_dispatch: MapDispatch): MapDispatchProps => ({
  // nothing here, yet
})

export default connect(mapState, mapDispatch)(FreeTrialAlert)
