import { connect } from 'react-redux'

import { RootState } from '../../modules/reducer'
import { getCurrentTeam } from '../../modules/team/selectors'
import { updateUserRequest } from '../../modules/user/actions'
import { getUserHasClosedFreeTrialExpiredModal } from '../../modules/user/selectors'
import FreeTrialExpiredModal from './FreeTrialExpiredModal'
import {
  MapDispatch,
  MapDispatchProps,
  MapStateProps,
} from './FreeTrialExpiredModal.types'

const mapState = (state: RootState): MapStateProps => ({
  userHasClosedFreeTrialModal: getUserHasClosedFreeTrialExpiredModal(state),
  currentTeam: getCurrentTeam(state),
})

const mapDispatch = (dispatch: MapDispatch): MapDispatchProps => ({
  onFreeTierExpiredModalClose: () =>
    dispatch(
      updateUserRequest({
        uiState: { userHasClosedFreeTrialExpiredModal: true },
      }),
    ),
})

export default connect(mapState, mapDispatch)(FreeTrialExpiredModal)
