import { routerMiddleware } from 'connected-react-router'
import { createBrowserHistory } from 'history'
import { applyMiddleware, compose, createStore } from 'redux'
import { createLogger } from 'redux-logger'
import createSagasMiddleware from 'redux-saga'

import { initHotjar } from '../lib/hotjar'
import { loadIntercom } from '../lib/intercom'
import { initSentry } from '../lib/sentry'
import { initAnalytics } from './analytics/utils'
import { auth0LoginSuccess } from './auth0/actions'
import { retrieveAuth0AuthenticationResult } from './auth0/storage'
import { createRootReducer } from './reducer'
import { rootSaga } from './sagas'
import { saveIsComingFromLandingPageInStorage } from './user/storage'

const isDev = process.env.NODE_ENV === 'development'
const anyWindow = window as Window &
  typeof globalThis & {
    // ignore eslint warning because 'any' type has been defined by Redux
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    __REDUX_DEVTOOLS_EXTENSION_COMPOSE__?: (_: Record<string, unknown>) => any
    getState?: unknown
  }

const composeEnhancers =
  isDev && anyWindow.__REDUX_DEVTOOLS_EXTENSION_COMPOSE__
    ? anyWindow.__REDUX_DEVTOOLS_EXTENSION_COMPOSE__({})
    : compose

// init Sentry error monitoring
initSentry()

// setup analytics
initAnalytics()
// setup hotjar analytics
initHotjar()
// we store the referrer in localStorage to identify if the user comes from the landing page
// note: we need to do it as soon as possible because we could lose the referrer with the auth0 login
saveIsComingFromLandingPageInStorage()

// init Intercom Web chat
loadIntercom()

export const history = createBrowserHistory()
const rootReducer = createRootReducer(history)

const sagasMiddleware = createSagasMiddleware()
const loggerMiddleware = createLogger({
  collapsed: () => true,
  predicate: (_, action) => isDev || action.type.includes('Failure'),
})

const middleware = applyMiddleware(
  sagasMiddleware,
  routerMiddleware(history),
  loggerMiddleware,
)
const enhancer = composeEnhancers(middleware)
const store = createStore(rootReducer, enhancer)

sagasMiddleware.run(rootSaga)

if (isDev) {
  anyWindow.getState = store.getState
}

// retrieve auth0 data from storage -> dispatch initial pluggy login request (ie. get pluggy user data from auth-api)
const auth0result = retrieveAuth0AuthenticationResult()
if (auth0result !== null) {
  // TODO: review/uncomment once refresh token is implemented -
  //  otherwise, the app just stucks when jwt expires which breaks it.
  //  This way, we can at least prompt the user to login again.
  // if (!isJwtExpired(auth0result.accessToken)) {
  store.dispatch(auth0LoginSuccess(auth0result))
  // }
}

export { store }
