import { captureException } from '@sentry/react'
import { AxiosResponse } from 'axios'
import { format, sub } from 'date-fns'
import i18next from 'i18next'
import { all, call, put, select, takeEvery } from 'redux-saga/effects'

import { StatsService } from '../../lib/api/StatsService'
import { getAuth0AccessToken } from '../auth0/selectors'
import { getCurrentTeam } from '../team/selectors'
import { Team } from '../team/types'
import { isCurrentUserTest } from '../user/selectors'
import {
  FETCH_ITEMS_STATS_REQUEST,
  fetchItemsStatsFailure,
  FetchItemsStatsRequestAction,
  fetchItemsStatsSuccess,
  FETCH_CONNECTORS_STATS_REQUEST,
  FetchConnectorsStatsRequestAction,
  fetchConnectorsStatsSuccess,
  fetchConnectorsStatsFailure,
  FETCH_TOTAL_ITEMS_STATS_REQUEST,
  fetchTotalItemsStatsSuccess,
  fetchTotalItemsStatsFailure,
  CHANGE_ITEM_STATS_FILTERS,
  fetchItemsStatsRequest,
  ChangeItemStatsFiltersAction,
} from './actions'
import { getItemStatsFilters } from './selectors'
import {
  ConnectorsStats,
  ConnectorsStatsRequest,
  ItemsStats,
  ItemsStatsRequest,
  ItemStatsFilters,
} from './types'
import { mapExecutionStatusGroupsToExecutionStatuses } from './utils'

const isLocaldev = process.env.NODE_ENV === 'development'
const isDev = window.location.href.includes('.dev')

// substract 1 year from current date in format 'YYYY-MM-DD'
const ITEMS_STATS_ONE_YEAR_AGO_DATE = format(
  sub(new Date(), { years: 1 }),
  'yyyy-MM-dd',
)

function* handleFetchItemsStatsRequest(action: FetchItemsStatsRequestAction) {
  const { request } = action.payload

  const itemsStatsRequestDefault: Partial<ItemsStatsRequest> = {
    // default start: 1 year ago
    startDate: ITEMS_STATS_ONE_YEAR_AGO_DATE,
  }

  const isOverviewPageItemsStats =
    request?.endDate !== undefined && request.startDate !== undefined

  const isTestUser: boolean = yield select(isCurrentUserTest)

  if (isLocaldev || isDev || isTestUser) {
    // default: include sandbox items in localdev or stage Dev environments
    itemsStatsRequestDefault.sandbox =
      request?.sandbox !== undefined ? request.sandbox : true
  }

  const requestWithDefaults: ItemsStatsRequest = {
    ...itemsStatsRequestDefault,
    ...request,
  }

  try {
    const accessToken: string = yield select(getAuth0AccessToken)
    if (!accessToken) {
      throw new Error('No access token in state, can not fetch stats')
    }
    const statsService: StatsService = new StatsService(accessToken)

    const currentTeam: Team | null = yield select(getCurrentTeam)
    if (currentTeam) {
      requestWithDefaults.teamId = currentTeam.id
    }

    const { data }: AxiosResponse<ItemsStats> = yield call(() =>
      statsService.getItemsStats(requestWithDefaults),
    )

    yield put(
      fetchItemsStatsSuccess(
        data,
        request?.applicationId,
        isOverviewPageItemsStats,
      ),
    )
  } catch (error) {
    // In this case when there's an error in one application itemStats request
    // "Go to Demo Application" link will be hidden in all applications,
    // and no errors will be shown in the screen.
    const errorI18nKey = 'dailyItems.error.fetch'
    const errorI18nMessage = i18next.t(errorI18nKey)
    error.message = `Failed to fetch items stats: ${error.message}`
    captureException(error, {
      contexts: {
        error: {
          action,
          errorI18nKey,
          errorI18nMessage,
          object: error,
        },
      },
    })
    yield put(fetchItemsStatsFailure(errorI18nMessage))
  }
}
function* handleFetchTotalItemStatsRequest() {
  const requestForAllItems: ItemsStatsRequest = { sandbox: true }

  try {
    const accessToken: string = yield select(getAuth0AccessToken)
    if (!accessToken) {
      throw new Error('No access token in state, can not fetch stats')
    }
    const statsService: StatsService = new StatsService(accessToken)

    const currentTeam: Team | null = yield select(getCurrentTeam)
    if (currentTeam) {
      requestForAllItems.teamId = currentTeam.id
    }

    const { data }: AxiosResponse<ItemsStats> = yield call(() =>
      statsService.getItemsStats(requestForAllItems),
    )

    yield put(fetchTotalItemsStatsSuccess(data))
  } catch (error) {
    const errorMessage = i18next.t('dailyItems.error.fetch')
    yield put(fetchTotalItemsStatsFailure(errorMessage))
  }
}

const CONNECTORS_STATS_TIME_DEFAULT_DAYS = '24'

function* handleFetchConnectorsStatsRequest(
  action: FetchConnectorsStatsRequestAction,
) {
  const { request } = action.payload

  const connectorsStatsRequestDefault: Partial<ConnectorsStatsRequest> = {
    fromDay: request?.fromDay || CONNECTORS_STATS_TIME_DEFAULT_DAYS,
  }

  const isTestUser: boolean = yield select(isCurrentUserTest)

  if (isLocaldev || isDev || isTestUser) {
    // default: include sandbox items in localdev or stage Dev environments
    connectorsStatsRequestDefault.sandbox =
      request?.sandbox !== undefined ? request.sandbox : true
  }

  const requestWithDefaults: ConnectorsStatsRequest = {
    ...connectorsStatsRequestDefault,
    ...(request || {}),
  }

  try {
    const accessToken: string = yield select(getAuth0AccessToken)
    if (!accessToken) {
      throw new Error('No access token in state, can not fetch stats')
    }
    const statsService: StatsService = new StatsService(accessToken)

    const currentTeam: Team | null = yield select(getCurrentTeam)
    if (currentTeam) {
      requestWithDefaults.teamId = currentTeam.id
    }

    const { data }: AxiosResponse<ConnectorsStats> = yield call(() =>
      statsService.getConnectorsStats(requestWithDefaults),
    )
    yield put(fetchConnectorsStatsSuccess(data))
  } catch (error) {
    const errorMessage = i18next.t('dailyItems.error.fetch')
    yield put(fetchConnectorsStatsFailure(errorMessage))
  }
}

function* handleStatsFilterChange(action: ChangeItemStatsFiltersAction) {
  const previousStatsFilters: ItemStatsFilters = yield select(
    getItemStatsFilters,
  )

  const { request } = action.payload

  const newStatsFilters: ItemStatsFilters = {
    ...previousStatsFilters,
    ...request,
  }

  const {
    startDate,
    endDate,
    application,
    showSandbox,
    selectedConnector,
    selectedExecutionStatusGroups,
  } = newStatsFilters

  yield put(
    fetchItemsStatsRequest({
      applicationId: application.id === 'all' ? undefined : application.id,
      sandbox: showSandbox,
      connectorId:
        selectedConnector.id === 'all' ? undefined : selectedConnector.id,
      startDate: format(startDate, 'yyyy-MM-dd'),
      endDate: format(endDate, 'yyyy-MM-dd'),
      executionStatuses: mapExecutionStatusGroupsToExecutionStatuses(
        selectedExecutionStatusGroups,
      ),
    }),
  )
}

export function* statsSaga() {
  yield all([
    takeEvery(FETCH_ITEMS_STATS_REQUEST, handleFetchItemsStatsRequest),
    takeEvery(
      FETCH_CONNECTORS_STATS_REQUEST,
      handleFetchConnectorsStatsRequest,
    ),
    takeEvery(
      FETCH_TOTAL_ITEMS_STATS_REQUEST,
      handleFetchTotalItemStatsRequest,
    ),
    takeEvery(CHANGE_ITEM_STATS_FILTERS, handleStatsFilterChange),
  ])
}
