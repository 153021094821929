import { action } from 'typesafe-actions'

import { Application, ApplicationFields } from './types'

/// Fetch Applications request

export const FETCH_APPLICATIONS_REQUEST = '[Request] Fetch Applications'
export const FETCH_APPLICATIONS_SUCCESS = '[Success] Fetch Applications'
export const FETCH_APPLICATIONS_FAILURE = '[Failure] Fetch Applications'

export const fetchApplicationsRequest = () => action(FETCH_APPLICATIONS_REQUEST)
export const fetchApplicationsSuccess = (applications: Application[]) =>
  action(FETCH_APPLICATIONS_SUCCESS, { applications })
export const fetchApplicationsFailure = (error: string) =>
  action(FETCH_APPLICATIONS_FAILURE, { error })

export type FetchApplicationsRequestAction = ReturnType<
  typeof fetchApplicationsRequest
>
export type FetchApplicationsSuccessAction = ReturnType<
  typeof fetchApplicationsSuccess
>
export type FetchApplicationsFailureAction = ReturnType<
  typeof fetchApplicationsFailure
>

/// Fetch Application request

export const FETCH_APPLICATION_REQUEST = '[Request] Fetch Application'
export const FETCH_APPLICATION_SUCCESS = '[Success] Fetch Application'
export const FETCH_APPLICATION_FAILURE = '[Failure] Fetch Application'

export const fetchApplicationRequest = (id: string) =>
  action(FETCH_APPLICATION_REQUEST, { id })
export const fetchApplicationSuccess = (application: Application) =>
  action(FETCH_APPLICATION_SUCCESS, { application })
export const fetchApplicationFailure = (error: string) =>
  action(FETCH_APPLICATION_FAILURE, { error })

export type FetchApplicationRequestAction = ReturnType<
  typeof fetchApplicationRequest
>
export type FetchApplicationSuccessAction = ReturnType<
  typeof fetchApplicationSuccess
>
export type FetchApplicationFailureAction = ReturnType<
  typeof fetchApplicationFailure
>

/// Create Application request

export const CREATE_APPLICATION_REQUEST = '[Request] Create Application'
export const CREATE_APPLICATION_SUCCESS = '[Success] Create Application'
export const CREATE_APPLICATION_FAILURE = '[Failure] Create Application'

export const createApplicationRequest = (
  createApplicationFields: ApplicationFields,
  isProductionApplication?: boolean,
  webhookSetup?: boolean,
) =>
  action(CREATE_APPLICATION_REQUEST, {
    createApplicationFields,
    isProductionApplication,
    webhookSetup,
  })
export const createApplicationSuccess = (application: Application) =>
  action(CREATE_APPLICATION_SUCCESS, { application })
export const createApplicationFailure = (error: string) =>
  action(CREATE_APPLICATION_FAILURE, { error })

export type CreateApplicationRequestAction = ReturnType<
  typeof createApplicationRequest
>
export type CreateApplicationSuccessAction = ReturnType<
  typeof createApplicationSuccess
>
export type CreateApplicationFailureAction = ReturnType<
  typeof createApplicationFailure
>

/// Update Application request

export const UPDATE_APPLICATION_REQUEST = '[Request] Update Application'
export const UPDATE_APPLICATION_SUCCESS = '[Success] Update Application'
export const UPDATE_APPLICATION_FAILURE = '[Failure] Update Application'

export const updateApplicationRequest = (
  id: string,
  updateApplicationFields: ApplicationFields,
) =>
  action(UPDATE_APPLICATION_REQUEST, {
    id,
    updateApplicationFields,
  })
export const updateApplicationSuccess = (application: Application) =>
  action(UPDATE_APPLICATION_SUCCESS, { application })
export const updateApplicationFailure = (error: string) =>
  action(UPDATE_APPLICATION_FAILURE, { error })

export type UpdateApplicationRequestAction = ReturnType<
  typeof updateApplicationRequest
>
export type UpdateApplicationSuccessAction = ReturnType<
  typeof updateApplicationSuccess
>
export type UpdateApplicationFailureAction = ReturnType<
  typeof updateApplicationFailure
>

/// Delete Application request

export const DELETE_APPLICATION_REQUEST = '[Request] Delete Application'
export const DELETE_APPLICATION_SUCCESS = '[Success] Delete Application'
export const DELETE_APPLICATION_FAILURE = '[Failure] Delete Application'

export const deleteApplicationRequest = (id: string) =>
  action(DELETE_APPLICATION_REQUEST, { id })
export const deleteApplicationSuccess = (id: string) =>
  action(DELETE_APPLICATION_SUCCESS, { id })
export const deleteApplicationFailure = (error: string) =>
  action(DELETE_APPLICATION_FAILURE, { error })

export type DeleteApplicationRequestAction = ReturnType<
  typeof deleteApplicationRequest
>
export type DeleteApplicationSuccessAction = ReturnType<
  typeof deleteApplicationSuccess
>
export type DeleteApplicationFailureAction = ReturnType<
  typeof deleteApplicationFailure
>

/// Update Application Enabled request

export const UPDATE_APPLICATION_ENABLED_REQUEST =
  '[Request] Update Application Enabled'
export const UPDATE_APPLICATION_ENABLED_SUCCESS =
  '[Success] Update Application Enabled'
export const UPDATE_APPLICATION_ENABLED_FAILURE =
  '[Failure] Update Application Enabled'

export const updateApplicationEnabledRequest = (id: string, enabled: boolean) =>
  action(UPDATE_APPLICATION_ENABLED_REQUEST, { id, enabled })
export const updateApplicationEnabledSuccess = (application: Application) =>
  action(UPDATE_APPLICATION_ENABLED_SUCCESS, { application })
export const updateApplicationEnabledFailure = (error: string) =>
  action(UPDATE_APPLICATION_ENABLED_FAILURE, { error })

export type UpdateApplicationEnabledRequestAction = ReturnType<
  typeof updateApplicationEnabledRequest
>
export type UpdateApplicationEnabledSuccessAction = ReturnType<
  typeof updateApplicationEnabledSuccess
>
export type UpdateApplicationEnabledFailureAction = ReturnType<
  typeof updateApplicationEnabledFailure
>
