import React, { useCallback, useState } from 'react'
import { Trans, useTranslation } from 'react-i18next'

import { Alert, Button, Header, Image } from '@pluggyai/ui'

import { TrackEventName } from '../../modules/analytics/events'
import { track } from '../../modules/analytics/utils'
import { TermsAndConditionsModal } from '../TermsAndConditionsModal'
import { Props } from './ActionNeededContainer.types'

import './ActionNeededContainer.css'

const ActionNeededContainer = ({
  loading,
  error,
  disabled,
  title,
  subtitle,
  children,
  page,
  buttonText,
  onSubmitButtonClick,
  onLogout,
  showConsentSection,
}: Props) => {
  const { t } = useTranslation()

  const [termsAndConditionsModalVisible, setTermsAndConditionsModalVisible] =
    useState(false)

  const logoutButtonI18nKey = 'team.onboarding.logout'
  const logoutButtonText = t(logoutButtonI18nKey)

  const termsAndConditionsI18nKey =
    'team.onboarding.termsAndConditionsAndPrivacyPolicy'
  const termsAndConditionsText = t(termsAndConditionsI18nKey)

  const handleLogoutButtonClick = useCallback(() => {
    track(TrackEventName.BUTTON_CLICKED, {
      location: page,
      text: logoutButtonText,
      i18nKey: logoutButtonI18nKey,
    })
    onLogout()
  }, [logoutButtonText, onLogout, page])

  const handleOpenTermsAndConditionsModal = useCallback(() => {
    track(TrackEventName.BUTTON_CLICKED, {
      location: page,
      text: termsAndConditionsText,
      i18nKey: termsAndConditionsI18nKey,
    })
    setTermsAndConditionsModalVisible(true)
  }, [page, termsAndConditionsText])

  const handleCloseTermsAndConditionsModal = useCallback(() => {
    setTermsAndConditionsModalVisible(false)
  }, [])

  return (
    <div className={`ActionNeededContainer ${page}`}>
      <div className={'logout-container'}>
        <Button className={'logout-button'} onClick={handleLogoutButtonClick}>
          {logoutButtonText}
        </Button>
      </div>
      <div className={'wrapper-container'}>
        <div className={'main-container'}>
          <Image
            src={'/logo-full.png'}
            className={'pluggy-logo'}
            alt={'pluggy logo'}
          />
          {error !== null && (
            <Alert size={'medium'} type={'error'} message={error} />
          )}
          <Header className={'title'} as={'h2'}>
            {title}
          </Header>

          <p className={'subtitle'}>{subtitle}</p>

          {children}

          {showConsentSection && (
            <p className={'terms-and-conditions'}>
              <Trans
                i18nKey={'team.onboarding.consent'}
                values={{ buttonText }}
                components={{
                  a: (
                    // we don't need content inside the anchor because we are
                    // interpolating the element
                    // eslint-disable-next-line jsx-a11y/anchor-has-content
                    <Button link onClick={handleOpenTermsAndConditionsModal} />
                  ),
                }}
              />
            </p>
          )}

          {termsAndConditionsModalVisible && (
            <TermsAndConditionsModal
              onClose={handleCloseTermsAndConditionsModal}
            />
          )}
          <Button
            primary
            onClick={onSubmitButtonClick}
            disabled={disabled}
            loading={loading}
            fluid
          >
            {buttonText}
          </Button>
        </div>
      </div>
    </div>
  )
}

export default React.memo(ActionNeededContainer)
