import i18n from 'i18next'

import { GoToProductionFields } from '../../modules/goToProduction/types'
import { isValidEmail, isValidPhoneNumber } from '../../utils/validation'

const MAX_COMPANY_NAME_LENGTH = 40

const validations: Record<
  keyof GoToProductionFields,
  (fieldName: string, value: unknown) => string | undefined
> = {
  companyName: (fieldName: string, value: unknown): string | undefined => {
    if (typeof value !== 'string') {
      throw new Error('Not a string value')
    }
    if (value.length === 0) {
      return i18n.t('team.form.error.empty', {
        fieldName,
      })
    }
    if (value.length > MAX_COMPANY_NAME_LENGTH) {
      return i18n.t('team.form.error.max-length', {
        fieldName,
        length: MAX_COMPANY_NAME_LENGTH,
      })
    }
    return undefined
  },
  contactInfo: (fieldName: string, value: unknown): string | undefined => {
    if (typeof value !== 'string') {
      throw new Error('Not a string value')
    }
    if (value.length === 0) {
      return i18n.t('team.form.error.empty', {
        fieldName,
      })
    }

    if (!isValidEmail(value) && !isValidPhoneNumber(value)) {
      return i18n.t('team.form.error.format', {
        fieldName,
      })
    }

    return undefined
  },
}

export function validateRequestProductionFields(
  fieldName: keyof GoToProductionFields,
  value: unknown,
): string | undefined {
  const fieldLabel = i18n.t(
    `team.go-to-production.form.field.${fieldName}.label`,
  )
  return validations[fieldName](fieldLabel, value)
}
