import React, { useCallback, useMemo } from 'react'
import { useTranslation } from 'react-i18next'

import { Popup } from '@pluggyai/ui'
import { Card } from 'semantic-ui-react'

import { ApplicationClientKeys } from '../../ApplicationPage/ApplicationClientKeys'
import { ApplicationDemoPreviewLink } from '../../ApplicationPage/ApplicationDemoPreviewLink'
import { DEMO_LINK_MAX_DEVELOPMENT_APPLICATION_ITEMS } from '../../ApplicationPage/ApplicationDemoPreviewLink/ApplicationDemoPreviewLink'
import { ApplicationHeader } from '../../ApplicationPage/ApplicationHeader'
import { ApplicationNoCodeDashboardLink } from '../../ApplicationPage/ApplicationNoCodeDashboardLink'
import { EditIcon } from '../../Icon'
import { Props } from './ApplicationItem.types'

import './ApplicationItem.css'

const ApplicationItem = ({
  application,
  isLoading,
  itemStatsByApplicationId,
  onShowClientSecretClick,
  onGoToApplicationPage,
  onGoToDemoApplicationClick,
  onGoToNoCodeDashboardApplicationClick,
  onCopyClientIdClick,
  onCopyClientSecretClick,
}: Props) => {
  const { t } = useTranslation()

  const { id, environment, isNoCode } = application

  const handleGoToApplicationPageClick = useCallback(
    () => onGoToApplicationPage?.(id),
    [onGoToApplicationPage, id],
  )

  const GoToApplicationPageButton = useMemo(
    () => (
      <Popup
        position="top center"
        trigger={
          <div>
            <EditIcon onClick={handleGoToApplicationPageClick} />{' '}
          </div>
        }
      >
        {t('application.header.edit')}
      </Popup>
    ),
    [handleGoToApplicationPageClick, t],
  )

  const isDemoApplication = environment === 'DEMO'
  const isDevelopmentApplication = environment === 'DEVELOPMENT'

  const applicationItemStats = itemStatsByApplicationId?.[application.id]

  const totalItemsCreated = applicationItemStats
    ? applicationItemStats.itemStats.totalItems
    : '-'

  return (
    <Card
      className={`ApplicationItem environment-${environment.toLowerCase()}`}
      fluid
    >
      <Card.Content>
        <ApplicationHeader
          application={application}
          actions={isDemoApplication ? undefined : GoToApplicationPageButton}
          hasDemoPreviewLink={isDemoApplication} // note: in ApplicationItem, demo link is displayed below
        />
        {!isDemoApplication &&
          onShowClientSecretClick &&
          onCopyClientIdClick &&
          onCopyClientSecretClick && (
            <ApplicationClientKeys
              onShowClientSecretClick={onShowClientSecretClick}
              application={application}
              isLoading={isLoading}
              onCopyClientIdClick={onCopyClientIdClick}
              onCopyClientSecretClick={onCopyClientSecretClick}
            />
          )}
        {isDevelopmentApplication && (
          <div className={'bottom-section'}>
            <p>
              {t('application.development.items-created', {
                itemsCreated: totalItemsCreated,
                maxItems: DEMO_LINK_MAX_DEVELOPMENT_APPLICATION_ITEMS,
              })}
            </p>
            <ApplicationDemoPreviewLink
              application={application}
              onClick={onGoToDemoApplicationClick}
            />
          </div>
        )}
        {isNoCode && (
          <div className={'bottom-section'}>
            <p>
              {t('application.no-code.items-created', {
                itemsCreated: totalItemsCreated,
              })}
            </p>
            <ApplicationNoCodeDashboardLink
              application={application}
              onClick={onGoToNoCodeDashboardApplicationClick}
            />
          </div>
        )}
      </Card.Content>
    </Card>
  )
}
export default React.memo(ApplicationItem)
