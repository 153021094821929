import { connect } from 'react-redux'

import { RootState } from '../../modules/reducer'
import {
  updateUserRequest,
  UPDATE_USER_REQUEST,
} from '../../modules/user/actions'
import {
  getLoading,
  getUser,
  getUserError,
  isLoadingType,
} from '../../modules/user/selectors'
import { UpdateUserFields } from '../../modules/user/types'
import CompanyRolePage from './CompanyRolePage'
import {
  MapDispatch,
  MapDispatchProps,
  MapStateProps,
} from './CompanyRolePage.types'

const mapState = (state: RootState): MapStateProps => ({
  loading: isLoadingType(getLoading(state), UPDATE_USER_REQUEST),
  error: getUserError(state),
  companyRole: getUser(state)?.companyRole,
  userAcceptedTerms: getUser(state)?.termsAccepted,
})

const mapDispatch = (dispatch: MapDispatch): MapDispatchProps => ({
  onUpdateUser: (fields: UpdateUserFields) =>
    dispatch(updateUserRequest(fields)),
})

export default connect(mapState, mapDispatch)(CompanyRolePage)
