import React, { useCallback } from 'react'
import { useTranslation } from 'react-i18next'

import { Input, Button } from '@pluggyai/ui'

import { DeleteIcon } from '../../Icon/DeleteIcon'
import { Props } from './AllowedUrlsInput.types'
import { demoIsOnlyOrigin, isDefaultPluggyDemoOrigin } from './utils'

import './AllowedUrlsInput.css'

const AllowedUrlsInput = ({
  onFieldChange,
  value: currentUrls,
  errors,
  isSubmitting,
  isEditAllowed,
  onAddNewAllowedUrlInput,
  onRemoveAllowedUrlInput,
  isEdit,
}: Props) => {
  const { t } = useTranslation()

  const onAllowedOriginsValueChange = useCallback(
    (value: string[]) => {
      onFieldChange('allowedOrigins', value)
    },
    [onFieldChange],
  )

  const handleOnAddNewUrlInput = useCallback(
    (event: React.MouseEvent<HTMLButtonElement>) => {
      // preventing default & propagation to prevent parent form unwanted 'submit' event
      event.preventDefault()
      event.stopPropagation()
      const newCurrentUrls = [...currentUrls, '']
      onAllowedOriginsValueChange(newCurrentUrls)
      onAddNewAllowedUrlInput()
    },
    [currentUrls, onAllowedOriginsValueChange, onAddNewAllowedUrlInput],
  )

  const onValueChange = useCallback(
    (value: string, index: number) => {
      currentUrls[index] = value.trim()
      onAllowedOriginsValueChange([...currentUrls])
    },
    [currentUrls, onAllowedOriginsValueChange],
  )

  const onUrlRemove = useCallback(
    (index: number) => {
      let newCurrentUrls: string[]
      if (currentUrls.length === 1) {
        // don't delete the only available URL input, just clear it
        currentUrls[0] = ''
        newCurrentUrls = [...currentUrls]
      } else {
        // more than one URL input available, remove it
        newCurrentUrls = [...currentUrls.filter((_, i) => i !== index)]
      }
      onAllowedOriginsValueChange(newCurrentUrls)
      onRemoveAllowedUrlInput()
    },
    [currentUrls, onAllowedOriginsValueChange, onRemoveAllowedUrlInput],
  )

  // if pluggy demo application is the only origin add one
  // empty string origin to prevent not having any input fields.
  const currentUrlsDisplay = demoIsOnlyOrigin(currentUrls)
    ? [...currentUrls, '']
    : currentUrls

  return (
    <div className={'AllowedUrlsInput'}>
      <div className={'allowed-urls-header'}>
        <span>{t('application.form.field.allowedOrigins.label')}</span>
        {isEditAllowed && (
          <Button onClick={handleOnAddNewUrlInput} link type={'button'}>
            {t('application.form.field.allowedOrigins.action.new')}
          </Button>
        )}
      </div>
      {currentUrlsDisplay.map((url, i) => {
        // Filter out only the PLUGGY_DEMO_ORIGIN_URL set by default by the backend
        //  when user is editing allowed origins in created app. Not when creating new application
        //  leaving the extra DEMO origins added by the user. filter was not used because
        //  indices references were lost when using onValueChange().
        if (isEdit && isDefaultPluggyDemoOrigin(url, i)) {
          // if origin is the default Pluggy Demo Origin hide it from the Input array
          return undefined
        }

        return (
          <Input
            key={`urls-${i}`}
            label={t('application.form.field.allowedOrigins.add')}
            type="text"
            onChange={({ value }) => {
              onValueChange(value, i)
            }}
            value={url}
            error={errors?.[i]}
            disabled={isSubmitting || !isEditAllowed}
            icon={
              isEditAllowed && <DeleteIcon onClick={() => onUrlRemove(i)} />
            }
            optionalMessage={
              isEdit ? undefined : t('general.form.optional-message')
            }
          />
        )
      })}
    </div>
  )
}

export default React.memo(AllowedUrlsInput)
